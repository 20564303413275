import { useFormik } from 'formik';
import * as Yup from 'yup';
import { message } from 'antd';
import { createLocationNew } from '../../../../services/locations';

const useLocationForm = (clickedLatLng, addFavorite, setPopupVisible) => {
  return useFormik({
    initialValues: { name: '' },
    validationSchema: Yup.object({
      name: Yup.string().required('¡Por favor ingrese el nombre de la ubicación!'),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (!clickedLatLng) {
        message.error('No se ha seleccionado ninguna ubicación');
        return;
      }

      const newLocation = {
        lat: clickedLatLng.lat,
        lng: clickedLatLng.lng,
        type: 2,
        name: values.name,
      };

      try {
        const success = await createLocationNew(newLocation);
        if (success) {
          addFavorite(newLocation);
          setPopupVisible(false);
          resetForm();
          message.success('¡Ubicación creada exitosamente!');
        } else {
          message.error('No se pudo crear la ubicación');
        }
      } catch (error) {
        console.error('No se pudo crear la ubicación', error);
        message.error('Ocurrió un error al crear la ubicación');
      }
    },
  });
};

export default useLocationForm;