import {
  LOADING_RPT_QUIZZES_FAILED,
  LOADED_DOWNLOAD_EMERGENCY,
  LOADING_DOWNLOAD_EMERGENCY_FAILED,
  LOADING_DOWNLOAD_ROAD_EMERGENCY_FAILED,
  LOADED_DOWNLOAD_ROAD_EMERGENCY,
  LOADING_RPT_DASHBOARD_USERS_FAILED,
  LOADED_RPT_DASHBOARD_USERS,
} from '../redux/actions/actionsType';
import { get } from '../lib/ajax';

export const handleSuccessResponseDownloadEmergencies = (
  response,
  dispatch,
) => {
  if (response.data.payload.running) {
    const intervalId = setInterval(() => {
      checkDownloadStatusDownloadEmergencies(intervalId, dispatch);
    }, 6000);
  } else {
    dispatch({ type: LOADING_RPT_QUIZZES_FAILED });
  }
};

export const checkDownloadStatusDownloadEmergencies = async (
  intervalId,
  dispatch,
) => {
  const r = await get('/dashboard/downloadEmergenciesStatus');
  if (r.data && r?.data?.success) {
    handleDownloadStatusDownloadEmergencies(r, intervalId, dispatch);
  }
};

export const handleDownloadStatusDownloadEmergencies = (
  response,
  intervalId,
  dispatch,
) => {
  const { payload } = response.data;
  const config = payload ? JSON.parse(payload.value) : {};

  if (config.error) {
    dispatch({ type: LOADING_DOWNLOAD_EMERGENCY_FAILED });
    clearInterval(intervalId);
  } else if (config.fileDownloadPath) {
    dispatch({
      type: LOADED_DOWNLOAD_EMERGENCY,
      payload: config.fileDownloadPath,
    });
    clearInterval(intervalId);
  }
};

export const handleRoadEmergencyResponse = (response, dispatch) => {
  if (response.data.payload.running) {
    const intervalId = setInterval(() => {
      checkRoadEmergencyDownloadStatus(intervalId, dispatch);
    }, 6000);
  } else {
    dispatch({ type: LOADING_RPT_QUIZZES_FAILED });
  }
};

export const checkRoadEmergencyDownloadStatus = async (
  intervalId,
  dispatch,
) => {
  const r = await get('/dashboard/downloadRoadEmergenciesStatus');
  if (r.data && r?.data?.success) {
    handleRoadEmergencyDownloadStatusResponse(r, intervalId, dispatch);
  }
};

export const handleRoadEmergencyDownloadStatusResponse = (
  response,
  intervalId,
  dispatch,
) => {
  const { payload } = response.data;
  const config = payload ? JSON.parse(payload.value) : {};

  if (config.error) {
    dispatch({ type: LOADING_DOWNLOAD_ROAD_EMERGENCY_FAILED });
    clearInterval(intervalId);
  } else if (config.fileDownloadPath) {
    dispatch({
      type: LOADED_DOWNLOAD_ROAD_EMERGENCY,
      payload: config.fileDownloadPath,
    });
    clearInterval(intervalId);
  }
};

export const handleDashboardUsersResponse = (response, dispatch) => {
  if (response.data.payload.running) {
    const intervalId = setInterval(() => {
      checkDashboardUsersStatus(intervalId, dispatch);
    }, 6000);
  } else {
    dispatch({ type: LOADING_RPT_DASHBOARD_USERS_FAILED });
  }
};

export const checkDashboardUsersStatus = async (intervalId, dispatch) => {
  const r = await get('/dashboard/reportUsersStatus');
  if (r.data && r?.data?.success) {
    handleDashboardUsersStatusResponse(r, intervalId, dispatch);
  }
};

export const handleDashboardUsersStatusResponse = (
  response,
  intervalId,
  dispatch,
) => {
  const { payload } = response.data;
  const config = payload ? JSON.parse(payload.value) : {};

  if (config.error) {
    dispatch({ type: LOADING_RPT_DASHBOARD_USERS_FAILED });
    clearInterval(intervalId);
  } else if (config.fileDownloadPath) {
    dispatch({
      type: LOADED_RPT_DASHBOARD_USERS,
      payload: config.fileDownloadPath,
    });
    clearInterval(intervalId);
  }
};

export const handleDashboardCompanyResponse = (response, dispatch) => {
  if (response.data.payload.running) {
    const intervalId = setInterval(() => {
      checkDashboardCompanyStatus(intervalId, dispatch);
    }, 6000);
  } else {
    dispatch({ type: LOADING_RPT_DASHBOARD_USERS_FAILED });
  }
};

export const checkDashboardCompanyStatus = async (intervalId, dispatch) => {
  const r = await get('/dashboard/reportCompanyStatus');
  if (r.data && r?.data?.success) {
    handleDashboardUsersStatusResponse(r, intervalId, dispatch);
  }
};
