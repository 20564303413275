import { get, post, put } from '../lib/ajax';
import {
  CHANGE_BROKERS_QUERY,
  LOADED_BROKERS,
  LOADING_CREATE_BROKERS,
  LOAD_BROKERS_LOADING,
  OPEN_FORM_BROKERS,
} from '../redux/actions/actionsType';

export const getBrokers = async (dispatch, data = {}) => {
  dispatch({ type: LOAD_BROKERS_LOADING, payload: true });
  dispatch({ type: CHANGE_BROKERS_QUERY, payload: data.query });
  const r = await post('/brokers/list', data);
  if (r?.data?.success) {
    dispatch({ type: LOADED_BROKERS, payload: r.data.payload });
  }
};

export const getAllBrokers = async () => {
  const r = await get('/brokers/get_all');
  if (r?.data?.success) {
    return r.data.payload.list;
  } else {
    return null;
  }
};

export const getUserManagers = async () => {
  const r = await get('/brokers/usersManagers');
  if (r?.data?.success) {
    return r.data.payload;
  } else {
    return null;
  }
};

export const removeBrokersPag = async (id, dispatch, pagination = {}) => {
  const r = await post('/brokers/removePagination?id=' + id);
  if (r?.data?.success) {
    await getBrokers(dispatch, pagination);

    return true;
  }

  return false;
};

export const createBrokersPag = async (broker, dispatch, pagination = {}) => {
  dispatch && dispatch({ type: LOADING_CREATE_BROKERS, payload: true });
  const r = await post('/brokers/createPagination', broker);

  if (r?.data?.success) {
    dispatch && dispatch({ type: OPEN_FORM_BROKERS, payload: false });
    dispatch && dispatch({ type: LOADING_CREATE_BROKERS, payload: false });
    dispatch && (await getBrokers(dispatch, pagination));

    return true;
  }
  dispatch && dispatch({ type: LOADING_CREATE_BROKERS, payload: false });

  return false;
};

export const updateBrokersPag = async (broker, dispatch, pagination) => {
  dispatch({ type: LOADING_CREATE_BROKERS, payload: true });
  const r = await put('/brokers/updatePagination', broker);

  if (r?.data?.success) {
    dispatch({ type: OPEN_FORM_BROKERS, payload: false });
    dispatch({ type: LOADING_CREATE_BROKERS, payload: false });
    await getBrokers(dispatch, pagination);

    return true;
  }
  dispatch({ type: LOADING_CREATE_BROKERS, payload: false });

  return false;
};
