import React from 'react';
import {
  RideButton,
  RideGlStarOutline,
} from '@rimac-seguros/ride-system-components';

const LocationButtons = ({ setStep }) => {
  return (
    <RideButton
      className='mx-4'
      onClick={(e) => {
        e.stopPropagation();
        setStep(2);
      }}
      glyph={<RideGlStarOutline />}
      glyphPosition='left'
      text='Ubicación favorita'
      variant='text-primary'
    />
  );
};

export default LocationButtons;
