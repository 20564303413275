import { get, post, put } from '../lib/ajax';
import {
  LOADED_ROAD_EMERGENCY,
  LOADING_ROAD_EMERGENCY_UPDATE_POINT,
  LOAD_ROAD_EMERGENCY_LOADING,
} from '../redux/actions/actionsType';
import store from '../redux/store';

export const getListRoadEmergency = async () => {
  const dispatch = store.dispatch;

  try {
    dispatch({ type: LOADED_ROAD_EMERGENCY, payload: [] });
    const r = await get(`/road_emergency/listAll`);

    if (r?.data?.success) {
      dispatch({ type: LOADED_ROAD_EMERGENCY, payload: r.data.payload.list });
    }
  } catch (e) {
    dispatch({ type: LOADED_ROAD_EMERGENCY, payload: [] });
  }
};

export const postListRoadEmergency = async ({ dispatch, data }) => {
  try {
    dispatch({ type: LOADED_ROAD_EMERGENCY, payload: [] });
    const r = await post('/road_emergency/listAll', data);
    if (r?.data?.success) {
      dispatch({ type: LOADED_ROAD_EMERGENCY, payload: r.data.payload.list });
    }
  } catch (e) {
    dispatch({ type: LOADED_ROAD_EMERGENCY, payload: [] });
  }
};

export const getRoadEmergency = async (id, dispatch) => {
  dispatch({ type: LOAD_ROAD_EMERGENCY_LOADING, payload: true });
  const r = await get('/road_emergency/get_road_emergency?id=' + id);
  if (r?.data?.success) {
    dispatch({ type: LOAD_ROAD_EMERGENCY_LOADING, payload: false });

    return r.data.payload;
  }
  dispatch({ type: LOAD_ROAD_EMERGENCY_LOADING, payload: false });
};

export const createRoadEmergency = async (data = {}, dispatch) => {
  dispatch?.({ type: LOADING_ROAD_EMERGENCY_UPDATE_POINT, payload: true });

  try {
    const r = await post('/road_emergency/create', data);

    if (r?.data?.success) {
      dispatch?.({ type: LOADING_ROAD_EMERGENCY_UPDATE_POINT, payload: false });
      getListRoadEmergency();

      return true;
    }
    dispatch?.({ type: LOADING_ROAD_EMERGENCY_UPDATE_POINT, payload: false });

    return false;
  } catch (e) {
    dispatch?.({ type: LOADING_ROAD_EMERGENCY_UPDATE_POINT, payload: false });

    return false;
  }
};

export const updateRoadEmergency = async ({ data = {}, dispatch, filters }) => {
  dispatch?.({ type: LOADING_ROAD_EMERGENCY_UPDATE_POINT, payload: true });

  try {
    const r = await post('/road_emergency/update', data);

    if (r?.data?.success) {
      dispatch?.({ type: LOADING_ROAD_EMERGENCY_UPDATE_POINT, payload: false });
      postListRoadEmergency({ dispatch, data: filters });

      return true;
    }
    dispatch?.({ type: LOADING_ROAD_EMERGENCY_UPDATE_POINT, payload: false });

    return false;
  } catch (e) {
    dispatch?.({ type: LOADING_ROAD_EMERGENCY_UPDATE_POINT, payload: false });

    return false;
  }
};

export const removeRoadEmergency = async ({ id, dispatch, filters }) => {
  try {
    const r = await put(`/road_emergency/remove?id=${id}`);
    if (r?.data?.success) {
      postListRoadEmergency({ dispatch, data: filters });

      return true;
    }

    return false;
  } catch (e) {
    return false;
  }
};

export const postRoadEmergencyPoints = async (points) => {
  const r = await post('/road_emergency/create_list', points);
  return r?.data?.success || false;
};

export const getCircleEmergencies = async () => {
  const r = await post('/road_emergency/get_circle_data', {});

  if (r?.data?.success && r?.data?.payload) {
    return r?.data?.payload?.locationsAffected;
  }

  return false;
};

export const exportEmergencies = async () => {
  const r = await post('/road_emergency/export_all');
  if (r?.data?.success) {
    return r?.data?.payload;
  }

  return false;
};

export const getAirports = async () => {
  const r = await post('/road_emergency/listAirport');

  if (r?.data?.success) {
    return r?.data?.payload?.list || [];
  }

  return [];
};

export const getHarbors = async () => {
  const r = await post('/road_emergency/listHarbor');

  if (r?.data?.success) {
    return r?.data?.payload?.list || [];
  }

  return [];
};
