import { useMemo } from 'react';
import useStaticLayer from '../utils/useStaticLayer';
import GeologicalHazardsLayer from '../layers/GeologicalHazardsLayer/GeologicalHazardsLayer';
import UVLayer from '../layers/UVLayer/UVLayer';
import VolcanoLayer from '../layers/VolcanoLayer/VolcanoLayer';
import EmergenciesLayer from '../layers/EmergenciesLayer/EmergenciesLayer';
import { activeLayer } from '../utils/layers';

const StaticLayer = ({ selectLayer }) => {
  const selectedLayer = useMemo(
    () => activeLayer.find((layer) => layer.id === selectLayer),
    [selectLayer],
  );

  useStaticLayer(selectLayer);

  const LayerComponent = selectedLayer?.component;

  const layerComponents = {
    mapa_PeligrosGeologicos: GeologicalHazardsLayer,
    '03_04_001_03_001_513_0000_00_00': UVLayer,
    '03_04_002_03_001_513_0000_00_00': UVLayer,
    mapa_volcanes: VolcanoLayer,
    emergencias: EmergenciesLayer,
  };

  const RenderedLayerComponent = layerComponents[selectLayer];

  return (
    <>
      {RenderedLayerComponent ? (
        <RenderedLayerComponent selectLayer={selectLayer} />
      ) : null}
      {LayerComponent ? <LayerComponent /> : null}
    </>
  );
};

export default StaticLayer;
