import React from 'react';
import Legend from '../Legend';

const CloudLegend = ({ toggleVisibility }) => {
  return (
    <Legend
      title='Nubes en porcentaje %'
      numbers={[0, 25, 50, 70, 90, 96, 100]}
      descriptions={['Despejado', 'Nublado']}
      toggleVisibility={toggleVisibility}
      type='cloud'
    />
  );
};

export default CloudLegend;
