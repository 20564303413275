import React from 'react';
import { Switch, ColorPicker } from 'antd';

const LimitesWebLayerControls = ({
  showLimitesWebLayer,
  setShowLimitesWebLayer,
}) => {
  return (
    <div className='flex items-center gap-2'>
      <label htmlFor='limitesWebLayerSwitch'>Mostrar Limites Web Layer</label>
      <Switch
        id='limitesWebLayerSwitch'
        checked={showLimitesWebLayer}
        onChange={setShowLimitesWebLayer}
      />
    </div>
  );
};

export default LimitesWebLayerControls;
