import React from 'react';
import './LightningDisplay.css';
import TimelineItem from './TimelineItem';
import { RideGlError } from '@rimac-seguros/ride-system-components';

const timelineData = [
  { time: '1', className: 'oneMin' },
  { time: '2', className: 'twoMin' },
  { time: '3', className: 'threeMin' },
  { time: '4', className: 'fourMin' },
  { time: '10', className: 'tenMin' },
];

const LightningLegend = ({ toggleVisibility }) => {
  return (
    <section className='lightning-legend-container'>
      <h2 className='title'>Rayos en los últimos 5 MIN</h2>
      {toggleVisibility && (
        <button
          onClick={toggleVisibility}
          className='close-button'
          aria-label='Cerrar leyenda'
        >
          <RideGlError size='large' />
        </button>
      )}
      <div className='timelineContainer'>
        {timelineData.map((item, index) => (
          <TimelineItem
            key={index}
            time={item.time}
            className={item.className}
          />
        ))}
      </div>
    </section>
  );
};

export default LightningLegend;
