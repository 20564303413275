import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useScreen } from '../../../../../../../lib/screen';
import { ProfileCompanyContext } from '../..';
import IconArrow from '../icons/IconArrow';
import IconHome from '../icons/IconHome';
import { RideButton } from '@rimac-seguros/ride-system-components';

const Header = ({ tabActive }) => {
  const { isMobileScreen } = useScreen();
  const { setModalUser, setEditingUser, setUserSelected, company_id } =
    useContext(ProfileCompanyContext);

  const totalUsers = useSelector((state) => state.users.totalUsers);
  const isLoading = useSelector((state) => state.users.isLoadingUser);

  const handleOpenModal = () => {
    if (tabActive === 'users') {
      setEditingUser(false);
      setUserSelected({ company_id });
      setModalUser(true);
    }
  };

  const isDisabledButton = useMemo(() => {
    if (tabActive === 'users') return isLoading || totalUsers >= 5;

    return false;
  }, [isLoading, tabActive, totalUsers]);

  return (
    <div className='profile-company__header'>
      <div className='profile-company__header--nav'>
        <IconHome /> <IconArrow /> <span>Inicio</span> <IconArrow />{' '}
        <span className='disabled'>Perfil de la empresa</span>
      </div>
      <div className='profile-company__header--title'>
        <h1>Perfil de la empresa</h1>

        <div className='actions'>
          {tabActive === 'users' && (
            <RideButton
              size={isMobileScreen ? 'small' : 'large'}
              onClick={handleOpenModal}
              disabled={isDisabledButton}
              text='Nuevo usuario'
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
