import React from 'react';
import Legend from '../Legend';

const DailyRainLegend = ({ toggleVisibility }) => {
  return (
    <Legend
      title='Lluvia horaria en mm.'
      numbers={[0, 0.1, 0.2, 0.5, 1, 2, 4, 6, 8, 10, 15, 20, 30, 40, 60]}
      descriptions={['Sin lluvia', 'Lluvia intensa']}
      toggleVisibility={toggleVisibility}
      type='daily-rain'
    />
  );
};

export default DailyRainLegend;
