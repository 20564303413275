import { useState, useEffect } from 'react';
import { useMap, CircleMarker, Popup } from 'react-leaflet';
import axios from 'axios';
import proj4 from 'proj4';

const wmsUrl = process.env.REACT_APP_GEOSERVER_MAPS_NEW;

const proj4EPSG4326 = '+proj=longlat +datum=WGS84 +no_defs';
const proj4EPSG3857 =
  '+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs';

const GeologicalHazardsLayer = () => {
  const map = useMap();
  const [markers, setMarkers] = useState([]);

  const getBounds3857 = () => {
    const bounds = map.getBounds();
    const minLatLng = bounds.getSouthWest();
    const maxLatLng = bounds.getNorthEast();

    const minXY = proj4(proj4EPSG4326, proj4EPSG3857, [
      minLatLng.lng,
      minLatLng.lat,
    ]);
    const maxXY = proj4(proj4EPSG4326, proj4EPSG3857, [
      maxLatLng.lng,
      maxLatLng.lat,
    ]);

    let buffer = 0;
    if (map.getZoom() >= 16 && map.getZoom() <= 18) {
      buffer = 10000;
    } else if (map.getZoom() >= 14 && map.getZoom() < 16) {
      buffer = 100;
    }
    const extraBottomBuffer =
      map.getZoom() >= 12 && map.getZoom() <= 18 ? 8000 : 0;

    return {
      minXY: [minXY[0] - buffer, minXY[1] - buffer - extraBottomBuffer],
      maxXY: [maxXY[0] + buffer, maxXY[1] + buffer],
    };
  };

  const updateBounds = () => {
    const zoomLevel = map.getZoom();
    if (zoomLevel >= 10) {
      const { minXY, maxXY } = getBounds3857();

      const url = `${wmsUrl}?service=WFS&version=1.0.0&request=GetFeature&typeName=cmo%3Amapa_PeligrosGeologicos&outputFormat=application%2Fjson&styles=PeligrosGeologicos&bbox=${minXY[0]},${minXY[1]},${maxXY[0]},${maxXY[1]}`;

      axios
        .get(url)
        .then((response) => {
          const features = response.data.features.map((feature) => ({
            coordinates: feature.geometry.coordinates,
            properties: feature.properties,
          }));
          setMarkers(features);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } else {
      setMarkers([]);
    }
  };

  useEffect(() => {
    const staticLayer = L.tileLayer
      .wms(wmsUrl, {
        layers: 'mapa_PeligrosGeologicos',
        styles: '',
        version: '1.3.0',
        format: 'image/png',
        tileSize: 256,
        transparent: true,
      })
      .addTo(map);

    const handleZoomEnd = () => {
      updateBounds();
      if (map.getZoom() >= 10) {
        map.on('moveend', updateBounds);
      } else {
        map.off('moveend', updateBounds);
      }
    };

    map.on('zoomend', handleZoomEnd);

    return () => {
      map.off('zoomend', handleZoomEnd);
      map.off('moveend', updateBounds);
      map.removeLayer(staticLayer);
    };
  }, [map]);

  const getColorByTipoPeligro = (tipoPeligro) => {
    if (!tipoPeligro) return '#000000';
    const trimmedTipoPeligro = tipoPeligro.trim();
    switch (trimmedTipoPeligro) {
      case 'Caida de rocas':
        return '#ca5afa';
      case 'Flujo':
        return '#ffa066';
      case 'Deslizamiento':
        return '#b28f00';
      case 'Reptacion':
        return '#ffdb4d';
      case 'Erosión Fluvial':
        return '#52cb67';
      case 'Inundacion fluvial':
        return '#5c69ff';
      default:
        return '#000000';
    }
  };

  return (
    <>
      {markers.map((marker) => (
        <CircleMarker
          key={marker.properties.OBJECTID_1}
          center={[marker.properties.LATITUD, marker.properties.LONGITUD]}
          color={getColorByTipoPeligro(marker.properties.TIPO_PELIGRO)}
          fillColor={getColorByTipoPeligro(marker.properties.TIPO_PELIGRO)}
          fillOpacity={0.8}
          radius={6}
        >
          <Popup>
            <div style={{ padding: '10px' }}>
              <strong>Departamento:</strong> {marker.properties.DEPARTAMENTO}
              <br />
              <strong>Provincia:</strong> {marker.properties.PROVINCIA}
              <br />
              <strong>Distrito:</strong> {marker.properties.DISTRITO}
              <br />
              <strong>Tipo de Peligro:</strong> {marker.properties.TIPO_PELIGRO}
              <br />
              <strong>Latitud:</strong> {marker.properties.LATITUD}
              <br />
              <strong>Longitud:</strong> {marker.properties.LONGITUD}
            </div>
          </Popup>
        </CircleMarker>
      ))}
    </>
  );
};

export default GeologicalHazardsLayer;
