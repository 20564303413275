import { Modal, Table, Progress, Select } from 'antd';
import dayjs from 'dayjs';
import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { buildRequestUrl } from '../../utils/tools';
import { RideSpinner } from '@rimac-seguros/ride-system-components';
import { ArrowRightOutlined } from '@ant-design/icons';

const { Option } = Select;
const wsmUrl = process.env.REACT_APP_GEOSERVER_MAPS_NEW;

const generateTimestamps = () => {
  const startDate = dayjs().startOf('day');
  const timestamps = [];
  const totalIntervals = 5 * 24;

  for (let i = 0; i < totalIntervals; i++) {
    const newDate = startDate.add(i, 'hour');
    timestamps.push(newDate.toISOString());
  }

  return timestamps;
};

const ModalTempRainWindForecast = ({
  open,
  onClose,
  bbox,
  shouldFetchData,
  onFetchComplete,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format('DD/MM/YYYY'),
  );

  const arrayTime = useMemo(() => generateTimestamps(), []);

  const availableDates = useMemo(
    () =>
      arrayTime
        .map((time) => dayjs(time).format('DD/MM/YYYY'))
        .filter((value, index, self) => self.indexOf(value) === index),
    [arrayTime],
  );

  console.log('shouldFetchData', shouldFetchData);
  console.log('onFetchComplete', onFetchComplete);

  useEffect(() => {
    if (open && shouldFetchData) {
      const fetchData = async (layer) => {
        const results = await Promise.all(
          arrayTime
            .filter((time) => dayjs(time).format('DD/MM/YYYY') === selectedDate)
            .map(async (time, index) => {
              const params = {
                bbox: bbox,
                styles: '',
                format: 'jpeg',
                request: 'GetFeatureInfo',
                layers: layer,
                query_layers: layer,
                width: 2,
                height: 2,
                x: 1,
                y: 1,
                INFO_FORMAT: 'application/json',
                time: time,
              };

              const requestUrl = buildRequestUrl(wsmUrl, params);

              const response = await axios.get(requestUrl);
              const responseData = response.data;

              setProgress(Math.round(((index + 1) / arrayTime.length) * 100));

              return {
                time: dayjs(time).format('HH:mm'),
                grayIndex:
                  responseData.features && responseData.features.length > 0
                    ? responseData.features[0].properties.GRAY_INDEX !== null
                      ? Math.round(
                          responseData.features[0].properties.GRAY_INDEX,
                        )
                      : 'GRAY_INDEX is null'
                    : 'No data available',
              };
            }),
        );
        return results;
      };

      const fetchAllData = async () => {
        setLoading(true);
        const wdData = await fetchData('wd');
        const tempData = await fetchData('temp');
        const hrData = await fetchData('hr');
        const ppData = await fetchData('pp');
        const wsData = await fetchData('ws');

        const combinedData = arrayTime
          .filter((time) => dayjs(time).format('DD/MM/YYYY') === selectedDate)
          .map((time, index) => ({
            time: dayjs(time).format('HH:mm'),
            wdGrayIndex: wdData[index].grayIndex,
            tempGrayIndex: tempData[index].grayIndex,
            hrGrayIndex: hrData[index].grayIndex,
            ppGrayIndex: ppData[index].grayIndex,
            wsGrayIndex: wsData[index].grayIndex,
          }));

        setData(combinedData);
        setLoading(false);
        onFetchComplete();
      };

      fetchAllData();
    }
  }, [arrayTime, bbox, selectedDate, open, shouldFetchData, onFetchComplete]);

  const getCardinalDirection = (angle) => {
    if (angle === 0) return 'Este';
    if (angle === 90) return 'Norte';
    if (angle === 180) return 'Oeste';
    if (angle === 270) return 'Sur';

    if (angle >= 0 && angle < 90) {
      return 'Noreste';
    } else if (angle >= 90 && angle < 180) {
      return 'Noroeste';
    } else if (angle >= 180 && angle < 270) {
      return 'Suroeste';
    } else {
      return 'Sureste';
    }
  };

  const renderWindDirection = (value) => (
    <>
      {getCardinalDirection(value)} {value}°
      <ArrowRightOutlined style={{ transform: `rotate(${-value}deg)` }} />
    </>
  );

  const renderTemperature = (value) => `${value}°C`;
  const renderPrecipitation = (value) => `${value} mm`;
  const renderHumidity = (value) => `${value} %`;
  const renderWindSpeed = (value) => `${value} km/h`;

  const columns = [
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      fixed: 'left',
    },
    ...data.map((item) => ({
      title: item.time,
      dataIndex: item.time,
      key: item.time,
    })),
  ];

  const dataSource = [
    data.reduce(
      (acc, item) => {
        acc[item.time] = renderPrecipitation(item.ppGrayIndex);
        return acc;
      },
      {
        key: 'pp',
        type: 'Precipitación',
      },
    ),
    data.reduce(
      (acc, item) => {
        acc[item.time] = renderTemperature(item.tempGrayIndex);
        return acc;
      },
      {
        key: 'temp',
        type: 'Temperatura',
      },
    ),
    data.reduce(
      (acc, item) => {
        acc[item.time] = renderHumidity(item.hrGrayIndex);
        return acc;
      },
      {
        key: 'hr',
        type: 'Humedad Relativa',
      },
    ),
    data.reduce(
      (acc, item) => {
        acc[item.time] = renderWindSpeed(item.wsGrayIndex);
        return acc;
      },
      {
        key: 'ws',
        type: 'Velocidad del Viento',
      },
    ),
    data.reduce(
      (acc, item) => {
        acc[item.time] = renderWindDirection(item.wdGrayIndex);
        return acc;
      },
      {
        key: 'wd',
        type: 'Dirección del Viento',
      },
    ),
  ];

  return (
    <Modal
      title='Pronóstico a largo plazo'
      open={open}
      onCancel={onClose}
      width={1200}
      footer={null}
    >
      <Select
        defaultValue={selectedDate}
        style={{ width: 200, marginBottom: 20 }}
        onChange={(value) => setSelectedDate(value)}
      >
        {availableDates.map((date) => (
          <Option key={date} value={date}>
            {date}
          </Option>
        ))}
      </Select>
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <RideSpinner size='large' />
          <Progress percent={progress} strokeColor='#000000' />
        </div>
      ) : (
        <Table
          dataSource={dataSource}
          columns={columns}
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
      )}
    </Modal>
  );
};

export default ModalTempRainWindForecast;
