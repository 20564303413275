import { useState, useEffect, useMemo } from 'react';
import { Card, Divider, notification, Skeleton } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { getServiceDetailItemsById } from '../../../../../services/requestServices';
import {
  generateCertificates,
  updateOnlyParticipants,
} from '../../../../../services/requestServicesItemsDataParticipants';
import { statusAdminColors } from '../../PreventionService/ServiceRequest/components/ServiceRequestList';
import ImageIcon from '../../../../../images/icons/icon_services.svg';
import ButtonSchedule from './ButtonSchedule';
import Workshop from './Workshop';
import ButtonReportParticipants from '../../../RequestServicesItems/ModalCheckParticipants/ButtonReportParticipants';
import DatePicker from '../../../../../common/DatePicker';
import TimePicker from '../../../../../common/TimePicker';
import {
  RideGlFamilySolid,
  RideGlUserSolid,
} from '@rimac-seguros/ride-system-components';

const noData = 'Sin información';
const SERVICE_ROUTE = '/servicio-de-riesgos';

const ServiceDetail = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [selected, setSelected] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [dateValues, setDateValues] = useState({ date: null, time: null });
  const { id, itemId } = useParams();
  const [manegeAccount, setManegeAccount] = useState('');
  const { listRequestService } = useSelector((state) => ({
    listRequestService: state.requestServices.riskItems || [],
  }));
  const dispatch = useDispatch();
  const workshopCourse = useMemo(() => {
    if (selected?.service?.type !== '5') {
      return {};
    }

    const course = selected?.requestServiceItemsData?.find(
      (el) => el.key === 'courses',
    );
    const [courseParse] = Object.values(
      JSON.parse(course?.value ?? '{}') ?? {},
    );

    return course
      ? {
          ...courseParse,
          id: course.id,
          courseKey: courseParse.course_key,
          date: dateValues?.date,
          participantsData: course?.requestServiceItemsDataParticipants,
        }
      : {};
  }, [selected]);

  useEffect(() => {
    let res = selected?.requestServiceItemsData?.find(
      (el) => el.key === 'event_date',
    );
    res = Number(res?.value || 0) * 1000;

    if (res) {
      setDateValues({ date: dayjs(res), time: dayjs(res) });
    }
  }, [selected]);

  useEffect(() => {
    if (selected && listRequestService.length) {
      const managerAccountExit = listRequestService.find(
        (it) => it.requestServiceId === selected?.requestService?.id,
      );
      const u =
        managerAccountExit &&
        managerAccountExit?.requestService?.company?.companiesUsers &&
        managerAccountExit?.requestService?.company?.companiesUsers[0]
          ? managerAccountExit?.requestService?.company?.companiesUsers[0]
          : null;
      if (u) {
        setManegeAccount(`${u?.name} ${u?.last_name1} ${u?.last_name2}`);
      } else {
        selected?.company?.companiesUsers &&
          selected?.company?.companiesUsers[0] &&
          setManegeAccount(selected?.company?.companiesUsers[0]?.name);
      }
    }
  }, [listRequestService, selected]);

  const setBadge = (state) => {
    const { color, status, dot } = statusAdminColors(state);

    return (
      <div
        className='service-detail__header__badge'
        style={{ background: color }}
      >
        <div
          className='service-detail__header__badge__ico'
          style={{ background: dot }}
        ></div>
        <div className='service-detail__header__badge__name'>{status}</div>
      </div>
    );
  };

  useEffect(() => {
    setLoadingData(true);
    if (id) {
      getServiceDetailItemsById(itemId).then((item) => {
        item ? setSelected(item) : navigate(SERVICE_ROUTE);
        setLoadingData(false);
      });
    } else {
      navigate(SERVICE_ROUTE);
    }
  }, [id]);

  const handleChangeDates = (type) => (dates) => {
    setDateValues((prev) => ({
      ...prev,
      [type]: dates,
    }));
  };

  const _handleUpdateParticipants = async (list) => {
    const data = {
      course_key: workshopCourse.courseKey,
      participants: list.map((el) => ({ ...el, state: 1, score: 20 })),
      requestServiceItemDataId: workshopCourse.id,
    };

    const res = await updateOnlyParticipants(data, dispatch);
    if (!res) {
      notification.warning({
        message: 'Hubo un error al agendar fecha',
      });
    } else {
      notification.success({
        message: 'Participantes actualizados correctamente.',
      });
      navigate(SERVICE_ROUTE);
    }
  };

  const onGenerateCertificates = () => {
    generateCertificates(
      {
        course_key: workshopCourse.courseKey,
        requestServiceItemDataId: workshopCourse.id,
      },
      () => {
        getServiceDetailItemsById(itemId)
          .then((item) => {
            item ? setSelected(item) : navigate(SERVICE_ROUTE);
            setLoadingData(false);
          })
          .finally(() => {
            notification.success({
              message: 'Certificados actualizados correctamente.',
            });
          });
      },
      dispatch,
    );
  };

  return (
    <>
      <div className='service-detail'>
        <div className='service-detail__header mb-3 pb-3'>
          {loadingData ? (
            <Skeleton active paragraph={false} title={{ width: '100%' }} />
          ) : (
            <div className='flex flex-col full-width'>
              <div className='flex'>
                <div className='flex items-center flex-1'>
                  <img
                    src={ImageIcon}
                    style={{ cursor: 'pointer' }}
                    alt='risk_back'
                    onClick={() => navigate(SERVICE_ROUTE)}
                  />
                  <div
                    className='service-detail__header__title'
                    style={{ width: '70%' }}
                  >
                    {selected?.service?.name || 'Servicio sin nombre'}
                  </div>
                  {setBadge(selected?.state)}
                </div>
                {selected?.state > 5 ? (
                  <ButtonReportParticipants
                    requestServiceItemDataId={workshopCourse.id}
                    courseKey={workshopCourse.courseKey}
                  />
                ) : null}
              </div>

              {user && user?.rol?.slug === 'admin_rimac' && (
                <div
                  className='service-detail__body__item'
                  style={{ maxWidth: 380 }}
                >
                  <p className='service-detail__body__item__name title-sections'>
                    Agendar fecha
                  </p>
                  <DatePicker
                    placeholder='Seleccionar fecha'
                    onChange={handleChangeDates('date')}
                    disabled={selected?.state}
                    value={dateValues?.date}
                  />
                  <p className='service-detail__body__item__name title-sections'>
                    Hora para agendar
                  </p>
                  <TimePicker
                    placeholder='Seleccionar hora'
                    onChange={handleChangeDates('time')}
                    disabled={selected?.state}
                    value={dateValues?.time}
                  />
                </div>
              )}

              <div className='service-detail__body__item'>
                <p className='service-detail__body__item__name title-sections'>
                  Detalles de la solicitud
                </p>
                <div className='service-detail__body__item__card'>
                  <div
                    className='service-detail__body__item__card__group'
                    style={{ width: '100%' }}
                  >
                    <Card
                      className='service-detail__body__item__card__group__company flex-1'
                      style={{ border: '3px solid #D7DBF5' }}
                    >
                      <div className='service-detail__body__item__card__group__company__header'>
                        <RideGlUserSolid size='large' />
                        <p className='service-detail__body__item__card__group__company__header__title'>
                          Datos del contacto
                        </p>
                      </div>
                      <Divider className='service-detail__body__item__card__group__company__divider' />
                      <div className='service-detail__body__item__card__group__company__text'>
                        <p className='service-detail__body__item__card__group__company__text__name'>
                          Nombre:
                        </p>
                        <p className='service-detail__body__item__card__group__company__text__value'>
                          {selected?.contact || noData}
                        </p>
                      </div>
                      <div className='service-detail__body__item__card__group__company__text'>
                        <p className='service-detail__body__item__card__group__company__text__name'>
                          Celular:
                        </p>
                        <p className='service-detail__body__item__card__group__company__text__value'>
                          {selected?.number || noData}
                        </p>
                      </div>
                      <div className='service-detail__body__item__card__group__company__text'>
                        <p className='service-detail__body__item__card__group__company__text__name'>
                          Dirección:
                        </p>
                        <p className='service-detail__body__item__card__group__company__text__value'>
                          {selected?.address || noData}
                        </p>
                      </div>
                      <div className='service-detail__body__item__card__group__company__text'>
                        <p className='service-detail__body__item__card__group__company__text__name'>
                          Correo electrónico:
                        </p>
                        <p className='service-detail__body__item__card__group__company__text__value'>
                          {selected?.email || noData}
                        </p>
                      </div>
                      <div className='service-detail__body__item__card__group__company__text'>
                        <p className='service-detail__body__item__card__group__company__text__name'>
                          Cargo:
                        </p>
                        <p className='service-detail__body__item__card__group__company__text__value'>
                          {selected?.position || noData}
                        </p>
                      </div>
                      <div className='service-detail__body__item__card__group__company__text'>
                        <p className='service-detail__body__item__card__group__company__text__name'>
                          Fecha de solicitud:
                        </p>
                        <p className='service-detail__body__item__card__group__company__text__value'>
                          {selected?.createdAt
                            ? dayjs(selected?.createdAt).format('DD/MM/YYYY')
                            : noData}
                        </p>
                      </div>
                      {selected?.requestServiceItemsData?.length > 0 ? (
                        selected.requestServiceItemsData.map(
                          (itemData) =>
                            itemData.key === 'event_date' && (
                              <div className='service-detail__body__item__card__group__company__text'>
                                <p className='service-detail__body__item__card__group__company__text__name'>
                                  Fecha agendada:
                                </p>
                                <p className='service-detail__body__item__card__group__company__text__value'>
                                  {itemData?.value
                                    ? dayjs
                                        .unix(itemData?.value)
                                        .format('DD/MM/YYYY')
                                    : noData}
                                </p>
                              </div>
                            ),
                        )
                      ) : (
                        <div className='service-detail__body__item__card__group__company__text'>
                          <p className='service-detail__body__item__card__group__company__text__name'>
                            Fecha agendada:
                          </p>
                          <p className='service-detail__body__item__card__group__company__text__value'>
                            Pendiente
                          </p>
                        </div>
                      )}
                    </Card>

                    <Card
                      className='service-detail__body__item__card__group__company contact flex-1'
                      style={{ border: '3px solid #D7DBF5' }}
                    >
                      <div className='service-detail__body__item__card__group__company__header'>
                        <RideGlFamilySolid size='large' />
                        <p className='service-detail__body__item__card__group__company__header__title'>
                          Contacto gestor RIMAC
                        </p>
                      </div>
                      <Divider className='service-detail__body__item__card__group__company__divider' />
                      <div className='service-detail__body__item__card__group__company__text'>
                        <p className='service-detail__body__item__card__group__company__text__name'>
                          Nombre:
                        </p>
                        <p className='service-detail__body__item__card__group__company__text__value'>
                          {manegeAccount ? manegeAccount : noData}
                        </p>
                      </div>
                      <div className='service-detail__body__item__card__group__company__text'>
                        <p className='service-detail__body__item__card__group__company__text__name'>
                          Celular:
                        </p>
                        <p className='service-detail__body__item__card__group__company__text__value'>
                          {selected?.requestService?.company?.companiesUsers
                            ?.length
                            ? selected?.requestService?.company
                                ?.companiesUsers[0].phone
                            : noData}
                        </p>
                      </div>
                      <div className='service-detail__body__item__card__group__company__text'>
                        <p className='service-detail__body__item__card__group__company__text__name'>
                          Correo electrónico:
                        </p>
                        <p className='service-detail__body__item__card__group__company__text__value'>
                          {selected?.requestService?.company?.companiesUsers
                            ?.length
                            ? selected?.requestService?.company
                                ?.companiesUsers[0].username
                            : noData}
                        </p>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
              {selected?.service?.type === '5' &&
              ['admin_rimac', 'gestor_de_cuenta'].includes(user?.rol?.slug) &&
              selected?.state ? (
                <Workshop
                  courseSelectedId={workshopCourse.id}
                  courseKey={workshopCourse.courseKey}
                  selected={selected}
                  onGenerateCertificates={onGenerateCertificates}
                  onUpdateForm={_handleUpdateParticipants}
                  participants={workshopCourse?.participantsData ?? []}
                />
              ) : null}
            </div>
          )}
        </div>
        {!selected?.state ? (
          <div
            className='mb-3'
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <ButtonSchedule {...dateValues} requestServiceItemId={itemId} />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ServiceDetail;
