import axios from 'axios';
import { get, post } from '../lib/ajax';
import {
  CHANGE_REQUEST_SERVICES_FILTERS,
  LOADED_REQUEST_SERVICES,
  LOADING_CREATE_REQUEST_SERVICES,
  OPEN_FORM_REQUEST_SERVICES,
  UPDATE_REQUEST_SERVICES_ROW,
  LOADED_REQUEST_SERVICES_MANAGER_ACCOUNT,
  LOADED_REQUEST_SERVICES_USERS_CREATE,
  UPDATE_FORM_MULTIPLE_VALUES,
} from '../redux/actions/actionsType';

import { processDataToStorage } from './requestServices.utils';

export const getMyPolizas = async (companyId) => {
  const r = await get(
    companyId
      ? `/request_services/polizas?companyId=${companyId}`
      : '/request_services/polizas',
  );
  if (r?.data?.success) return r.data.payload.list;
};

export const getServicesOfPoliza = async (polizaId) => {
  const r = await get(
    `/request_services/getServicesOfPoliza?polizaId=${polizaId}`,
  );
  if (r?.data?.success) return r.data.payload;
};

export const getRiskServicesOfPoliza = async (polizaId) => {
  const r = await post(
    `/request_services/getFilteredServicesOfPoliza?polizaId=${polizaId}`,
    {
      serviceType: ['4', '5'],
      op: 'in',
    },
  );
  if (r?.data?.success) return r.data.payload.list;
};

export const getPreventionServicesOfPoliza = async (polizaId) => {
  const r = await post(
    `/request_services/getFilteredServicesOfPoliza?polizaId=${polizaId}`,
    {
      serviceType: ['4', '5', '6'],
      op: 'notIn',
    },
  );
  if (r?.data?.success) return r.data.payload.list;
};

export const getServiceDetailItemsById = async (itemId) => {
  const r = await get(`/request_services/itemId?itemId=${itemId}`);
  if (r?.data?.success) return r.data.payload;
};

export const getMyListRequestServiceItems = async (dispatch, data) => {
  dispatch({
    type: LOADED_REQUEST_SERVICES,
    payload: {
      total: 0,
      pagination: {
        pageSize: 5,
        current: 1,
        page: 1,
        total: 0,
      },
    },
  });
  dispatch({ type: CHANGE_REQUEST_SERVICES_FILTERS, payload: data.filters });
  const r = await post('/request_services/items', data);
  if (r?.data?.success)
    dispatch({ type: LOADED_REQUEST_SERVICES, payload: r.data.payload });
};

export const getParticipantsList = async (courseId) => {
  const r = await get(
    `/request_service_items_data_participants/get_by_course?courseId=${courseId}`,
  );
  if (r?.data?.success) {
    return r.data.payload;
  }
};

export const geRiskRequestServiceItems = async (dispatch, data) => {
  dispatch({
    type: LOADED_REQUEST_SERVICES,
    payload: {
      total: 0,
      pagination: {
        pageSize: 5,
        current: 1,
        page: 1,
        total: 0,
      },
    },
  });
  dispatch({ type: CHANGE_REQUEST_SERVICES_FILTERS, payload: data.filters });
  const r = await post('/request_services/risk_items', data);
  if (r?.data?.success) {
    const { listItems, ...response } = r.data.payload;
    dispatch({
      type: LOADED_REQUEST_SERVICES,
      payload: {
        ...response,
        riskItems: listItems,
      },
    });
  }
};

export const deleteRequestService = async (
  rsId,
  rsiId,
  dispatch,
  data,
  isRisk,
) => {
  try {
    const r = await post(
      `/request_services/delete?rsId=${rsId}&rsiId=${rsiId}`,
    );

    if (r?.data?.success) {
      isRisk
        ? await geRiskRequestServiceItems(dispatch, data)
        : await getMyListRequestServiceItems(dispatch, data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const createRequestServiceWithParticipants = async (
  requestService,
  dispatch,
) => {
  try {
    dispatch?.({ type: LOADING_CREATE_REQUEST_SERVICES, payload: true });
    const r = await post('/request_services/withParticipants', requestService);

    if (r?.data?.success) {
      dispatch?.({ type: OPEN_FORM_REQUEST_SERVICES, payload: false });
      dispatch?.({ type: LOADING_CREATE_REQUEST_SERVICES, payload: false });

      return true;
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch?.({ type: LOADING_CREATE_REQUEST_SERVICES, payload: false });
  }

  return false;
};

export const derivarRequestServicesItem = async (
  requestServiceItemId,
  providerId,
  dispatch,
) => {
  try {
    const r = await get(
      `/request_services/derivar?requestServiceItemId=${requestServiceItemId}&providerId=${providerId}`,
    );

    if (r?.data?.success) {
      dispatch({ type: UPDATE_REQUEST_SERVICES_ROW, payload: r.data.payload });

      return true;
    }
  } catch (error) {
    console.error(error);
  }

  return false;
};

export const getLastService = async (companyId) => {
  try {
    const r = await get(`/request_services/lastService?companyId=${companyId}`);
    if (r?.data?.success) return r.data.payload;
  } catch (error) {
    console.error(error);
  }
};

export const updateRequesServiceInCreate = async (data) => {
  try {
    const r = await post('/request_services/updateServicesData', data);
    return r?.data?.success || false;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getRequestServicesManagerAccounts = async (dispatch) => {
  try {
    const r = await post('/request_services/listManagerAccount');
    if (r?.data?.success)
      dispatch({
        type: LOADED_REQUEST_SERVICES_MANAGER_ACCOUNT,
        payload: r.data.payload,
      });
  } catch (error) {
    console.error(error);
  }
};

export const getRequestServicesUsersCreate = async (dispatch) => {
  try {
    const r = await post('/request_services/listUsersCreateRequestServices');
    if (r?.data?.success)
      dispatch({
        type: LOADED_REQUEST_SERVICES_USERS_CREATE,
        payload: r.data.payload,
      });
  } catch (error) {
    console.error(error);
  }
};

export const getRequestServicesByPoliza = async (polizaId) => {
  try {
    const r = await get(
      `/request_services/requestServices?polizaId=${polizaId}`,
    );
    if (r?.data?.success) return r.data.payload;
  } catch (error) {
    console.error(error);
  }
};

export const getInfoCompany = async (companyId) => {
  try {
    const r = await get(`/request_services/prevention?companyId=${companyId}`);
    if (r?.data?.success) return r.data.payload;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const createRiskRainService = async (data, polizaId) => {
  try {
    const r = await post(
      `/request_services/createRiskRainService?polizaId=${polizaId}`,
      data,
    );
    if (r?.data?.success) return r;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getRiskRainService = async (polizaId, dispatch) => {
  try {
    const r = await get(
      `/request_services/riskRainService?polizaId=${polizaId}`,
    );
    dispatch({ type: UPDATE_FORM_MULTIPLE_VALUES, payload: { polizaId } });

    if (!r?.data?.success) {
      return false;
    }

    if (!r?.data?.payload?.data) {
      return { lastItemData: null, payload: r?.data?.payload };
    }

    const { requestServiceItem } = r.data.payload;
    dispatch({
      type: 'UPDATE_FORM_MULTIPLE_VALUES',
      payload: {
        personalDataForm: {
          contact: requestServiceItem?.contact,
          email: requestServiceItem?.email,
          position: requestServiceItem?.position,
          number: requestServiceItem?.number,
        },
      },
    });

    const rsIdata =
      typeof r.data.payload.data === 'string'
        ? JSON.parse(r.data.payload.data)
        : r.data.payload.data;
    const dataToStorage = processDataToStorage(rsIdata);
    dispatch({ type: 'UPDATE_FORM_MULTIPLE_VALUES', payload: dataToStorage });

    const lastItemData = rsIdata.pop();

    return { lastItemData, payload: r.data.payload };
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const finishRiskRainService = async (polizaId) => {
  try {
    const response = await post(
      `/request_services/finishRiskRainService?polizaId=${polizaId}`,
    );
    return response;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const downloadRiskRainServiceReport = async (polizaId) => {
  try {
    const r = await post(
      `/request_services/downloadRiskRainServiceReport?polizaId=${polizaId}`,
    );
    if (r?.data?.success) return r?.data?.payload?.url;
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const presignedRainRiskService = async (file, key) => {
  try {
    const payload = {
      type: file.type,
      name: key,
    };

    const { data } = await post(
      '/request_services/presignedRainRiskService',
      payload,
    );
    const url = data?.payload?.url;

    if (url) {
      try {
        await axios.put(url, file);
        return true;
      } catch (error) {
        console.error('Error in put:', error);
      }
    }

    throw Error('Error');
  } catch (error) {
    console.error(error);
    return false;
  }
};
