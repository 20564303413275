import React, { useCallback, useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import {
  RideActionButton,
  RideGlPlus,
  RideGlIndeterminate,
} from '@rimac-seguros/ride-system-components';

const ZoomControls = () => {
  const map = useMap();
  const zoomControlRef = useRef(null);

  useEffect(() => {
    if (zoomControlRef.current) {
      L.DomEvent.disableClickPropagation(zoomControlRef.current);
    }
  }, []);

  const handleZoom = useCallback(
    (zoomFunction) => (event) => {
      event.preventDefault();
      zoomFunction();
    },
    [],
  );

  const zoomIn = handleZoom(() => map.zoomIn());
  const zoomOut = handleZoom(() => map.zoomOut());

  return (
    <div
      ref={zoomControlRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        bottom: 90,
        right: 10,
        zIndex: 1000,
        gap: '0.5rem',
      }}
    >
      <RideActionButton
        tooltipText='Aumentar zoom'
        glyph={<RideGlPlus />}
        placement='left-center'
        onClick={zoomIn}
        size='small'
      />
      <RideActionButton
        tooltipText='Disminuir zoom'
        glyph={<RideGlIndeterminate />}
        placement='left-center'
        onClick={zoomOut}
        size='small'
      />
    </div>
  );
};

export default ZoomControls;
