import React, { useEffect, useRef, useState } from 'react';
import { Drawer, Typography, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  RideGlSearchSolid,
  RideGlToolsSolid,
  RideActionButton,
} from '@rimac-seguros/ride-system-components';
import GoogleSearch from '../tools/GoogleSearch';
import Ruler from '../tools/Ruler';
import L from 'leaflet';
import TileLayerSelect from '../tools/TileLayerSelect';
import LimitesWebLayerControls from '../tools/LimitesWebLayerControls';
import SearchFavorites from '../tools/Favorites/SearchFavorites';
import { MAP_CHANGE_BLOCKED_CLICK } from '../../../redux/actions/actionsType';
import DrawerCumulusTools from '../tools/Cumulus/CumulusTools';
import DrawerMyLayers from '../tools/DrawerMyLayers';
import Roads from '../tools/Roads';

const { Title, Paragraph } = Typography;

const ToolButtons = ({
  selectTileLayer,
  setSelectTileLayer,
  showLimitesWebLayer,
  setShowLimitesWebLayer,
  activateCumulusTool,
  setActivateCumulusTool,
}) => {
  const dispatch = useDispatch();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerContent, setDrawerContent] = useState('');
  const buttonsRef = useRef(null);
  const isMapBlocked = useSelector((state) => state.maps.isBlockedClick);

  const toggleDrawer = (content) => {
    setDrawerContent(content);
    setDrawerVisible(!drawerVisible);
  };

  const toggleBlockedClick = (blocked) => {
    dispatch({ type: MAP_CHANGE_BLOCKED_CLICK, payload: blocked });
  };

  useEffect(() => {
    if (buttonsRef.current) {
      L.DomEvent.disableClickPropagation(buttonsRef.current);
      L.DomEvent.disableScrollPropagation(buttonsRef.current);
    }
  }, []);

  return (
    <>
      <div
        ref={buttonsRef}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          zIndex: 1000,
          display: 'flex',
          gap: '0.5rem',
        }}
      >
        <RideActionButton
          glyph={<RideGlSearchSolid />}
          tooltipText='Buscar'
          placement='left-bottom'
          size='small'
          onClick={() => toggleDrawer('buscar')}
        />
        <RideActionButton
          glyph={<RideGlToolsSolid />}
          tooltipText='Herramientas'
          placement='left-bottom'
          size='small'
          onClick={() => toggleDrawer('herramientas')}
        />
      </div>
      <Drawer
        title={drawerContent === 'buscar' ? 'Buscar' : 'Herramientas'}
        placement='right'
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        mask={false}
      >
        {drawerContent === 'buscar' ? (
          <GoogleSearch />
        ) : (
          <>
            <Title level={4}>Regla</Title>
            <Paragraph>
              Utiliza esta herramienta para medir distancias en el mapa.
            </Paragraph>
            <Ruler />
            <hr />
            <Title level={4}>Seleccionar Capa de Mosaico</Title>
            <Paragraph>
              Elige una capa de mosaico para visualizar en el mapa.
            </Paragraph>
            <TileLayerSelect
              selectTileLayer={selectTileLayer}
              setSelectTileLayer={setSelectTileLayer}
            />
            <hr />
            <Title level={4}>Controles de Limites Web Layer</Title>
            <Paragraph>
              Configura la visualización de los límites de la capa web.
            </Paragraph>
            <LimitesWebLayerControls
              showLimitesWebLayer={showLimitesWebLayer}
              setShowLimitesWebLayer={setShowLimitesWebLayer}
            />
            <hr />
            <Title level={4}>Favoritos</Title>
            <Paragraph>Busca tus favoritos en el mapa.</Paragraph>
            <SearchFavorites />
            <hr />
            <Title level={4}>Herramientas de Cúmulos</Title>
            <Paragraph>
              Activa o desactiva las herramientas de cúmulos.
            </Paragraph>
            <Switch
              checkedChildren='Activar'
              unCheckedChildren='Desactivar'
              onChange={(checked) => setActivateCumulusTool(checked)}
              value={activateCumulusTool}
            />
            {activateCumulusTool && <DrawerCumulusTools />}
            <hr />
            <Title level={4}>Carreteras</Title>
            <Paragraph>Activa o desactiva las capas de carreteras.</Paragraph>
            <Roads />
            <hr />
            <Title level={4}>Mis Capas</Title>
            <Paragraph>
              Carga tus propias capas para visualizar en el mapa.
            </Paragraph>
            <DrawerMyLayers />
            <hr />
            <Title level={4}>Otras Herramientas</Title>
            <Paragraph>
              Aquí se mostrarán otras herramientas que se vayan agregando.
            </Paragraph>
            <Switch
              checkedChildren='Bloquear Popups'
              unCheckedChildren='Desbloquear Popups'
              onChange={toggleBlockedClick}
              value={isMapBlocked}
            />
          </>
        )}
      </Drawer>
    </>
  );
};

export default ToolButtons;
