const CODE_EQUIVALENCE = {
  '01': 'Tránsito Normal',
  '02': 'Tránsito Restringido',
  '03': 'Tránsito Interrumpido',
  '04': 'Por confirmar',
  NORMAL: 'Tránsito Normal',
  RESTRINGIDO: 'Tránsito Restringido',
  INTERRUMPIDO: 'Tránsito Interrumpido',
  Abierto: 'Tránsito Abierto',
  Parcial: 'Tránsito Parcial',
  Cerrado: 'Tránsito Cerrado',
};

export default CODE_EQUIVALENCE;
