import { useEffect, useState } from 'react';
import L from 'leaflet';
import { Marker } from 'react-leaflet';

import PopupMTC from '../LayerRoadEmergency/PopupMTC';
import { getHarbors } from '../../../../services/roadEmergency';
import { getSvgIcon, portTypes } from '../../utils';

const LayerHarbors = () => {
  const [points, setPoints] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [popUpData, setPopUpData] = useState(null);

  const getIcon = (point) =>
    L.divIcon({
      html: getSvgIcon(
        'harbors',
        portTypes.find((ct) => ct.type === point.condicionTransito)?.color ??
          '#00BFFF',
      ),
      iconSize: [27, 38],
      iconAnchor: [13, 38],
      className: 'leaflet-icon-marker-custom',
    });

  useEffect(() => {
    setIsLoading(true);
    getHarbors()
      .then((data) => {
        setPoints(data);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return points.map((point) => (
    <Marker
      key={point.id}
      position={[point.latitud, point.longitud]}
      icon={getIcon(point)}
      eventHandlers={{ click: () => setPopUpData(point) }}
    >
      <PopupMTC isLoading={isLoading} popUpData={popUpData} />
    </Marker>
  ));
};

export default LayerHarbors;
