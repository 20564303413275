import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Popup } from 'react-leaflet';
import CODE_EQUIVALENCE from './codeEquivalence';

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 56, width: 100, margin: '30px auto' }}
    spin
  />
);

const PopupSutran = ({ isLoading, popUpData }) => {
  return (
    <Popup>
      {isLoading ? (
        <div
          style={{
            display: 'grid',
            placeContent: 'center',
            minWidth: '299px',
            minHeight: '352px',
          }}
        >
          <Spin size='large' indicator={antIcon} />
        </div>
      ) : (
        <div className='popup-map'>
          <div className='popup-title'>
            <span style={{ fontWeight: 'bold', color: '#ea8402' }}>
              {'EMERGENCIA VIAL - SUTRAN'}
            </span>
          </div>
          <div
            className='popup-content popup-map-scrollable'
            style={{ maxHeight: 220 }}
          >
            <span className='popup-detail'>
              {popUpData?.fechaEmergencia !== 0 &&
                popUpData?.fechaEmergencia !== '' && (
                  <>
                    <span className='popup-detail-header'>
                      FECHA DEL EVENTO:
                    </span>
                    <span className='popup-detail-content'>
                      {popUpData?.fechaEmergencia}
                    </span>
                  </>
                )}
              {popUpData?.fechaActualizacion &&
                popUpData?.fechaActualizacion !== '' && (
                  <>
                    <span className='popup-detail-header'>
                      FECHA DE ACTUALIZACIÓN:
                    </span>
                    <span className='popup-detail-content'>
                      {popUpData?.fechaActualizacion}
                    </span>
                  </>
                )}
              {popUpData?.codRuta && popUpData?.codRuta !== '' && (
                <>
                  <span className='popup-detail-header'>AFECTACIÓN:</span>
                  <span className='popup-detail-content'>
                    {popUpData?.codRuta}
                  </span>
                </>
              )}
              {popUpData?.ubicacion && popUpData?.ubicacion !== '' && (
                <>
                  <span className='popup-detail-header'>CARRETERA:</span>
                  <span className='popup-detail-content'>
                    {popUpData?.ubicacion}
                  </span>
                </>
              )}
              {popUpData?.ubigeo && popUpData?.ubigeo !== '' && (
                <>
                  <span className='popup-detail-header'>UBIGEO:</span>
                  <span className='popup-detail-content'>
                    {popUpData?.ubigeo}
                  </span>
                </>
              )}
              {(popUpData?.latitud || popUpData?.longitud) &&
                (popUpData?.latitud !== '' || popUpData?.longitud !== '') && (
                  <>
                    <span className='popup-detail-header'>COORDENADAS:</span>
                    <span className='popup-detail-content'>
                      {popUpData?.latitud || '-'}, {popUpData?.longitud || '-'}{' '}
                    </span>
                  </>
                )}
              {popUpData?.descripcion && popUpData?.descripcion !== '' && (
                <>
                  <span className='popup-detail-header'>EVENTO:</span>
                  <span className='popup-detail-content'>
                    {popUpData?.descripcion}
                  </span>
                </>
              )}
            </span>
          </div>
          <div className='popup-footer-road-emergency'>
            <div
              style={{
                borderTop: '3px solid #FF9800',
                display: 'inline-flex',
                width: '100%',
              }}
            >
              <div className='item '>
                <div className='popup-detail-header'>CONDICIÓN</div>
                <div className='popup-detail-content'>
                  {popUpData?.condicionTransitoCodigo
                    ? CODE_EQUIVALENCE[popUpData?.condicionTransitoCodigo]
                    : ''}
                </div>
              </div>
              <div className='item'>
                <div className='popup-detail-header'>TIPO EMERGENCIA</div>
                <div className='popup-detail-content popup-source'>
                  {popUpData?.tipoEmergencia ?? ''}
                </div>
              </div>
              {popUpData?.rutasAlt && popUpData?.rutasAlt?.trim() !== '' && (
                <div
                  className='item'
                  style={{
                    borderTop: '3px solid #cc3f44',
                    width: '100%',
                  }}
                >
                  <div className='popup-detail-header'>RUTAS ALTERNAS</div>
                  <div className='popup-detail-content'>
                    {popUpData?.rutasAlt}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default PopupSutran;
