import React from 'react';
import Legend from '../Legend';

const WavesLegend = ({ toggleVisibility }) => {
  return (
    <Legend
      title='Altura total de oleaje en m.'
      numbers={[1, 1.5, 2, 2.5, 3, 4, 5]}
      descriptions={['Mar en calma', 'Oleaje muy fuerte']}
      toggleVisibility={toggleVisibility}
      type='waves'
    />
  );
};

export default WavesLegend;
