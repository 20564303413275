import { uploadImage } from '../services/articles';

export const toolbar =
  'undo redo | template | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor fontselect fontsizeselect | numlist bullist';

export const plugins = [
  'anchor',
  'autolink',
  'autosave',
  'code',
  'directionality',
  'emoticons',
  'lists',
  'link',
  'advlist',
  'charmap',
  'preview',
  'pagebreak',
  'fullscreen',
  'image',
  'insertdatetime',
  'nonbreaking',
  'media',
  'quickbars',
  'wordcount',
  'save',
  'searchreplace',
  'table',
  'visualblocks',
  'visualchars',
];

export const images_upload_handler = (blobInfo) =>
  new Promise((resolve, reject) => {
    const image = {
      base64: blobInfo.base64(),
      file: blobInfo.blob(),
      name: blobInfo.filename(),
      size: blobInfo.blob().size,
      type: blobInfo.blob().type,
    };
    uploadImage(image)
      .then((url) => {
        resolve(`${process.env.REACT_APP_WEB_PATH_S3_NEW}${url}`);
      })
      .catch(() => {
        reject('Fallo la carga de imagen');
      });
  });

export const initTinyEditor = {
  min_height: 500,
  menubar: true,
  language: 'es',
  plugins,
  toolbar,
  autosave_interval: '5s',
  autosave_prefix: 'tinymce-autosave-{path}{query}-',
  autosave_restore_when_empty: true,
  autosave_retention: '240m',
  images_upload_url: 'postAcceptor.php',
  images_file_types: 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp',
  images_upload_handler,
  content_style: `
        @import url('https://fonts.googleapis.com/css?family=Lato:300,400,600,700|Roboto:300,400&display=swap');
        body { color: #141938; font-family: 'BR Sonoma', serif, sans-serif, monospace;
        font-size: 16px; line-height: 24px;font-weight: 400; letter-spacing: 0px;}
        b, strong {font-weight: 600;}
        img { max-width: 100%; height: auto !important; }`,
};
