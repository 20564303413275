export const generateChartOptions = (selectLayer, categories) => ({
  chart: { type: 'line' },
  xaxis: { categories },
  yaxis: {
    title: {
      text:
        selectLayer === 'pp'
          ? 'Valor (mm)'
          : selectLayer === 'hcwv'
          ? 'Valor (m)'
          : selectLayer === 'vis'
          ? 'Valor (km)'
          : ['hr', 'lc', 'hc', 'mc'].includes(selectLayer)
          ? 'Valor (%)'
          : ['satellite_c02', 'satellite_c13', 'temp'].includes(selectLayer)
          ? 'Valor (°C)'
          : 'Valor',
    },
  },
  tooltip: {
    y: {
      formatter: (value) => {
        const units = {
          pp: 'mm',
          hcwv: 'm',
          vis: 'km',
          hr: '%',
          lc: '%',
          hc: '%',
          mc: '%',
          satellite_c02: '°C',
          satellite_c13: '°C',
          temp: '°C',
        };
        return `${value} ${units[selectLayer] || ''}`;
      },
    },
  },
});
