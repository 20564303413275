import { getGroup } from '../../../../services/polizas';

const getDataCircle = async (coord, data) => {
  const key = `${coord.z}_${coord.x}_${coord.y}`;
  const result = await query({ [key]: { ...data } });

  return result[key];
};

const query = async (r) =>
  getGroup(r).then((response) => {
    if (
      response.status === 200 &&
      response.data &&
      response.data.success &&
      response.data.payload
    )
      return response.data.payload;
    else return false;
  });

export default getDataCircle;
