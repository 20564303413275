import { get, post, put } from '../lib/ajax';
import {
  LOAD_NEWSLETTERS_LOADING,
  CHANGE_NEWSLETTERS_QUERY,
  LOADED_NEWSLETTERS,
} from '../redux/actions/actionsType';

export const getNewsletters = async (dispatch, data) => {
  dispatch({ type: LOAD_NEWSLETTERS_LOADING, payload: true });
  dispatch({ type: CHANGE_NEWSLETTERS_QUERY, payload: data.query });
  const r = await post('/newsletters/list', data);
  if (r?.data?.success) {
    dispatch({ type: LOADED_NEWSLETTERS, payload: r.data.payload });
  }
};

export const getNewsletterTemplate = async (name, parameters) => {
  try {
    const r = await post(
      `/newsletters/getTemplate?template=${name}`,
      parameters,
    );
    if (r?.data?.success) {
      return r.data.payload;
    }
  } catch (error) {
    console.error(error);
  }

  return '';
};

export const getNewsletter = async (id) => {
  const r = await get(`/newsletters/getById?id=${id}`);
  if (r.data?.success) {
    return r.data?.payload;
  }

  return null;
};

export const getNewslettersToClient = async (dispatch, data) => {
  try {
    dispatch({ type: LOAD_NEWSLETTERS_LOADING, payload: true });
    const r = await post('/newsletters/list', {
      ...data,
      filters: { ...(data.filters ?? {}), state: 1 },
    });

    if (r?.data?.success) {
      dispatch({ type: LOADED_NEWSLETTERS, payload: r.data.payload });
      return r.data.payload;
    }

    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createNewsletters = async (
  newsletter,
  data,
  dispatch,
  notList = false,
) => {
  const r = await post('/newsletters/create', newsletter);

  if (r?.data?.success) {
    if (!notList) {
      await getNewsletters(dispatch, data);
    }

    return true;
  }

  return false;
};

export const updateNewsletters = async (
  newsletters,
  data,
  dispatch,
  notList = false,
) => {
  const r = await put(
    `/newsletters/update?id=${newsletters.id}&type=3`,
    newsletters,
  );
  if (r?.data?.success) {
    if (!notList) {
      await getNewsletters(dispatch, data);
    }

    return true;
  }

  return false;
};

export const newslettersApprove = async (newsletters, data, dispatch) => {
  const r = await put(`/newsletters/update?id=${newsletters.id}&type=1`);
  if (r?.data?.success) {
    await getNewsletters(dispatch, data);

    return true;
  }

  return false;
};

export const removeNewsletter = async ({ id, data, dispatch }) => {
  try {
    const r = await post(`/newsletters/delete?id=${id}`);
    if (r?.data?.success) {
      await getNewsletters(dispatch, data);
      return true;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const newslettersToggleDisabled = async (newsletter, data, dispatch) => {
  const toggleStatus = newsletter.disabled ? 0 : 1;
  const url = `/newsletters/toggleDisabled?id=${newsletter.id}&disabled=${toggleStatus}`;

  try {
    const response = await put(url);

    if (!response.data.success) {
      throw new Error('Request failed');
    }

    await getNewsletters(dispatch, data);

    return response.data.newslettersList;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const newslettersSentNotification = async (
  newsletters,
  data,
  dispatch,
) => {
  try {
    const r = await put(`/newsletters/update?id=${newsletters.id}&type=2`);
    if (r?.data?.success) {
      await getNewsletters(dispatch, data);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
};

export const updateNewslettersPosition = async (
  newsletters,
  data,
  dispatch,
) => {
  dispatch({ type: LOAD_NEWSLETTERS_LOADING, payload: true });
  try {
    const r = await put('/newsletters/updatePosition', newsletters);
    if (r?.data?.success) {
      await getNewslettersToClient(dispatch, data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const changePositionPagination = async (newsletters, data, dispatch) => {
  dispatch({ type: LOAD_NEWSLETTERS_LOADING, payload: true });
  try {
    const r = await put('/newsletters/changePositionNewsletter', newsletters);
    if (r?.data?.success) {
      await getNewslettersToClient(dispatch, data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateNewsletterImagesPosition = async (data) => {
  const r = await put('/newsletters/changePositionImages', data);
  return r?.data?.success;
};
