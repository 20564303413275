import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationsNew } from '../../../../services/locations';
import LocationPopup from './LocationPopup';
import Favorites from './Favorites';
import { SET_FAVORITES } from '../../../../redux/actions/actionsType';

const FavoriteManager = ({ selectLayer, selectLayerName }) => {
  const dispatch = useDispatch();
  const isMapBlocked = useSelector((state) => state.maps.isBlockedClick);

  useEffect(() => {
    const fetchFavorites = async () => {
      const locations = await getLocationsNew();
      dispatch({ type: SET_FAVORITES, payload: locations });
    };

    fetchFavorites();
  }, [dispatch]);

  const addFavorite = async () => {
    const updatedFavorites = await getLocationsNew();
    dispatch({ type: SET_FAVORITES, payload: updatedFavorites });
  };

  return (
    <>
      {!isMapBlocked && (
        <LocationPopup
          addFavorite={addFavorite}
          selectLayer={selectLayer}
          selectLayerName={selectLayerName}
        />
      )}
      <Favorites selectLayer={selectLayer} selectLayerName={selectLayerName} />
    </>
  );
};

export default FavoriteManager;
