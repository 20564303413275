import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { SelectWithLabel } from '../../../../common/InputWithLabel';

import RightDrawer from '../../../../common/RightDrawer';
import DatePicker from '../../../../common/DatePicker';

const { RangePicker } = DatePicker;

const DrawerFilters = ({ visible, onClose, handleFilter, filters }) => {
  const eventTypes = useSelector((state) => state.eventType.eventTypes);

  return (
    <RightDrawer
      onCloseDrawer={onClose}
      title={'Filtra tu búsqueda'}
      visible={visible}
      hiddenDivider
      content={
        <div className='flex flex-col gap-2'>
          <SelectWithLabel
            label='Seleccione el tipo de evento'
            value={filters?.event_type_id}
            onChange={(event_type_id) =>
              handleFilter('event_type_id', event_type_id)
            }
            options={eventTypes}
          />
          <RangePicker
            value={[
              filters?.date_event_end && dayjs(filters?.date_event_start),
              filters?.date_event_end && dayjs(filters?.date_event_end),
            ]}
            name='date'
            placeholder={['Fecha de inicio', 'Fecha de fin']}
            format='DD-MM-YYYY'
            onChange={(dates) => handleFilter('date', dates)}
          />
        </div>
      }
    />
  );
};

export default DrawerFilters;
