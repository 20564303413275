import axios from 'axios';
import { buildRequestUrl } from '../../utils/tools';

const wsmUrl = process.env.REACT_APP_GEOSERVER_MAPS_NEW;

export const fetchDataForAllTimes = async (
  arrayTime,
  bbox,
  selectLayer,
  setProgress,
) => {
  const totalTimes = arrayTime.length;
  let completedRequests = 0;

  const fetchData = async (time) => {
    const params = {
      time: time,
      bbox: bbox,
      styles: '',
      format: 'jpeg',
      request: 'GetFeatureInfo',
      layers: selectLayer,
      query_layers: selectLayer,
      width: 2,
      height: 2,
      x: 1,
      y: 1,
      INFO_FORMAT: 'application/json',
    };
    const requestUrl = buildRequestUrl(wsmUrl, params);
    try {
      const response = await axios.get(requestUrl);
      const featuresWithDate = response.data.features.map((feature) => ({
        ...feature,
        date: time,
      }));
      completedRequests++;
      setProgress((completedRequests / totalTimes) * 100);
      return featuresWithDate;
    } catch (error) {
      console.error('Error fetching data:', error);
      completedRequests++;
      setProgress((completedRequests / totalTimes) * 100);
      return [];
    }
  };

  const allData = await Promise.all(arrayTime.map(fetchData));
  return allData.flat();
};
