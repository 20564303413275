import L from 'leaflet';
import moment from 'moment';

export const generateTicksAndMarksForDays = (activeSubLayer) => {
  const ticks = {};
  const marks = {};

  if (!activeSubLayer || !activeSubLayer.animate) {
    return { ticks, marks };
  }

  const { times, unitTime, format } = activeSubLayer.animate;

  for (let i = 1; i <= times; i += 1) {
    ticks[i] = moment()
      .startOf('day')
      .add(i, unitTime || 'hour')
      .format(format || 'DD/MM hh:mm A');
  }

  for (let i = 0; i <= times; i += 24) {
    marks[i] = moment()
      .startOf('day')
      .add(i, unitTime || 'hour')
      .format(format || 'DD/MM hh:mm A');
  }

  return { ticks, marks };
};

export const generateTicksAndMarksForRealtime = (activeSubLayer, idxTimes) => {
  const INTERVAL = 10; // minutes
  const SEPARATION =
    activeSubLayer?.animate.times[idxTimes] / activeSubLayer?.animate.splits;
  const ticks = {};
  const marks = {};
  for (let i = 1; i <= activeSubLayer?.animate.times[idxTimes]; i += 1)
    ticks[i] = moment()
      .add(
        (activeSubLayer?.animate.times[idxTimes] - i) * INTERVAL * -1,
        activeSubLayer?.animate.unitTime || 'hour',
      )
      .format(activeSubLayer?.animate?.format || 'DD/MM hh:mm A');
  for (
    let i = 0;
    i <= activeSubLayer?.animate.times[idxTimes];
    i += SEPARATION
  ) {
    if (i === 0) continue;
    marks[i] = moment()
      .add(
        (activeSubLayer?.animate.times[idxTimes] - i) * INTERVAL * -1,
        activeSubLayer?.animate.unitTime || 'hour',
      )
      .format(activeSubLayer?.animate?.format || 'DD/MM hh:mm A');
  }
  return { ticks, marks };
};

export const generateTicksAndMarksDefault = (activeSubLayer) => {
  const ticks = {};
  const marks = {};
  for (let i = 1; i <= activeSubLayer?.animate.times; i += 1)
    ticks[i] = moment()
      .add(
        i * activeSubLayer?.animate.splits,
        activeSubLayer?.animate.unitTime || 'hour',
      )
      .format(activeSubLayer?.animate?.format || 'DD/MM hh:mm A');
  for (
    let i = 1;
    i <= activeSubLayer?.animate.times;
    i += activeSubLayer?.animate.splits
  )
    marks[i] = moment()
      .add(
        i * activeSubLayer?.animate.splits,
        activeSubLayer?.animate.unitTime || 'hour',
      )
      .format(activeSubLayer?.animate?.format || 'DD/MM hh:mm A');
  return { ticks, marks };
};

export const removeLayers = (map, scenesLayers) => {
  Object.values(scenesLayers).forEach((l) => {
    map.removeLayer(l.layer);
    l.layer.removeFrom(map);
  });
};

export const createSubLayer = (activeSubLayer, defaultScene, map) => {
  const subLayer = L.tileLayer.wms(activeSubLayer.url, {
    layers: `cmo:${activeSubLayer.layersId}_${
      activeSubLayer?.animate?.realtime ? '1' : defaultScene || '1'
    }`,
    tileSize: 1024,
    format: 'image/png',
    tiled: true,
    transparent: false,
    tileLayerBase: true,
    version: '1.3.0',
    dateTime: moment(),
  });
  map.addLayer(subLayer);
  return subLayer;
};

export const updateSubLayer = (subLayer, activeSubLayer, defaultScene) => {
  subLayer.setParams({
    layers: `cmo:${activeSubLayer.layersId}_${
      activeSubLayer?.animate?.realtime ? '1' : defaultScene
    }`,
  });
};

export const createSubLayerRegions = (activeSubLayer, map) => {
  const subLayerRegions = L.tileLayer.wms(
    `${process.env.REACT_APP_GEOSERVER_MAPS_NEW}`,
    {
      layers:
        activeSubLayer.styles === 'dark'
          ? 'cmo:lineas_level_2_dark'
          : 'cmo:lineas_level_2',
      tileSize: 1024,
      format: 'image/png',
      tiled: true,
      transparent: true,
      tileLayerBase: true,
      mode: activeSubLayer.id,
      version: '1.3.0',
      dateTime: moment(),
      zIndex: 11,
    },
  );
  map.addLayer(subLayerRegions);
  return subLayerRegions;
};

export const updateSubLayerRegions = (subLayerRegions, activeSubLayer, map) => {
  if (map.getZoom() > 8)
    subLayerRegions.setParams({
      layers:
        activeSubLayer.styles === 'dark'
          ? 'cmo:lineas_level_2_dark'
          : 'cmo:lineas_level_2',
    });
  else
    subLayerRegions.setParams({
      layers:
        activeSubLayer.styles === 'dark'
          ? 'cmo:lineas_level_1_dark'
          : 'cmo:lineas_level_1',
    });
};

export const removeSubLayerRegions = (subLayerRegions, map) => {
  if (subLayerRegions) {
    map.removeLayer(subLayerRegions);
    return null;
  }
  return subLayerRegions;
};

export const loadLayer = (
  url,
  layersId,
  sceneIndex,
  setScenesLayersLoaded,
  visible,
  map,
) => {
  const item = {
    state: 'loading',
    layer: L.tileLayer.wms(url, {
      layers: `cmo:${layersId}_${sceneIndex}`,
      tileSize: 1024,
      format: 'image/png',
      tiled: true,
      transparent: false,
      tileLayerBase: true,
      version: '1.3.0',
      dateTime: moment(),
    }),
  };

  const setSceneLoaded = (loaded) => {
    setScenesLayersLoaded((prev) => ({
      ...prev,
      [sceneIndex]: loaded,
    }));
  };

  item.layer.on('load', function () {
    setSceneLoaded(true);
  });

  item.layer.on('loading', function () {
    setSceneLoaded(false);
  });

  map.addLayer(item.layer);

  if (!visible && item.layer.getContainer()) {
    item.layer.getContainer().style.visibility = 'hidden';
  }

  return item;
};

export const loadedAllScenes = (
  activeSubLayer,
  scenesLayers,
  setScenesLayersLoaded,
  setScenesLayers,
  map,
  idxTimes,
) => {
  if (activeSubLayer?.animate?.times) {
    const times = activeSubLayer?.animate?.realtime
      ? activeSubLayer?.animate.times[idxTimes]
      : activeSubLayer?.animate.times;
    for (let i = 1; i <= times; i += 1) {
      const indexScene = activeSubLayer?.animate?.realtime ? times - i : i;
      if (!scenesLayers[i]) {
        const item = loadLayer(
          activeSubLayer.url,
          activeSubLayer.layersId,
          indexScene,
          setScenesLayersLoaded,
          false,
          map,
        );
        setScenesLayers((prev) => ({
          ...prev,
          [indexScene]: item,
        }));
      }
    }
  }
};

export const hideAllLayers = (scenesLayers) => {
  Object.values(scenesLayers).forEach((item) => {
    item.layer.getContainer().style.visibility = 'hidden';
  });
};

export const showLayer = (scenesLayers, indexScene) => {
  if (scenesLayers[indexScene]) {
    scenesLayers[indexScene].layer.getContainer().style.visibility = 'visible';
  }
};

export const loadAndShowLayer = (
  activeSubLayer,
  indexScene,
  setScenesLayersLoaded,
  map,
  setScenesLayers,
  loadLayer,
) => {
  const item = loadLayer(
    activeSubLayer.url,
    activeSubLayer.layersId,
    indexScene,
    setScenesLayersLoaded,
    true,
    map,
  );
  setScenesLayers((prev) => ({
    ...prev,
    [indexScene]: item,
  }));
};

export const handleSceneBarChange = (
  activeSubLayer,
  selectedSceneBar,
  scenesLayers,
  setScenesLayersLoaded,
  map,
  setScenesLayers,
  loadLayer,
  idxTimes,
) => {
  if (activeSubLayer && selectedSceneBar) {
    const indexScene = activeSubLayer?.animate?.realtime
      ? activeSubLayer?.animate.times[idxTimes] - selectedSceneBar
      : selectedSceneBar;
    hideAllLayers(scenesLayers);
    showLayer(scenesLayers, indexScene);
    if (!scenesLayers[indexScene]) {
      loadAndShowLayer(
        activeSubLayer,
        indexScene,
        setScenesLayersLoaded,
        map,
        setScenesLayers,
        loadLayer,
      );
    }
  }
};

export const startSceneInterval = (
  activeSubLayer,
  defaultScene,
  idxTimes,
  setSelectedSceneBar,
) => {
  return setInterval(() => {
    setSelectedSceneBar((prev) => {
      const times = activeSubLayer?.animate?.realtime
        ? activeSubLayer?.animate.times[idxTimes]
        : activeSubLayer?.animate.times;
      if (prev === null) prev = defaultScene;
      if (prev >= times) return 1;
      else return prev + 1;
    });
  }, 200);
};

export const startTileUpdateInterval = (
  scenesLayers,
  setForceGenerateTimes,
  secureRandom,
) => {
  return setInterval(() => {
    Object.values(scenesLayers).forEach((item) => {
      item.layer.setParams({ c: secureRandom() }, true);
      item.layer.redraw();
    });
    setForceGenerateTimes(secureRandom());
  }, 1000 * 60 * 10);
};

export const handleSceneLoading = (
  isLoadingScenes,
  isPlaying,
  intervalControl,
  intervalUpdateTiles,
  activeSubLayer,
  defaultScene,
  idxTimes,
  setSelectedSceneBar,
  scenesLayers,
  setForceGenerateTimes,
  secureRandom,
) => {
  if (!isLoadingScenes && isPlaying && !intervalControl.current) {
    intervalControl.current = startSceneInterval(
      activeSubLayer,
      defaultScene,
      idxTimes,
      setSelectedSceneBar,
    );
    intervalUpdateTiles.current = startTileUpdateInterval(
      scenesLayers,
      setForceGenerateTimes,
      secureRandom,
    );
  }
};
