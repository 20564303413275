import React, { useState, useCallback } from 'react';
import { Input, Spin, Button } from 'antd';
import { debounce } from '../../../../lib/utils';
import { getLocationsNew } from '../../../../services/locations';
import { useDispatch } from 'react-redux';
import { SET_FAVORITES } from '../../../../redux/actions/actionsType';
import { RideSpinner } from '@rimac-seguros/ride-system-components';

const SearchFavorites = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const debouncedSearch = useCallback(
    debounce(async (term) => {
      setLoading(true);
      const locations = await getLocationsNew(term);
      dispatch({ type: SET_FAVORITES, payload: locations });
      setLoading(false);
    }, 300),
    [dispatch],
  );

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
  };

  const clearSearch = async () => {
    setSearchTerm('');
    setLoading(true);
    const locations = await getLocationsNew();
    dispatch({ type: SET_FAVORITES, payload: locations });
    setLoading(false);
  };

  return (
    <div>
      <Input
        placeholder='Buscar favoritos...'
        value={searchTerm}
        onChange={handleSearch}
      />
      {loading && <RideSpinner style={{ marginLeft: 10 }} />}
      {searchTerm && (
        <Button onClick={clearSearch} style={{ marginLeft: 10 }}>
          Limpiar
        </Button>
      )}
    </div>
  );
};

export default SearchFavorites;
