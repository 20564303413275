import { useState, useEffect } from 'react';
import { getRisksArticles } from '../../../services/articles';

import Button from '../components/Button';
import FooterPortal from '../../../common/FooterPortal';
import HeaderPortal from '../../../common/HeaderPortal';
import ArticleCard from '../components/ArticleCard';
import OfferList from '../components/OfferList';
import OfferCard from '../components/OfferCard';
import SERVICES_ITEMS from './SERVICES_ITEMS';

const Risks = () => {
  const [risksArticles, setRisksArticles] = useState([]);

  useEffect(() => {
    getRisksArticles().then((data) => {
      if (data?.list) setRisksArticles(data.list);
    });
  }, []);

  return (
    <div className='portal-web'>
      <HeaderPortal />
      <div className='portal-prevention'>
        <div className='section'>
          <div className='main main--reverse'>
            <div className='main__left main__left--reverse'>
              <img
                src='/images/portal/risks_main.png'
                className='main__img'
                alt='Prevención'
              />
            </div>
            <div className='main__right'>
              <h1 className='main__title'>
                Desarrollamos una cultura en{' '}
                <span className='main__title--strong'>
                  Gestión Integral de Riesgos
                </span>
              </h1>
              <p className='main__description'>
                A través de nuestros expertos en gestión de riesgos, brindamos
                un conjunto de soluciones dinámicas y eficientes que apoyana la
                organización en el desarrollo de su cultura de gestión de
                riesgos.
              </p>
              <div className='main__buttons'>
                <Button
                  href='https://www.rimac.com/empresa?rfid=publica:categoria-empresas:menu-principal:tab'
                  target='blank'
                >
                  Adquirir póliza
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className='section back-color-gray-7'>
          <div className='reasons'>
            <div className='reasons__left'>
              <div className='reasons__title reasons__title--risks'>
                Conoce nuestra{' '}
                <span className='reasons__title--strong'>oferta de valor</span>
              </div>
              <div className='reasons__description'>
                Asesoramos a tu organización, de la mano del líder y/o equipo
                encargado de la gestión de riesgos, a través de talleres y
                herramientas dinámicas y lúdicas, con la finalidad de crear y
                proteger valor para la compañía, mejorar el desempeño, fomentar
                la innovación y contribuir al logro de objetivos.
              </div>
            </div>
            <div className='reasons__right--risks'>
              <OfferList
                src={'/images/portal/icon_tablet-analytics.svg'}
                title={'Evaluación'}
                description={'Incluye un diagnóstico de madurez en riesgos:'}
              >
                <ul>
                  <li>
                    Determina el nivel de madurez de la gestión integral de
                    riesgos en el que se encuentra tu Organización.
                  </li>
                  <li>
                    Brinda recomendaciones para mejorar el nivel de madurez.
                  </li>
                  <li>Define un plan de trabajo conjunto con RIMAC.</li>
                </ul>
              </OfferList>
              <OfferList
                src={'/images/portal/icon_training.svg'}
                title={'Capacitación'}
                description={
                  'Incluye talleres para fomentar la cultura de riesgos y entender los principales conceptos de forma didáctica e inclusive lúdica.'
                }
              >
                <ol>
                  <li>Workshop Riesgos y Estrategias</li>
                  <li>
                    Taller ¿Cómo iniciar el proceso de Gestión de Riesgos?
                  </li>
                  <li>Workshop Gestiona tus Riesgos</li>
                  <li>
                    Curso Risk Manager ISO 31000 – con opción a certificación
                    por PECB
                  </li>
                </ol>
              </OfferList>
            </div>
          </div>
        </div>
        <div className='section'>
          <div className='offers'>
            <h4 className='section__title'>
              ¿Por qué usar el servicio{' '}
              <span className='section__title--red'>Asesoría en Riesgos?</span>
            </h4>
            <p className='offers__subtitle'>
              Este servicio no interfiere, superpone o adiciona condiciones en
              la póliza
            </p>
            <div className={'offers__list'}>
              {SERVICES_ITEMS.map((service, idx) => (
                <OfferCard
                  key={idx}
                  img={service.img}
                  title={service.title}
                  description={service.description}
                  border
                />
              ))}
            </div>
          </div>
        </div>
        {risksArticles && risksArticles.length > 0 && (
          <div className='section back-color-gray-7 articles-section'>
            <div className='articles'>
              <h4 className='section__title'>
                Revisa nuestros{' '}
                <span className='section__title--red'>artículos recientes</span>
              </h4>
              <div className='articles__list'>
                {risksArticles.slice(0, 4).map((article) => (
                  <ArticleCard
                    key={`article-${article.id}`}
                    href={`/articulos/${article.url}`}
                    title={article.title}
                    description={article.subtitle}
                    img={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${article.image}`}
                  />
                ))}
              </div>
              <a className='section__button-more' href='/articulos?f=riesgos'>
                <span>ver todos los artículos</span>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle
                    cx='12'
                    cy='12'
                    r='11.5'
                    stroke='#6F7DFF'
                    strokeOpacity='0.4'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M9.93988 15.5709C10.1867 15.8097 10.5825 15.8097 10.8293 15.5709L14.0601 12.4459C14.3133 12.2011 14.3133 11.7989 14.0601 11.5541L10.8293 8.42905C10.5825 8.19032 10.1867 8.19032 9.93988 8.42905C9.6867 8.67394 9.6867 9.07606 9.93988 9.32095L12.7096 12L9.93988 14.6791C9.68671 14.9239 9.68671 15.3261 9.93988 15.5709Z'
                    fill='#6F7DFF'
                  />
                </svg>
              </a>
            </div>
          </div>
        )}
      </div>
      <FooterPortal />
    </div>
  );
};

export default Risks;
