import React, { useEffect, useState } from 'react';
import { Slider, Checkbox, Typography, notification } from 'antd';
import {
  updateFiltersAlerts,
  getForecastConfigById,
} from '../../../services/locations';
import { pushDigitalData } from '../../../lib/utils';
import { TRACK } from '../../../lib/consts';
import Button from '../../../common/Button';

const { Text } = Typography;

const TemperatureAlerts = ({ data, idLocation, onUpdatedData }) => {
  const [minValue, setMinValue] = useState(-20);
  const [maxValue, setMaxValue] = useState(20);
  const [checkboxes, setCheckboxes] = useState({ min: true, max: true });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCheckboxes({ min: true, max: true });
    setMinValue(-20);
    setMaxValue(20);
  }, [data]);

  useEffect(() => {
    if (data) {
      const temperatureData = data?.type === 'temperature';

      if (temperatureData) {
        if (data.min === null) {
          data.min = -20;
        }
        if (data.max === null) {
          data.max = 20;
        }
        setMinValue(Number(data.min));
        setMaxValue(Number(data.max));
      } else {
        setMinValue(-20);
        setMaxValue(20);
      }
    }
  }, [data]);

  const handleCheckboxChange = (e) => {
    setCheckboxes({ ...checkboxes, [e.target.name]: e.target.checked });

    if (e.target.name === 'min' && !e.target.checked) {
      setMinValue(-50);
    } else if (e.target.name === 'max' && !e.target.checked) {
      setMaxValue(50);
    }
  };

  const handleMinSliderChange = (value) => {
    if (checkboxes.min) {
      setMinValue(Number(value));
    }
  };

  const handleMaxSliderChange = (value) => {
    if (checkboxes.max) {
      setMaxValue(Number(value));
    }
  };

  const handleSubmit = () => {
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData(
        {
          action: {
            group: 'Centro Monitoreo',
            category: 'Configuración',
            name: `Alerta -Configurar`,
            label: `Temperatura - ${minValue} - ${maxValue}`,
          },
        },
        TRACK.ACTION,
      );
    }

    setLoading(true);
    const info = {
      id: idLocation,
      temperature: {
        state: 1,
        min: Number(minValue),
        max: Number(maxValue),
      },
    };

    updateFiltersAlerts(info)
      .then((res) => {
        if (res === true) {
          notification.success({
            message: 'Alerta de temperatura configurada',
            description: 'Se ha configurado la alerta de temperatura',
          });
        }
      })
      .finally(() => {
        getForecastConfigById(idLocation).then((res) => {
          if (res) {
            const temperatureData = res?.find(
              (item) => item.type === 'temperature',
            );
            setMinValue(Number(temperatureData?.min));
            setMaxValue(Number(temperatureData?.max));
            onUpdatedData('temperature', temperatureData);
          }
          setLoading(false);
        });
      });
  };

  const onDeleteAlert = () => {
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData(
        {
          action: {
            group: 'Centro Monitoreo',
            category: 'Configuración',
            name: `Alerta -Eliminar`,
            label: `Temperatura - ${minValue} - ${maxValue}`,
          },
        },
        TRACK.ACTION,
      );
    }

    setLoading(true);
    const info = {
      id: idLocation,
      temperature: {
        state: 0,
        min: null,
        max: null,
      },
    };

    updateFiltersAlerts(info)
      .then((res) => {
        if (res === true) {
          notification.success({
            message: 'Alerta de temperatura eliminada',
            description: 'Se ha eliminado la alerta de temperatura',
          });
        }
      })
      .finally(() => {
        getForecastConfigById(idLocation).then((res) => {
          if (res) {
            const temperatureData = res?.find(
              (item) => item.type === 'temperature',
            );
            setMinValue(-20);
            setMaxValue(20);
            onUpdatedData('temperature', temperatureData);
          }
          setLoading(false);
        });
      });
  };

  return (
    <>
      <section className='temperature-section mt-2'>
        <h5>Establecer Temperatura Mínima</h5>
        <Slider
          className={'slider-background'}
          marks={{ '-50': '-50 °C', 50: '50 °C' }}
          style={{ marginTop: 48, marginBottom: 40 }}
          min={-50}
          max={50}
          step={1}
          value={minValue}
          onChange={handleMinSliderChange}
          tooltip={{
            open: true,
            getPopupContainer: (e) => e,
          }}
        />
        <Checkbox
          checked={checkboxes.min}
          onChange={handleCheckboxChange}
          name='min'
          disabled={!checkboxes.max}
        >
          Alerta cuando la temperatura sea menor que {minValue} °C
        </Checkbox>
      </section>
      <section className='temperature-section mt-2'>
        <h5>Establecer Temperatura Máxima</h5>
        <Slider
          className={'slider-background-max'}
          marks={{ '-50': '-50 °C', 50: '50 °C' }}
          style={{ marginTop: 48, marginBottom: 40 }}
          min={-50}
          max={50}
          step={1}
          value={maxValue}
          onChange={handleMaxSliderChange}
          tooltip={{
            open: true,
            getPopupContainer: (e) => e,
          }}
        />
        <Checkbox
          checked={checkboxes.max}
          onChange={handleCheckboxChange}
          name='max'
          disabled={!checkboxes.min}
        >
          Alerta cuando la temperatura sea mayor que {maxValue} °C
        </Checkbox>
      </section>

      <div className='flex flex-col items-center mt-2'>
        <Button
          variant='fill'
          style={{ width: '50%' }}
          disabled={loading}
          onClick={handleSubmit}
          loading={loading}
          text='Configurar'
        />
        <Button
          variant='text-primary'
          className='mt-2'
          disabled={data?.state === 0 || loading}
          onClick={onDeleteAlert}
          text='Eliminar alerta'
        />
      </div>
    </>
  );
};

export default TemperatureAlerts;
