import React from 'react';
import Legend from '../Legend';

const InfraredLegend = ({ toggleVisibility }) => {
  return (
    <Legend
      title='Temperatura de las nubes C°'
      numbers={[
        -92, -85, -80, -75, -70, -65, -60, -55, -50, -45, -40, -30, -20, -15,
        -10, 30, 50,
      ]}
      descriptions={['Poca probabilidad de lluvia', 'Lluvia fuerte o tormenta']}
      toggleVisibility={toggleVisibility}
      type='infrared'
    />
  );
};

export default InfraredLegend;
