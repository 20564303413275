import React from 'react';
import Legend from '../Legend';

const UVLegend = ({ toggleVisibility }) => {
  return (
    <Legend
      title='Índice de radiación ultravioleta sin nubosidad'
      numbers={[1, 3, 5, 6, 7, 8, 10, 11, 20]}
      descriptions={[
        'Baja',
        'Moderada',
        'Alta',
        'Muy alta',
        'Extremadamente alta',
      ]}
      toggleVisibility={toggleVisibility}
      type='uv'
    />
  );
};

export default UVLegend;
