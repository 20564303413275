import { memo, useCallback, useEffect, useState } from 'react';
import {
  Card,
  Modal,
  Skeleton,
  Pagination,
  notification,
  Empty,
  Typography,
} from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  RideButton,
  RideGlNotificationsSolid,
  RideGlAddSolid,
} from '@rimac-seguros/ride-system-components';

import ModalSubscription from './components/ModalSubscription';
import LocationForm from './LocationForm';
import {
  getLocations,
  removeLocation,
  updateLocation,
  updateAllAlerts,
} from '../../../services/locations';
import { checkPermission } from '../../../lib/security';
import { pushDigitalData } from '../../../lib/utils';
import { TRACK } from '../../../lib/consts';
import PopoverOptions from './components/PopoverOptions';

const itemsPerPage = 8;
const { Title } = Typography;

const Locations = () => {
  const dispatch = useDispatch();
  const { user, listLocations, isLoading } = useSelector((state) => ({
    user: state.auth.user,
    listLocations: state.locations.list || [],
    isLoading: state.locations.isLoadingList,
  }));

  const [idToDelete, setIdToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalSubscriptionOpen, setIsModalSubscriptionOpen] = useState(false);
  const [isLocationFormOpen, setIsLocationFormOpen] = useState(false);
  const [locationData, setLocationData] = useState(null);

  const removeLocationAlert = (id) => removeLocation(id, dispatch);
  const updateAlertsLocation = (locations) =>
    updateLocation(locations, dispatch);
  const onUpdateAllAlerts = async (state) => {
    const update = await updateAllAlerts(state, dispatch);
    if (update) {
      notification.success({
        description: 'Configurada con éxito.',
        message: '¡Alerta configurada!',
      });
    } else {
      notification.error({
        message: 'Ha ocurrido un error al configurar alerta.',
      });
    }
  };

  const handleDeleteLocation = useCallback(
    (id) => () => {
      setIdToDelete(id);
    },
    [],
  );

  const handleChangePagination = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleOpenModalSuscription = () => {
    handlePushDigital('Desactivar alertas');
    setIsModalSubscriptionOpen(true);
  };

  const handleClose = () => {
    setIsLocationFormOpen(false);
    setLocationData(null);
  };

  const refreshList = useCallback(() => {
    getLocations(dispatch);
  }, [dispatch]);

  const onOpenForm = useCallback((location) => {
    setLocationData(location);
    setIsLocationFormOpen(true);
  }, []);

  const handleToggleAllAlerts = useCallback(
    (reason) => {
      if (!user?.allow_alerts) {
        handlePushDigital('Activar alertas');
      }
      onUpdateAllAlerts({
        allow_alerts: !user?.allow_alerts,
        reason: reason || '',
      });
      setIsModalSubscriptionOpen(false);
    },
    [onUpdateAllAlerts, user],
  );

  const handleModalLocation = () => {
    handlePushDigital('Nueva Ubicación');
    if (window.innerWidth >= 768) {
      setIsLocationFormOpen(true);
    } else {
      window.location.href = '/monitoreo/mapas?newLocation=true';
    }
  };

  const handlePushDigital = (label) => {
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData(
        {
          action: {
            group: 'Centro Monitoreo',
            category: 'Configuración',
            name: 'Mis ubicaciones',
            label,
          },
        },
        TRACK.ACTION,
      );
    }
  };

  useEffect(() => {
    getLocations(dispatch);
  }, []);

  return (
    <>
      <div className='flex items-center justify-between mb-2 top-text-configuration'>
        <p>Crea y edita tus ubicaciones favoritas</p>
        <div className='flex items-center gap-2'>
          {checkPermission(user, 'allow_change_status_all-alerts') && (
            <RideButton
              variant='text-primary'
              size='small'
              onClick={
                user?.allow_alerts
                  ? handleOpenModalSuscription
                  : () => handleToggleAllAlerts()
              }
              text={
                user?.allow_alerts ? 'Desactivar alertas' : 'Activar alertas'
              }
              glyph={<RideGlNotificationsSolid />}
              glyphPosition='left'
            />
          )}
          {checkPermission(user, 'allow_create_new-location_configuration') && (
            <RideButton
              variant='text-primary'
              onClick={handleModalLocation}
              glyph={<RideGlAddSolid />}
              glyphPosition='left'
              size='small'
              text='Nueva ubicación'
            />
          )}
        </div>
      </div>

      <div className='service-list-container'>
        {isLoading ? (
          <div>
            {[1, 2, 3, 4, 5, 6].map((_, index) => (
              <Card className='card-style' key={index}>
                <Skeleton loading={isLoading} active />
              </Card>
            ))}
          </div>
        ) : (
          listLocations.length > 0 &&
          listLocations
            .slice(itemsPerPage * (currentPage - 1), itemsPerPage * currentPage)
            .map(({ id, name, allow_alerts, province, lat, lng, type }) => (
              <Card key={id} className='card-location'>
                <div className='flex justify-between items-center'>
                  <div className='flex items-center gap-2'>
                    <EnvironmentOutlined style={{ fontSize: '16px' }} />
                    <Title style={{ marginBottom: 0 }} level={5}>
                      {name}
                    </Title>
                  </div>
                  <PopoverOptions
                    allow_alerts={allow_alerts}
                    location={{ id, name, lat, lng }}
                    isMainLocation={type === 1}
                    onUpdateLocation={() =>
                      updateAlertsLocation({
                        id,
                        allow_alerts: !allow_alerts,
                      })
                    }
                    onDeleteLocation={handleDeleteLocation(id)}
                    onOpenForm={onOpenForm}
                  />
                </div>
                <hr className='mt-2 mb-1' />
                <div className='favorite-location-content'>
                  <p>
                    Ubicación: <span>{province}</span>
                  </p>
                  <p>
                    Alertas:{' '}
                    <span>{allow_alerts ? 'Activadas' : 'Inactivas'}</span>
                  </p>
                </div>
              </Card>
            ))
        )}
      </div>

      {listLocations.length === 0 && !isLoading && (
        <Empty
          image={<EnvironmentOutlined style={{ fontSize: '32px' }} />}
          imageStyle={{
            height: 60,
          }}
          style={{ marginTop: '50px' }}
          description={
            <span>
              No tienes ubicaciones favoritas aún. Haz clic en 'Nueva ubicación'
              para añadir una nueva ubicación a tus favoritos.
            </span>
          }
        />
      )}

      {Array.isArray(listLocations) && listLocations.length > 0 && (
        <div className='pt-3 mt-2 flex mb-3 flex-end'>
          <Pagination
            className='new-design-pagination'
            defaultCurrent={1}
            current={currentPage}
            total={listLocations.length}
            pageSize={itemsPerPage}
            onChange={handleChangePagination}
          />
        </div>
      )}

      <Modal
        open={!!idToDelete}
        onCancel={() => setIdToDelete(null)}
        onOk={() => {
          removeLocationAlert(idToDelete);
          setIdToDelete(null);
        }}
        title='¿Seguro de eliminar la ubicación favorita?'
        cancelButtonProps={{
          children: 'Cancelar',
        }}
        okButtonProps={{
          type: 'primary',
          children: 'Aceptar',
        }}
      />
      <ModalSubscription
        visible={isModalSubscriptionOpen}
        handleClose={() => setIsModalSubscriptionOpen(false)}
        onAccept={handleToggleAllAlerts}
      />
      <LocationForm
        locationData={locationData}
        visible={isLocationFormOpen}
        handleClose={handleClose}
        onItemCreated={refreshList}
      />
    </>
  );
};

export default memo(Locations);
