import { Badge, Button, Tooltip } from 'antd';
import { ExclamationCircleOutlined, ProfileOutlined } from '@ant-design/icons';

const ButtonRsiParticipants = ({ onClick, courseData }) => {
  const hasParticipants = courseData?.participants > 0;

  return (
    <Tooltip title={hasParticipants ? 'Participantes' : 'No hay participantes'}>
      <Badge
        dot={hasParticipants ? '' : 'No hay participantes'}
        count={
          !hasParticipants ? (
            <ExclamationCircleOutlined style={{ color: '#EF3340' }} />
          ) : null
        }
      >
        <Button size='small' className='btn-cmo-action-list' onClick={onClick}>
          <ProfileOutlined />
        </Button>
      </Badge>
    </Tooltip>
  );
};

export default ButtonRsiParticipants;
