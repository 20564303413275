import { Button, List, Popover } from 'antd';
import {
  DownloadOutlined,
  FileTextFilled,
  EyeFilled,
  EditFilled,
  DeleteFilled,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { IconDots } from '../../../../../common/Icons/iconText';

export const ListActions = ({
  onDownload = () => {},
  onSeeDownloads = () => {},
  onEdit = () => {},
  onDelete = () => {},
  onReniewPolicy = () => {},
  userAuth,
  account_renew,
  isMobile,
}) => (
  <List
    className={`full-width ${isMobile ? 'text-base' : ''}`}
    dataSource={[
      {
        action: onDownload,
        icon: <DownloadOutlined className='mr-2' />,
        title: 'Descargar',
        key: 'download',
      },
      ...[
        account_renew === 2 &&
        ['admin_rimac', 'gestor_de_cuenta'].includes(userAuth?.rol?.slug)
          ? {
              action: onReniewPolicy,
              icon: <FileTextFilled className='mr-2' />,
              title: 'Renovar póliza',
              key: 'registerPolicy',
            }
          : false,
      ],
      ...(['admin_rimac', 'gestor_de_cuenta'].includes(userAuth?.rol?.slug)
        ? [
            {
              action: onSeeDownloads,
              icon: <EyeFilled className='mr-2' />,
              title: 'Ver descargas',
              key: 'seeDownload',
            },
            {
              action: onEdit,
              icon: <EditFilled className='mr-2' />,
              title: 'Editar',
              key: 'edit',
            },
            {
              action: onDelete,
              icon: <DeleteFilled className='mr-2' />,
              title: 'Eliminar',
              key: 'delete',
            },
          ]
        : [false]),
    ].filter(Boolean)}
    renderItem={({ action, icon, key, title }) => (
      <List.Item
        className='flex items-center gap-2'
        style={!isMobile ? { height: 40, paddingLeft: 16, width: 228 } : {}}
        key={key}
        onClick={action}
      >
        {icon}
        {title}
      </List.Item>
    )}
  />
);

export const columnsRenovationOffer = [
  {
    title: 'RUC',
    dataIndex: 'ruc',
    key: 'ruc',
    width: 170,
  },
  {
    title: 'Razón Social',
    dataIndex: 'business_name',
    key: 'business_name',
    width: 200,
  },
  {
    title: 'Segmento Comercial',
    dataIndex: 'businessSegment',
    key: 'businessSegment',
    width: 110,
    render: (bussinesSegment, record) =>
      bussinesSegment?.name ?? (record?.business_segment || '-'),
  },
  {
    title: 'Tipo de Póliza',
    dataIndex: 'poliza',
    key: 'typePoliza',
    width: 100,
    render: (value) => value?.typePoliza?.code || '-',
  },
  {
    title: 'No. Póliza',
    dataIndex: 'poliza',
    key: 'no_poliza',
    width: 100,
    render: (poliza) => poliza?.no_poliza ?? '-',
  },
  {
    title: 'Costo Mercado',
    dataIndex: 'market_cost',
    key: 'market_cost',
    width: 100,
    render: (value) =>
      value ? `$${new Intl.NumberFormat('en-US').format(value)}` : '',
  },
  {
    title: 'Prima',
    dataIndex: 'poliza',
    key: 'prima',
    width: 100,
    render: (value, obj) => {
      if (value?.prima)
        return `$${new Intl.NumberFormat('en-US').format(value?.prima)}`;

      return `$${new Intl.NumberFormat('en-US').format(obj.prima)}`;
    },
  },
  {
    title: 'Mes Renovación',
    dataIndex: 'month_renew',
    key: 'month_renew',
    render: (month_renew) => {
      if (!month_renew) return '';

      const month =
        dayjs()
          .month(month_renew - 1)
          .format('MMMM') || '';
      return month.charAt(0).toUpperCase() + month.slice(1);
    },
    width: 120,
  },
  {
    title: 'Año Renovación',
    dataIndex: 'year_renew',
    key: 'year_renew',
    width: 100,
  },
  {
    title: 'Fecha',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 120,
    render: (value) => dayjs(value).format('DD-MM-YYYY'),
  },
  {
    title: 'Días',
    dataIndex: 'createdAt',
    key: 'days',
    width: 70,
    render: (value, item) => {
      const startDate = dayjs(value);
      let endDate = dayjs();
      if (item.haveRenew) {
        endDate = dayjs(item.haveRenew.start_date);
      }

      return endDate.diff(startDate, 'day');
    },
  },
  {
    title: 'Observaciones',
    dataIndex: 'observation',
    key: 'observation',
    width: 200,
  },
  {
    title: 'Usuario',
    dataIndex: 'user_register',
    key: 'user_register',
    width: 120,
    render: (u) => (u ? <div>{`${u.name} ${u.last_name1}`}</div> : ''),
  },
  {
    title: '',
    dataIndex: 'action',
    fixed: 'right',
    hiddenMobile: true,
    render: (_, record) => (
      <Popover
        placement='topRight'
        trigger='click'
        className='popover-actions'
        content={<ListActions {...record} />}
      >
        <Button>
          <IconDots />
        </Button>
      </Popover>
    ),
  },
];

export const filtersCommon = [
  {
    key: 'businessSegment',
    placeholder: 'Por segmento comercial',
    type: 'selectMultiple',
    value: 'id',
    label: 'name',
  },
  {
    key: 'poliza.typePoliza',
    placeholder: 'Por tipo de póliza',
    type: 'selectMultiple',
    value: 'code',
    label: 'name',
  },
  {
    title: 'Mes de renovación',
    key: 'month_renew',
    placeholder: 'Por Mes de renovación',
    type: 'select',
    render: (month_renew) => {
      if (!month_renew) {
        return '';
      }

      const month = dayjs()
        .month(month_renew - 1)
        .format('MMMM');
      return month.charAt(0).toUpperCase() + month.slice(1);
    },
  },
  {
    title: 'Año de renovación',
    key: 'year_renew',
    placeholder: 'Por Año de renovación',
    type: 'select',
  },
];
