import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';
import { RideButton } from '@rimac-seguros/ride-system-components';

import Topbar from '../../../../common/ui/Topbar';
import ServicesAvailable from './ServicesAvailable';
import ServiceDetail from './ServiceDetail';
import ServiceRequest from './ServiceRequest';
import Reports from './Reports';
import Providers from './providers';
import ReportsRimac from './report';
import { useScreen } from '../../../../lib/screen';
import ModalEditContact from './ServiceRequest/components/ModalEditContact';
import { pushDigitalData } from '../../../../lib/utils';
import { TRACK } from '../../../../lib/consts';
import Tabs from '../../../../common/Tabs';

const { Title } = Typography;

const listTabPanelCommon = [
  {
    key: '1',
    component: ServicesAvailable,
    title: 'Servicios disponibles',
  },
  {
    key: '2',
    component: ServiceRequest,
    title: 'Servicios solicitados',
  },
  {
    key: '3',
    component: Reports,
    title: 'Informes técnicos',
  },
];

const listTabAdminRimac = [
  {
    key: '1',
    component: ServiceRequest,
    title: 'Lista de servicios',
  },
  {
    key: '2',
    component: Providers,
    title: 'Lista de proveedores',
  },
  {
    key: '3',
    component: ReportsRimac,
    title: 'Informes técnicos',
  },
];

const PreventionService = () => {
  const [active, setActive] = useState('1');
  const [editContact, setEditContact] = useState(null);
  const userAuth = useSelector((state) => state.auth.user);
  const { widthScreen } = useScreen();
  const isPhone = widthScreen <= 767;

  const navigate = useNavigate();
  const { search } = useLocation();

  const { id, itemId } = useParams();
  const matchId = id && itemId;

  const callback = (key, analytics) => {
    setActive(key);
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0' && analytics) {
      pushDigitalData(
        {
          action: {
            group: 'Centro Monitoreo',
            category: 'Servicios de Prevención',
            name: 'Click',
            label: `Tab - ${
              (isAdminRimac ? listTabAdminRimac : listTabPanelCommon).find(
                (x) => x.key === key,
              )?.title
            }`,
          },
        },
        TRACK.ACTION,
      );
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(search);
    const mapRedirect = {
      services: '1',
      request: '2',
      report: '3',
      poliza: '4',
    };

    callback(mapRedirect[query.get('redirect') || 'services']);

    setTimeout(() => {
      if (query.has('redirect')) {
        query.delete('redirect');
        navigate({ search: query.toString() }, { replace: true });
      }
    }, 800);
  }, []);

  const isAdminRimac = ['admin_rimac', 'gestor_de_cuenta'].includes(
    userAuth?.rol?.slug,
  );

  return matchId ? (
    <ServiceDetail />
  ) : (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />
      {editContact && (
        <ModalEditContact
          editContact={editContact}
          onBack={() => {
            setEditContact(null);
          }}
        />
      )}
      <div className={`d_content  ${editContact ? 'hidden' : 'block'}`}>
        <div className='flex justify-between items-center gap-2 mb-2'>
          <Title className='header-container'>
            Servicios de Prevención Patrimonial
          </Title>
          {!isPhone &&
            ((active === '2' &&
              userAuth.rol.slug === 'administrador_cliente') ||
              (active === '1' && isAdminRimac)) && (
              <RideButton
                size='medium'
                onClick={() => {
                  if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
                    pushDigitalData(
                      {
                        action: {
                          group: 'Centro Monitoreo',
                          category: 'Servicios de Prevención',
                          name: 'Lista de Servicios',
                          label: `Nueva Solicitud`,
                        },
                      },
                      TRACK.ACTION,
                    );
                  }
                  navigate('/solicitar-servicio', { replace: true });
                }}
                text='Nueva solicitud'
              />
            )}
        </div>
        <Tabs
          activeKey={active}
          onChange={(key) => callback(key, true)}
          items={(isAdminRimac ? listTabAdminRimac : listTabPanelCommon).map(
            ({ component: Component, title, key }) => ({
              label: title,
              key: key,
              children: (
                <Component
                  isActive={active === key}
                  isAdminRimac={isAdminRimac}
                  editContact={editContact}
                  companyId={userAuth?.company_id}
                  setEditContact={setEditContact}
                />
              ),
            }),
          )}
        />
      </div>
    </div>
  );
};

export default PreventionService;
