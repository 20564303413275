import React, { useCallback, useState, useEffect } from 'react';
import { Slider, notification } from 'antd';
import Button from '../../../common/Button';
import { genSliderMark } from './utils';

import {
  updateFiltersAlerts,
  getForecastConfigById,
} from '../../../services/locations';
import { pushDigitalData } from '../../../lib/utils';
import { TRACK } from '../../../lib/consts';

const WindsAlerts = ({ data, idLocation, onUpdatedData }) => {
  const [windsState, setWindsState] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setWindsState({
      id: idLocation,
      state: 0,
      winds: {
        min: null,
        max: null,
        value: '',
      },
    });
  }, [idLocation]);

  useEffect(() => {
    if (data) {
      const windsData = data?.type === 'winds';

      if (windsData) {
        if (data.value === null) {
          data.value = 0;
        }
        setWindsState(data);
      } else {
        setWindsState({
          max: null,
          min: null,
          value: 0,
        });
      }
    }
  }, [data]);

  const handleSubmit = useCallback(() => {
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData(
        {
          action: {
            group: 'Centro Monitoreo',
            category: 'Configuración',
            name: `Alerta - Configurar`,
            label: `Vientos - ${windsState.value}`,
          },
        },
        TRACK.ACTION,
      );
    }

    setLoading(true);
    const data = {
      id: idLocation,
      winds: {
        value: windsState.value === '' ? null : windsState.value,
        min: windsState.value === '' ? null : windsState.value,
        max: 1000,
        state: 1,
      },
    };
    updateFiltersAlerts(data)
      .then((res) => {
        if (res === true) {
          notification.success({
            message: 'Alerta de vientos configurada',
            description: 'Se ha configurado la alerta de vientos',
          });
        }
      })
      .finally(() => {
        getForecastConfigById(idLocation).then((res) => {
          if (res) {
            const windsData = res?.find((el) => el.type === 'winds');
            setWindsState(windsData);
            onUpdatedData('winds', windsData);
          }
          setLoading(false);
        });
      });
  }, [windsState]);

  const onDeleteAlert = useCallback(() => {
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData(
        {
          action: {
            group: 'Centro Monitoreo',
            category: 'Configuración',
            name: `Alerta - Eliminar`,
            label: `Vientos - ${windsState.value}`,
          },
        },
        TRACK.ACTION,
      );
    }

    setLoading(true);
    const data = {
      id: idLocation,
      winds: {
        min: null,
        max: null,
        value: null,
        state: 0,
      },
    };
    updateFiltersAlerts(data)
      .then((res) => {
        if (res === true) {
          notification.success({
            message: 'Alerta de vientos eliminada',
            description: 'Se ha eliminado la alerta de vientos',
          });
        }
      })
      .finally(() => {
        getForecastConfigById(idLocation).then((res) => {
          if (res) {
            const windsData = res?.find((el) => el.type === 'winds');

            setWindsState(windsData);
            onUpdatedData('winds', windsData);
          }
          setLoading(false);
        });
      });
  }, [windsState]);

  const handleChangeSlider = useCallback((el) => {
    setWindsState((prev) => ({
      ...prev,
      value: el < 0 ? 0 : el > 30 ? 30 : el,
    }));
  }, []);

  return (
    <div>
      <h5>Ajusta la fuerza del viento</h5>
      <Slider
        marks={{
          0: genSliderMark(0, 'km/h'),
          10: genSliderMark(10, 'km/h'),
          20: genSliderMark(20, 'km/h'),
          30: genSliderMark(30, 'km/h'),
        }}
        step={1}
        min={0}
        max={30}
        value={windsState?.value || windsState?.min || 0}
        tooltip={{
          open: true,
          getPopupContainer: (e) => e,
        }}
        onChange={handleChangeSlider}
        style={{ marginTop: '50px' }}
      />
      <div className='flex flex-col items-center' style={{ marginTop: '64px' }}>
        <Button
          variant='fill'
          style={{ width: '50%' }}
          disabled={windsState?.value == null || loading}
          onClick={handleSubmit}
          loading={loading}
          text='Configurar'
        />
        <Button
          className='mt-2'
          variant='text-primary'
          disabled={windsState.state === 0 || loading}
          onClick={onDeleteAlert}
          loading={loading}
          text='Eliminar alerta'
        />
      </div>
    </div>
  );
};

export default WindsAlerts;
