import React from 'react';
import Legend from '../Legend';

const AccumulatedRainLegend = ({ toggleVisibility }) => {
  return (
    <Legend
      title='Lluvia acumulada en mm.'
      numbers={[0, 5, 10, 20, 40, 60, 80, 100, 125, 170, 200]}
      descriptions={['Sin lluvia acumulada', 'Lluvia acumulada intensa']}
      toggleVisibility={toggleVisibility}
      type='accumulated-rain'
    />
  );
};

export default AccumulatedRainLegend;
