import dayjs from 'dayjs';

export const roundToFiveDecimals = (num) => Math.round(num * 100000) / 100000;

export const buildRequestUrl = (wsmUrl, params) => {
  const queryString = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
    )
    .join('&');
  return `${wsmUrl}?${queryString}`;
};

export const getFormattedTime = (timeDimension) => {
  if (timeDimension) {
    const currentTime = timeDimension.getCurrentTime();
    return dayjs(currentTime).toISOString();
  }
  return null;
};

export const validLayers = [
  'ws',
  'temp',
  'pp',
  'hcwv',
  'vis',
  'hr',
  'lc',
  'hc',
  'mc',
];

export const validTRWF = [
  'ws',
  'temp',
  'pp',
  'hcwv',
  'vis',
  'hr',
  'satellite_c02',
  'satellite_c13',
  'lc',
  'hc',
  'mc',
];
