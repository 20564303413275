import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';
import { Button, Form, notification } from 'antd';
import { DeleteFilled } from '@ant-design/icons';

import { loadCircleDetails } from '../../../../../../lib/panelTool';
import {
  CUMULUS_SET_DRAW_LAYER,
  CUMULUS_SET_MARKER,
} from '../../../../../../redux/actions/actionsType';

import { closePopUp, debounce } from '../../../../../../lib/utils';
import { RideTextField } from '@rimac-seguros/ride-system-components';
import { circleStyle } from '../utils';

const DrawerMarker = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const { map, filters, marker, drawLayer } = useSelector((state) => ({
    map: state.maps.map,
    filters: state.cumulus.filters,
    marker: state.cumulus.marker,
    drawLayer: state.cumulus.drawLayer,
  }));

  const doGetCircleDetails = (circle, filters) => {
    loadCircleDetails({ circle, filters, dispatch, fromDrawer: true, map });
  };

  const handleCreateCircle = async () => {
    try {
      const values = await form.validateFields();
      const coordinate = { lat: values.lat, lng: values.lng };

      if (drawLayer) {
        map.removeLayer(drawLayer);
        const parent = document.querySelector('.leaflet-popup-close-button');
        if (parent) {
          parent.click();
        }
      }

      if (coordinate) {
        closePopUp();
        const circleLayer = L.circle([coordinate.lat, coordinate.lng], {
          ...circleStyle,
          radius: values.radio * 1000,
        });

        setDrawLayer(circleLayer);

        circleLayer.addTo(map).on('click', (e) => {
          L.DomEvent.stopPropagation(e);
          doGetCircleDetails(circleLayer, filters);
        });
        doGetCircleDetails(circleLayer, filters);
      }

      setMarker({ ...coordinate, radius: values.radio });
    } catch {
      notification.error({
        description: 'Hubo un error intente más tarde',
        message: '¡Error!',
      });
    }
  };

  const handleClearFilters = () => {
    form.resetFields();
    clearMarker();
    setDisabled(true);
  };

  const clearMarker = () =>
    dispatch({ type: CUMULUS_SET_MARKER, payload: null });
  const setMarker = (marker) =>
    dispatch({ type: CUMULUS_SET_MARKER, payload: marker });
  const setDrawLayer = (layer) =>
    dispatch({ type: CUMULUS_SET_DRAW_LAYER, payload: layer });

  const handleChange = debounce(() => {
    setDisabled(
      !form.getFieldValue('lat')?.toString() ||
        !form.getFieldValue('lng')?.toString() ||
        !form.getFieldValue('radio')?.toString(),
    );
  }, 500);

  useEffect(() => {
    return () => {
      if (map && drawLayer) {
        map.removeLayer(drawLayer);
        closePopUp();
      }
    };
  }, [map, drawLayer]);

  return (
    <Form form={form} className='form-simulations'>
      <Form.Item
        name='lat'
        initialValue={marker ? marker.lat : ''}
        rules={[{ required: true, message: 'Inserte la Latitud' }]}
      >
        <RideTextField
          label='Latitud'
          value={marker ? marker.lat : ''}
          onChange={handleChange}
        />
      </Form.Item>

      <Form.Item
        name='lng'
        initialValue={marker ? marker.lng : ''}
        rules={[{ required: true, message: 'Inserte la Longitud' }]}
      >
        <RideTextField
          label='Longitud'
          value={marker ? marker.lng : ''}
          onChange={handleChange}
        />
      </Form.Item>

      <Form.Item
        name='radio'
        initialValue={marker ? marker.radius : ''}
        rules={[{ required: true, message: 'Inserte un radio' }]}
      >
        <RideTextField
          label='Radio (Km)'
          value={marker ? marker.radius : ''}
          onChange={handleChange}
        />
      </Form.Item>

      <div className='form-actions'>
        <Button
          type='submit'
          className='form-button-success'
          onClick={handleCreateCircle}
          disabled={disabled}
        >
          Consultar
        </Button>
        <Button
          type='link'
          className='form-button-link'
          onClick={handleClearFilters}
        >
          <DeleteFilled />
          Limpiar
        </Button>
      </div>
    </Form>
  );
};

export default DrawerMarker;
