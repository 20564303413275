import { get, post } from '../lib/ajax';
import {
  LOADING_RPT_QUIZZES,
  LOADING_RPT_QUIZZES_FAILED,
} from '../redux/actions/actionsType';
import { handleSuccessResponse } from './survey.utils';

export const reportQuizzes = async (dispatch, reportType, params = {}) => {
  dispatch({ type: LOADING_RPT_QUIZZES, payload: true });
  let r = await post(`/request_service_items/${reportType}`, params);
  if (r?.data?.success) {
    handleSuccessResponse(dispatch, reportType, r);
  } else {
    dispatch({ type: LOADING_RPT_QUIZZES_FAILED });
  }
};

export const getIncompleteSurveys = async () => {
  const r = await get('/request_service_items/surver_list');
  if (r?.data?.success) return r.data.payload;
};
