export const listSider = [
  {
    id: '0',
    title: 'Menu Mobile',
    icon: 'menu',
  },
  {
    id: '1',
    title: 'Acercar',
    icon: 'zoom-in',
  },
  {
    id: '2',
    title: 'Alejar',
    icon: 'zoom-out',
  },
  {
    id: '3',
    title: 'Buscar',
    icon: 'search',
    style: true,
    descriptions: 'Busca ubicaciones por la dirección o nombre de empresa.',
    drawer: true,
  },
  {
    id: '4',
    title: 'Capas',
    icon: 'layers',
    descriptions:
      'Visualiza los fenómenos y eventos que pueden afectar tu operación.',
    drawer: true,
  },
  {
    id: 'my-layers',
    title: 'Mis Capas',
    icon: 'my-layers',
    descriptions: 'Carga tus propios archivos para visualizar tus capas.',
    drawer: true,
  },
  {
    id: 'utils',
    title: 'Utilidades',
    icon: 'utils',
    descriptions: 'Agrega nuevas capas a tu capa principal.',
    permission: 'show_utility_sidemenu',
    drawer: true,
  },
  {
    id: 'my-routes',
    title: 'Mis Rutas',
    icon: 'my-routes',
    descriptions: 'Genera rutas basadas en puntos seleccionados en el mapa.',
    drawer: true,
  },
  {
    id: 'cumulusTools',
    title: 'Herramientas para Cúmulos',
    icon: 'cumulus-tools',
    style: true,
    descriptions: 'Conjunto de herramientas para trabajar con cúmulos.',
    drawer: true,
  },
  {
    id: 'tools',
    title: 'Herramientas',
    icon: 'tools',
    descriptions: 'Amplía tus capacidades con nuestras herramientas.',
    drawer: true,
  },
  {
    id: 'alerts',
    title: 'Alertas',
    icon: 'alerts',
    descriptions:
      'Recibe alertas en tu correo que te ayude a prevenir riesgos a tiempo.',
    permission: 'show_alert_sidemenu',
    drawer: true,
  },
  {
    id: '6',
    title: 'Filtros',
    icon: 'filters',
    descriptions: 'Selecciona sólo las opciones que deseas visualizar.',
    permission: 'show_filters_sidemenu',
    drawer: true,
  },
  {
    id: 'forecast',
    title: 'Pronósticos',
    icon: 'forecasts',
    descriptions:
      'Revisa los pronósticos aplicados a tus ubicaciones favoritas o punto seleccionado en el mapa.',
    style: true,
    drawer: true,
  },
  {
    id: '10',
    title: 'Información de capa',
    icon: 'information',
    drawer: true,
    descriptions: 'Conoce más de la capa elegida a monitorear.',
  },
];

export const checkLayerHasFilters = (
  layers,
  activeLayerId,
  activeSubLayerId,
) => {
  const activeLayer = layers.find((layer) => layer.id === activeLayerId);

  if (!activeLayer) {
    return false;
  }

  if (activeLayer.filters === true) {
    return true;
  }

  if (activeLayer.mode) {
    const activeSubLayer = activeLayer.mode.find(
      (subLayer) => subLayer.id === activeSubLayerId,
    );

    if (activeSubLayer && activeSubLayer.filters === true) {
      return true;
    }
  }

  return false;
};
