import dayjs from 'dayjs';

export const getMonthNames = (id) => {
  let months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  return months[id];
};

export const isValidField = (type, value) => {
  switch (type) {
    case 'number':
      return !isNaN(value);
    case 'text':
      return Boolean(value && String(value).trim().length);
    case 'email':
      const regExp = new RegExp(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      );
      return regExp.test(String(value).trim());
    default:
      return Boolean(value);
  }
};

export const fields = [
  {
    key: 'ruc',
    type: 'text',
    valid: 'text',
    label: 'Ruc',
    required: true,
  },
  {
    key: 'business_name',
    label: 'Razon social',
    valid: 'text',
    type: 'select',
    required: true,
  },
  {
    key: 'business_segment',
    type: 'select',
    label: 'Segmento comercial',
    required: true,
  },
  {
    key: 'poliza_id',
    type: 'select',
    label: 'Póliza',
    required: true,
  },
  {
    key: 'month_renew',
    type: 'select',
    label: 'Mes de renovación',
    options: [...new Array(12)].map((_, index) => ({
      label: getMonthNames(index),
      value: (index + 1).toString().padStart(2, '0'),
    })),
  },
  {
    key: 'year_renew',
    type: 'select',
    label: 'Año de renovación',
    options: Array.from({ length: 6 }, (_, i) => {
      const year = dayjs()
        .subtract(4 - i, 'year')
        .format('YYYY');
      return { value: year, label: year };
    }),
  },
  {
    key: 'prima',
    type: 'text',
    label: 'Prima (US$)',
    required: true,
  },
  {
    key: 'market_cost',
    type: 'number',
    label: 'Costo mercado',
    optional: true,
  },
  {
    key: 'file',
    type: 'file',
    title: 'Subir archivo *',
    required: true,
  },
  {
    key: 'observation',
    type: 'textarea',
    label: 'Observaciones',
    maxLength: 100,
    rows: 4,
  },
];
