import React, { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

const wsUrl = process.env.REACT_APP_GEOSERVER_MAPS_NEW;

const VolcanoLayer = () => {
  const map = useMap();
  const [showAshes, setShowAshes] = useState(true);

  useEffect(() => {
    if (!map) return;

    const wmsLayer = L.tileLayer.wms(wsUrl, {
      layers: 'mapa_volcanes',
      format: 'image/png',
      tileSize: 256,
      transparent: true,
      version: '1.3.0',
    });

    wmsLayer.addTo(map);

    return () => {
      map.removeLayer(wmsLayer);
    };
  }, [map]);

  useEffect(() => {
    if (!map) return;

    const ashLayerUbinas = L.tileLayer.wms(
      'https://ide.igp.gob.pe/geoserver/ceniza_ubinas/wms',
      {
        layers: 'ceniza_ubinas:cenizas_ubinas',
        format: 'image/png',
        transparent: true,
        version: '1.1.0',
      },
    );

    const ashLayerSabancaya = L.tileLayer.wms(
      'https://ide.igp.gob.pe/geoserver/ceniza_sabancaya/wms',
      {
        layers: 'ceniza_sabancaya:cenizas_sabancaya',
        format: 'image/png',
        transparent: true,
        version: '1.1.0',
      },
    );

    if (showAshes) {
      ashLayerUbinas.addTo(map);
      ashLayerSabancaya.addTo(map);
    } else {
      map.removeLayer(ashLayerUbinas);
      map.removeLayer(ashLayerSabancaya);
    }

    return () => {
      map.removeLayer(ashLayerUbinas);
      map.removeLayer(ashLayerSabancaya);
    };
  }, [map, showAshes]);

  const handleCheckboxChange = (e) => {
    e.stopPropagation();
    setShowAshes(e.target.checked);
  };

  return (
    <div
      style={{
        position: 'absolute',
        top: 120,
        right: 10,
        backgroundColor: 'white',
        zIndex: 1000,
        padding: 10,
        borderRadius: 5,
      }}
    >
      <label>
        <input
          type='checkbox'
          checked={showAshes}
          onChange={handleCheckboxChange}
        />
        Mostrar cenizas
      </label>
    </div>
  );
};

export default VolcanoLayer;
