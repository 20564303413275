import { Fragment } from 'react';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/es_ES';

import { Button, DatePicker } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { IconCloseCircle, IconFilter } from '../../../../common/Icons';
import MultipleSelect from '../../../../common/MultipleSelect';
import { useScreen } from '../../../../lib/screen';
import { filtersCommon } from './utils';

const { RangePicker } = DatePicker;

const getCopy = (viewType, nounType) => {
  if (nounType === 'singular') {
    return viewType === 'defeated' ? 'póliza vencida' : 'póliza por vencer';
  } else {
    return viewType === 'defeated' ? 'pólizas vencidas' : 'pólizas por vencer';
  }
};

const RenderFilterAndInfo = ({
  onChangeDates,
  onUpdateFilters,
  onClearOptions,
  onClearFilters,
  onModalFilter,
  filterMobile,
  filterDate,
  filtersOptions,
  listFiltered,
  filterSwitchComponent: FilterSwitch = () => <></>,
  viewType,
}) => {
  const { isBeforeTable, isMobileScreen } = useScreen();

  return (
    <div
      className='filter-section filter-custom filter-defeated'
      style={{
        marginBottom: isBeforeTable || isMobileScreen ? '0' : '32px auto',
      }}
    >
      <div
        className={`filter__box filter-news ${
          filterMobile ? 'modal__filter' : ''
        }`}
        style={{ border: 'none', alignItems: 'flex-start' }}
      >
        {filterMobile && (
          <>
            <div className='modal__filter--close' onClick={onModalFilter}>
              <span>Cerrar</span>
              <IconCloseCircle />
            </div>
            <h3 className='modal__filter--title'>
              {viewType === 'defeated'
                ? 'Filtrar pólizas vencidas'
                : 'Filtrar pólizas por vencer'}
            </h3>
          </>
        )}

        {filtersCommon.map(({ key, type, ...item }) => (
          <Fragment key={key}>
            {type === 'selectMultiple' ? (
              <MultipleSelect
                filters={filtersOptions[key] || []}
                updateFilterColumns={onUpdateFilters}
                clearOptions={onClearOptions}
                style={{ minWidth: 'auto' }}
                placeholder={item?.placeholder}
                name={key}
              />
            ) : null}
          </Fragment>
        ))}

        <RangePicker
          value={[
            filterDate?.start && dayjs(filterDate?.start),
            filterDate?.end && dayjs(filterDate?.end),
          ]}
          name='date'
          placeholder={['Fecha de inicio', 'Fecha de fin']}
          format='DD-MM-YYYY'
          onChange={onChangeDates}
          locale={locale}
        />

        <Button
          type='link'
          className='btn-clearFilter'
          onClick={onClearFilters}
        >
          <DeleteOutlined />
          Borrar Filtros
        </Button>

        {filterMobile && (
          <button
            className='modal__filter--button'
            type='button'
            onClick={onModalFilter}
          >
            Filtrar
          </button>
        )}
      </div>

      <div
        className='flex flex-row flex-justify-justify-between with-border'
        style={{
          alignItems: 'center',
          columnGap: 32,
          marginBottom: 24,
          marginTop: isMobileScreen ? 0 : 32,
        }}
      >
        <p className='info-total-data' style={{ margin: 0 }}>
          Se encontraron <span>{listFiltered.length}</span>{' '}
          {listFiltered.length === 1
            ? getCopy(viewType, 'singular')
            : getCopy(viewType, 'plural')}
        </p>

        <div className='filter-action' onClick={onModalFilter}>
          <IconFilter />
          <span>Filtrar</span>
        </div>

        {!isMobileScreen && <FilterSwitch />}
      </div>

      {isMobileScreen ? <FilterSwitch /> : null}
    </div>
  );
};

export default RenderFilterAndInfo;
