import { useEffect, Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { connect, useDispatch } from 'react-redux';

import {
  MAP_SET_ACTIVE_LAYER,
  MAP_SET_ACTIVE_SUBLAYER,
  MAP_SET_MAP,
} from '../../redux/actions/actionsType';
import { compareLayersIds, selectSublayer } from './utils';
import { checkPermission } from '../../lib/security';
import getMapProperties, {
  updateMapProperties,
  handleNewLocation,
  handleLayerChange,
} from './mapProperties';

import ControlMap from '../../common/ui/ControlMap';
import { layers, weatherForecast } from '../../common/SideMenu/data';
import WMS from './LayersTypes/WMS';
import SubLayerMapTop from './SubLayerMapTop';
import LegendColors from './LegendBottom/LegendColors';
// import LayerAirports from './LayersTypes/LayerAirports';
import LayerSocialConflict from './LayersTypes/LayerSocialConflict';
import LayerRoadEmergency from './LayersTypes/LayerRoadEmergency';
import LayerFireMan from './LayersTypes/LayerFireMan';
import LayerPoints from './LayersTypes/LayerPoints';
import LayerEarthquake from './LayersTypes/LayerEarthquake';
import LayerFlashed from './LayersTypes/LayerFlashed';
import LayerGeoTiff from './LayersTypes/LayerGeoTiff';
import LayerWeatherForecast from './LayersTypes/LayerWeatherForecast';
import LayerGeologicalHazard from './LayersTypes/LayerGeologicalHazard';
import RingsFlash from './LayersTypes/RingsFlash';
import MultiLayerWMS from './LayersTypes/MultiLayerWMS';

import LegendBottom from './LegendBottom';
import LegendEarthquakes from './LegendBottom/LegendEarthquakes';
import LegendAcceleration from './LegendBottom/LegendAcceleration';
import LegendTop from './LegendTop';

import MarqueeContainer from './MarqueeContainer';
import MyMaps from './MyMaps';
import MyRoutes from './LayersTypes/MyRoutes';
import ModalNewLocation from './ModalNewLocation';
import Tools from './Tools';
import UtilsLayers from './UtilsLayers';
import CreateMyMap from './CreateMyMap';
import CreateMyRoute from './CreateMyRoute';
import FloatingSwitcher from './LayersTypes/LayerPoints/FloatingSwitcher';
import LayerTsunami from './LayersTypes/LayerTsunami';
import Popups from './Popups';
import Logo from './Logo';
import { loadGoogleApi } from '../../lib/google';

const Index = ({
  map,
  setMap,
  activeLayer,
  user,
  activeSubLayer,
  activeUtilsLayer,
  selectedLegend,
  isOpened,
  drawMode,
  createRouteMode,
  multiSelectMode,
  activeMultiLayers,
  setActiveLayer,
  setActiveSubLayer,
  drawerVisible,
  siderVisible,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [lastEarthquake, setLastEarthquake] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    handleNewLocation(dispatch, setIsModalVisible, navigate);
  }, []);

  useEffect(() => {
    handleLayerChange(
      loadGoogleApi,
      layers,
      setActiveLayer,
      setActiveSubLayer,
      setLastEarthquake,
      navigate,
      user,
      activeLayer,
      selectSublayer,
    );
  }, [activeLayer]);

  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const renderLayer = ({ type, activeSubLayer, opacity, isAnimate }) => {
    const LAYERS = {
      'layer-map': (
        <MultiLayerWMS
          activeSubLayer={activeSubLayer}
          isAnimate={isAnimate}
          opacity={opacity}
        />
      ),
      // 'layer-airports': <LayerAirports opacity={opacity} />,
      'layer-social-conflicts': <LayerSocialConflict opacity={opacity} />,
      'layer-road-emergency': <LayerRoadEmergency opacity={opacity} />,
      'layer-fireman': <LayerFireMan opacity={opacity} />,
      'layer-earthquake': <LayerEarthquake opacity={opacity} />,
      'layer-tsunami': <LayerTsunami opacity={opacity} />,
      'layer-flashed': (
        <LayerFlashed activeSubLayer={activeSubLayer} opacity={opacity} />
      ),
      'layer-ligthing_den': (
        <LayerGeoTiff activeSubLayer={activeSubLayer} opacity={opacity} />
      ),
      'layer-ligthing_ene': (
        <LayerGeoTiff activeSubLayer={activeSubLayer} opacity={opacity} />
      ),
      'layer-ligthing_aen': (
        <LayerGeoTiff activeSubLayer={activeSubLayer} opacity={opacity} />
      ),
      'layer-rainfall-efm': (
        <LayerWeatherForecast
          opacity={opacity}
          colors={weatherForecast['rainfall-efm'].colors}
          type={weatherForecast['rainfall-efm'].type}
        />
      ),
      'layer-rainfall': (
        <LayerWeatherForecast
          opacity={opacity}
          colors={weatherForecast.rainfall.colors}
          type={weatherForecast.rainfall.type}
        />
      ),
      'layer-max-temperature': (
        <LayerWeatherForecast
          opacity={opacity}
          colors={weatherForecast['max-temperature'].colors}
          type={weatherForecast['max-temperature'].type}
        />
      ),
      'layer-min-temperature': (
        <LayerWeatherForecast
          opacity={opacity}
          colors={weatherForecast['min-temperature'].colors}
          type={weatherForecast['min-temperature'].type}
        />
      ),
      'layer-geological_hazards': (
        <LayerGeologicalHazard
          activeSubLayer={activeSubLayer}
          opacity={opacity}
        />
      ),
    };

    return LAYERS[type];
  };

  useEffect(() => {
    updateMapProperties(map, activeLayer);
  }, [activeLayer?.id]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
      }}
    >
      <div
        className={'relative'}
        style={{ width: '100%', height: '100%', overflow: 'hidden' }}
      >
        <MarqueeContainer />
        <Logo />
        {checkPermission(user, 'view_switch_favorites') && (
          <FloatingSwitcher
            isToggled={isToggled}
            onToggle={handleToggle}
            drawerVisible={drawerVisible}
            siderVisible={siderVisible}
          />
        )}
        <MapContainer
          style={{ height: 'calc(100vh - 36px)', width: '100%' }}
          center={[-8.9382, -74.8882]}
          zoom={6}
          minZoom={getMapProperties(activeLayer).minZoom}
          maxBounds={getMapProperties(activeLayer).maxBounds}
          scrollWheelZoom={true}
          zoomControl={false}
          editable
          ref={setMap}
        >
          {!multiSelectMode ? (
            <>
              {activeSubLayer?.type === 'layer-map' && <WMS />}
              {/* {activeSubLayer?.type === 'layer-airports' && <LayerAirports />} */}
              {activeSubLayer?.type === 'layer-social-conflicts' && (
                <LayerSocialConflict />
              )}
              {activeSubLayer?.type === 'layer-road-emergency' && (
                <LayerRoadEmergency />
              )}
              {activeSubLayer?.type === 'layer-fireman' && <LayerFireMan />}
              {activeSubLayer?.type === 'layer-earthquake' && (
                <LayerEarthquake lastEarthquake={lastEarthquake} />
              )}
              {activeSubLayer?.type === 'layer-tsunami' && <LayerTsunami />}
              {activeSubLayer?.type === 'layer-flashed' && <LayerFlashed />}
              {activeSubLayer?.type === 'layer-ligthing_den' && (
                <LayerGeoTiff />
              )}
              {activeSubLayer?.type === 'layer-ligthing_ene' && (
                <LayerGeoTiff />
              )}
              {activeSubLayer?.type === 'layer-ligthing_aen' && (
                <LayerGeoTiff />
              )}
              {activeSubLayer?.type === 'layer-rainfall-efm' && (
                <LayerWeatherForecast
                  colors={weatherForecast['rainfall-efm'].colors}
                  type={weatherForecast['rainfall-efm'].type}
                />
              )}
              {activeSubLayer?.type === 'layer-rainfall' && (
                <LayerWeatherForecast
                  colors={weatherForecast.rainfall.colors}
                  type={weatherForecast.rainfall.type}
                />
              )}
              {activeSubLayer?.type === 'layer-max-temperature' && (
                <LayerWeatherForecast
                  colors={weatherForecast['max-temperature'].colors}
                  type={weatherForecast['max-temperature'].type}
                />
              )}
              {activeSubLayer?.type === 'layer-min-temperature' && (
                <LayerWeatherForecast
                  colors={weatherForecast['min-temperature'].colors}
                  type={weatherForecast['min-temperature'].type}
                />
              )}
              {activeSubLayer?.type === 'layer-geological_hazards' && (
                <LayerGeologicalHazard />
              )}
            </>
          ) : (
            <>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              />
              {activeMultiLayers.map(
                ({ activeSubLayer, opacity }, _, multiLayers) => {
                  const type = activeSubLayer?.type;
                  const key = `${activeLayer?.id}-${activeSubLayer?.id}`;
                  const lastSubLayerWithAnimate = multiLayers.findLast(
                    (layer) => layer?.activeSubLayer?.animate,
                  )?.activeSubLayer;
                  const isAnimate = compareLayersIds(
                    activeSubLayer,
                    lastSubLayerWithAnimate,
                  );

                  return (
                    <Fragment key={key}>
                      {renderLayer({
                        type,
                        activeSubLayer,
                        opacity,
                        isAnimate,
                      })}
                    </Fragment>
                  );
                },
              )}
            </>
          )}
          <LayerPoints isToggled={isToggled} />
          <UtilsLayers />
          <Popups />
          <MyMaps />
          <MyRoutes />
          {activeLayer?.id === 'ligthing' && (
            <RingsFlash isToggled={isToggled} />
          )}
          {drawMode && <CreateMyMap />}
          {createRouteMode && <CreateMyRoute />}
        </MapContainer>
      </div>
      {multiSelectMode === false && (
        <>
          <SubLayerMapTop />
          {['emergencias', 'geological', 'weather-forecast'].includes(
            activeLayer?.id,
          ) && <LegendColors />}
          {['sismos', 'tsunamis'].includes(activeLayer?.id) && (
            <LegendEarthquakes />
          )}
          {activeLayer?.id === 'sismos' && <LegendAcceleration />}
          <LegendBottom />
        </>
      )}
      {activeUtilsLayer.findIndex((layer) => layer.key === 'cumulo') !== -1 &&
        selectedLegend !== '' && <LegendTop />}
      <Tools />
      <ControlMap />
      <ModalNewLocation
        visible={isModalVisible}
        setVisible={setIsModalVisible}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setActiveLayer: (layer) =>
    dispatch({ type: MAP_SET_ACTIVE_LAYER, payload: layer }),
  setActiveSubLayer: (layer) =>
    dispatch({ type: MAP_SET_ACTIVE_SUBLAYER, payload: layer }),
  setMap: (map) => dispatch({ type: MAP_SET_MAP, payload: map }),
});

const mapStateToProps = (state) => ({
  map: state.maps.map,
  activeLayer: state.maps.activeLayer,
  activeSubLayer: state.maps.activeSubLayer,
  activeUtilsLayer: state.maps.activeUtilsLayer,
  selectedLegend: state.cumulus.selectedLegend,
  user: state.auth.user,
  isOpened: state.sideMenu.isOpened,
  drawMode: state.myLayers.drawMode,
  createRouteMode: state.myRoutes.createRouteMode,
  multiSelectMode: state.maps.multiSelectMode,
  activeMultiLayers: state.maps.activeMultiLayers,
  drawerVisible: state.controlMap.drawerVisible,
  siderVisible: state.controlMap.siderVisible,
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
