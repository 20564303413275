import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMapEvents, useMap } from 'react-leaflet';
import L from 'leaflet';

import {
  CUMULUS_SET_SELECTED_LEGEND,
  MAP_CHANGE_BLOCKED_CLICK,
} from '../../../../redux/actions/actionsType';
import getDataCircle from './getDataCircle';
import { getIcon, scaleGroup } from './utils';

const Cumulus = ({ activateCumulusTool }) => {
  const map = useMap();

  const dispatch = useDispatch();
  const selectedLegend = useSelector((state) => state.cumulus.selectedLegend);
  const filters = useSelector((state) => state.cumulus.filters);

  const [points, setPoints] = useState([]);
  const pointsLayer = useRef();
  const layersGroups = useRef();

  let mapEvents = useMapEvents({
    zoomstart() {
      setPoints([]);
    },
    zoomend() {
      if (layersGroups.current) {
        layersGroups.current.bringToFront();
      }
    },
  });

  useEffect(() => {
    if (!activateCumulusTool) {
      if (layersGroups.current) {
        layersGroups.current.removeFrom(map);
      }
      if (pointsLayer.current) {
        pointsLayer.current.clear();
      }
      return;
    }

    setPoints([]);

    layersGroups.current = new L.GridLayer();
    layersGroups.current.createTile = function (coords, done) {
      const tile = L.DomUtil.create('canvas', 'leaflet-tile');
      let error;
      const ctx = tile.getContext('2d');
      const size = this.getTileSize();
      tile.width = size.x;
      tile.height = size.y;

      const nwPoint = coords.scaleBy(size);
      const nwLatLng = mapEvents.unproject(nwPoint, coords.z);

      const sePoint = {
        ...nwPoint,
        y: nwPoint.y + size.y,
        x: nwPoint.x + size.x,
      };
      const seLatLng = mapEvents.unproject(sePoint, coords.z);

      const data01 = {
        north: nwLatLng.lat,
        south: seLatLng.lat,
        west: nwLatLng.lng,
        east: seLatLng.lng,
        filters,
      };
      getDataCircle(coords, data01)
        .then((response) => {
          if (response.center && response.total) {
            const pointToAbsolutePixel = mapEvents.project(
              window.L.latLng(response.center),
              coords.z,
            );
            let diffX = pointToAbsolutePixel.x - nwPoint.x;
            let diffY = pointToAbsolutePixel.y - nwPoint.y;

            const radio = scaleGroup(response.total);
            if (diffX + radio > 256) {
              diffX = 256 - radio;
            }

            if (diffX - radio < 0) {
              diffX = radio;
            }

            if (diffY + radio > 256) {
              diffY = 256 - radio;
            }

            if (diffY - radio < 0) {
              diffY = radio;
            }

            ctx.beginPath();
            ctx.strokeStyle = '#e3000e';
            ctx.arc(diffX, diffY, scaleGroup(response.total), 0, 2 * Math.PI);
            ctx.fillStyle = 'rgba(227,93,101,0.36)';
            ctx.fill();
            ctx.fillStyle = '#e3000e';
            ctx.lineWidth = 0.2;
            ctx.stroke();

            ctx.translate(diffX, diffY);
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = 'white';
            ctx.fillText(response.total, 0, 0);
            ctx.strokeStyle = 'blue';
            ctx.beginPath();
          }
          if (response.points) {
            setPoints((points) => [...points, ...response.points]);
          }

          done(error, tile);
        })
        .catch((error) => {
          done(error, tile);
        });

      return tile;
    };
    layersGroups.current.addTo(map);

    return () => {
      if (layersGroups.current) layersGroups.current.removeFrom(map);
    };
  }, [filters, selectedLegend, activateCumulusTool]);

  useEffect(() => {
    return () => {
      dispatch({ type: CUMULUS_SET_SELECTED_LEGEND, payload: '' });
    };
  }, []);

  useEffect(() => {
    dispatch({ type: MAP_CHANGE_BLOCKED_CLICK, payload: activateCumulusTool });

    if (!map || !activateCumulusTool) {
      return;
    }

    let markers = points?.map((point) => {
      const {
        xgeo,
        ygeo,
        cenpred_riesgo_inundacion,
        cenpred_riesgo_movimiento_masa,
      } = point;

      let icon = getIcon(
        cenpred_riesgo_inundacion,
        cenpred_riesgo_movimiento_masa,
        selectedLegend,
      );

      let marker = L.marker([ygeo, xgeo], {
        icon: L.icon({
          iconUrl: icon,
          iconSize: [12, 12],
          iconAnchor: [6, 4],
        }),
      });

      return marker.bindPopup(`
        <div class='popup-map'>
          <div class='popup-title'>
            <div class='layer-name'>Detalle</div>
          </div>
          <div class='popup-content popup-map-scrollable'>
            <p><b>Producto: </b><span class='capitalize'>${String(
              point.prod,
            )?.toLowerCase()}</span></p>
            <p><b>Nombre del Cliente: </b><span class='capitalize'>${point.nombre_cliente?.toLowerCase()}</span></p>
            <p><b>Monto del Valor: </b><span class='capitalize'>${String(
              point.monto_valor,
            )?.toLowerCase()}</span></p>
            <p><b>Dirección del Riesgo:</b> <span class='capitalize'>${point.direccion_del_riesgo?.toLowerCase()}</span></p>
            <p><b>Departamento: </b><span class='capitalize'>${point.departamento?.toLowerCase()}</span></p>
            <p><b>Provincia:</b> <span class='capitalize'>${point.provincia?.toLowerCase()}</span></p>
            <p><b>Distrito:</b> <span class='capitalize'>${point.districto?.toLowerCase()}</span></p>
            <p><b>Riesgo de Inundación:</b> <span class='capitalize'>${
              point.cenpred_riesgo_inundacion?.toLowerCase() || '-'
            }</span></p>
            <p><b>Riesgo de Movimientos en Masa:</b> <span class='capitalize'>${
              point.cenpred_riesgo_movimiento_masa?.toLowerCase() || '-'
            }</span></p>
          </div>
        </div>
      `);
    });

    if (pointsLayer.current) {
      pointsLayer.current.clear();
      pointsLayer.current.addMarkers(markers);
    } else {
      pointsLayer.current = new L.MarkersCanvas();
      pointsLayer.current.onAdd(map);
      pointsLayer.current.addMarkers(markers);
      pointsLayer.current.redraw();

      const currentCenter = map.getCenter();
      map.setView(currentCenter);
    }
  }, [map, points, activateCumulusTool]);

  return null;
};

export default Cumulus;
