import dayjs from 'dayjs';
import { parseDayjsDate } from '../../../lib/utils';

export const getServiceDate = (item) => {
  const technicalReports = item.request_service_item_results;

  if (technicalReports && technicalReports.length > 0) {
    const technicalReport = technicalReports.at(-1);

    return parseDayjsDate(technicalReport?.date);
  }

  const date = item?.requestServiceItemsData?.find(
    (rsId) => rsId.key === 'event_date',
  )?.value;

  return date ? dayjs.unix(date) : null;
};
