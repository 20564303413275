import { useState } from 'react';
import { Button, Table, Tooltip } from 'antd';
import {
  UploadOutlined,
  CalendarOutlined,
  EyeOutlined,
} from '@ant-design/icons';

import { parseDayjsDate } from '../../../lib/utils';
import FormCreateEventDate from './FormCreateEventDate';
import { getStateRequestServiceItem } from '.';
import ButtonRsiParticipants from './ButtonRsiParticipants';

const ExpandedCapacitacion = ({
  allListCourses,
  item,
  handlerSendEventDateCapacitacion,
  handlerShowDetailsCapacitacion,
  handlerUploadTechnicalReport,
  handlerCheckParticipants,
  userSlug,
  isPreliquidation,
}) => {
  const [
    visibleFormEventDateCapacitacion,
    setVisibleFormEventDateCapacitacion,
  ] = useState(false);
  const [visibleFormEventDateEdit, setVisibleFormEventDateEdit] =
    useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [courseKeySelected, setCourseKeySelected] = useState(null);

  const handlerCreateEventDateCapacitacion = (course_key) => {
    setItemSelected(item);
    setCourseKeySelected(course_key);
    setVisibleFormEventDateCapacitacion(true);
  };
  const handlerCreateEventDateCapacitacionEdit = (course_key) => {
    setItemSelected(item);
    setCourseKeySelected(course_key);
    setVisibleFormEventDateCapacitacion(true);
    setVisibleFormEventDateEdit(true);
  };

  const capacitationColumns = [
    {
      title: 'Fecha',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 90,
      align: 'center',
    },
    {
      title: 'Servicio',
      dataIndex: 'courseName',
      key: 'requestServiceItemsServicio',
      width: 220,
    },
    ...(isPreliquidation
      ? [
          {
            title: 'Zona',
            dataIndex: 'request_service_item_results',
            key: 'request_service_item_results_zone',
            width: 200,
            render: (_, item) =>
              `${item?.request_service_item_results?.[0]?.zone ?? ''}`,
          },
          {
            title: 'Precio',
            dataIndex: 'request_service_item_results',
            key: 'request_service_item_results_price',
            width: 120,
            render: (_, item) =>
              `${item?.request_service_item_results?.[0]?.price ?? ''}`,
          },
        ]
      : []),
    {
      title: 'Empresa',
      dataIndex: 'requestServiceItems',
      key: 'requestServiceItemsEmpresa',
      width: 200,
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'state',
      width: 160,
      render: (_status, record) => {
        const technicalReport = item?.request_service_item_results?.find(
          ({ course }) => course === record.key,
        );
        const finalStatus = technicalReport ? 6 : _status;
        const { color, status, dot } =
          getStateRequestServiceItem(finalStatus) || {};

        return (
          <div
            className='flex flex-row flex-justify-justify-between'
            style={{ height: '20px' }}
          >
            <span
              style={{ background: color, borderRadius: 6, padding: '2px 8px' }}
              className={'withpoint'}
            >
              <div
                style={{ background: dot }}
                className={'withpoint-circle'}
              ></div>
              <span>{status}</span>
            </span>
          </div>
        );
      },
    },
    {
      title: 'F. Programada',
      key: 'fservice',
      width: 140,
      align: 'center',
      render: (_, record) => record.fservice,
    },
    {
      title: 'F. Ejecutada',
      key: 'finforme',
      dataIndex: 'finforme',
      width: 110,
      align: 'center',
    },
    {
      title: 'Acción',
      dataIndex: 'operation',
      key: 'operation',
      width: 110,
      render: (_, record) => {
        const technicalReport = item?.request_service_item_results?.find(
          ({ course }) => course === record.key,
        );

        return (
          !isPreliquidation && (
            <span className='table-operation'>
              {technicalReport && (
                <Tooltip title='Detalles'>
                  <Button
                    size='small'
                    className='btn-cmo-action-list'
                    onClick={() =>
                      handlerShowDetailsCapacitacion(item, technicalReport)
                    }
                  >
                    <EyeOutlined />
                  </Button>
                </Tooltip>
              )}
              {!record.hideScheduleDate && (
                <Tooltip title='Agendar fecha de visita'>
                  <Button
                    size='small'
                    className='btn-cmo-action-list'
                    onClick={() =>
                      handlerCreateEventDateCapacitacion(record.key)
                    }
                  >
                    <CalendarOutlined />
                  </Button>
                </Tooltip>
              )}
              {userSlug === 'proveedor' && (
                <>
                  {record.hideScheduleDate && record.status !== 6 && (
                    <Tooltip title='Agendar fecha de visita'>
                      <Button
                        size='small'
                        className='btn-cmo-action-list'
                        onClick={() =>
                          handlerCreateEventDateCapacitacionEdit(record.key)
                        }
                      >
                        <CalendarOutlined />
                      </Button>
                    </Tooltip>
                  )}
                </>
              )}
              {(record.status === 4 ||
                record.status === 5 ||
                item.state === 4 ||
                item.state === 5 ||
                (item.editable && record.status && technicalReport)) && (
                <Tooltip title={'Subir informe técnico'}>
                  <Button
                    size='small'
                    className='btn-cmo-action-list'
                    onClick={() =>
                      handlerUploadTechnicalReport(item, record.key)
                    }
                  >
                    <UploadOutlined />
                  </Button>
                </Tooltip>
              )}
              <ButtonRsiParticipants
                courseData={record}
                onClick={() => handlerCheckParticipants(item, record)}
              />
            </span>
          )
        );
      },
    },
    {
      title: '',
      width: 120,
    },
  ];

  const getDateServiceCapacitacion = (item, course_key) => {
    const technicalReports = item.request_service_item_results;
    if (technicalReports) {
      const technicalReport = technicalReports.find(
        (report) => report.course === course_key,
      );
      if (technicalReport) {
        return parseDayjsDate(technicalReport.date);
      }
    }
  };

  const transformCapacitacionToList = (item) => {
    const data = item.requestServiceItemsData?.find(
      (rsid) => rsid.key === 'courses',
    );

    if (data?.courses) {
      const courses = data.courses;
      const coursesParse = Object.keys(courses).map((key) => {
        const course = courses[key];

        if (!course?.course) return null;

        let fservice = null;
        const visitDate = parseDayjsDate(course.visitDate);

        if (!fservice) {
          fservice = visitDate;
        }

        const finforme = getDateServiceCapacitacion(item, key);

        let courseFounded = '';
        if (!course.course)
          courseFounded = allListCourses.find(
            (course) => course.id === courses[key].courseId,
          );

        return {
          key,
          courseName: course.course
            ? course.course
            : courseFounded
            ? courseFounded.name
            : '',
          hideScheduleDate: !!fservice || item.state === 1,
          fservice:
            fservice && fservice.isValid()
              ? `${fservice.format(`DD/MM/YYYY`)}`
              : '',
          finforme: finforme?.format('DD/MM/YYYY'),
          status: course.status,
          participants: course.participants,
        };
      });

      return coursesParse.filter(Boolean);
    }

    return [];
  };

  return (
    <>
      <Table
        columns={capacitationColumns}
        dataSource={transformCapacitacionToList(item)}
        showHeader={false}
        className={'rows-bg-expanded'}
        size='small'
        pagination={false}
        loading={!allListCourses || allListCourses.length === 0}
        locale={{
          emptyText: 'No hay cursos',
        }}
      />

      <FormCreateEventDate
        visible={visibleFormEventDateCapacitacion}
        item={itemSelected}
        setVisible={setVisibleFormEventDateCapacitacion}
        setVisibleFormEventDateEdit={setVisibleFormEventDateEdit}
        visibleFormEventDateEdit={visibleFormEventDateEdit}
        courseKeySelected={courseKeySelected}
        doSendEventDate={(date, item) =>
          handlerSendEventDateCapacitacion(date, item, courseKeySelected)
        }
      />
    </>
  );
};

export default ExpandedCapacitacion;
