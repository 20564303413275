import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Input,
  Card,
  Skeleton,
  Popover,
  Button,
  List,
  notification,
} from 'antd';
import {
  EditOutlined,
  HddOutlined,
  UserOutlined,
  ReloadOutlined,
  CopyOutlined,
} from '@ant-design/icons';

import { LOG_IN_SIMULATE_USER } from '../../../../../redux/actions/actionsType';
import { checkPermission } from '../../../../../lib/security';
import { useScreen } from '../../../../../lib/screen';
import openNotification from '../../../../../common/Notification';

import SimpleTable from '../../../../../common/Table/SimpleTable';
import Title from '../../../../../common/Typograph/Title';
import { IconDots } from '../../../../../common/Icons/iconText';

import {
  getUserList,
  resendRegistrationEmail,
} from '../../../../../services/users';
import useDebounce from '../../../../../hooks/useDebounce';

import CardUserMobile from '../../common/CardUserMobile';
import DrawerMoreOptionsMobile from '../../../../../common/DrawerMoreOptionsMobile';
import PaginationMobile from '../../../../../common/PaginationMobile';
import ManagerCompaniesList from './ManagerCompaniesList';
import { handleCopyCodeRegister } from '../../../../../lib/handler';

const { Search } = Input;

const statusColors = {
  1: {
    class: 'active-color',
    status: 'Activo',
  },
  0: {
    class: 'disabled-color',
    status: 'Inactivo',
  },
  3: {
    class: 'disabled-color',
    status: 'Eliminado',
  },
};

const ListActionsUsers = ({
  onEdit = () => {},
  onSeeAsUser = () => {},
  onRegisterEmail = () => {},
  onCopy = () => {},
  onEditCompany = () => {},
  state,
  userAuth,
  username,
  activation_date,
  isMobile,
  ...item
}) => (
  <List
    className={`full-width ${isMobile ? 'text-base' : ''}`}
    dataSource={[
      {
        action: () => {
          onEdit();
        },
        icon: <EditOutlined />,
        title: 'Editar',
        key: 'edit',
      },
      ...[
        checkPermission(item, 'allows_to_manager_accounts') &&
        checkPermission(userAuth, 'write_companies_to_account_managers')
          ? {
              action: onEditCompany,
              icon: <HddOutlined />,
              title: 'Editar Empresas',
              key: 'editCompany',
            }
          : false,
      ],
      ...[
        state === 1 &&
        checkPermission(userAuth, 'view_session_as_others_users') &&
        userAuth?.username !== username
          ? {
              action: () => {
                onSeeAsUser();
              },
              icon: <UserOutlined />,
              title: 'Ver como usuario',
              key: 'seeAsUser',
            }
          : false,
      ],
      ...(![1, 3].includes(state) &&
      !Boolean(activation_date) &&
      checkPermission(userAuth, 'allows_to_resend_registration_email')
        ? [
            {
              action: () => {
                onRegisterEmail();
              },
              icon: <ReloadOutlined />,
              title: 'Reenviar correo de registro',
              key: 'registermail',
            },
            {
              action: () => {
                onCopy();
              },
              icon: <CopyOutlined />,
              title: 'Copiar link',
              key: 'copylink',
              theme: 'filled',
            },
          ]
        : [false]),
    ].filter(Boolean)}
    renderItem={({ action, icon, key, title, theme }) => (
      <List.Item
        style={
          !isMobile
            ? { height: 40, paddingLeft: 16, width: 228, cursor: 'pointer' }
            : { cursor: 'pointer' }
        }
        key={key}
        onClick={action}
      >
        <span style={{ marginRight: 12 }}>{icon}</span>
        {title}
      </List.Item>
    )}
  />
);

const columnsListUsers = [
  {
    title: 'Empresa',
    dataIndex: 'company',
    width: 200,
    render: (value) => value?.name,
  },
  {
    title: 'Rol',
    dataIndex: 'rol',
    width: 170,
    render: (value) => value?.name,
  },
  {
    title: 'Cargo',
    dataIndex: 'position',
    width: 120,
  },
  {
    title: 'Usuario',
    dataIndex: 'username',
    width: 140,
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    width: 140,
  },
  {
    title: 'Primer apellido',
    dataIndex: 'last_name1',
    width: 170,
  },
  {
    title: 'Segundo apellido',
    dataIndex: 'last_name2',
    width: 170,
  },
  {
    title: 'Telefonía móvil',
    dataIndex: 'phone',
    width: 170,
  },
  {
    title: 'Estado',
    dataIndex: 'state',
    key: 'state',
    fixedMobile: 'top',
    render: (value) => {
      const state = statusColors[value] ?? statusColors[0];

      return (
        <div
          className='flex flex-row flex-justify-justify-between'
          style={{ height: 20 }}
        >
          <span className={`withpoint state-${state ? state.class : ''}`}>
            <div
              className={`withpoint-circle dot-${state ? state.class : ''}`}
            ></div>
            <span>{state?.status}</span>
          </span>
        </div>
      );
    },
  },
  {
    title: '',
    dataIndex: 'action',
    fixed: 'right',
    hiddenMobile: true,
    render: (_, record) => {
      if (record?.userAuth?.rol?.slug === 'comercial') {
        return null;
      }

      return (
        <Popover
          placement='topRight'
          trigger='hover'
          className='popover-actions'
          content={<ListActionsUsers {...record} />}
        >
          <Button>
            <IconDots />
          </Button>
        </Popover>
      );
    },
  },
];

const UsersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const successMessage = localStorage.getItem('userSuccess');
  const { isMobileScreen } = useScreen();
  const { users, pagination, isLoadingUser, userAuth } = useSelector(
    (state) => ({ ...state.users, userAuth: state.auth.user }),
  );
  const [textSearch, setTextSearch] = useState('');
  const debouncedTextSearch = useDebounce(textSearch);
  const [mobileActions, setMobileActions] = useState(null);
  const [companiesManaged, setCompaniesManaged] = useState(false);

  const handleGetUsersList = useCallback(
    (data) => {
      getUserList(dispatch, data);
    },
    [dispatch],
  );

  useEffect(() => {
    if (successMessage) {
      openNotification('success', 'Usuario', successMessage);
      localStorage.removeItem('userSuccess');
    }
  }, [successMessage]);

  useEffect(() => {
    handleGetUsersList({
      query: debouncedTextSearch,
      limit: 5,
      page: 1,
    });
  }, [debouncedTextSearch]);

  const handleChangeSearch = (e) => setTextSearch(e.target.value.trim());

  const handleSeeAsUser = useCallback((user) => {
    dispatch({ type: LOG_IN_SIMULATE_USER, payload: user?.username });

    let route = 'monitoreo/mapa';

    if (user?.rol?.permissions?.find((p) => p.slug === 'access_dashboard')) {
      route = 'administracion/perfil-empresa';
    } else if (user?.rol?.slug === 'proveedor') {
      route = 'servicio-de-prevencion-solicitud-proveedor';
    }

    if (['admin_rimac', 'gestor_de_cuenta'].includes(user?.rol?.slug)) {
      route = 'administracion/resumen';
    }

    window.location.href = `${process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN}${route}`;
  }, []);

  const handleEmailSchedule = useCallback(async ({ id, username }) => {
    const { success } = await resendRegistrationEmail({ id });
    success
      ? notification.success({
          message: '¡Correo enviado correctamente!',
        })
      : notification.error({ error: 'Ha ocurrido un error' });
  }, []);

  const handlerCompaniesManaged = (item) => setCompaniesManaged(item);

  return (
    <div>
      <div
        className={`flex justify-between ${
          isMobileScreen ? 'flex-col pt-2 mb-3' : 'items-center mt-1 mb-4'
        }`}
      >
        <Title>Filtrar lista de usuarios</Title>
        <Search
          placeholder={'Palabras clave'}
          allowClear
          style={{
            width: '100%',
            maxWidth: '300px',
          }}
          onChange={handleChangeSearch}
        />
      </div>
      {pagination?.total !== 1 ? (
        <span className='text-base'>
          Se encontraron <strong>{pagination?.total}</strong> usuarios
        </span>
      ) : (
        <span className='text-base'>
          Se encontró <strong>{pagination?.total}</strong> usuario
        </span>
      )}
      {isMobileScreen ? (
        isLoadingUser ? (
          [1, 2, 3].map((i) => (
            <Card className='mt-2' key={i}>
              <Skeleton />
            </Card>
          ))
        ) : (
          <>
            {users.map((user) => (
              <CardUserMobile
                key={user.id}
                columns={columnsListUsers}
                onAction={() => {
                  setMobileActions({
                    ...user,
                    onEdit: () => {
                      navigate(
                        `/administracion/usuarios/formulario-usuario/${user?.uuid}`,
                      );
                    },
                    onSeeAsUser: () => {
                      handleSeeAsUser(user);
                    },
                    onRegisterEmail: () => {
                      handleEmailSchedule(user);
                      setMobileActions(null);
                    },
                    onCopy: () => {
                      handleCopyCodeRegister(user);
                    },
                    onEditCompany: () => {
                      handlerCompaniesManaged(user);
                    },
                    userAuth,
                  });
                }}
                item={user}
                userAuth={userAuth}
              />
            ))}
            <PaginationMobile
              {...pagination}
              onChange={(page) => {
                handleGetUsersList({
                  limit: 5,
                  page,
                  query: textSearch,
                });
              }}
            />
          </>
        )
      ) : (
        <SimpleTable
          className={'mt-4'}
          loading={isLoadingUser}
          columns={columnsListUsers}
          dataSource={users.map((el) => ({
            ...el,
            onEdit: () => {
              navigate(
                `/administracion/usuarios/formulario-usuario/${el?.uuid}`,
              );
            },
            onEditCompany: () => {
              handlerCompaniesManaged(el);
            },
            onSeeAsUser: () => {
              handleSeeAsUser(el);
            },
            onRegisterEmail: () => {
              handleEmailSchedule(el);
            },
            onCopy: () => {
              handleCopyCodeRegister(el);
            },
            userAuth,
          }))}
          pagination={{
            ...pagination,
            onChange: (page) => {
              handleGetUsersList({
                limit: 5,
                page,
                query: textSearch,
              });
            },
          }}
        />
      )}
      <ManagerCompaniesList
        visible={Boolean(companiesManaged)}
        user={companiesManaged}
        userId={companiesManaged?.id}
        onCancel={() => setCompaniesManaged(null)}
        onRemoveCompany={() => {}}
      />
      {isMobileScreen && (
        <DrawerMoreOptionsMobile
          visible={mobileActions}
          onClose={() => {
            setMobileActions(null);
          }}
        >
          <ListActionsUsers isMobile {...(mobileActions ?? {})} />
        </DrawerMoreOptionsMobile>
      )}
    </div>
  );
};

export default memo(UsersList);
