import React, { useState, useEffect } from 'react';
import InfraredLegend from './satellite/InfraredLegend';
import VisibleLegend from './satellite/VisibleLegend';
import DailyRainLegend from './rains/DailyRainLegend';
import AccumulatedRainLegend from './rains/AccumulatedRainLegend';
import RelativeHumidityLegend from './relative_humidity/RelativeHumidityLegend';
import LightningLegend from './lightning/LightningLegend';
import TemperatureLegend from './temperature/TemperatureLegend';
import WindLegend from './wind/WindLegend';
import CloudLegend from './clouds/CloudLegend';
import WavesLegend from './waves/WavesLegend';
import HorizontalVisibilityLegend from './horizontal_visibility/HorizontalVisibilityLegend';
import UVLegend from './uv/UVLegend';
import {
  RideActionButton,
  RideGlInformationOutline,
} from '@rimac-seguros/ride-system-components';
import { useMap } from 'react-leaflet';

const legendComponents = {
  satellite_c13: InfraredLegend,
  satellite_c02: VisibleLegend,
  pp: DailyRainLegend,
  pp_12: AccumulatedRainLegend,
  pp_24: AccumulatedRainLegend,
  pp_72: AccumulatedRainLegend,
  pp_120: AccumulatedRainLegend,
  hr: RelativeHumidityLegend,
  lightning: LightningLegend,
  temp: TemperatureLegend,
  ws: WindLegend,
  hc: CloudLegend,
  lc: CloudLegend,
  mc: CloudLegend,
  hcwv: WavesLegend,
  vis: HorizontalVisibilityLegend,
  '03_04_001_03_001_513_0000_00_00': UVLegend,
  '03_04_002_03_001_513_0000_00_00': UVLegend,
};

const Legends = ({ selectLayer, hasTimeControl }) => {
  const [isVisible, setIsVisible] = useState(true);
  const LegendComponent = legendComponents[selectLayer];
  const map = useMap();

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    if (map) {
      if (!isVisible) {
        map.dragging.disable();
        map.doubleClickZoom.disable();
        map.scrollWheelZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();
        map.off('click');
      } else {
        map.dragging.enable();
        map.doubleClickZoom.enable();
        map.scrollWheelZoom.enable();
        map.boxZoom.enable();
        map.keyboard.enable();
        map.on('click');
      }
    }
  }, [isVisible, map]);

  return (
    <>
      {!isVisible && (
        <RideActionButton
          className={`legend-button ${hasTimeControl ? '' : 'no-time-control'}`}
          glyph={<RideGlInformationOutline />}
          tooltipText='Mostrar Leyenda'
          placement='right-center'
          size='small'
          onClick={toggleVisibility}
        />
      )}
      {LegendComponent && (
        <div
          className={`legend-container 
          ${isVisible ? 'visible' : ''} 
          ${hasTimeControl ? '' : 'no-time-control'}
          ${selectLayer === 'lightning' ? 'lightning' : ''}
          `}
        >
          <LegendComponent toggleVisibility={toggleVisibility} />
        </div>
      )}
    </>
  );
};

export default Legends;
