import { post, put, get } from '../lib/ajax';
import {
  GENERATING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS,
  GENERATING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED,
  LOADED_REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS,
  LOADED_REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS,
  LOADED_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS,
  LOADING_UPDATE_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS,
  LOAD_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS,
  REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS,
  REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED,
  REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS,
  REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED,
} from '../redux/actions/actionsType';

export const getParticipants = async (data, dispatch) => {
  try {
    dispatch({
      type: LOADED_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS,
      payload: { list: [] },
    });
    dispatch({
      type: LOAD_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS,
      payload: true,
    });

    const r = await post('/request_service_items_data_participants/list', data);

    if (r?.data?.success)
      dispatch({
        type: LOADED_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS,
        payload: r.data.payload,
      });
  } catch (error) {
    console.error(error);
  }
};

export const updateDataParticipants = async (data, dispatch) => {
  try {
    dispatch({
      type: LOADING_UPDATE_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS,
      payload: true,
    });

    const r = await put(
      '/request_service_items_data_participants/update',
      data,
    );

    if (r?.data?.success) {
      dispatch({
        type: LOADING_UPDATE_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS,
        payload: false,
      });
      dispatch({
        type: LOADED_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS,
        payload: r.data.payload,
      });

      return true;
    }

    dispatch({
      type: LOADING_UPDATE_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS,
      payload: false,
    });

    return false;
  } catch (error) {
    console.error(error);
    dispatch({
      type: LOADING_UPDATE_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS,
      payload: false,
    });
    return false;
  }
};

export const updateOnlyParticipants = async (data, dispatch) => {
  try {
    dispatch({
      type: LOADING_UPDATE_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS,
      payload: true,
    });

    const r = await put(
      '/request_service_items_data_participants/update_participants',
      data,
    );

    if (r?.data?.success) {
      dispatch({
        type: LOADING_UPDATE_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS,
        payload: false,
      });
      dispatch({
        type: LOADED_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS,
        payload: r.data.payload,
      });

      return true;
    }

    dispatch({
      type: LOADING_UPDATE_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS,
      payload: false,
    });

    return false;
  } catch (error) {
    console.error(error);
    dispatch({
      type: LOADING_UPDATE_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS,
      payload: false,
    });
    return false;
  }
};

export const generateCertificates = async (
  data,
  loadParticipants,
  dispatch,
) => {
  try {
    dispatch({
      type: GENERATING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS,
      payload: true,
    });
    let r = await post(
      '/request_service_items_data_participants/make_certificates',
      data,
    );

    // Check if the response or the data property is undefined
    if (!r || !r.data) {
      throw new Error(
        'Post response is undefined or does not have a data property',
      );
    }

    if (r?.data?.success && r.data.running) {
      const intervalId = setInterval(async () => {
        try {
          r = await get(
            '/request_service_items_data_participants/make_certificates_status',
          );
          if (r.data && r?.data?.success) {
            const { payload } = r.data;
            const config = payload ? JSON.parse(payload.value) : {};

            if (config.error) {
              dispatch({
                type: GENERATING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED,
                payload: true,
              });
              clearInterval(intervalId);
            } else if (config.finish) {
              dispatch({
                type: GENERATING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS,
                payload: false,
              });
              loadParticipants();
              clearInterval(intervalId);
            }
          }
        } catch (error) {
          console.error(error);
          dispatch({
            type: GENERATING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED,
            payload: true,
          });
          clearInterval(intervalId);
        }
      }, 6000);
    }

    return false;
  } catch (error) {
    console.error(error);
    dispatch({
      type: GENERATING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED,
      payload: true,
    });
    return false;
  }
};

export const reportParticipants = async (data, dispatch) => {
  try {
    dispatch({
      type: REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS,
      payload: true,
    });
    let r = await post(
      '/request_service_items_data_participants/report_participants',
      data,
    );

    if (r.data && r?.data?.success && r.data.running) {
      const intervalId = setInterval(async () => {
        try {
          r = await get(
            '/request_service_items_data_participants/report_participants_status',
          );
          if (r.data && r?.data?.success) {
            const { payload } = r.data;
            const config = payload ? JSON.parse(payload.value) : {};

            if (config.error) {
              dispatch({
                type: REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED,
                payload: true,
              });
              clearInterval(intervalId);
            } else if (config.fileDownloadPath) {
              dispatch({
                type: LOADED_REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS,
                payload: config.fileDownloadPath,
              });
              clearInterval(intervalId);
            }
          }
        } catch (error) {
          console.error(error);
          dispatch({
            type: REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED,
            payload: true,
          });
          clearInterval(intervalId);
        }
      }, 6000);
    }

    return false;
  } catch (error) {
    console.error(error);
    dispatch({
      type: REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED,
      payload: true,
    });
    return false;
  }
};

export const reportCertificatesParticipants = async (data, dispatch) => {
  try {
    dispatch({
      type: REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS,
      payload: true,
    });
    let r = await post(
      '/request_service_items_data_participants/report_certificates',
      data,
    );

    if (r.data && r?.data?.success && r.data.running) {
      const intervalId = setInterval(async () => {
        try {
          r = await get(
            '/request_service_items_data_participants/report_certificates_status',
          );
          if (r.data && r?.data?.success) {
            const { payload } = r.data;
            const config = payload ? JSON.parse(payload.value) : {};

            if (config.error) {
              dispatch({
                type: REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED,
                payload: true,
              });
              clearInterval(intervalId);
            } else if (config.fileDownloadPath) {
              dispatch({
                type: LOADED_REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS,
                payload: config.fileDownloadPath,
              });
              clearInterval(intervalId);
            }
          }
        } catch (error) {
          console.error(error);
          dispatch({
            type: REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED,
            payload: true,
          });
          clearInterval(intervalId);
        }
      }, 6000);
    }

    return false;
  } catch (error) {
    console.error(error);
    dispatch({
      type: REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED,
      payload: true,
    });
    return false;
  }
};
