import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, notification } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import L from 'leaflet';
import 'leaflet-draw';

import {
  createMyLayer,
  getMyLayers,
  getPresignedCustomLayersUrl,
} from '../../../../services/maps';
import { sendBannerImage } from '../../../../services/banner';
import {
  MY_LAYERS_IS_DRAW_MODE,
  MY_LAYERS_IS_LOADING_CREATE,
  MY_LAYERS_SET_ACTIVE_LAYERS,
  MY_LAYERS_SET_DRAWS,
  MAP_CHANGE_BLOCKED_CLICK,
} from '../../../../redux/actions/actionsType';

import { transformDrawsToGeoJSON } from '../../../../lib/utils';
import { RideTextField } from '@rimac-seguros/ride-system-components';

const FormCreate = ({ setStep, setShowForm }) => {
  const dispatch = useDispatch();
  const isLoadingCreate = useSelector(
    (state) => state.myLayers.isLoadingCreate,
  );
  const [draws, setDraws] = useState(null);
  const [isErrorFile, setErrorFile] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Este campo es requerido'),
    }),
    onSubmit: async (values) => {
      try {
        let file = transformDrawsToGeoJSON(draws);

        if (!file) {
          setErrorFile(true);
          return;
        }

        dispatch({ type: MY_LAYERS_IS_LOADING_CREATE, payload: true });

        if (file.name) {
          const res = await getPresignedCustomLayersUrl({ name: file.name });
          const { success, payload } = res?.data ?? {};
          const typeFile = file.name.split('.').pop();

          if (success && payload && payload?.url) {
            await sendBannerImage(payload?.url, file);

            await createMyLayer({
              ...values,
              typeFile,
              url: payload.nameKey,
            });

            await getMyLayers(dispatch);
          }
        }
      } catch (error) {
        notification.error({
          message: 'Ha ocurrido un error al subir el archivo.',
        });
      } finally {
        dispatch({ type: MY_LAYERS_IS_LOADING_CREATE, payload: false });
        setDraws(null);
        setStep(1);
        setShowForm(false);
      }
    },
  });

  useEffect(() => {
    dispatch({ type: MY_LAYERS_SET_ACTIVE_LAYERS, payload: [] });
    dispatch({ type: MY_LAYERS_IS_DRAW_MODE, payload: true });
    dispatch({ type: MAP_CHANGE_BLOCKED_CLICK, payload: true });

    return () => {
      dispatch({ type: MY_LAYERS_IS_DRAW_MODE, payload: false });
      dispatch({ type: MAP_CHANGE_BLOCKED_CLICK, payload: false });
    };
  }, [dispatch]);

  const MapWithDraw = () => {
    const map = useMap();
    const drawnItemsRef = useRef(new L.FeatureGroup());

    useEffect(() => {
      const drawnItems = drawnItemsRef.current;
      map.addLayer(drawnItems);

      const drawControl = new L.Control.Draw({
        edit: {
          featureGroup: drawnItems,
        },
      });
      map.addControl(drawControl);

      map.on(L.Draw.Event.CREATED, (event) => {
        const layer = event.layer;
        drawnItems.addLayer(layer);
        const geoJsonData = drawnItems.toGeoJSON();
        setDraws(geoJsonData);
      });

      return () => {
        map.removeControl(drawControl);
        map.removeLayer(drawnItems);
      };
    }, [map]);

    useEffect(() => {
      const drawnItems = drawnItemsRef.current;
      drawnItems.clearLayers();
      if (draws) {
        const layers = L.geoJSON(draws);
        layers.eachLayer((layer) => {
          drawnItems.addLayer(layer);
        });
      }
    }, [draws]);

    return null;
  };

  return (
    <div>
      <MapWithDraw />
      <form
        className='form-simulations'
        style={{ marginTop: '8px' }}
        onSubmit={formik.handleSubmit}
      >
        <div className='form-item'>
          <RideTextField
            label={'Nombre para la capa'}
            name='name'
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : null
            }
          />
        </div>
        <div className='form-actions'>
          <Button
            type='primary'
            className='form-button-success'
            htmlType='submit'
            loading={isLoadingCreate}
            disabled={isErrorFile}
          >
            Crear Capa
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FormCreate;
