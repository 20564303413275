import { useEffect, useState } from 'react';
import { Row, Modal } from 'antd';
import EmergencyInfo from './EmergencyInfo';
import { getCircleEmergencies } from '../../services/roadEmergency';

const EmergencyPopup = () => {
  const [visible, setVisible] = useState(false);
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    getCircleEmergencies().then((fetchedFavorites) => {
      if (!fetchedFavorites || !fetchedFavorites.length) {
        return;
      }

      const hasAffectedLocations = fetchedFavorites.some(
        (location) => location.emergencies && location.emergencies.length > 0,
      );

      if (!hasAffectedLocations) {
        return;
      }

      setFavorites(fetchedFavorites);
      setVisible(true);
    });
  }, []);

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title='Se han detectado emergencias que podrían afectarte:'
      className='emergency-popup-modal'
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      mask={false}
    >
      <div className='emergency-popup'>
        {favorites.slice(0, 3).map((location) => (
          <Row className='flex flex-col' key={location.id}>
            <span className='location-name'>
              <strong>{location.name}</strong> es afectada por:
            </span>
            {location.emergencies &&
              location.emergencies.length > 0 &&
              location.emergencies
                .slice(0, 3)
                .map((emergency) => (
                  <EmergencyInfo key={emergency.id} emergency={emergency} />
                ))}
          </Row>
        ))}
      </div>
    </Modal>
  );
};

export default EmergencyPopup;
