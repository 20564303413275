import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePicker, Form, notification, TimePicker } from 'antd';
import {
  RideGlLeft,
  RideIconButton,
} from '@rimac-seguros/ride-system-components';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { Editor } from '@tinymce/tinymce-react';

import {
  createClientsTracking,
  presignedUrl,
} from '../../../../services/clientTracking';
import { UPDATE_REQUEST_SERVICE_FIELD } from '../../../../redux/actions/actionsType';
import { getCompaniesWithPolicies } from '../../../../services/company';

import InputFile from '../../../../common/InputFile';
import {
  SelectWithLabel,
  InputWithLabel,
} from '../../../../common/InputWithLabel';
import Topbar from '../../../../common/ui/Topbar';
import SelectCompany from './SelectCompany';
import MultiSelect from './MultiSelect';

moment.locale('es');
moment.tz.setDefault('America/Lima');

const ADMIN_PATH = '/administracion/seguimiento-a-clientes';

const templateDefault = `
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office"><head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="format-detection" content="telephone=no">
    <title>Rimac</title>
    <style type="text/css">
        body{margin:0;padding:0;font-family:Arial,Helvetica,sans-serif;color:#494f66;font-size:14px}.ReadMsgBody{width:100%}.ExternalClass{width:100%}.ExternalClass,.ExternalClass div,.ExternalClass font,.ExternalClass p,.ExternalClass span,.ExternalClass td{line-height:100%}.yshortcuts a{border-bottom:none!important}.vb-outer{min-width:0!important}.ExternalClass,.RMsgBdy{width:100%;background-color:#3f3f3f}[o365] button{margin:0!important}table{mso-table-rspace:0;mso-table-lspace:0}#outlook a{padding:0}a{border:0!important;text-decoration:none!important}img{outline:0;text-decoration:none;border:none;-ms-interpolation-mode:bicubic}a img{border:none}.ii a[href]{color:#494f66!important}@media only screen and (max-width:480px){.mobile-hide,td[class=mobile-hide]{display:none!important}.mobile-textcenter,td[class=mobile-textcenter]{text-align:center!important}.mobile-textleft,td[class=mobile-textleft]{text-align:left!important}.mobile-height-auto{height:auto!important}.mobile-img-full{width:100%!important}.mobile-full{width:100%!important;max-width:480px!important}.mobile-margen-banner {width: 25px !important;}.mobile-width-table-soat{width:100px}.mobile-table-auto{width:auto!important}table[class=mobile-full]{width:100%!important;max-width:480px!important}}
    </style>
    <!--[if (gte mso 9)|(IE)]>
    <style type="text/css" media="all">
        table {border-collapse:collapse;}
    </style>
    <![endif]-->
</head>

<body alink="#939dff" vlink="#939dff" style=" color: #494F66;"><div>
Estimado [client]
por la renovación de su póliza patrimonial con Rimac, le enviamos la propuesta de servicios gratuitos de valor agregado para esta nueva vigencia.
Esta oferta de valor es un conjunto de servicios especializados que buscan apoyar la gestión de riesgos y prevención que ustedes realizan para sus instalaciones
y operaciones. Para explicarle el detalle de la misma y absolver todas las consultas le propongo una reunión el día [date] a las [hora] y poder iniciar con su
ejecución.
</div>
<br>
<div>Quedo atento a su gentil respuesta</div>
</body></html>`;

const subjectDefault = 'Oferta de valor prevención - [client]';

const errorNotification = () =>
  notification.error({
    description: 'Hubo un error.',
    message: 'Error',
  });

const FormClient = () => {
  let editorTinyVar = false;

  const [form] = Form.useForm();
  const { setFieldsValue, getFieldValue, resetFields } = form;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingPolicies, setLoadingPolicies] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [companySelected, setCompanySelected] = useState();
  const [policies, setPolicies] = useState([]);
  const [contentHtml, setContentHtml] = useState(templateDefault);
  const [subject, setSubject] = useState(subjectDefault);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState();

  const handleOnBack = () => {
    resetFields();
    navigate(ADMIN_PATH);
  };

  const { userSlug, polizaId } = useSelector((state) => ({
    userSlug: state.auth.user?.rol?.slug || '',
    polizaId: state.requestServiceForm.polizaId,
  }));

  useEffect(() => {
    setLoadingCompanies(true);
    getCompaniesWithPolicies()
      .then((res) => {
        setCompanies(res);
      })
      .finally(() => setLoadingCompanies(false));
  }, []);

  const handleSave = () => {
    form.validateFields().then((values) => {
      if (!values) {
        return errorNotification();
      }

      setIsLoading(true);

      values.content = contentHtml;
      values.meeting_date = moment(values.date).set({
        hour: values.hour.get('hour'),
        minute: values.hour.get('minute'),
        second: 0,
        millisecond: 0,
      });

      if (file) {
        presignedUrl(file).then(({ url, nameKey }) => {
          if (!url || !nameKey) {
            return errorNotification();
          }

          axios.put(url, file).then(() => {
            setIsLoading(false);
            values.attachedFile = nameKey;
            createClientsTracking(values, dispatch).then(() => {
              setIsLoading(false);
              navigate(ADMIN_PATH);
            });
          });
        });
      } else {
        createClientsTracking(values, dispatch).then(() => {
          setIsLoading(false);
          navigate(ADMIN_PATH);
        });
      }
    });
  };

  const _handleSelectPolicy = useCallback(
    (poliza) => {
      localStorage.setItem('polizaId', poliza);
      const { typePolizaId } =
        policies.find((el) => String(el.id) === polizaId) || {};
      dispatch({
        type: UPDATE_REQUEST_SERVICE_FIELD,
        payload: { polizaId: poliza, typePolizaId },
      });
    },
    [dispatch, policies, polizaId],
  );

  const _handleSelectCompany = useCallback(
    (company, label) => {
      localStorage.setItem('companyId', company);
      const valueSubject = getFieldValue('subject');
      setFieldsValue({
        subject: valueSubject.replace(
          '[client]',
          label.props.children.props.children,
        ),
      });
      setCompanySelected(company);
    },
    [setCompanySelected],
  );

  const setCompanyPolicy = (companyId) => {
    const company = companies.find((el) => String(el.id) === companyId);
    if (companySelected) {
      setPolicies(company.polizas);
    }
  };

  const handleEmailsChange = (emails) => {
    setFieldsValue({ clientEmail: emails.join(',') });
  };

  const handleChangeComapny = (companyId) => {
    setCompanySelected(companyId);
    setCompanyPolicy(companyId);
    form.resetFields(['polizaId']);
  };

  useEffect(() => {
    setCompanyPolicy(companySelected);
  }, [companySelected]);

  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />

      <div className='d_content'>
        <section className='section-form-newsletters'>
          <div className='return'>
            <RideIconButton
              variant='outline'
              size='small'
              glyph={<RideGlLeft />}
              onClick={handleOnBack}
            />
            <h2>Realizar seguimiento</h2>
          </div>

          <div className='form-content'>
            <Form form={form}>
              <Form.Item name='clientName' initialValue=''>
                <SelectCompany
                  companySelected={companySelected}
                  onSelectCompany={_handleSelectCompany}
                  setCompaniesList={setCompanies}
                  loadingCompanies={loadingCompanies}
                  companies={companies.map((company) => ({
                    value: String(company.id),
                    label: company.name,
                  }))}
                  onChange={handleChangeComapny}
                />
              </Form.Item>
              <div className='text-alert'>
                Solo se mostrarán las empresas que cuenten con pólizas vigentes.
              </div>
              <Form.Item
                label='Póliza'
                name='polizaId'
                initialValue=''
                rules={[{ required: true, message: 'Please select a policy!' }]}
              >
                <SelectWithLabel
                  value={loadingPolicies ? null : polizaId}
                  label='Póliza'
                  disabled={
                    ['admin_rimac', 'gestor_de_cuenta'].includes(userSlug) &&
                    !companySelected
                  }
                  hoverable={false}
                  loading={loadingPolicies}
                  options={policies.map((pol) => ({
                    value: String(pol.id),
                    label: `${pol?.typePoliza?.name} - ${
                      pol.no_poliza
                    } - FV: ${moment(
                      moment(pol.start_date).add(
                        Number(pol.validity),
                        'months',
                      ),
                    ).format('DD/MM/YYYY')}`,
                  }))}
                  onChange={_handleSelectPolicy}
                />
              </Form.Item>
              <Form.Item
                label='Fecha propuesta de la reunión '
                name='date'
                initialValue={null}
              >
                <DatePicker
                  format='DD/MM/YYYY'
                  placeholder='Seleccionar fecha'
                />
              </Form.Item>
              <Form.Item
                label='Hora de la reunión'
                name='hour'
                initialValue={null}
              >
                <TimePicker
                  className='full-width'
                  placeholder='Seleccionar hora'
                  format='HH:mm'
                  getPopupContainer={(e) => e}
                  use12Hours
                  minuteStep={15}
                />
              </Form.Item>

              <Form.Item
                label='Para:'
                name='clientEmail'
                initialValue=''
                rules={[
                  { required: true, message: 'Please input the client email!' },
                ]}
              >
                <MultiSelect
                  company={companySelected}
                  onEmailsChange={handleEmailsChange}
                />
              </Form.Item>
              <Form.Item label='CC:' name='description' initialValue=''>
                <InputWithLabel />
              </Form.Item>
              <Form.Item
                label='Asunto:'
                name='subject'
                initialValue={subjectDefault}
              >
                <InputWithLabel
                  value={subject}
                  onChange={(e) => setSubject(e)}
                />
              </Form.Item>
              <Form.Item label='Cuerpo del correo'>
                <Editor
                  apiKey={process.env.REACT_APP_TINYMCE_KEY}
                  init={{
                    setup: (editor) => {
                      editorTinyVar = editor;
                    },
                    min_height: 500,
                    cleanup: false,
                    menubar: true,
                    language: 'es',
                    fullpage_default_doctype: '<!DOCTYPE html>',
                    plugins: [
                      'autoresize advlist autolink lists link image charmap print preview hr anchor pagebreak',
                      'searchreplace wordcount visualblocks visualchars  fullscreen',
                      'insertdatetime media nonbreaking save table contextmenu directionality',
                      'paste textcolor code template colorpicker',
                    ],
                    toolbar:
                      'undo redo | template | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor fontselect fontsizeselect',
                  }}
                  value={contentHtml}
                  onEditorChange={(e) => setContentHtml(e)}
                />
              </Form.Item>
              <Form.Item label='Adjuntar archivo:'>
                <InputFile
                  filesAccepted={[]}
                  withoutPreview
                  title='Previsualización'
                  onChangeFile={(selectedFile) => {
                    setFile(selectedFile.file);
                  }}
                  allowClear={true}
                />
              </Form.Item>
              <div className='form-actions'>
                <Button
                  onClick={handleSave}
                  disabled={isLoading}
                  htmlType='submit'
                >
                  Crear seguimiento
                </Button>
              </div>
            </Form>
          </div>
        </section>
      </div>
    </div>
  );
};

export default FormClient;
