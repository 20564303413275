import { post, put } from '../lib/ajax';
import {
  LOAD_EVENTS_LOADING,
  LOADED_EVENTS,
  LOADING_CREATE_EVENT,
  OPEN_FORM_EVENT,
  CHANGE_EVENTS_QUERY,
} from '../redux/actions/actionsType';

export const getEvents = async (dispatch, pagination = {}) => {
  dispatch({ type: LOAD_EVENTS_LOADING, payload: true });
  dispatch({ type: CHANGE_EVENTS_QUERY, payload: pagination.query });
  const r = await post('/events/list', pagination);
  if (r?.data?.success) {
    dispatch({ type: LOADED_EVENTS, payload: r.data.list });
  }
};

export const createEvents = async (obj, dispatch, reload = false) => {
  dispatch({ type: LOADING_CREATE_EVENT, payload: true });
  const r = await post('/events/create', obj);
  if (r?.data?.success) {
    dispatch({ type: OPEN_FORM_EVENT, payload: false });
    if (!reload) {
      await getEvents(dispatch);
    }

    return true;
  }
  dispatch({ type: LOADING_CREATE_EVENT, payload: false });

  return false;
};

export const updateEvents = async (
  obj,
  dispatch,
  pagination,
  reload = false,
) => {
  dispatch({ type: LOADING_CREATE_EVENT, payload: true });
  const r = await put('/events/update?id=' + obj.id, obj);
  if (r?.data?.success) {
    dispatch({ type: OPEN_FORM_EVENT, payload: false });
    if (!reload) {
      await getEvents(dispatch, pagination);
    }

    return true;
  }
  dispatch({ type: LOADING_CREATE_EVENT, payload: false });

  return false;
};

export const getEventsImagesProvinces = async (data) => {
  const r = await post('/events/getProvincesOfEventImages', data);
  if (r?.data?.success) {
    return r.data.list;
  }

  return null;
};
