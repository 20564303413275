import { post } from '../lib/ajax';
import { LOADED_SISMOS_TO_MAP } from '../redux/actions/actionsType';
import moment from 'moment';

export const getSismoEventsToMap = async ({
  dispatch,
  date,
  magnitud,
  acceleration,
}) => {
  const r = await post(
    '/sismos?toMap=1',
    {
      date,
      magnitud,
      acceleration,
    },
    false,
  );
  if (r?.data?.success) {
    let sismosOrdered = [];
    if (r.data.list && r.data.list.length > 0) {
      sismosOrdered = r.data.list
        .sort((a, b) => {
          const aData = JSON.parse(a.data);
          const bData = JSON.parse(b.data);
          return moment(bData?.fechaHoraLocal) - moment(aData?.fechaHoraLocal);
        })
        .slice();
      sismosOrdered.push(sismosOrdered.shift());
    }
    dispatch({
      type: LOADED_SISMOS_TO_MAP,
      payload: sismosOrdered.length > 0 ? sismosOrdered : r.data.list,
    });
  }
};
