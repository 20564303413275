import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Select, Tooltip, message } from 'antd';
import {
  WarningOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FilterOutlined,
} from '@ant-design/icons';

import {
  CHANGE_REQUEST_SERVICES_ITEMS_RESULTS_FILTERS,
  LOAD_REQUEST_SERVICES_ITEMS_RESULTS_LOADING,
} from '../../../../../redux/actions/actionsType';
import { getRequestServicesItemsResultsClient } from '../../../../../services/requestServicesItemsResults';
import { parseDayjsDate } from '../../../../../lib/utils';
import { checkPermission } from '../../../../../lib/security';
import { useScreen } from '../../../../../lib/screen';
import {
  CardDetail,
  IconClose,
  iconLeft,
  iconRight,
  CardSkeleton,
} from './utils';
import { getService } from '../../../../../services/service';
import TableMessage from '../../../../../common/Table/TableMessage';
import ModalPreview from './components/ModalPreview';
import ModalSurvey from '../ServiceDetail/Service/components/ModalSurvey';
import ModalFinishSurvey from '../ServiceDetail/Service/components/ModalFinishSurvey';
import TableCommon from '../../../../../common/Table';

const { Search } = Input;
const { Option } = Select;

const Reports = ({
  isRisks,
  data,
  isLoading,
  query,
  pagination,
  filters,
  user,
  loadingList,
  loadList,
  changeFilter,
  listServices,
  isActive,
}) => {
  const [isAdminRimac, setIsAdminRimac] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [textSearch, setTextSearch] = useState('');
  const [rows, setRows] = useState(data);
  const [internalFilters, setInternalsFilters] = useState({});
  const [filterMobile, setFilterMobile] = useState(false);
  const [rowSelection, setRowSelection] = useState([]);
  const [survey, setSurvey] = useState(false);
  const [showModalSurvey, setShowModalSurvey] = useState(false);
  const [finishSurvey, setFinishSurvey] = useState(null);
  const { widthScreen } = useScreen();
  const isPhone = widthScreen <= 767;
  const service = useMemo(
    () =>
      isRisks
        ? { eq: true, property: 'type', value: 4 }
        : { ne: true, property: 'type', value: 4 },
    [isRisks],
  );
  const rsiHasAllReports = (requestServiceItem) => {
    if (!requestServiceItem) return false;
    const { requestServiceItemsData, request_service_item_results } =
      requestServiceItem;

    if (
      !requestServiceItemsData ||
      !requestServiceItemsData.find(({ key }) => key === 'courses')
    )
      return true;

    const numberCourses = getCoursesArray(requestServiceItemsData).length;
    const numberResults = request_service_item_results
      ? request_service_item_results.length
      : 0;

    return numberCourses === numberResults;
  };

  const getCoursesArray = (requestServiceItemsData) => {
    const obj = JSON.parse(
      requestServiceItemsData.find((el) => el.key === 'courses').value,
    );
    const courses = Object.values(obj);

    return courses;
  };

  const columns = [
    {
      title: 'Nombre de Contacto',
      dataIndex: 'contact_name',
      key: 'contact_name',
      visible: true,
      disabled: true,
      width: '180px',
    },
    {
      title: 'Servicio',
      dataIndex: 'request_service_item',
      key: 'service',
      width: '150px',
      visible: true,
      render: (el) => (el && el.service ? el.service.name : ''),
    },
    {
      title: 'Fecha de servicio',
      dataIndex: 'date',
      key: 'date',
      visible: true,
      render: (date) => parseDayjsDate(date).format('DD/MM/YYYY H:mm:ss'),
      width: '150px',
    },
    (isAdminRimac || isManager) && {
      title: 'Empresa',
      dataIndex: 'request_service_item',
      key: 'request_service_item',
      visible: true,
      disabled: true,
      render: (el) => el?.requestService?.company?.name || '',
    },
    (isAdminRimac || isManager) && {
      title: 'Gestor de cuenta',
      dataIndex: 'request_service_item',
      key: 'managerAccount',
      visible: true,
      render: (el) => {
        if (el?.requestService?.company?.companiesUsers?.length)
          return el.requestService.company.companiesUsers
            .map((u) => {
              return `${u.name} ${u.last_name1} ${u.last_name2}`;
            })
            .join(', ');

        return '';
      },
    },
    {
      title: '% cumplimiento',
      dataIndex: 'compliance_percent',
      key: 'compliance_percent',
      visible: true,
      width: '135px',
      render: (compliance_percent, item) => (
        <center>
          {compliance_percent}
          {checkPermission(user, 'show_all_request_services') &&
            item?.request_service_item?.state === 5 &&
            rsiHasAllReports(item?.request_service_item) && (
              <Tooltip title={'Requiere atención'}>
                &nbsp
                <WarningOutlined />
              </Tooltip>
            )}
        </center>
      ),
    },
    {
      title: 'Informe',
      dataIndex: 'filename',
      key: 'filename',
      visible: true,
      width: '120px',
      render: (element) =>
        element
          ? element.length > 13
            ? element.substring(0, 13) + '...'
            : element
          : '',
    },
    {
      title: 'Descargar',
      dataIndex: '',
      key: 'actions',
      visible: true,
      disabled: true,
      render: (__, item) => (
        <Button style={{ border: 'none' }} onClick={() => openPreview(item)}>
          <DownloadOutlined />
        </Button>
      ),
    },
  ].filter(Boolean);

  const find = (e) => {
    const txt = e.target.value;

    setTextSearch(txt);

    if (txt === query) return;

    loadList({
      query: txt.length >= 2 ? txt : '',
      page: 1,
      limit: pagination.pageSize,
      service,
      isRisks,
      filters,
    });
  };

  const clearFilters = () => {
    changeFilter({
      query: '',
    });
    setInternalsFilters({ service: undefined });
    loadList({
      page: 1,
      limit: pagination.pageSize,
      filters,
      service,
      isRisks,
    });
    setTextSearch('');
  };

  const handleTableChange = (pagination) => {
    setRowSelection([]);
    loadList({
      query,
      page: pagination.current,
      limit: pagination.pageSize,
      service,
      filters,
      isRisks,
    });
  };

  const handleTableChangeMobile = (current) => {
    setRowSelection([]);
    if (
      current === 0 ||
      Math.ceil(pagination.total / pagination.pageSize) + 1 === current
    )
      return;

    loadList({
      page: current,
      limit: pagination.pageSize,
      service,
      filters,
      ...internalFilters,
      isRisks,
    });
  };

  const handleModalFilter = () => setFilterMobile((prev) => !prev);

  const onChangeCheck = (e) => {
    if (e.target.checked) setRowSelection([...rowSelection, e.target.value]);
    else
      setRowSelection(rowSelection.filter((item) => item !== e.target.value));
  };

  const handleDownloadReport = () => {
    rowSelection.forEach((id) => {
      rows.forEach((item) => {
        if (item.id === id)
          window.open(
            `${process.env.REACT_APP_WEB_PATH_S3_NEW}${item.filename_uploaded}`,
            '_blank',
          );
      });
    });
  };

  const openPreview = (record) => {
    const serviceItem = record.request_service_item;

    record.filename_uploaded
      ? setSurvey({
          id: serviceItem.id,
          name: serviceItem.service?.name,
          fileName: record.filename_uploaded,
          type: serviceItem.service.type,
          quiz: serviceItem?.quiz,
        })
      : message.warning('No hay un informe disponible');
  };

  useEffect(() => {
    if (widthScreen >= 768) setFilterMobile(false);
  }, [widthScreen]);

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    setIsAdminRimac(user?.rol?.slug === 'admin_rimac');
    setIsManager(user?.rol?.slug === 'gestor_de_cuenta');
    loadingList(true);
    loadList({
      page: pagination.current,
      limit: pagination.pageSize,
      service,
      filters,
      isRisks,
    });

    return () => {
      clearFilters();
    };
  }, [isActive]);

  const _handleChangeFilters = useCallback(
    (key) => (value) => {
      const nameService = listServices.find(({ id }) => id === value).name;

      setInternalsFilters((prev) => ({
        ...prev,
        [key]: value,
      }));
      loadList({
        page: pagination.current,
        limit: pagination.pageSize,
        query: nameService || '',
        filters,
        service,
        [key]: value,
        ...internalFilters,
        isRisks,
      });
      setTextSearch('');
    },
    [internalFilters, loadList, pagination, query],
  );

  return (
    <div className='container_reports_technical'>
      <div className='d_content' style={{ paddingTop: '24px' }}>
        <div className='filter__text'>Filtrar informes</div>

        <div
          className={`filter__box flex flex-row flex-wrap pb-4 mb-4 ${
            filterMobile ? 'modal__filter' : ''
          }`}
        >
          {filterMobile && (
            <>
              <div className='modal__filter--close' onClick={handleModalFilter}>
                <span>Cerrar</span>
                <IconClose />
              </div>

              <h3 className='modal__filter--title'>Filtrar informes</h3>
            </>
          )}

          <Search
            value={textSearch}
            className='mr-2 inputs-class'
            placeholder='Palabra clave'
            style={!filterMobile ? { width: 244, minWidth: 160 } : {}}
            onChange={find}
          />
          <Select
            className='mr-2 inputs-class'
            value={internalFilters.service_id}
            style={!filterMobile ? { width: 250 } : {}}
            onChange={_handleChangeFilters('service_id')}
            placeholder='Servicio'
          >
            {listServices && listServices.length
              ? listServices.map(({ name, id }) => (
                  <Option value={id} key={id}>
                    {name}
                  </Option>
                ))
              : null}
          </Select>
          <Button
            type='link'
            className='btn-clearFilter'
            onClick={clearFilters}
          >
            <DeleteOutlined />
            Borrar Filtros
          </Button>

          {filterMobile && (
            <button
              className='modal__filter--button'
              type='button'
              disabled={
                textSearch === '' &&
                String(internalFilters.service_id || '') === ''
              }
              onClick={handleModalFilter}
            >
              Filtrar
            </button>
          )}
        </div>

        {!isLoading && rows.length > 0 && (
          <div className='d_content__list__action mb-4 flex flex-row'>
            <div className='info-header'>
              <div>
                {!isPhone && pagination.total === 1 ? (
                  <>
                    Se encontró <b>1</b> informe
                  </>
                ) : (
                  <>
                    Se encontraron <b>{pagination.total}</b> informes
                  </>
                )}
              </div>
            </div>

            <div className='filter-action' onClick={handleModalFilter}>
              <FilterOutlined />
              <span>Filtrar</span>
            </div>
          </div>
        )}

        {isPhone ? (
          <section className='detail'>
            {isLoading ? (
              [1, 2, 3].map((el) => <CardSkeleton key={el} />)
            ) : rows.length === 0 ? (
              <TableMessage
                complete={true}
                message='No se encontraron informes.'
              />
            ) : (
              rows.map((el) => (
                <CardDetail
                  key={el.id}
                  info={el}
                  show={isAdminRimac || isManager}
                  rowSelection={rowSelection}
                  onChangeCheck={onChangeCheck}
                />
              ))
            )}

            {!isLoading && rows.length > 0 && (
              <div className='d_content__list__pag'>
                <div
                  className={'d_content__list__pag__button'}
                  onClick={() =>
                    handleTableChangeMobile(pagination.current - 1)
                  }
                >
                  {iconLeft({
                    color: pagination.current === 1 ? '#A9AFD9' : '#4F4FFF',
                  })}
                </div>

                <div className='d_content__list__pag__number color-red-1'>
                  {pagination.current}
                </div>

                <div className='d_content__list__pag__sep'>/</div>

                <div className='d_content__list__pag__number color-gray-3'>
                  {Math.ceil(pagination.total / pagination.pageSize)}
                </div>

                <div
                  className={'d_content__list__pag__button'}
                  onClick={() =>
                    handleTableChangeMobile(pagination.current + 1)
                  }
                >
                  {iconRight({
                    color:
                      pagination.current ===
                      Math.ceil(pagination.total / pagination.pageSize)
                        ? '#A9AFD9'
                        : '#4F4FFF',
                  })}
                </div>
              </div>
            )}
          </section>
        ) : (
          <div style={{ paddingBottom: '32px' }}>
            {!isLoading && rows.length === 0 ? (
              <TableMessage
                complete={true}
                message='No se encontraron informes.'
              />
            ) : (
              <TableCommon
                columns={columns.filter((el) => el !== false)}
                dataSource={rows}
                rowKey={(record) => record.id}
                onChangeRows={setRows}
                locale={{ emptyText: <span>No se encontrarón reportes</span> }}
                pagination={pagination}
                onChange={handleTableChange}
                loading={isLoading}
              />
            )}
          </div>
        )}

        {isPhone && rowSelection.length > 0 && (
          <>
            <div className='d_content__selected_item_gradient'></div>

            <div className='d_content__selected_item'>
              <Button
                type='text'
                className='d_content__selected_item__cancel'
                onClick={() => setRowSelection([])}
              >
                Cancelar
              </Button>

              <p className='d_content__selected_item__text'>{`${
                rowSelection.length
              } Seleccionada ${rowSelection.length > 1 ? 's' : ''}`}</p>

              <Button
                type='text'
                className='d_content__selected_item__delete'
                onClick={handleDownloadReport}
              >
                Descargar
              </Button>
            </div>
          </>
        )}

        {survey && (
          <ModalPreview
            visible={Boolean(survey)}
            fileName={survey?.fileName}
            requestServiceItemId={survey.id}
            requestServiceItemName={survey.name ?? ''}
            requestServiceItemType={survey.type}
            requestServiceItemQuiz={survey?.quiz}
            setShowModalSurvey={setShowModalSurvey}
            onCancel={() => setSurvey(null)}
            closable={false}
          />
        )}

        {showModalSurvey && (
          <div className='h100vh'>
            <ModalSurvey
              visible={Boolean(survey)}
              onFinishQuiz={() => {
                setRowSelection([]);
                loadList({
                  query,
                  page: pagination.current,
                  limit: pagination.pageSize,
                  service,
                  filters,
                  isRisks,
                });
                window.open(
                  `${process.env.REACT_APP_WEB_PATH_S3_NEW}${survey.fileName}`,
                  '_blank',
                );
              }}
              onCancel={() => {
                setShowModalSurvey(false);
                setSurvey(null);
              }}
              setFinishSurvey={setFinishSurvey}
              initStep={0}
              initTypeMessage={'InfoTecnico'}
              requestServiceItemId={survey.id}
              requestServiceItemName={survey.name ?? ''}
              fileName={survey?.fileName}
            />
          </div>
        )}
        {finishSurvey && (
          <ModalFinishSurvey
            visible={finishSurvey}
            onCancel={() => {
              setFinishSurvey(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadingList: (payload) =>
    dispatch({ type: LOAD_REQUEST_SERVICES_ITEMS_RESULTS_LOADING, payload }),
  loadList: async (data) => {
    dispatch({
      type: LOAD_REQUEST_SERVICES_ITEMS_RESULTS_LOADING,
      payload: true,
    });
    await getRequestServicesItemsResultsClient(dispatch, data);
    dispatch({
      type: LOAD_REQUEST_SERVICES_ITEMS_RESULTS_LOADING,
      payload: false,
    });
  },
  changeFilter: (filter) =>
    dispatch({
      type: CHANGE_REQUEST_SERVICES_ITEMS_RESULTS_FILTERS,
      payload: filter,
    }),
  loadListService: () => getService(dispatch),
});

const mapStateToProps = (state) => ({
  listServices: state.service.list || [],
  data: state.requestServicesItemsResults.list || [],
  query: state.requestServicesItemsResults.query,
  pagination: state.requestServicesItemsResults.pagination,
  filters: state.requestServicesItemsResults.filters,
  isLoading: state.requestServicesItemsResults.isLoading,
  user: state.auth.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
