import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button, notification, Skeleton } from 'antd';
import Search from 'antd/lib/input/Search';
import {
  RideGlDownloadSolid,
  RideIconButton,
  RideTag,
} from '@rimac-seguros/ride-system-components';

import { parseDayjsDate, pushDigitalData } from '../../../../../lib/utils';
import { useScreen } from '../../../../../lib/screen';
import { checkPermission } from '../../../../../lib/security';
import { sendFinishService } from '../../../../../services/requestServicesItems';
import { getRequestServicesItemsResults } from '../../../../../services/requestServicesItemsResults';
import { LOAD_REQUEST_SERVICES_ITEMS_RESULTS_LOADING } from '../../../../../redux/actions/actionsType';

import Table from '../../../../../common/Table';
import TableMessage from '../../../../../common/Table/TableMessage';
import Title from '../../../../../common/Typograph/Title';
import { CardSkeleton, iconLeft, iconRight } from '../Reports/utils';
import CardReport from './components/CardReport';
import { rsiHasAllReports } from './utils';

const ReportsRimac = ({
  loadList,
  list,
  isLoading,
  pagination,
  query,
  isRisks,
  isActive,
}) => {
  const dispatch = useDispatch();
  const { isMobileScreen } = useScreen();
  const [rows, setRows] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const [isLoadingFinishService, setIsLoadingFinishService] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const showAll = checkPermission(user, 'show_all_request_services');

  const _handleDownloadReport = (row) => () => {
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData({}, 'TrackTechnicalReportDownload');
    }
    window.open(
      `${process.env.REACT_APP_WEB_PATH_S3_NEW}${row.filename_uploaded}`,
      '_blank',
    );
  };

  const _handleFillSurvey = () => () => {};

  const handleFinishService = (requestServiceItemId) => {
    setIsLoadingFinishService(requestServiceItemId);
    sendFinishService(dispatch, requestServiceItemId)
      .then((r) => {
        if (!r) {
          notification.success({
            description: 'Servicio finalizado correctamente.',
            message: '¡Informe técnico!',
          });

          loadList(
            {
              query,
              page: pagination.current,
              limit: pagination.pageSize,
            },
            isRisks,
          );
        }
      })
      .finally(() => setIsLoadingFinishService(false));
  };

  const columns = [
    {
      title: 'Fecha de servicio',
      dataIndex: 'date',
      key: 'date',
      width: '160px',
      render: (date) => parseDayjsDate(date).format('DD MMM YYYY - hh:mm'),
    },
    {
      title: 'Empresa',
      dataIndex: 'request_service_item',
      key: 'request_service_item',
      width: '160px',
      render: (rsi) => rsi?.requestService?.company?.name || '',
    },
    {
      title: 'Gestor de Cuenta',
      dataIndex: 'request_service_item',
      key: 'managerAccount',
      width: '140px',
      render: (rsi) => {
        if (rsi?.requestService?.company?.companiesUsers?.length)
          return rsi?.requestService?.company?.companiesUsers
            .map((u) => {
              return `${u.name} ${u.last_name1} ${u.last_name2}`;
            })
            .join(', ');

        return '';
      },
    },
    {
      title: 'Cumplimiento',
      dataIndex: 'compliance_percent',
      key: 'compliance_percent',
      width: '130px',
      render: (compliance_percent) => <center>{compliance_percent}</center>,
    },
    {
      title: 'Informe',
      dataIndex: 'filename',
      key: 'filename',
      width: '140px',
    },
    {
      title: 'Tipo de servicio',
      dataIndex: 'request_service_item',
      key: 'service',
      width: '140px',
      render: (v) => (v && v.service ? v.service.name : ''),
    },
    {
      title: 'Nombre de Contacto',
      dataIndex: 'contact_name',
      key: 'contact_name',
      width: '180px',
    },
    {
      dataIndex: 'eye',
      title: 'Acciones',
      editable: false,
      fixed: 'right',
      key: 'eye',
      width: 80,
      render: (__, record) => {
        return rows.length > 0 ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            {record?.request_service_item?.state === 5 &&
            rsiHasAllReports(record?.request_service_item) &&
            showAll ? (
              <Button
                style={{
                  border: '2px solid #4F4FFF',
                  borderRadius: 16,
                  color: '#4F4FFF',
                  width: 72,
                  height: 28,
                }}
                onClick={() => {
                  handleFinishService(record?.request_service_item_id);
                }}
                disabled={
                  isLoadingFinishService === record.request_service_item.id
                }
              >
                Validar
              </Button>
            ) : (
              <RideTag text='Finalizado' case='null' />
            )}
            <RideIconButton
              glyph={<RideGlDownloadSolid />}
              variant='outline'
              disabled={!record.filename_uploaded}
              onClick={_handleDownloadReport(record)}
            />
          </div>
        ) : null;
      },
    },
  ];

  const handleTableChange = (pag) =>
    loadList(
      {
        page: pag.current,
        limit: pag.pageSize,
        query: textSearch,
      },
      isRisks,
    );

  const handleTableChangeMobile = (current) => {
    if (
      current === 0 ||
      Math.ceil(pagination.total / pagination.pageSize) + 1 === current
    )
      return;

    loadList(
      {
        page: current,
        limit: pagination.pageSize,
      },
      isRisks,
    );
  };

  const handleFilter = (e) => {
    const txt = e.target.value;
    setTextSearch(txt);

    if (txt.length >= 2)
      loadList(
        {
          query: txt,
          page: 1,
          limit: pagination.pageSize,
        },
        isRisks,
      );
    else
      loadList(
        {
          query: '',
          page: 1,
          limit: pagination.pageSize,
        },
        isRisks,
      );
  };

  const handleClearFilter = () => {
    setTextSearch('');
  };

  useEffect(() => {
    setRows(list);
  }, [list]);

  useEffect(() => {
    if (isActive) loadList({ page: 1, limit: pagination.pageSize }, isRisks);

    return () => {
      handleClearFilter();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <>
      {/* <FilterSection text={'informes técnicos'} handleFilter={handleFilter} /> */}
      <div className='flex full-width justify-between mb-2'>
        <Title className='mt-1' type='bold-20'>
          Filtrar lista de informes técnicos
        </Title>
        {!isMobileScreen ? (
          <Search
            placeholder='Palabras clave'
            value={textSearch}
            style={{
              width: '100%',
              maxWidth: '300px',
            }}
            allowClear
            onChange={handleFilter}
          />
        ) : null}
      </div>
      {isMobileScreen && (
        <Search
          value={textSearch}
          placeholder='Palabras clave'
          onChange={handleFilter}
          allowClear
          style={{
            width: '100%',
            maxWidth: '300px',
          }}
        />
      )}
      {!isMobileScreen ? (
        !isLoading && list.length === 0 ? (
          <TableMessage
            complete={true}
            message='No se encontrarón informes técnicos'
          />
        ) : (
          <section className='show-data'>
            {isLoading ? (
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            ) : (
              <p className='info-total-data'>
                Se encontraron <span>{pagination.total}</span> informes técnicos
              </p>
            )}

            <Table
              dataSource={rows}
              columns={columns}
              rowKey={(record) => record.id}
              loading={isLoading}
              locale={{ emptyText: 'No se encontraron servicios.' }}
              pagination={pagination}
              onChangeRows={setRows}
              onChange={handleTableChange}
            />
          </section>
        )
      ) : isLoading ? (
        [1, 2, 3].map((el) => <CardSkeleton key={el} />)
      ) : !isLoading && list.length === 0 ? (
        <TableMessage complete={true} message='No se encontrarón proveedores' />
      ) : (
        <section className='content-mobile'>
          {isLoading ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : (
            <p className='info-total-data'>
              Se encontraron <span>{pagination.total}</span> informes técnicos
            </p>
          )}
          {rows.map((report) => (
            <CardReport
              key={report.id}
              report={report}
              _handleDownloadReport={_handleDownloadReport}
              _handleFillSurvey={_handleFillSurvey}
              handleFinishService={handleFinishService}
              showAll={showAll}
            />
          ))}

          <div className='d_content__list__pag'>
            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination.current - 1)}
            >
              {iconLeft({
                color: pagination.current === 1 ? '#A9AFD9' : '#4F4FFF',
              })}
            </div>

            <div className='d_content__list__pag__number color-red-1'>
              {pagination.current}
            </div>
            <div className='d_content__list__pag__sep'>/</div>
            <div className='d_content__list__pag__number color-gray-3'>
              {Math.ceil(pagination.total / pagination.pageSize)}
            </div>

            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination.current + 1)}
            >
              {iconRight({
                color:
                  pagination.current ===
                  Math.ceil(pagination.total / pagination.pageSize)
                    ? '#A9AFD9'
                    : '#4F4FFF',
              })}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadList: async (data, isRisks) => {
    dispatch({
      type: LOAD_REQUEST_SERVICES_ITEMS_RESULTS_LOADING,
      payload: true,
    });
    const payload = isRisks
      ? { ...data, filters: { ...(data?.filters ?? {}), isRisk: true } }
      : data;
    await getRequestServicesItemsResults(dispatch, payload);
    dispatch({
      type: LOAD_REQUEST_SERVICES_ITEMS_RESULTS_LOADING,
      payload: false,
    });
  },
});

const mapStateToProps = (state) => ({
  list: state.requestServicesItemsResults.list || [],
  query: state.requestServicesItemsResults.query,
  pagination: state.requestServicesItemsResults.pagination,
  isLoading: state.requestServicesItemsResults.isLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsRimac);
