import React from 'react';
import Legend from '../Legend';

const VisibleLegend = ({ toggleVisibility }) => {
  return (
    <Legend
      title='Temperatura de las nubes C°'
      numbers={[-92, -85, -45, -40, -30, -20, -15, -10, 30, 55]}
      descriptions={['Sin nubes', 'Nublado parcial', 'Nublado']}
      toggleVisibility={toggleVisibility}
      type='visible'
    />
  );
};

export default VisibleLegend;
