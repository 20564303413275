import { useState, useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import { notification } from 'antd';
import { useDispatch } from 'react-redux';
import L from 'leaflet';
import {
  RideGlLocationMapSolid,
  RideGlCurrentLocationOutline,
  RideActionButton,
  RideSpinner,
} from '@rimac-seguros/ride-system-components';
import { getLocationsNew } from '../../../services/locations';
import { SET_FAVORITES } from '../../../redux/actions/actionsType';
import LocationPopup from '../tools/Favorites/LocationPopup';

const FlyToButton = ({ selectLayer, selectLayerName }) => {
  const map = useMap();
  const dispatch = useDispatch();
  const [markerPosition, setMarkerPosition] = useState(null);
  const [loading, setLoading] = useState(false);
  const actionButtonRef = useRef(null);

  useEffect(() => {
    if (actionButtonRef.current) {
      L.DomEvent.disableClickPropagation(actionButtonRef.current);
    }
  }, []);

  const goToCurrentLocation = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMarkerPosition({ lat: latitude, lng: longitude });
          map.flyTo([latitude, longitude], 18);
          setLoading(false);
        },
        () => {
          notification.error({
            message: 'Error',
            description: 'No se pudo obtener la ubicación actual.',
          });
          setLoading(false);
        },
      );
    } else {
      notification.error({
        message: 'Error',
        description: 'Tu navegador no soporta geolocalización.',
      });
      setLoading(false);
    }
  };

  const removeMarker = () => {
    setMarkerPosition(null);
  };

  const centerMap = () => {
    const centerCoordinates = [-8.9382, -74.8882];
    map.flyTo(centerCoordinates, 6);
  };

  const addFavorite = async () => {
    const updatedFavorites = await getLocationsNew();
    dispatch({ type: SET_FAVORITES, payload: updatedFavorites });
  };

  return (
    <>
      <div
        ref={actionButtonRef}
        style={{ position: 'absolute', bottom: 10, right: 10, zIndex: 1000 }}
        className='flex flex-col gap-2'
      >
        <RideActionButton
          glyph={<RideGlCurrentLocationOutline />}
          tooltipText='Centrar mapa'
          onClick={centerMap}
          placement='left-center'
          size='small'
        />
        <RideActionButton
          glyph={loading ? <RideSpinner /> : <RideGlLocationMapSolid />}
          tooltipText='Ir a mi ubicación'
          onClick={goToCurrentLocation}
          placement='left-center'
          size='small'
        />
      </div>
      {markerPosition && (
        <LocationPopup
          addFavorite={addFavorite}
          selectLayer={selectLayer}
          selectLayerName={selectLayerName}
          markerPosition={markerPosition}
        />
      )}
    </>
  );
};

export default FlyToButton;
