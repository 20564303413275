import React from 'react';
import Legend from '../Legend';

const HorizontalVisibilityLegend = ({ toggleVisibility }) => {
  return (
    <Legend
      title='Visibilidad horizontal en km.'
      numbers={[0, 0.1, 0.5, 1, 2, 3, 5, 10, 20]}
      descriptions={['Visibilidad escasa', 'Visibilidad óptima']}
      toggleVisibility={toggleVisibility}
      type='horizontal-visibility'
    />
  );
};

export default HorizontalVisibilityLegend;
