import { useEffect } from 'react';
import { useMap, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

const wmsUrl = `${process.env.REACT_APP_GEOSERVER_MAPS_NEW}?service=WFS&version=1.0.0&request=GetFeature&typeName=cmo%3Aemergencias&outputFormat=application%2Fjson`;

const EmergenciesLayer = () => {
  const map = useMap();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(wmsUrl);
        const data = await response.json();

        data.features.forEach((feature) => {
          const { coordinates } = feature.geometry;
          const { descripcion, tipoemergencia, ubicacion } = feature.properties;

          const marker = L.marker([coordinates[1], coordinates[0]]).addTo(map);
          marker.bindPopup(`
            <strong>Tipo de Emergencia:</strong> ${tipoemergencia}<br/>
            <strong>Descripción:</strong> ${descripcion}<br/>
            <strong>Ubicación:</strong> ${ubicacion}
          `);
        });
      } catch (error) {
        console.error('Error fetching GeoJSON data:', error);
      }
    };

    fetchData();
  }, [map]);

  return null;
};

export default EmergenciesLayer;
