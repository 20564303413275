import { useCallback, useState, useMemo } from 'react';
import { Typography, Upload, Input, Divider, Button } from 'antd';
import {
  FileTextOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import IconUpload from '../../images/icon_upload.svg';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import TableCommon from '../Table';

const { Text } = Typography;
const { Dragger } = Upload;

const EditableCell = ({
  dataIndex,
  record,
  handleSave,
  children,
  ...othersProps
}) => {
  const [values, setValues] = useState(record ? record[dataIndex] : '');

  const save = () => {
    handleSave({ ...record, [dataIndex]: values });
  };

  const handleChangeInput = ({ target: { value } }) => {
    setValues(value);
  };

  return (
    <td {...othersProps}>
      {record ? (
        <Input
          onPressEnter={save}
          onBlur={save}
          onChange={handleChangeInput}
          value={values}
        />
      ) : (
        children
      )}
    </td>
  );
};

const columns = [
  {
    dataIndex: 'name',
    key: 'name',
    title: 'NOMBRES',
    width: '80px',
  },
  {
    dataIndex: 'last_name',
    key: 'last_name',
    title: 'APELLIDOS',
    width: '100px',
  },
  {
    dataIndex: 'email',
    key: 'email',
    title: 'CORREO',
    width: '100px',
  },
  {
    dataIndex: 'document',
    key: 'document',
    title: 'DNI',
    width: '80px',
  },
  {
    dataIndex: 'position',
    key: 'position',
    title: 'AREA ó CARGO',
    width: '80px',
  },
];

const filesAcceptedDefault = ['.xlsx', '.xls'];

const InputFile = ({
  className,
  onChangeRows = () => {},
  participants,
  filesAccepted = filesAcceptedDefault,
  withoutPreview,
  title = 'Lista de participantes',
  onChangeFile = () => {},
  processExcelData,
  processExcelDefault,
  onloadendFile = () => {},
  withCertification,
  allowClear,
  multipleFilesText,
  defaultFileList,
}) => {
  const [uploading, setIsUploading] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadProgressPercent, setUploadProgressPercent] = useState(0);

  const processExcelRows = useCallback(
    (rows) =>
      rows
        .filter((row) => row['NOMBRES'] || row['APELLIDOS'])
        .map((row) => {
          return {
            key: uuidv4(),
            name: row['NOMBRES'],
            last_name: row['APELLIDOS'],
            email: row['CORREO'],
            document: row['DNI'],
            position: row['AREA ó CARGO'],
          };
        }),
    [],
  );

  const handleUpload = useCallback(
    (file) => {
      setFile(file);
      let reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = async (e) => {
        if (filesAcceptedDefault.some((el) => filesAccepted.includes(el))) {
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: 'array' });
          try {
            workbook.SheetNames.slice(0, 1).forEach(function (sheetName) {
              const XL_row_object = XLSX.utils.sheet_to_row_object_array(
                workbook.Sheets[sheetName],
              );
              let filtered;

              if (processExcelData) {
                filtered = processExcelData(XL_row_object);
              } else if (processExcelDefault) {
                filtered = processExcelRows(XL_row_object);
              } else {
                filtered = XL_row_object;
              }
              if (withoutPreview) {
                setUploadProgressPercent(1);
              }
              onChangeRows(filtered);
            });
          } catch (e) {
            console.error(e);
          }
        } else {
          const base64 = await reader.result;
          onloadendFile({ base64, file });
          if (withoutPreview) {
            setUploadProgressPercent(1);
          }
        }
      };
    },
    [processExcelRows, withoutPreview, filesAccepted],
  );

  const columnsResult = useMemo(
    () =>
      columns
        .map((col) => ({
          ...col,
          editable: true,
          onCell: (record) => ({
            record,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: (data) => onChangeRows(data, 'save'),
          }),
        }))
        .concat({
          dataIndex: 'delete',
          title: '',
          editable: false,
          fixed: 'right',
          key: 'delete',
          width: '20px',
          render: (__, record) =>
            participants?.length > 0 ? (
              <Button
                shape='circle'
                size='small'
                onClick={() => onChangeRows(record, 'delete')}
              >
                <DeleteOutlined />
              </Button>
            ) : null,
        })
        .concat(
          withCertification
            ? {
                dataIndex: 'certificate',
                title: '',
                editable: false,
                fixed: 'right',
                key: 'certificate',
                width: '20px',
                render: (link) =>
                  link ? (
                    <Button
                      shape='circle'
                      size='small'
                      target='_blank'
                      href={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${link}`}
                    >
                      <DownloadOutlined />
                    </Button>
                  ) : null,
              }
            : [],
        ),
    [onChangeRows, participants, withCertification],
  );

  const hasParticipants = participants && participants.length;
  const isUploadComplete = uploadProgressPercent === 1 && file?.name;

  let content;

  if (hasParticipants && !withoutPreview) {
    content = (
      <div className='flex flex-col container-previsualization'>
        <TableCommon
          columns={columnsResult}
          dataSource={participants}
          loading={uploading}
          components={{ body: { cell: EditableCell } }}
        />
      </div>
    );
  } else if (isUploadComplete) {
    content = (
      <div className='flex flex-col container-previsualization pt-3 pb-3 pl-3 pr-3'>
        <div className='flex items-center'>
          <FileTextOutlined />
          <Text className='title ml-2'>{title}</Text>
        </div>
        <Divider className='mt-2 mb-2' />
        <div className='flex items-center' key='file'>
          <Text className='item-label'>Archivo adjunto:</Text>
          <Text className='item-value ml-2'>{file.name}</Text>
          {allowClear && file && (
            <Button
              className='delete-button'
              type='default'
              shape='circle'
              size='small'
              onClick={() => {
                setFile(null);
                onChangeRows(null);
              }}
            >
              <DeleteOutlined />
            </Button>
          )}
        </div>
        <div className='flex mt-1' key='file'>
          <Text className='item-label'>Fecha:</Text>
          <Text className='item-value ml-2'>
            {dayjs().format('DD/MM/YYYY')}
          </Text>
        </div>
      </div>
    );
  } else {
    content = (
      <Dragger
        accept={filesAccepted.join(', ')}
        name='file-upload'
        onChange={onChangeFile}
        defaultFileList={defaultFileList}
        beforeUpload={(file) => {
          setIsUploading(true);
          file.type !== 'application/vnd.google-earth.kml+xml' &&
            handleUpload(file);
          setIsUploading(false);

          return false;
        }}
      >
        <div className={'flex flex-col items-center flex-justify-center'}>
          <img
            className='mb-2'
            src={IconUpload}
            width='56px'
            alt='icon_upload'
          />
          <Text className='title-upload-input mb-1'>
            Arrastre el archivo a esta área o <span>haz click aquí</span>
          </Text>
          {multipleFilesText ? (
            <Text className='subtitle-upload-input-multiple-files mb-2'>
              Si deseas subir más de un documento adjunta un archivo ZIP
            </Text>
          ) : null}
          <Text className='subtitle-upload-input'>
            Máximo 10 MB - Formatos{' '}
            {filesAccepted.map(
              (el, i) =>
                `${!i ? '' : i + 1 < filesAccepted.length ? ',' : ' y'} ${el
                  .slice(1)
                  .toUpperCase()}`,
            )}
          </Text>
        </div>
      </Dragger>
    );
  }

  return <div className={`input-file-container ${className}`}>{content}</div>;
};

export default InputFile;
