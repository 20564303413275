import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Upload, notification } from 'antd';
import { WarningFilled } from '@ant-design/icons';

import { validateFile } from '../../../../lib/security';
import {
  createMyLayer,
  updateMyLayer,
  getMyLayers,
  getPresignedCustomLayersUrl,
} from '../../../../services/maps';
import { sendBannerImage } from '../../../../services/banner';
import {
  MY_LAYERS_IS_LOADING_CREATE,
  MY_LAYERS_SET_ACTIVE_LAYERS,
} from '../../../../redux/actions/actionsType';

import InputWithLabel from '../../../InputWithLabel';
import IconUpload from '../../../../images/icon_upload.svg';
import ErrorMessage from './ErrorMessage';
import { useForm } from 'antd/es/form/Form';

const { Dragger } = Upload;

const FormUpload = ({ setShowForm, layerData = {}, setStep }) => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const isLoadingCreate = useSelector(
    (state) => state.myLayers.isLoadingCreate,
  );
  const [isErrorFile, setErrorFile] = useState(null);
  const [file, setFile] = useState(() => layerData.url ?? null);

  const handleSendLayer = async () => {
    try {
      const data = await form.validateFields();

      if (!file) {
        setErrorFile({ type: 'noFile' });
        return;
      }

      dispatch({ type: MY_LAYERS_IS_LOADING_CREATE, payload: true });

      if (file.name) {
        const res = await getPresignedCustomLayersUrl({ name: file.name });
        const { success, payload } = res?.data ?? {};
        const typeFile = file.name.split('.').pop();

        if (success && payload && payload?.url) {
          await sendBannerImage(payload?.url, file);

          if (layerData?.id) {
            await updateMyLayer(layerData?.id, {
              ...data,
              typeFile,
              url: payload.nameKey,
            });
          } else {
            await createMyLayer({
              ...data,
              typeFile,
              url: payload.nameKey,
            });
          }

          await getMyLayers(dispatch);
        }
      } else {
        await updateMyLayer(layerData?.id, {
          ...data,
          url: file,
        });
      }

      setStep(1);
      setShowForm(null);
    } catch (error) {
      notification.error({
        message: 'Ha ocurrido un error al subir el archivo.',
      });
      console.error(error);
    } finally {
      dispatch({ type: MY_LAYERS_IS_LOADING_CREATE, payload: false });
    }
  };

  const allowedExtensions = ['.zip', '.kml', '.kmz', '.geojson'];

  const beforeUpload = (file) => {
    const validations = {
      maxSize: 2 * 1024 * 1024,
      allowedExtensions,
      maxNameLength: 50,
    };
    const { isValid, errors } = validateFile(file, validations);

    if (isValid) {
      setErrorFile(null);
      setFile(file);
      return false; // Prevent automatic upload
    } else {
      const errorType = Object.keys(errors)[0];
      setErrorFile({ type: errorType });
      return Upload.LIST_IGNORE; // Prevent file from being added to the list
    }
  };

  const defaultProps = {
    name: 'file',
    multiple: false,
    beforeUpload,
    accept: allowedExtensions.join(','),
  };

  useEffect(() => {
    dispatch({ type: MY_LAYERS_SET_ACTIVE_LAYERS, payload: [] });
  }, [dispatch]);

  return (
    <Form form={form} className='form-simulations' style={{ marginTop: '8px' }}>
      {file ? (
        <div className='progressbar-summaryLayer mb-3'>
          <div className='progressbar-summaryLayer--file'>
            <div className='progressbar-summaryLayer--file-icon'>
              <WarningFilled style={{ fontSize: '16px' }} />
            </div>
            <span className='progressbar-summaryLayer--file-name'>
              {file.name ?? layerData.fileName}
            </span>
          </div>
          <div className='progressbar-summaryLayer--action'>
            <Button type='link' onClick={() => setFile(null)}>
              ELIMINAR
            </Button>
          </div>
        </div>
      ) : (
        <Dragger {...defaultProps} className='mb-3'>
          <p className='ant-upload-drag-icon'>
            <img src={IconUpload} alt='Subir archivo' />
          </p>
          <div className='ant-upload-text'>
            Arrastra tu archivo o{' '}
            <span className='ant-upload-text--link'>adjuntalo aquí</span>
          </div>
          <div className='ant-upload-text-secondary'>
            Máximo 2 MB - Formatos SHP(Archivo .zip), KML, y GEOJSON
          </div>
        </Dragger>
      )}
      {isErrorFile && <ErrorMessage typeError={isErrorFile.type} />}
      <Form.Item
        name='name'
        initialValue={layerData.name}
        rules={[{ required: true, message: 'Este campo es requerido' }]}
      >
        <InputWithLabel label={'Nombre para la capa'} />
      </Form.Item>
      <div className='form-actions'>
        <Button
          type='primary'
          className='form-button-success'
          onClick={handleSendLayer}
          loading={isLoadingCreate}
          disabled={isErrorFile}
        >
          Subir Capa
        </Button>
      </div>
    </Form>
  );
};

export default FormUpload;
