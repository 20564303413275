import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { parseDayjsDate } from '../../../lib/utils';

const TYPE_CAPACITACION = 1;
const TYPE_ESPECIALIZADO = 2;
const TYPE_SIGNAL = 3;

const formatDate = (date) => {
  return date && dayjs(date).isValid()
    ? parseDayjsDate(date).format('DD-MM-YY')
    : 'Desconocida';
};

const TechnicalReportView = ({
  report,
  serviceType,
  requestServiceItemsData,
}) => {
  const allListCourses = useSelector((state) => state.courses.list || []);

  const getCourseByKey = (key) => {
    let courses = requestServiceItemsData?.find(
      (data) => data.key === 'courses',
    );
    courses = courses ? JSON.parse(courses.value) : {};
    const course =
      courses[key].course ||
      allListCourses.find((c) => c.id === courses[key].courseId)?.name;

    return course;
  };

  return (
    <div style={{ width: '100%' }}>
      <div className='flex request_service_items_details'>
        <div className='flex-1'>
          {serviceType === TYPE_CAPACITACION && (
            <>
              <label>Curso de capacitación</label>
              <p>{getCourseByKey(report.course)}</p>
              <label>Contacto</label>
              <p>{report.contact_name}</p>
              <label>Fecha de capacitación</label>
              <p>{formatDate(report.date)}</p>
            </>
          )}
          {serviceType === TYPE_ESPECIALIZADO && (
            <>
              <label>Nombre de la persona de contacto</label>
              <p>{report.contact_name}</p>
              <label>Nombre del archivo</label>
              <p>{report.filename}</p>
              <label>Fecha de servicio</label>
              <p>{formatDate(report.date)}</p>
            </>
          )}
          {serviceType === TYPE_SIGNAL && (
            <>
              <label>Contacto</label>
              <p>{report.contact_name}</p>
              <label>Fecha de entrega</label>
              <p>{formatDate(report.date)}</p>
            </>
          )}
        </div>
        <div className='flex-1'>
          {serviceType === TYPE_CAPACITACION && (
            <>
              <label>¿Cuantas horas duró el servicio?</label>
              <p>{report.service_hours}</p>
            </>
          )}
          {serviceType === TYPE_ESPECIALIZADO && (
            <>
              <label>¿Cuantas horas duró el servicio?</label>
              <p>{report.service_hours}</p>
            </>
          )}
          <label>Archivo</label>
          <br />
          <Button
            href={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${report.filename_uploaded}`}
            icon={<DownloadOutlined />}
            type='link'
          >
            Descargar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TechnicalReportView;
