import { post } from '../lib/ajax';
import {
  LOADING_PURCHASE_ORDER_LIST,
  LOADED_PURCHASE_ORDER_LIST,
} from '../redux/actions/actionsType';

export const generatePurchaseOrder = async (data) => {
  const r = await post('/purchase_order/generate_order', data);

  if (r?.data.success) return true;

  return false;
};

export const getPurchaseOrderList = async (data, dispatch) => {
  try {
    dispatch({ type: LOADING_PURCHASE_ORDER_LIST, payload: true });

    const r = await post('/purchase_order/list', data);

    if (r?.data.success) {
      const payload = {
        ...r.data.payload,
        current: data.page,
      };

      dispatch({ type: LOADED_PURCHASE_ORDER_LIST, payload });

      return true;
    } else {
      return false;
    }
  } finally {
    dispatch({ type: LOADING_PURCHASE_ORDER_LIST, payload: false });
  }
};

export const updateOrder = async (id, type, dispatch) => {
  try {
    dispatch({ type: LOADING_PURCHASE_ORDER_LIST, payload: true });
    if (type === 'approved') {
      const r = await post(
        `/purchase_order/accept_order?purchase_order_id=${id}`,
      );
      const payload = {
        ...r.data.payload,
        current: 1,
      };
      if (r?.data?.success) {
        dispatch({ type: LOADED_PURCHASE_ORDER_LIST, payload });

        return true;
      } else {
        return false;
      }
    } else {
      const r = await post(
        `/purchase_order/reject_order?purchase_order_id=${id}`,
      );
      const payload = {
        ...r.data.payload,
        current: 1,
      };
      if (r?.data?.success) {
        dispatch({ type: LOADED_PURCHASE_ORDER_LIST, payload });

        return true;
      } else {
        return false;
      }
    }
  } finally {
    dispatch({ type: LOADING_PURCHASE_ORDER_LIST, payload: false });
  }
};
