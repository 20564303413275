import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { Input, Button, notification, Modal, Typography } from 'antd';
import {
  EditOutlined,
  SyncOutlined,
  CopyOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import { LOG_IN_SIMULATE_USER } from '../../../../../../../redux/actions/actionsType';
import useDebounce from '../../../../../../../hooks/useDebounce';
import { ProfileCompanyContext } from '../..';
import { checkPermission } from '../../../../../../../lib/security';
import { useScreen } from '../../../../../../../lib/screen';
import { CardSkeleton } from '../../../../../../client/ui/PreventionService/Reports/utils';
import {
  deleteUser,
  getUserList,
  resendRegistrationEmail,
} from '../../../../../../../services/users';

import SimpleTable from '../../../../../../../common/Table/SimpleTable';
import CardUser from './CardUser';
import PopoverActions from '../../../../../../../common/Table/PopoverActions';
import TableMessage from '../../../../../../../common/Table/TableMessage';
import PaginationMobile from '../../../../../../../common/PaginationMobile';
import { handleCopyCodeRegister } from '../../../../../../../lib/handler';

const { Search } = Input;
const { Title } = Typography;

const Users = ({
  list,
  isLoading,
  pagination,
  loadUsers,
  user,
  changeOfSession,
  removeUserFromList,
  totalUsers,
}) => {
  const { setModalUser, setUserSelected, setEditingUser } = useContext(
    ProfileCompanyContext,
  );
  const { isMobileScreen } = useScreen();

  const [deletingUser, setDeletingUser] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText);
  const [rows, setRows] = useState([]);

  const _handleDeleteUser = (user) => () => {
    setUserToDelete(user);
    setDeletingUser(true);
  };

  const removeUser = async () => {
    setLoadingDelete(true);
    await removeUserFromList(
      userToDelete,
      { current: 1, pageSize: 5 },
      user,
      totalUsers,
    );
    setLoadingDelete(false);
    setDeletingUser(false);
  };

  const columns = [
    {
      title: 'NOMBRE COMPLETO',
      key: 'name',
      render: (el) => {
        return `${el.name} ${el.last_name1}`;
      },
      visible: true,
      width: '170px',
    },
    {
      title: 'CORREO ELECTRONICO',
      dataIndex: 'username',
      key: 'username',
      visible: true,
    },
    {
      title: 'CELULAR',
      dataIndex: 'phone',
      key: 'phone',
      visible: true,
      width: '110px',
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      key: 'state',
      visible: true,
      width: '120px',
      render: (state) => {
        return (
          <div className='flex'>
            <span
              style={{
                background:
                  state === 1 ? '#A7EB92' : state === 3 ? '#f87a7e' : '#D7DBF5',
              }}
              className='table-state'
            >
              <div
                style={{
                  background:
                    state === 1
                      ? '#389E0D'
                      : state === 3
                      ? '#FF1C44'
                      : '#7981B2',
                }}
                className='point'
              ></div>
              <span>
                {state === 1
                  ? 'Activo'
                  : state === 3
                  ? 'Eliminado'
                  : 'Inactivo'}
              </span>
            </span>
          </div>
        );
      },
    },
    {
      dataIndex: 'actions',
      title: 'Acciones',
      fixed: 'right',
      key: 'actions',
      width: 80,
      render: (__, currentUser) =>
        rows.length > 0 ? (
          <PopoverActions
            actions={
              <>
                <Button onClick={_handleEditUser(currentUser)}>
                  <EditOutlined /> <span>Editar usuario</span>
                </Button>

                {currentUser.state !== 1 &&
                  currentUser.state !== 3 &&
                  currentUser.code_invitation !== '' &&
                  checkPermission(
                    user,
                    'allows_to_resend_registration_email',
                  ) && (
                    <Button onClick={() => handleResendEmail(currentUser)}>
                      <SyncOutlined /> <span>Reenviar correo de registro</span>
                    </Button>
                  )}

                {currentUser.state !== 1 &&
                  currentUser.state !== 3 &&
                  currentUser.code_invitation !== '' &&
                  checkPermission(
                    user,
                    'allows_to_resend_registration_email',
                  ) && (
                    <Button onClick={() => handleCopyCodeRegister(currentUser)}>
                      <CopyOutlined /> <span>Copiar link</span>
                    </Button>
                  )}
                <Button
                  onClick={_handleDeleteUser(currentUser)}
                  disabled={
                    currentUser.username === user.username ||
                    ['Administrador Rimac', 'Gestor de la cuenta'].includes(
                      currentUser?.rol?.name,
                    )
                  }
                >
                  <DeleteOutlined /> <span>Eliminar usuario</span>
                </Button>
              </>
            }
          />
        ) : null,
    },
  ];

  const _handleViewAsUser = (user) => () => {
    changeOfSession(user);
  };

  const handleResendEmail = async ({ id }) => {
    const { success } = await resendRegistrationEmail({ id });

    success
      ? notification.success({
          description: 'Correo enviado correctamente.',
          message: '¡Usuario - Reenviar correo!',
        })
      : notification.warning({
          description: 'Ocurrio un error.',
          message: '¡Usuario - Reenviar correo!',
        });
  };

  const handleTableChange = (pag) => {
    loadUsers({
      query: searchText,
      page: pag.current,
      limit: pagination.pageSize,
    });
  };

  const handleChangeSearch = (e) => setSearchText(e.target.value);

  const _handleEditUser = (u) => () => {
    setUserSelected({ ...u, rol_id: u.rol.id, company_id: u.company.id });
    setEditingUser(true);
    setModalUser(true);
  };

  const handleTableChangeMobile = (current) => {
    if (
      current === 0 ||
      Math.ceil(pagination.total / pagination.pageSize) + 1 === current
    )
      return;

    loadUsers({
      query: searchText,
      page: current,
      limit: pagination.pageSize,
    });
  };

  useEffect(() => {
    loadUsers({
      query: debouncedSearchText.length > 2 ? debouncedSearchText : '',
      page: 1,
      limit: pagination.pageSize,
    });
  }, [debouncedSearchText]);

  useEffect(() => {
    loadUsers(
      {
        page: pagination.current,
        limit: pagination.pageSize,
      },
      true,
    );
  }, []);

  useEffect(() => {
    setRows(list);
  }, [list]);

  return (
    <>
      <section className='profile-company__users'>
        <div className='profile-company__users--header'>
          <Title
            level={5}
            style={{
              marginBottom: 0,
            }}
          >
            Filtra usuarios
          </Title>

          <Search
            placeholder='Palabra clave'
            style={{ width: 255 }}
            onChange={handleChangeSearch}
          />
        </div>
        {!isMobileScreen ? (
          rows.length === 0 && !isLoading ? (
            <TableMessage
              complete={true}
              message='No se encontraron usuarios.'
            />
          ) : (
            <div className='relative'>
              {totalUsers >= 5 && (
                <p
                  className='absolute'
                  style={{ top: '-2rem', color: '#FF1C44', fontWeight: 'bold' }}
                >
                  Si desea habilitar más usuarios, por favor comunícate con tu
                  gestor de cuenta
                </p>
              )}
              <SimpleTable
                dataSource={rows}
                columns={columns}
                pagination={pagination}
                onChange={handleTableChange}
                loading={isLoading}
                locale={{ emptyText: 'No se encontraron usuarios.' }}
              />
            </div>
          )
        ) : (
          <section className='detail relative'>
            {isLoading ? (
              [1, 2, 3].map((el) => <CardSkeleton key={el} />)
            ) : rows.length === 0 && !isLoading ? (
              <TableMessage
                complete={true}
                message='No se encontraron usuarios.'
              />
            ) : (
              <>
                {totalUsers >= 5 && (
                  <p
                    className='absolute'
                    style={{
                      top: '-2rem',
                      color: '#FF1C44',
                      fontWeight: 'bold',
                    }}
                  >
                    Si desea habilitar más usuarios, por favor comunícate con tu
                    gestor de cuenta
                  </p>
                )}
                {rows.map((el) => (
                  <CardUser
                    key={el.id}
                    data={el}
                    user={user}
                    _handleCopyLink={handleCopyCodeRegister}
                    _handleEditUser={_handleEditUser}
                    _handleViewAsUser={_handleViewAsUser}
                    _handleResendEmail={handleResendEmail}
                    _handleDeleteUser={_handleDeleteUser}
                  />
                ))}
                <PaginationMobile
                  {...pagination}
                  onChange={(page) => handleTableChangeMobile(page)}
                />
              </>
            )}
          </section>
        )}
      </section>
      <Modal
        open={deletingUser}
        title='¿Eliminar usuario?'
        onCancel={() => setDeletingUser(false)}
        footer={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='button secondary-button mr-2'
              onClick={() => setDeletingUser(false)}
            >
              Cancelar
            </Button>
            <Button
              className='button ml-1'
              type='primary'
              loading={loadingDelete}
              onClick={removeUser}
            >
              Aceptar
            </Button>
          </div>
        }
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadUsers: (data, isFirstRender) =>
    getUserList(dispatch, data, isFirstRender),
  changeOfSession: (user) => {
    dispatch({ type: LOG_IN_SIMULATE_USER, payload: user.username });

    const route = find(
      user.rol.permissions,
      (p) => p.slug === 'access_view_profile_company',
    )
      ? 'administracion/perfil-empresa'
      : user.rol.slug === 'proveedor'
      ? 'servicio-de-prevencion-solicitud-proveedor'
      : 'monitoreo/mapa';

    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN}${route}`;
    }, 500);
  },
  removeUserFromList: async (user, pagination, currUser, totalUsers) => {
    const deleted = await deleteUser(
      user,
      dispatch,
      pagination,
      currUser,
      totalUsers,
    );

    deleted
      ? notification.success({
          description: 'Usuario eliminado correctamente.',
          message: '¡Perfil empresa!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: '¡Perfil empresa!',
        });
  },
});

const mapStateToProps = (state) => ({
  list: state.users.users,
  isLoading: state.users.isLoadingUser,
  pagination: state.users.pagination,
  query: state.users.query,
  user: state.auth.user,
  totalUsers: state.users.totalUsers,
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
