import React from 'react';

export const IconList = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    {...props}
  >
    <path
      d='M12.75 4.998h-6a.75.75 0 1 1 0-1.5h6a.751.751 0 0 1 0 1.5Zm.75 3a.75.75 0 0 0-.75-.75h-6a.751.751 0 1 0 0 1.5h6A.75.75 0 0 0 13.5 8V8Zm0 3.75a.75.75 0 0 0-.75-.75h-6a.751.751 0 1 0 0 1.5h6a.75.75 0 0 0 .75-.748v-.002Zm-9.747-8.75a1.251 1.251 0 0 0 0 2.5A1.25 1.25 0 1 0 3.75 3l.003-.001Zm0 3.75a1.251 1.251 0 0 0 0 2.5 1.25 1.25 0 0 0 1.25-1.25A1.251 1.251 0 0 0 3.75 6.756l.003-.007Zm0 3.751a1.25 1.25 0 1 0 .476.096 1.248 1.248 0 0 0-.48-.094l.004-.002Z'
      fill={props.color || '#432EFF'}
    />
  </svg>
);

export const IconUpload = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    {...props}
  >
    <path
      d='M3.431 7.154h1.907v2.667c0 .733.6 1.333 1.333 1.333h2.667c.733 0 1.333-.6 1.333-1.333V7.154h1.907c.68 0 1.013-.826.533-1.306L9.018 1.754c-.56-.56-1.48-.56-2.04 0L2.884 5.848c-.466.48-.133 1.306.547 1.306Z'
      fill={props.color || '#4F4FFF'}
    />
    <path
      d='M13.333 14.667H2.667c-.734 0-1.334-.6-1.334-1.334 0-.733.6-1.333 1.334-1.333h10.666c.734 0 1.334.6 1.334 1.333 0 .734-.6 1.334-1.334 1.334Z'
      fill={props.color || '#432EFF'}
    />
  </svg>
);

export const IconMessage = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    {...props}
  >
    <path
      d='M11.5 3h-7a2.002 2.002 0 0 0-2 2v5a2 2 0 0 0 2 2H5v2l2.4-2h4.1a2 2 0 0 0 2-2V5a2.002 2.002 0 0 0-2-2Z'
      fill={props.color || '#141938'}
    />
  </svg>
);

export const IconWarning = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={17}
    fill='none'
    {...props}
  >
    <path
      d='M7.995 2.994a5.502 5.502 0 0 0-5.5 5.5c0 3.037 2.464 5.5 5.5 5.5s5.5-2.463 5.5-5.5c0-3.036-2.453-5.5-5.5-5.5ZM7.505 5.5a.502.502 0 0 1 1.001 0v3.496a.502.502 0 0 1-1.002 0V5.5ZM7.994 12a.744.744 0 0 1-.746-.746.75.75 0 0 1 .746-.747.75.75 0 0 1 .746.747.744.744 0 0 1-.746.746Z'
      fill={props.color || '#141938'}
    />
  </svg>
);

export const IconArrowRight = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.151 3.151a1.2 1.2 0 0 1 1.697 0l4 4a1.2 1.2 0 0 1 0 1.697l-4 4a1.2 1.2 0 1 1-1.697-1.697L8.303 8 5.15 4.848a1.2 1.2 0 0 1 0-1.697Z'
      fill={props?.color || '#4F4FFF'}
    />
  </svg>
);

export const IconClose = (props = {}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={32}
    height={32}
    fill='none'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.913 11.38a.5.5 0 0 1 .707.707L16.707 16l3.913 3.913a.5.5 0 0 1-.707.707L16 16.707l-3.913 3.913a.5.5 0 0 1-.707-.707L15.293 16l-3.913-3.913a.5.5 0 0 1 .707-.707L16 15.293l3.913-3.913Z'
      fill='#A9AFD9'
    />
  </svg>
);

export const IconCloseCircle = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={32}
    height={32}
    fill='none'
    style={{ cursor: 'pointer' }}
    {...props}
  >
    <circle cx={16} cy={16} r={15.5} fill='#fff' stroke='#A9AFD9' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.913 11.38a.5.5 0 0 1 .707.707L16.707 16l3.913 3.913a.5.5 0 0 1-.707.707L16 16.707l-3.913 3.913a.5.5 0 0 1-.707-.707L15.293 16l-3.913-3.913a.5.5 0 0 1 .707-.707L16 15.293l3.913-3.913Z'
      fill='#A9AFD9'
    />
  </svg>
);

export const IconFile = (props) => (
  <svg
    width={16}
    height={20}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M14.026 0H6.015v2.996a2.999 2.999 0 0 1-2.996 2.997H0v12.01C0 19.12.903 20 1.997 20h12.006a1.994 1.994 0 0 0 1.996-1.998V1.998A1.959 1.959 0 0 0 14.026 0ZM12.03 15.03H4.018c-.547 0-.999-.452-.999-1 0-.546.452-.998.999-.998h8.011c.547 0 .999.452.999.999 0 .547-.452.999-.999.999Zm0-3.995H4.018c-.547 0-.999-.452-.999-1 0-.546.452-.998.999-.998h8.011c.547 0 .999.452.999.999 0 .547-.452.998-.999.998Z'
      fill='#141938'
    />
    <path
      d='M4.018 3.02V0L0 4.019h2.995a1.01 1.01 0 0 0 1.023-.999Z'
      fill='#141938'
    />
  </svg>
);

export const IconViewImage = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={28}
    height={28}
    fill='none'
    {...props}
  >
    <circle cx={14} cy={14} r={14} fill='#EDEFFC' />
    <path
      d='m19.296 18.222-2.259-2.259A4.99 4.99 0 0 0 18.02 13c0-2.76-2.241-5-5-5-2.76 0-5 2.24-5 5s2.24 5 5 5a4.99 4.99 0 0 0 2.963-.982l2.259 2.26a.742.742 0 0 0 .537.222.774.774 0 0 0 .537-.222c.26-.278.26-.76-.019-1.056ZM13 16.5A3.51 3.51 0 0 1 9.5 13 3.51 3.51 0 0 1 13 9.5a3.51 3.51 0 0 1 3.5 3.5c0 1.926-1.556 3.5-3.5 3.5Z'
      fill='#4F4FFF'
    />
  </svg>
);

export const IconFilter = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    {...props}
  >
    <path
      d='M13 11.26h-.926c-.278-.592-.87-1-1.574-1-.704 0-1.296.408-1.574 1H3a.763.763 0 0 0-.76.76c0 .407.334.759.76.759h5.926c.278.592.87 1 1.574 1 .704 0 1.296-.408 1.574-1H13c.407 0 .76-.334.76-.76a.75.75 0 0 0-.76-.759Zm-2.5 1.5a.763.763 0 0 1-.76-.759c0-.407.334-.76.76-.76s.76.334.76.76-.334.76-.76.76Zm2.5-5.5H7.074c-.278-.592-.87-1-1.574-1-.704 0-1.296.408-1.574 1H3a.763.763 0 0 0-.76.76c0 .407.334.759.76.759h.926c.278.592.87 1 1.574 1 .704 0 1.296-.408 1.574-1H13c.407 0 .76-.334.76-.76A.75.75 0 0 0 13 7.26Zm-7.5 1.5a.763.763 0 0 1-.76-.759c0-.426.334-.76.76-.76s.76.334.76.76-.334.76-.76.76Zm7.5-5.5h-2.426c-.278-.592-.87-1-1.574-1-.704 0-1.296.408-1.574 1H3a.763.763 0 0 0-.76.76c0 .407.334.759.76.759h4.426c.278.592.87 1 1.574 1 .704 0 1.296-.408 1.574-1H13c.407 0 .76-.334.76-.76A.75.75 0 0 0 13 3.26Zm-4 1.5a.763.763 0 0 1-.76-.759c0-.407.334-.76.76-.76.407 0 .76.334.76.76s-.334.76-.76.76Z'
      fill={props.color || props.colorSider || 'currentColor'}
    />
  </svg>
);

export const IconUser = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    viewBox='0 0 16 16'
    fill='none'
    {...(props || {})}
  >
    <path
      d='M13.4911 12.008C13.4911 12.8389 12.8231 13.507 11.9921 13.507H3.99214C3.16119 13.507 2.49316 12.8389 2.49316 12.008C2.49316 9.79209 3.81292 7.90207 5.68664 7.02223C6.30579 7.54362 7.10416 7.86949 7.984 7.86949C8.86383 7.86949 9.66221 7.54362 10.2814 7.02223C12.1877 7.88578 13.4911 9.79209 13.4911 12.008ZM10.4932 4.4316C10.4932 3.09555 9.36893 2.00391 8.00029 2.00391C6.61536 2.00391 5.50742 3.09555 5.50742 4.4316C5.50742 5.76765 6.63166 6.8593 8.00029 6.8593C9.36893 6.8593 10.4932 5.78395 10.4932 4.4316Z'
      fill='#141938'
    />
  </svg>
);

export const IconInfo = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={22}
    viewBox='0 0 20 22'
    fill='none'
    {...(props || {})}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 20C14.4183 20 18 16.4183 18 12C18 7.58172 14.4183 4 10 4C5.58172 4 2 7.58172 2 12C2 16.4183 5.58172 20 10 20Z'
      fill='currentColor'
    />
    <path
      d='M8.65146 14.4703C8.67619 14.3611 8.71311 14.2163 8.76255 14.0364L9.42878 11.6028C9.43601 11.5745 9.44195 11.5463 9.44725 11.518C9.45254 11.4899 9.45527 11.4634 9.45527 11.4387C9.45527 11.2942 9.40952 11.2043 9.31801 11.1688C9.22635 11.1337 9.05907 11.1107 8.81601 11.1001V10.8144C9.01925 10.8002 9.31432 10.7712 9.70122 10.727C10.0883 10.6831 10.3623 10.645 10.5233 10.6134L10.9857 10.5233L10.1445 13.6079C10.074 13.8723 10.0265 14.0558 10.0016 14.1582C9.93817 14.4192 9.90622 14.5886 9.90622 14.666C9.90622 14.7437 9.92388 14.7939 9.95936 14.8167C9.99452 14.8398 10.0334 14.8512 10.0759 14.8512C10.1856 14.8512 10.3215 14.7578 10.4842 14.5708C10.6469 14.3839 10.7901 14.1881 10.9139 13.9835L11.152 14.137C10.7988 14.6554 10.5285 15.0046 10.3411 15.1845C10.0338 15.4809 9.70555 15.629 9.3559 15.629C9.15812 15.629 8.98056 15.5684 8.82356 15.4464C8.66639 15.3248 8.58789 15.1388 8.58789 14.8882C8.58789 14.8002 8.60908 14.6608 8.65146 14.4703ZM11.1997 8.31706C11.3408 8.45817 11.4114 8.6293 11.4114 8.83029C11.4114 9.03129 11.3408 9.20338 11.1997 9.3461C11.0586 9.48898 10.8876 9.56042 10.6864 9.56042C10.4854 9.56042 10.3135 9.48898 10.1706 9.3461C10.0277 9.20322 9.95631 9.03129 9.95631 8.83029C9.95631 8.6293 10.0277 8.45833 10.1706 8.31706C10.3135 8.1761 10.4854 8.10547 10.6864 8.10547C10.8874 8.10547 11.0586 8.17594 11.1997 8.31706Z'
      fill='white'
    />
  </svg>
);

export const ErrorIcon = (props) => (
  <svg
    width='80'
    height='80'
    viewBox='0 0 80 80'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M47.04 30H26.6837L27.1056 27.5H47.4618L47.04 30ZM46.2118 35H25.8556L25.4337 37.5H45.79L46.2118 35ZM40.5493 67.5563L43.7118 48.75H23.3556L19.1181 73.9563C19.0692 74.2482 18.9184 74.5134 18.6923 74.7045C18.4663 74.8957 18.1797 75.0004 17.8837 75H9.20557C9.02384 75.0002 8.84425 74.9608 8.67931 74.8845C8.51436 74.8082 8.36804 74.6969 8.25054 74.5583C8.13303 74.4196 8.04716 74.2571 7.99892 74.0818C7.95068 73.9066 7.94122 73.723 7.9712 73.5438L18.7931 9.16877C18.9896 8.00311 19.5928 6.94478 20.4955 6.18162C21.3983 5.41846 22.5422 4.99982 23.7243 5.00002H56.0493C56.776 4.99998 57.494 5.15834 58.1533 5.46409C58.8125 5.76984 59.3972 6.21563 59.8666 6.7704C60.336 7.32517 60.6789 7.9756 60.8713 8.67639C61.0636 9.37717 61.1009 10.1115 60.9806 10.8281L55.6587 42.5C56.2899 42.0784 57.0009 41.7908 57.7477 41.6549C58.4945 41.519 59.2613 41.5377 60.0005 41.7099C60.7398 41.8822 61.4359 42.2042 62.0458 42.6562C62.6556 43.1081 63.1663 43.6804 63.5462 44.3375L72.1587 59.2656L63.6712 8.75002C63.3793 7.00048 62.4753 5.41156 61.1204 4.26678C59.7656 3.122 58.0481 2.49584 56.2743 2.50002H23.7243C21.9506 2.49584 20.2331 3.122 18.8782 4.26678C17.5234 5.41156 16.6194 7.00048 16.3274 8.75002L5.50557 73.125C5.41465 73.6629 5.44219 74.2142 5.58627 74.7404C5.73036 75.2666 5.98752 75.755 6.33981 76.1716C6.6921 76.5881 7.13103 76.9228 7.62599 77.1523C8.12095 77.3817 8.66002 77.5004 9.20557 77.5H17.8837C18.7703 77.5006 19.6285 77.187 20.3058 76.6149C20.9832 76.0428 21.4359 75.2492 21.5837 74.375L25.4712 51.25H33.9087L37.5524 72.9375C37.667 72.6184 37.8092 72.31 37.9775 72.0156L40.5493 67.5563Z'
      fill='url(#paint0_linear_4005_3525)'
    />
    <path
      d='M77.1258 73.3998L61.1508 45.7092C60.9074 45.288 60.5576 44.9384 60.1363 44.6952C59.715 44.4521 59.2372 44.3241 58.7508 44.3241C58.2644 44.3241 57.7866 44.4521 57.3653 44.6952C56.944 44.9384 56.5941 45.288 56.3508 45.7092L40.3758 73.3998C40.1327 73.8209 40.0047 74.2985 40.0048 74.7847C40.0049 75.2709 40.1331 75.7485 40.3763 76.1695C40.6196 76.5905 40.9694 76.94 41.3906 77.1828C41.8119 77.4257 42.2896 77.5533 42.7758 77.5529H74.7258C75.212 77.5533 75.6897 77.4257 76.1109 77.1828C76.5321 76.94 76.882 76.5905 77.1252 76.1695C77.3685 75.7485 77.4966 75.2709 77.4967 74.7847C77.4968 74.2985 77.3689 73.8209 77.1258 73.3998ZM60.5633 53.7686L60.2258 66.8936H57.3164L56.9508 53.7686H60.5633ZM60.4383 73.0717C60.0449 73.4956 59.5158 73.7687 58.9424 73.844C58.369 73.9192 57.7874 73.7918 57.2979 73.4838C56.8085 73.1758 56.442 72.7065 56.2618 72.1569C56.0816 71.6074 56.099 71.0122 56.3111 70.4742C56.5231 69.9361 56.9164 69.4891 57.423 69.2102C57.9296 68.9313 58.5177 68.8381 59.0857 68.9468C59.6537 69.0554 60.166 69.3591 60.5339 69.8053C60.9018 70.2514 61.1023 70.8121 61.1008 71.3904C61.1095 71.7014 61.0547 72.0109 60.9397 72.3C60.8247 72.5891 60.652 72.8516 60.432 73.0717H60.4383ZM44.4195 13.3467C44.5789 11.8342 43.6227 9.9248 40.2008 9.9248H33.5914L30.5664 22.4248H34.0039L35.1039 17.8123H37.0133L39.9602 22.4311H43.782L40.3445 17.3342C43.3039 16.2936 44.1789 15.0217 44.4195 13.3467ZM40.5976 13.5873C40.4383 15.0217 39.482 15.3373 37.8102 15.3373H35.2633L36.2977 11.8342H38.8445C40.2789 11.8342 40.757 12.6311 40.5976 13.5873Z'
      fill='url(#paint1_linear_4005_3525)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_4005_3525'
        x1='5.45312'
        y1='2.5'
        x2='48.7713'
        y2='89.1869'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.272135' stopColor='#34263B' />
        <stop offset='0.658079' stopColor='#13172C' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_4005_3525'
        x1='68.2265'
        y1='80.0577'
        x2='12.2646'
        y2='45.3722'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F93757' />
        <stop offset='1' stopColor='#FB8296' />
      </linearGradient>
    </defs>
  </svg>
);
