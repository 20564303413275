import { get, post } from '../lib/ajax';
import {
  MY_LAYERS_IS_LOADING,
  MY_LAYERS_SET_LIST,
} from '../redux/actions/actionsType';

export const getMyLayers = async (dispatch) => {
  try {
    dispatch({ type: MY_LAYERS_IS_LOADING, payload: true });
    const r = await get('/custom_layers/list');

    if (r?.data?.success) {
      dispatch({ type: MY_LAYERS_SET_LIST, payload: r.data.payload });
    }
  } catch (e) {
    console.error(e);
  } finally {
    dispatch({ type: MY_LAYERS_IS_LOADING, payload: false });
  }
};

export const createMyLayer = async (data) => {
  try {
    const r = await post('/custom_layers/add_file', data);

    if (r?.data?.success) {
      return null;
    }

    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getPresignedCustomLayersUrl = async (payload) => {
  try {
    return await post(
      '/custom_layers/get_custom_layers_url_to_import',
      payload,
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteMyLayer = async (id, dispatch) => {
  try {
    dispatch({ type: MY_LAYERS_IS_LOADING, payload: true });
    const r = await post(`/custom_layers/delete?id=${id}`);

    return !r?.data?.success;
  } catch (e) {
    console.error(e);
    return null;
  } finally {
    dispatch({ type: MY_LAYERS_IS_LOADING, payload: false });
  }
};

export const updateMyLayer = async (id, data) => {
  try {
    const r = await post(`/custom_layers/update?id=${id}`, data);

    if (r?.data?.success) {
      return null;
    }

    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};
