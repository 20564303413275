import dayjs from 'dayjs';
import {
  RideGlSateliteSolid,
  RideGlLightningSolid,
  RideGlRainySolid,
  RideGlWorldSolid,
  RideGlCloudySolid,
  RideGlGpsSolid,
  RideGlTemperatureSolid,
  RideGlSunnySolid,
  RideGlSunnyRainySolid,
} from '@rimac-seguros/ride-system-components';

import LightningLayer from '../layers/LightningLayer/LightningLayer';
import LayerEarthquake from '../layers/EarthqueakeLayer/EarthquakeLayer';

export const generateThreeDayInterval = () => {
  const today = dayjs();
  const start = today.add(5, 'hours').subtract(20, 'minutes');
  const end = today.add(3, 'days').add(5, 'hours').subtract(20, 'minutes');
  return `${start.format('YYYY-MM-DD')}T00:00:00Z/${end.format(
    'YYYY-MM-DD',
  )}T23:00:00Z`;
};

export const generateSatelliteInterval = () => {
  const today = dayjs();
  const adjustedMinutes = Math.floor(today.minute() / 10) * 10;
  const start = today
    .subtract(12, 'hours')
    .add(5, 'hours')
    .set('minute', adjustedMinutes)
    .startOf('minute')
    .subtract(20, 'minutes');
  const end = today
    .add(5, 'hours')
    .set('minute', adjustedMinutes)
    .startOf('minute')
    .subtract(20, 'minutes');
  return `${start.format('YYYY-MM-DDTHH:mm:00')}Z/${end.format(
    'YYYY-MM-DDTHH:mm:00',
  )}Z`;
};

export const activeLayer = [
  {
    id: 'satellite',
    name: 'Satelite',
    hasSubLayers: true,
    icon: RideGlSateliteSolid,
    subLayers: [
      {
        id: 'satellite_c02',
        name: 'Visible',
        timeInterval: generateSatelliteInterval(),
        period: 'PT10M',
        type: 'dynamic',
        timeControl: true,
        tileLayer: 'googleHybrid',
        default: true,
      },
      {
        id: 'satellite_c13',
        name: 'Infrarrojo',
        timeInterval: generateSatelliteInterval(),
        period: 'PT10M',
        type: 'dynamic',
        tileLayer: 'googleHybrid',
        timeControl: true,
      },
    ],
  },
  {
    id: 'lightning',
    name: 'Rayos',
    hasSubLayers: false,
    icon: RideGlLightningSolid,
    timeControl: false,
    tileLayer: 'openstreetmap',
    component: LightningLayer,
  },
  {
    id: 'scenarios',
    name: 'Escenarios',
    hasSubLayers: true,
    icon: RideGlRainySolid,
    subLayers: [
      {
        id: 'mapa_escenario_MM_trimestral',
        name: 'Deslizamientos',
        type: 'static',
        timeControl: false,
        tileLayer: 'openstreetmap',
        default: true,
      },
      {
        id: 'mapa_escenario_Inundacion_trimestral',
        name: 'Inundación',
        type: 'static',
        tileLayer: 'openstreetmap',
        timeControl: false,
      },
      {
        id: 'mapa_areas_expuestas_tsunami',
        name: 'Tsunami',
        type: 'static',
        tileLayer: 'openstreetmap',
        timeControl: false,
      },
    ],
  },
  {
    id: 'mapa_PeligrosGeologicos',
    name: 'Peligros Geológicos',
    hasSubLayers: false,
    type: 'static',
    timeControl: false,
    tileLayer: 'openstreetmap',
    icon: RideGlWorldSolid,
  },
  {
    id: 'rayos_uv',
    name: 'Rayos UV',
    hasSubLayers: true,
    icon: RideGlSunnySolid,
    timeControl: false,
    subLayers: [
      {
        id: '03_04_001_03_001_513_0000_00_00',
        name: '48 Horas',
        type: 'static',
        timeControl: false,
        tileLayer: 'openstreetmap',
        default: true,
      },
      {
        id: '03_04_002_03_001_513_0000_00_00',
        name: '72 Horas',
        type: 'static',
        tileLayer: 'openstreetmap',
        timeControl: false,
      },
    ],
  },
  {
    id: 'climatic_forecast',
    name: 'Pronóstico Climático',
    hasSubLayers: true,
    icon: RideGlSunnyRainySolid,
    timeControl: false,
    subLayers: [
      {
        id: 'mapa_pronostico_climatico_pp',
        name: 'Precipitación',
        type: 'static',
        timeControl: false,
        tileLayer: 'openstreetmap',
        default: true,
      },
      {
        id: 'mapa_pronostico_climatico_tmax',
        name: 'Temperatura Máxima',
        type: 'static',
        tileLayer: 'openstreetmap',
        timeControl: false,
      },
      {
        id: 'mapa_pronostico_climatico_tmin',
        name: 'Temperatura Mínima',
        type: 'static',
        tileLayer: 'openstreetmap',
        timeControl: false,
      },
    ],
  },
  {
    id: 'clouds',
    name: 'Nubes',
    hasSubLayers: true,
    icon: RideGlCloudySolid,
    timeControl: false,
    subLayers: [
      {
        id: 'lc',
        name: 'Bajas',
        timeInterval: generateThreeDayInterval(),
        period: 'PT1H',
        type: 'dynamic',
        tileLayer: 'googleHybrid',
        timeControl: true,
        default: true,
      },
      {
        id: 'mc',
        name: 'Medias',
        timeInterval: generateThreeDayInterval(),
        period: 'PT1H',
        type: 'dynamic',
        tileLayer: 'googleHybrid',
        timeControl: true,
      },
      {
        id: 'hc',
        name: 'Altas',
        timeInterval: generateThreeDayInterval(),
        period: 'PT1H',
        type: 'dynamic',
        timeControl: true,
        tileLayer: 'googleHybrid',
      },
    ],
  },
  {
    id: 'wind',
    name: 'Viento',
    hasSubLayers: true,
    icon: RideGlGpsSolid,
    timeControl: false,
    subLayers: [
      {
        id: 'wd',
        name: 'Dirección',
        timeInterval: generateThreeDayInterval(),
        period: 'PT1H',
        type: 'dynamic',
        tileLayer: 'openstreetmap',
        timeControl: true,
        default: true,
      },
      {
        id: 'ws',
        name: 'Velocidad',
        timeInterval: generateThreeDayInterval(),
        period: 'PT1H',
        tileLayer: 'openstreetmap',
        type: 'dynamic',
        timeControl: true,
      },
    ],
  },
  {
    id: 'pp',
    name: 'Lluvias',
    hasSubLayers: false,
    timeInterval: generateThreeDayInterval(),
    period: 'PT1H',
    type: 'dynamic',
    tileLayer: 'openstreetmap',
    icon: RideGlRainySolid,
    timeControl: true,
  },
  {
    id: 'earthquakes',
    name: 'Sismos',
    hasSubLayers: false,
    tileLayer: 'openstreetmap',
    timeControl: false,
    icon: RideGlWorldSolid,
    component: LayerEarthquake,
  },
  {
    id: 'pp_acc',
    name: 'Lluvias Acumuladas',
    hasSubLayers: true,
    icon: RideGlRainySolid,
    subLayers: [
      {
        id: 'pp_12',
        name: '12 Horas',
        type: 'static',
        timeControl: false,
        tileLayer: 'openstreetmap',
        default: true,
      },
      {
        id: 'pp_24',
        name: '24 Horas',
        type: 'static',
        tileLayer: 'openstreetmap',
        timeControl: false,
      },
      {
        id: 'pp_72',
        name: '72 Horas',
        type: 'static',
        tileLayer: 'openstreetmap',
        timeControl: false,
      },
      {
        id: 'pp_120',
        name: '120 Horas',
        type: 'static',
        tileLayer: 'openstreetmap',
        timeControl: false,
      },
    ],
  },
  {
    id: 'temp',
    name: 'Temperatura',
    hasSubLayers: false,
    timeInterval: generateThreeDayInterval(),
    period: 'PT1H',
    type: 'dynamic',
    icon: RideGlTemperatureSolid,
    tileLayer: 'satellite',
    timeControl: true,
  },
  {
    id: 'hr',
    name: 'Humedad Relativa',
    hasSubLayers: false,
    timeInterval: generateThreeDayInterval(),
    period: 'PT1H',
    type: 'dynamic',
    icon: RideGlGpsSolid,
    tileLayer: 'satellite',
    timeControl: true,
  },
  {
    id: 'hcwv',
    name: 'Oleaje',
    hasSubLayers: false,
    timeInterval: generateThreeDayInterval(),
    period: 'PT1H',
    type: 'dynamic',
    icon: RideGlGpsSolid,
    tileLayer: 'satellite',
    timeControl: true,
  },
  {
    id: 'vis',
    name: 'Visibilidad',
    hasSubLayers: false,
    timeInterval: generateThreeDayInterval(),
    period: 'PT1H',
    type: 'dynamic',
    tileLayer: 'satellite',
    icon: RideGlWorldSolid,
    timeControl: true,
  },
  {
    id: 'mapa_volcanes',
    name: 'Volcanes',
    hasSubLayers: false,
    type: 'static',
    timeControl: false,
    tileLayer: 'googleHybrid',
    icon: RideGlWorldSolid,
  },
  {
    id: 'mapa_emergencias',
    name: 'Emergencias',
    hasSubLayers: true,
    icon: RideGlWorldSolid,
    subLayers: [
      {
        id: 'emergencias',
        name: 'Emergencias Viales MTC',
        type: 'static',
        timeControl: false,
        tileLayer: 'googleHybrid',
        default: true,
      },
    ],
  },
];
