import React, { useCallback, useEffect, useState } from 'react';
import { Slider, notification, Select } from 'antd';
import { SelectWithLabel } from '../../../common/InputWithLabel';
import Button from '../../../common/Button';
import { useSelector } from 'react-redux';
import { RideSpinner } from '@rimac-seguros/ride-system-components';
import moment from 'moment';

import {
  updateFiltersAlerts,
  getForecastConfigById,
} from '../../../services/locations';

import { getDataPointLayer } from '../../../lib/apiClient';
import { pushDigitalData } from '../../../lib/utils';
import { TRACK } from '../../../lib/consts';

const layerPPDefault = [
  'pp_carac_clima_ene',
  'pp_carac_clima_feb',
  'pp_carac_clima_mar',
  'pp_carac_clima_abr',
  'pp_carac_clima_may',
  'pp_carac_clima_jun',
  'pp_carac_clima_jul',
  'pp_carac_clima_ago',
  'pp_carac_clima_sep',
  'pp_carac_clima_oct',
  'pp_carac_clima_nov',
  'pp_carac_clima_dic',
];

const RainAlert = ({ data, idLocation, favoritesLocations, onUpdatedData }) => {
  const [rainsState, setRainsState] = useState({});
  const [loading, setLoading] = useState(false);
  const [recommendedValue, setRecommendedValue] = useState(false);
  const [loadingRecommendedValue, setLoadingRecommendedValue] = useState(false);
  const item = favoritesLocations?.find((el) => el.id === idLocation);

  useEffect(() => {
    setRainsState({
      id: idLocation,
      state: 0,
      rains: {
        value: 0,
        max: '',
      },
    });
  }, [idLocation]);

  useEffect(() => {
    if (data) {
      const rainsData = data?.type === 'rains';

      if (rainsData) {
        if (data.value === null) {
          data.value = 0;
        }
        setRainsState(data);
      } else {
        setRainsState({
          id: idLocation,
          state: 0,
          rains: {
            value: 0,
            max: '',
          },
        });
      }
    }
  }, [data]);

  /* TODO: Agregar el nuevo pronostico climatico para la alerta de Lluvias */
  /*   useEffect(() => {
    setLoadingRecommendedValue(true);
    getDataPointLayer(
      { lat: item.lat, lng: item.lng },
      layerPPDefault[parseInt(moment().format('M')) - 1],
    ).then((result) => {
      console.log('result', result);
      const value = result?.features[0]?.properties?.RANGO;
      if (value) setRecommendedValue(value);
      setLoadingRecommendedValue(false);
    });
  }, [item]);
 */
  const handleSubmit = useCallback(() => {
    setLoading(true);
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData(
        {
          action: {
            group: 'Centro Monitoreo',
            category: 'Configuración',
            name: `Alerta - Configurar`,
            label: `Lluvia - ${rainsState.max}`,
          },
        },
        TRACK.ACTION,
      );
    }

    const info = {
      id: idLocation,
      rains: {
        max: rainsState.max,
        value: rainsState.value === '' ? null : rainsState.value,
        state: 1,
      },
    };
    updateFiltersAlerts(info)
      .then((res) => {
        if (res === true) {
          notification.success({
            message: 'Alerta actualizada',
            description: 'La alerta de lluvias se ha actualizado correctamente',
          });
        }
      })
      .finally(() => {
        getForecastConfigById(idLocation).then((res) => {
          if (res) {
            const rainsData = res?.find((el) => el.type === 'rains');
            setRainsState(rainsData);
            onUpdatedData('rains', rainsData);
          }
          setLoading(false);
        });
      });
  }, [rainsState]);

  const onDeleteAlert = useCallback(() => {
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData(
        {
          action: {
            group: 'Centro Monitoreo',
            category: 'Configuración',
            name: `Alerta - Eliminar`,
            label: `Lluvia - ${rainsState.max}`,
          },
        },
        TRACK.ACTION,
      );
    }

    setLoading(true);
    const info = {
      id: idLocation,
      rains: {
        ...rainsState,
        value: null,
        max: null,
        state: 0,
      },
    };
    updateFiltersAlerts(info)
      .then((res) => {
        if (res === true) {
          notification.success({
            message: 'Alerta eliminada',
            description: 'La alerta de lluvias se ha eliminado correctamente',
          });
        }
      })
      .finally(() => {
        getForecastConfigById(idLocation).then((res) => {
          if (res) {
            const rainsData = res?.find((el) => el.type === 'rains');
            setRainsState(rainsData);
            onUpdatedData('rains', rainsData);
          }
          setLoading(false);
        });
      });
  }, [rainsState]);

  const handleChangeRainsMax = useCallback((val) => {
    setRainsState((prev) => ({ ...prev, max: val }));
  }, []);

  const handleChangeSlider = useCallback((el) => {
    setRainsState((prev) => ({
      ...prev,
      value: el < 0 ? 0 : el > 90 ? 90 : el,
    }));
  }, []);

  return (
    <div className='mt-3 pt-2 drawer-map-alert__body'>
      <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
        Pronóstico climatológico mensual:
        {loadingRecommendedValue ? <RideSpinner /> : `  ${recommendedValue} mm`}
      </div>
      <div style={{ marginBottom: '10px' }}>
        Acumulación mayor a {rainsState?.value || 0} mm
      </div>
      <div className='pb-3' style={{ paddingTop: 50 }}>
        <Slider
          marks={{ 0: '0 mm', 90: '90 mm' }}
          onChange={handleChangeSlider}
          value={rainsState?.value || 0}
          step={1}
          min={0}
          max={90}
          style={{ marginTop: '50px' }}
          tooltip={{
            open: true,
            getPopupContainer: (e) => e,
          }}
        />
      </div>
      <div className='flex flex-col items-center'>
        <Select
          name='max'
          className='mb-3 full-width'
          placeholder='Periodo de acumulación'
          value={rainsState?.max}
          onChange={handleChangeRainsMax}
          options={[
            { value: 1, label: '1hr' },
            { value: 3, label: '3hr' },
            { value: 6, label: '6hr' },
            { value: 12, label: '12hr' },
            { value: 24, label: '24hr' },
            { value: 48, label: '48hr' },
            { value: 72, label: '72hr' },
          ]}
        />
        <Button
          type='primary'
          className='mt-2'
          style={{ width: '50%' }}
          disabled={
            (!rainsState?.value && rainsState?.value !== 0) ||
            rainsState?.max == null ||
            loading
          }
          onClick={handleSubmit}
          loading={loading}
          text='Configurar'
        />
        <Button
          variant='text-primary'
          className='mt-3'
          disabled={rainsState.state === 0 || loading}
          onClick={onDeleteAlert}
          loading={loading}
          text='Eliminar alerta'
        />
      </div>
    </div>
  );
};

export default RainAlert;
