import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutoComplete, Button, Form, Select } from 'antd';
import { DeleteFilled } from '@ant-design/icons';

import { getAutocompleteImportedCampus } from '../../../../../../services/polizas';
import { ramoOptions, tipoGeoOptions, yearsOptions } from '../utils';

import { CUMULUS_UPDATE_FILTERS } from '../../../../../../redux/actions/actionsType';
import { closePopUp } from '../../../../../../lib/utils';

const { Option } = Select;

const AutoCompleteFormItem = ({ attribute, label, initialValue, min = 3 }) => {
  const [results, setResults] = useState([]);

  useEffect(() => {
    const input = document
      .getElementById(attribute)
      ?.getElementsByTagName('input');
    if (input && input.length > 0) {
      input[0].setAttribute('autocomplete', 'newpasword');
    }
  }, [attribute]);

  const searchText = (text) => {
    if (!text || text.length < min) {
      setResults([]);
    }

    autocompleteHandle(text);
  };

  const focusText = () => {
    if (min === 0) {
      autocompleteHandle('');
    }
  };

  const autocompleteHandle = (text) => {
    getAutocompleteImportedCampus(attribute, text)
      .then((r) => {
        let results = r.data?.payload
          .filter(
            (res) =>
              res[attribute] !== '' &&
              res[attribute] !== null &&
              res[attribute] !== undefined,
          )
          .map((res) => `${res[attribute]}`);
        if (results && results.length === 10) {
          results = results.concat(['...']);
        }
        results = results.map((result, index) => (
          <AutoComplete.Option key={result} disabled={index === 10}>
            {result}
          </AutoComplete.Option>
        ));
        setResults(results || []);
      })
      .catch((r) => console.error(r));
  };

  return (
    <Form.Item name={attribute} initialValue={initialValue}>
      <AutoComplete
        size='small'
        id={attribute}
        onSearch={searchText}
        onFocus={focusText}
        placeholder={label}
        options={results.map((result) => ({
          value: result.props.children,
          label: result.props.children,
        }))}
      />
    </Form.Item>
  );
};

const DrawerFilters = () => {
  const [form] = Form.useForm();
  const filters = useSelector((state) => state.cumulus.filters);
  const dispatch = useDispatch();

  const handleCreateCircle = () => {
    form.validateFields().then((data) => {
      const filters = Object.fromEntries(
        Object.entries(data).filter(
          ([, value]) =>
            value !== undefined &&
            (value.length > 0 || typeof value === 'number'),
        ),
      );
      dispatch({ type: CUMULUS_UPDATE_FILTERS, payload: filters });
    });
  };

  const handleClearFilters = () => {
    form.resetFields();
    dispatch({ type: CUMULUS_UPDATE_FILTERS, payload: {} });
  };

  useEffect(() => {
    closePopUp();
  }, []);

  return (
    <Form form={form} className='form-simulations'>
      <AutoCompleteFormItem
        attribute='prod'
        label='Producto'
        initialValue={filters['prod']}
      />
      <AutoCompleteFormItem
        attribute='nombre_cliente'
        label='Cliente'
        initialValue={filters['nombre_cliente']}
      />
      <Form.Item name='ramo' initialValue={filters['ramo']}>
        <Select placeholder='Ramo' size='small'>
          {ramoOptions.map((e) => (
            <Option key={e.value} value={e.value}>
              {e.text}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <AutoCompleteFormItem
        attribute='ruc'
        label='RUC'
        initialValue={filters['ruc']}
      />
      <AutoCompleteFormItem
        attribute='nro_pisos'
        label='Nro. Pisos'
        min={0}
        initialValue={filters['nro_pisos']}
      />
      <AutoCompleteFormItem
        attribute='nro_sotanos'
        label='Nro. Sotanos'
        min={0}
        initialValue={filters['nro_sotanos']}
      />
      <Form.Item name='anno_contruc' initialValue={filters['anno_contruc']}>
        <Select placeholder='Año de Construcción' size='small'>
          {yearsOptions().map((item) => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <AutoCompleteFormItem
        attribute='uso_sbs'
        label='Uso SBS'
        initialValue={filters['uso_sbs']}
      />
      <AutoCompleteFormItem
        attribute='tep'
        label='TEP'
        initialValue={filters['tep']}
      />
      <Form.Item name='tipogeo' initialValue={filters['tipogeo']}>
        <Select placeholder='Tipo Geo' size='small'>
          {tipoGeoOptions.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {item.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <AutoCompleteFormItem
        attribute='fronting'
        label='Fronting'
        initialValue={filters['fronting']}
        min={0}
      />
      <AutoCompleteFormItem
        attribute='corredor'
        label='Corredor'
        initialValue={filters['corredor']}
      />
      <AutoCompleteFormItem
        attribute='giroNegocioL'
        label='GIRO NEGOCIO (L)'
        initialValue={filters['giroNegocioL']}
      />
      <AutoCompleteFormItem
        attribute='tipo_suelo'
        label='Tipo de Suelo'
        min={0}
        initialValue={filters['tipo_suelo']}
      />
      <AutoCompleteFormItem
        attribute='pais'
        label='País'
        initialValue={filters['pais']}
      />
      <AutoCompleteFormItem
        attribute='departamento'
        label='Departamento'
        initialValue={filters['departamento']}
      />
      <AutoCompleteFormItem
        attribute='provincia'
        label='Provincia'
        initialValue={filters['provincia']}
      />
      <AutoCompleteFormItem
        attribute='districto'
        label='Distrito'
        initialValue={filters['districto']}
      />
      <div className='form-actions'>
        <Button
          type='submit'
          className='form-button-success'
          onClick={handleCreateCircle}
        >
          Filtrar
        </Button>
        <Button
          type='link'
          className='form-button-link'
          onClick={handleClearFilters}
        >
          <DeleteFilled />
          Limpiar
        </Button>
      </div>
    </Form>
  );
};

export default DrawerFilters;
