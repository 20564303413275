export const LOG_IN = 'LOG_IN';
export const LOG_IN_LOADED_USER = 'LOG_IN_LOADED_USER';
export const LOG_IN_ROL = 'LOG_IN_ROL';
export const LOG_IN_LOADING = 'LOG_IN_LOADING';
export const LOG_IN_WORKING = 'LOG_IN_WORKING';
export const LOG_IN_SIMULATE_USER = 'LOG_IN_SIMULATE_USER';
export const LOG_IN_ERROR = 'LOG_IN_ERROR';
export const LOG_IN_CHANGE_USER_TYPE = 'LOG_IN_CHANGE_USER_TYPE';
export const LOG_IN_FIND_USER = 'LOG_IN_FIND_USER';

export const LOG_OUT = 'LOG_OUT';
export const LOAD_USERS_LOADING = 'LOAD_USERS_LOADING';
export const OPEN_FORM = 'OPEN_FORM';
export const FILTER_USER = 'FILTER_USER';
export const MAP_LOADING = 'MAP_LOADING';

export const LOADED_COMPANIES = 'LOADED_COMPANIES';
export const LOAD_COMPANIES_LOADING = 'LOAD_COMPANIES_LOADING';
export const OPEN_FORM_COMPANY = 'OPEN_FORM_COMPANY';
export const LOADING_CREATE_USER_COMPANY = 'LOADING_CREATE_USER_COMPANY';
export const CHANGE_COMPANY_QUERY = 'CHANGE_COMPANY_QUERY';
export const SELECT_ITEM_COMPANY = 'SELECT_ITEM_COMPANY';

export const LOADING_RPT_POLIZAS = 'LOADING_RPT_POLIZAS';
export const LOADING_RPT_POLIZAS_FAILED = 'LOADING_RPT_POLIZAS_FAILED';
export const LOADED_RPT_POLIZAS = 'LOADED_RPT_POLIZAS';
export const LOADED_RPT_POLIZAS_RESET = 'LOADED_RPT_POLIZAS_RESET';
export const LOADED_POLIZAS_COMPANY_TRACKING =
  'LOADED_POLIZAS_COMPANY_TRACKING';

export const LOADING_RPT_DEALERS_GENERAL = 'LOADING_RPT_DEALERS_GENERAL';
export const LOADING_RPT_DEALERS_GENERAL_FAILED =
  'LOADING_RPT_DEALERS_GENERAL_FAILED';
export const LOADED_RPT_DEALERS_GENERAL = 'LOADED_RPT_DEALERS_GENERAL';

export const LOADING_RPT_PROVIDERS = 'LOADING_RPT_PROVIDERS';
export const LOADING_RPT_PROVIDERS_FAILED = 'LOADING_RPT_PROVIDERS_FAILED';
export const LOADED_RPT_PROVIDERS = 'LOADED_RPT_PROVIDERS';
export const LOADING_RPT_PROVIDER_PAYMENTS_FAILED =
  'LOADING_RPT_PROVIDER_PAYMENTS_FAILED';

export const LOADING_RPT_DASHBOARD_USERS = 'LOADING_RPT_DASHBOARD_USERS';
export const LOADING_RPT_DASHBOARD_USERS_FAILED =
  'LOADING_RPT_DASHBOARD_USERS_FAILED';
export const LOADED_RPT_DASHBOARD_USERS = 'LOADED_RPT_DASHBOARD_USERS';
export const LOADED_RPT_DASHBOARD_USERS_RESET =
  'LOADED_RPT_DASHBOARD_USERS_RESET';

export const LOADED_LOCATIONS = 'LOADED_LOCATIONS';
export const LOAD_LOCATIONS_LOADING = 'LOAD_LOCATIONS_LOADING';
export const OPEN_FORM_LOCATIONS = 'OPEN_FORM_LOCATIONS';
export const LOADING_CREATE_LOCATIONS = 'LOADING_CREATE_LOCATIONS';
export const LOADING_UPDATE_ALL_ALERTS_LOCATIONS =
  'LOADING_UPDATE_ALL_ALERTS_LOCATIONS';

export const LOADED_TYPE_INDUSTRY = 'LOADED_TYPE_INDUSTRY';
export const LOAD_TYPE_INDUSTRY_LOADING = 'LOAD_TYPE_INDUSTRY_LOADING';
export const OPEN_FORM_TYPE_INDUSTRY = 'OPEN_FORM_TYPE_INDUSTRY';
export const LOADING_CREATE_TYPE_INDUSTRY = 'LOADING_CREATE_TYPE_INDUSTRY';
export const LOADED_PAG_TYPE_INDUSTRY = 'LOADED_PAG_TYPE_INDUSTRY';

export const LOADED_BUSINESS_SEGMENT = 'LOADED_BUSINESS_SEGMENT';
export const LOAD_BUSINESS_SEGMENT_LOADING = 'LOAD_BUSINESS_SEGMENT_LOADING';
export const OPEN_FORM_BUSINESS_SEGMENT = 'OPEN_FORM_BUSINESS_SEGMENT';
export const LOADING_CREATE_BUSINESS_SEGMENT =
  'LOADING_CREATE_BUSINESS_SEGMENT';

export const LOADED_REQUEST_SERVICES = 'LOADED_REQUEST_SERVICES';
export const LOAD_REQUEST_SERVICES_LOADING = 'LOAD_REQUEST_SERVICES_LOADING';
export const OPEN_FORM_REQUEST_SERVICES = 'OPEN_FORM_REQUEST_SERVICES';
export const LOADING_CREATE_REQUEST_SERVICES =
  'LOADING_CREATE_REQUEST_SERVICES';
export const CHANGE_REQUEST_SERVICES_FILTERS =
  'CHANGE_REQUEST_SERVICES_FILTERS';
export const UPDATE_REQUEST_SERVICES_ROW = 'UPDATE_REQUEST_SERVICES_ROW';
export const LOADED_REQUEST_SERVICES_MANAGER_ACCOUNT =
  'LOADED_REQUEST_SERVICES_MANAGER_ACCOUNT';
export const LOADED_REQUEST_SERVICES_USERS_CREATE =
  'LOADED_REQUEST_SERVICES_USERS_CREATE';

export const LOADED_REQUEST_SERVICES_ITEMS = 'LOADED_REQUEST_SERVICES_ITEMS';
export const LOAD_REQUEST_SERVICES_ITEMS_LOADING =
  'LOAD_REQUEST_SERVICES_ITEMS_LOADING';
export const LOADING_UPDATE_REQUEST_SERVICE_ITEM =
  'LOADING_UPDATE_REQUEST_SERVICE_ITEM';

export const LOADING_CREATE_REQUEST_SERVICE_ITEM_QUIZ =
  'LOADING_CREATE_REQUEST_SERVICE_ITEM_QUIZ';

export const LOADED_REQUEST_SERVICES_ITEMS_RESULTS =
  'LOADED_REQUEST_SERVICES_ITEMS_RESULTS';
export const LOAD_REQUEST_SERVICES_ITEMS_RESULTS_LOADING =
  'LOAD_REQUEST_SERVICES_ITEMS_RESULTS_LOADING';
export const CHANGE_REQUEST_SERVICES_ITEMS_RESULTS_QUERY =
  'CHANGE_REQUEST_SERVICES_ITEMS_RESULTS_QUERY';
export const CHANGE_REQUEST_SERVICES_ITEMS_RESULTS_FILTERS =
  'CHANGE_REQUEST_SERVICES_ITEMS_RESULTS_FILTERS';

export const LOADED_PURCHASE_ORDER_LIST = 'LOADED_PURCHASE_ORDER_LIST';
export const LOADING_PURCHASE_ORDER_LIST = 'LOADING_PURCHASE_ORDER_LIST';

export const LOAD_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS =
  'LOAD_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS';
export const LOADED_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS =
  'LOADED_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS';
export const LOADING_UPDATE_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS =
  'LOADING_UPDATE_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS';
export const GENERATING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS =
  'GENERATING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS';
export const GENERATING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED =
  'GENERATING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED';
export const REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS =
  'REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS';
export const LOADED_REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS =
  'LOADED_REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS';
export const REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED =
  'REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED';
export const REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS =
  'REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS';
export const LOADED_REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS =
  'LOADED_REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS';
export const REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED =
  'REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED';

export const LOADING_RPT_QUIZZES = 'LOADING_RPT_QUIZZES';
export const LOADING_RPT_QUIZZES_FAILED = 'LOADING_RPT_QUIZZES_FAILED';
export const LOADED_RPT_QUIZZES = 'LOADED_RPT_QUIZZES';

export const LOADED_BROKERS = 'LOADED_BROKERS';
export const LOAD_BROKERS_LOADING = 'LOAD_BROKERS_LOADING';
export const OPEN_FORM_BROKERS = 'OPEN_FORM_BROKERS';
export const LOADING_CREATE_BROKERS = 'LOADING_CREATE_BROKERS';
export const CHANGE_BROKERS_QUERY = 'CHANGE_BROKERS_QUERY';

export const LOADED_LEVEL_COMPANY = 'LOADED_LEVEL_COMPANY';
export const LOAD_LEVEL_COMPANY_LOADING = 'LOAD_LEVEL_COMPANY_LOADING';
export const LOADED_ALL_LEVEL_COMPANY = 'LOADED_ALL_LEVEL_COMPANY';

export const LOADED_SERVICE = 'LOADED_SERVICE';
export const LOAD_SERVICE_LOADING = 'LOAD_SERVICE_LOADING';
export const OPEN_FORM_SERVICE = 'OPEN_FORM_SERVICE';
export const LOADING_CREATE_SERVICE = 'LOADING_CREATE_SERVICE';

export const LOADED_TYPE_POLIZA = 'LOADED_TYPE_POLIZA';
export const LOAD_TYPE_POLIZA_LOADING = 'LOAD_TYPE_POLIZA_LOADING';
export const OPEN_FORM_TYPE_POLIZA = 'OPEN_FORM_TYPE_POLIZA';
export const LOADING_CREATE_TYPE_POLIZA = 'LOADING_CREATE_TYPE_POLIZA';
export const LOADED_ALL_TYPE_POLIZA = 'LOADED_ALL_TYPE_POLIZA';

export const LOAD_PARAMETER_LOADING = 'LOAD_PARAMETER_LOADING';
export const LOADED_ALL_PARAMETERS = 'LOADED_ALL_PARAMETERS';

export const LOADED_NEWSLETTERS = 'LOADED_NEWSLETTERS';
export const LOAD_NEWSLETTERS_LOADING = 'LOAD_NEWSLETTERS_LOADING';
export const CHANGE_NEWSLETTERS_QUERY = 'CHANGE_NEWSLETTERS_QUERY';
export const CHANGE_NEWSLETTERS_FILTER = 'CHANGE_NEWSLETTERS_FILTER';

export const LOADED_PROVIDER = 'LOADED_PROVIDER';
export const LOAD_PROVIDER_LOADING = 'LOAD_PROVIDER_LOADING';
export const CHANGE_PROVIDER_QUERY = 'CHANGE_PROVIDER_QUERY';

export const LOADED_EVENTS = 'LOADED_EVENTS';
export const LOAD_EVENTS_LOADING = 'LOAD_EVENTS_LOADING';
export const OPEN_FORM_EVENT = 'OPEN_FORM_EVENT';
export const LOADING_CREATE_EVENT = 'LOADING_CREATE_EVENT';
export const CHANGE_EVENTS_QUERY = 'CHANGE_EVENTS_QUERY';

export const LOADED_EVENT_TYPE = 'LOADED_EVENT_TYPE';

export const LOADED_EXPLORATIONS = 'LOADED_EXPLORATIONS';
export const LOADING_EXPLORATIONS = 'LOADING_EXPLORATIONS';

export const LOADED_ZONES = 'LOADED_ZONES';

export const LOADED_CAMPUS_IMPORTED = 'LOADED_CAMPUS_IMPORTED';
export const LOAD_CAMPUS_IMPORTED_LOADING = 'LOAD_CAMPUS_IMPORTED_LOADING';
export const CHANGE_CAMPUS_IMPORTED_QUERY = 'CHANGE_CAMPUS_IMPORTED_QUERY';
export const CHANGE_CAMPUS_IMPORTED_FILTERS = 'CHANGE_CAMPUS_IMPORTED_FILTERS';

export const LOAD_CHANGELOG_LOADING = 'LOAD_CHANGELOG_LOADING';
export const LOADED_CHANGELOG = 'LOADED_CHANGELOG';
export const LOADING_CREATE_CHANGELOG = 'LOADING_CREATE_CHANGELOG';
export const CHANGE_CHANGELOG_QUERY = 'CHANGE_CHANGELOG_QUERY';
export const CHANGE_CHANGELOG_PREVIEW_VERSION =
  'CHANGE_CHANGELOG_PREVIEW_VERSION';

export const LOADING_SYNC_CARNAT = 'LOADING_SYNC_CARNAT';
export const FAILED_SYNC_CARNAT = 'FAILED_SYNC_CARNAT';

export const LOADED_ROLS = 'LOADED_ROLS';

export const LOADED_SISMOS_TO_MAP = 'LOADED_SISMOS_TO_MAP';
export const CHANGE_SISMO_FILTERS = 'CHANGE_SISMO_FILTERS';

export const CHANGE_USERS_QUERY = 'CHANGE_USERS_QUERY';
export const LOAD_USERS = 'LOAD_USERS';
export const LOADED_USERS = 'LOADED_USERS';
export const UPDATE_TOTAL_USERS = 'UPDATE_TOTAL_USERS';
export const ERROR_FORM_USERS = 'ERROR_FORM_USERS';
export const LOADING_CREATE_USERS = 'LOADING_CREATE_USERS';

export const COMMON_SET_POSITION_LATLNG = 'COMMON_SET_POSITION_LATLNG';
export const COMMON_SELECT_LOCATION_ID = 'COMMON_SELECT_LOCATION_ID';
export const COMMON_SHOW_FORECAST_DAYS_DIALOG =
  'COMMON_SHOW_FORECAST_DAYS_DIALOG';
export const COMMON_FORECAST_POINT_DATA = 'COMMON_FORECAST_POINT_DATA';
export const COMMON_FORECAST_DAYS_DATA = 'COMMON_FORECAST_DAYS_DATA';

export const SENTMAIL_LOADING_LIST = 'SENTMAIL_LOADING_LIST';
export const SENTMAIL_LOADED_LIST = 'SENTMAIL_LOADED_LIST';

export const LOADED_COURSES = 'LOADED_COURSES';
export const LOADED_COURSES_PAGINATE = 'LOADED_COURSES_PAGINATE';
export const LOADING_COURSES = 'LOADING_COURSES';
export const LOADING_CREATE_COURSE = 'LOADING_CREATE_COURSE';

export const SIDEMENU_CHANGE_VISIBLE = 'SIDEMENU_CHANGE_VISIBLE';

export const LOADED_SIMULATIONS = 'LOADED_SIMULATIONS';
export const LOAD_SIMULATIONS_LOADING = 'LOAD_SIMULATIONS_LOADING';

export const OPEN_FORM_ARTICLE = 'OPEN_FORM_ARTICLE';
export const LOADING_CREATE_ARTICLE = 'LOADING_CREATE_ARTICLE';
export const LOADING_PUBLISH_ARTICLE = 'LOADING_PUBLISH_ARTICLE';
export const LOADED_ARTICLES = 'LOADED_ARTICLES';
export const LOAD_ARTICLES_LOADING = 'LOAD_ARTICLES_LOADING';
export const CHANGE_ARTICLE_QUERY = 'CHANGE_ARTICLE_QUERY';

export const LOADED_CONFLICT = 'LOADED_CONFLICT';
export const LOAD_CONFLICT_LOADING = 'LOAD_CONFLICT_LOADING';
export const CONFLICT_CHANGE_FILTERS = 'CONFLICT_CHANGE_FILTERS';
export const CLEAR_CONFLICT = 'CLEAR_CONFLICT';
export const LOADING_CREATE_CONFLICT = 'LOADING_CREATE_CONFLICT';
export const OPEN_FORM_CONFLICT = 'OPEN_FORM_CONFLICT';
export const LOADING_BULK_CONFLICTS = 'LOADING_BULK_CONFLICTS';
export const CONFLICTS_PROGRESS = 'CONFLICTS_PROGRESS';

export const LOADED_EMERGENCY = 'LOADED_EMERGENCY';
export const LOAD_EMERGENCY_LOADING = 'LOAD_EMERGENCY_LOADING';
export const CLEAR_EMERGENCY = 'CLEAR_EMERGENCY';
export const LOADING_DOWNLOAD_EMERGENCY = 'LOADING_DOWNLOAD_EMERGENCY';
export const LOADING_DOWNLOAD_EMERGENCY_FAILED =
  'LOADING_DOWNLOAD_EMERGENCY_FAILED';
export const LOADED_DOWNLOAD_EMERGENCY = 'LOADED_DOWNLOAD_EMERGENCY';
export const CHANGE_MAP_FILTERS_EMERGENCY = 'CHANGE_MAP_FILTERS_EMERGENCY';
export const LOADING_EMERGENCY_UPDATE_POINT = 'LOADING_EMERGENCY_UPDATE_POINT';
export const LOADING_EMERGENCY_CREATE_POINT = 'LOADING_EMERGENCY_CREATE_POINT';

export const LOADED_ROAD_EMERGENCY = 'LOADED_ROAD_EMERGENCY';
export const LOAD_ROAD_EMERGENCY_LOADING = 'LOAD_ROAD_EMERGENCY_LOADING';
export const FILTER_ROAD_EMERGENCY = 'FILTER_ROAD_EMERGENCY';
export const CLEAR_ROAD_EMERGENCY = 'CLEAR_ROAD_EMERGENCY';
export const LOADING_DOWNLOAD_ROAD_EMERGENCY =
  'LOADING_DOWNLOAD_ROAD_EMERGENCY';
export const LOADING_DOWNLOAD_ROAD_EMERGENCY_FAILED =
  'LOADING_DOWNLOAD_ROAD_EMERGENCY_FAILED';
export const LOADED_DOWNLOAD_ROAD_EMERGENCY = 'LOADED_DOWNLOAD_ROAD_EMERGENCY';
export const LOADING_ROAD_EMERGENCY_CREATE_POINT =
  'LOADING_ROAD_EMERGENCY_CREATE_POINT';
export const LOADING_ROAD_EMERGENCY_UPDATE_POINT =
  'LOADING_ROAD_EMERGENCY_UPDATE_POINT';

export const LOADED_VALUE_OFFER = 'LOADED_VALUE_OFFER';
export const LOAD_VALUE_OFFER_LOADING = 'LOAD_VALUE_OFFER_LOADING';
export const LOADING_CREATE_VALUE_OFFER = 'LOADING_CREATE_VALUE_OFFER';
export const OPEN_FORM_VALUE_OFFER = 'OPEN_FORM_VALUE_OFFER';

export const LOADED_HELP_CENTER = 'LOADED_HELP_CENTER';
export const LOAD_HELP_CENTER_LOADING = 'LOAD_HELP_CENTER_LOADING';
export const LOADING_CREATE_HELP_CENTER = 'LOADING_CREATE_HELP_CENTER';
export const OPEN_FORM_HELP_CENTER = 'OPEN_FORM_HELP_CENTER';
export const CHANGE_FILTER_HELP_CENTER = 'CHANGE_FILTER_HELP_CENTER';

export const CONTROLMAP_DRAWER_VISIBLE = 'CONTROLMAP_DRAWER_VISIBLE';
export const CONTROLMAP_SIDER_VISIBLE = 'CONTROLMAP_SIDER_VISIBLE';
export const CONTROLMAP_FILTER_SELECTED = 'CONTROLMAP_FILTER_SELECTED';
export const CONTROLMAP_DOWNLOAD_SELECTED = 'CONTROLMAP_DOWNLOAD_SELECTED';
export const CONTROLMAP_SELECTED = 'CONTROLMAP_SELECTED';
export const CONTROLMAP_SET_COORDENADAS = 'CONTROLMAP_SET_COORDENADAS';
export const CONTROLMAP_DRAW_CIRCLE = 'CONTROLMAP_DRAW_CIRCLE';

export const OPEN_FORM_REQUEST = 'OPEN_FORM_REQUEST';
export const UPDATE_FORM_REQUEST = 'UPDATE_FORM_REQUEST';
export const UPDATE_REQUEST_SERVICE_FIELD = 'UPDATE_REQUEST_SERVICE_FIELD';
export const SAVE_CONTACTS = 'SAVE_CONTACTS';
export const UPDATE_FROM_STORAGE = 'UPDATE_FROM_STORAGE';
export const CLEAR_FORM_REQUEST = 'CLEAR_FORM_REQUEST';

export const REMOVING_VALUE_OFFER = 'REMOVING_VALUE_OFFER';
export const LOADED_REQUEST_SERVICES_CONTACT =
  'LOADED_REQUEST_SERVICES_CONTACT';
export const UPDATE_CONFLICT_STORE = 'UPDATE_CONFLICT_STORE';
export const UPDATE_LIST_HISTORY_CONFLICT = 'UPDATE_LIST_HISTORY_CONFLICT';
export const UPDATE_PAGINATION_HISTORY_CONFLICTS =
  'UPDATE_PAGINATION_HISTORY_CONFLICTS';

export const LOADING_SLIDER = 'LOADING_SLIDER';
export const LOADING_CREATE_SLIDER = 'LOADING_CREATE_SLIDER';
export const LOADED_SLIDER = 'LOADED_SLIDER';

export const MAP_SET_MAP = 'MAP_SET_MAP';
export const MAP_SET_ACTIVE_LAYER = 'MAP_SET_ACTIVE_LAYER';
export const MAP_SET_ACTIVE_SUBLAYER = 'MAP_SET_ACTIVE_SUBLAYER';
export const MAP_SET_ACTIVE_TOOL = 'MAP_SET_ACTIVE_TOOL';
export const MAP_SET_ACTIVE_UTILS_LAYER = 'MAP_SET_ACTIVE_UTILS_LAYER';
export const MAP_SET_ACTIVE_CUMULUS_TOOL = 'MAP_SET_ACTIVE_CUMULUS_TOOL';
export const MAP_CHANGE_BLOCKED_CLICK = 'MAP_CHANGE_BLOCKED_CLICK';
export const MAP_CHANGE_MULTIPLE_SELECTION_MODE =
  'MAP_CHANGE_MULTIPLE_SELECTION_MODE';
export const MAP_SET_ACTIVE_MULTI_LAYERS = 'MAP_SET_ACTIVE_MULTI_LAYERS';

export const MY_LAYERS_SET_LIST = 'MY_LAYERS_SET_LIST';
export const MY_LAYERS_IS_LOADING = 'MY_LAYERS_IS_LOADING';
export const MY_LAYERS_SET_ACTIVE_LAYERS = 'MY_LAYERS_SET_ACTIVE_LAYERS';
export const MY_LAYERS_IS_LOADING_CREATE = 'MY_LAYERS_IS_LOADING_CREATE';
export const MY_LAYERS_IS_DRAW_MODE = 'MY_LAYERS_IS_DRAW_MODE';
export const MY_LAYERS_SET_DRAWS = 'MY_LAYERS_SET_DRAWS';

export const MY_LAYERS_SELECT_FEATURE = 'MY_LAYERS_SELECT_FEATURE';
export const MY_LAYERS_REMOVE_SELECTED_FEATURE =
  'MY_LAYERS_REMOVE_SELECTED_FEATURE';

export const TRACKING_SET_LIST = 'TRACKING_SET_LIST';
export const TRACKING_IS_LOADING = 'TRACKING_IS_LOADING';
export const TRACKING_UPDATE_FILTERS = 'TRACKING_UPDATE_FILTERS';
export const TRACKING_SET_PAGINATION = 'TRACKING_SET_PAGINATION';

export const CUMULUS_UPDATE_FILTERS = 'CUMULUS_UPDATE_FILTERS';
export const CUMULUS_SET_MARKER = 'CUMULUS_SET_MARKER';
export const CUMULUS_SET_DRAW_LAYER = 'CUMULUS_SET_DRAW_LAYER';
export const CUMULUS_SET_SELECTED_LEGEND = 'CUMULUS_SET_SELECTED_LEGEND';

export const CLIENT_TRACKING_SET_LIST = 'CLIENT_TRACKING_SET_LIST';
export const CLIENT_TRACKING_IS_LOADING = 'CLIENT_TRACKING_IS_LOADING';
export const CLIENT_TRACKING_SET_FILTERS = 'CLIENT_TRACKING_SET_FILTERS';
export const CLIENT_TRACKING_SET_PAGINATION = 'CLIENT_TRACKING_SET_PAGINATION';
export const MY_LAYERS_SELECT_POLYGON_LAYER = 'MY_LAYERS_SELECT_POLYGON_LAYER';

export const UPDATE_FORM_PERSONAL_DATA_VALUES =
  'UPDATE_FORM_PERSONAL_DATA_VALUES';
export const UPDATE_FORM_RECURRENCY_VALUES = 'UPDATE_FORM_RECURRENCY_VALUES';
export const UPDATE_FORM_LOCATION_VALUES = 'UPDATE_FORM_LOCATION_VALUES';
export const UPDATE_FORM_EDIFICATION_VALUES = 'UPDATE_FORM_EDIFICATION_VALUES';
export const UPDATE_FORM_MACHINE_VALUES = 'UPDATE_FORM_MACHINE_VALUES';
export const UPDATE_FORM_STOCK_VALUES = 'UPDATE_FORM_STOCK_VALUES';
export const UPDATE_FORM_LOSS_PROFIT_VALUES = 'UPDATE_FORM_LOSS_PROFIT_VALUES';
export const UPDATE_FORM_PROTECTION_VALUES = 'UPDATE_FORM_PROTECTION_VALUES';
export const UPDATE_FORM_GEOLOGY_VALUES = 'UPDATE_FORM_GEOLOGY_VALUES';
export const UPDATE_FORM_MULTIPLE_VALUES = 'UPDATE_FORM_MULTIPLE_VALUES';
export const MY_ROUTES_SET_LIST = 'MY_ROUTES_SET_LIST';
export const MY_ROUTES_IS_LOADING_LIST = 'MY_ROUTES_IS_LOADING_LIST';
export const MY_ROUTE_SET_ACTIVE_ROUTE = 'MY_ROUTE_SET_ACTIVE_ROUTE';
export const MY_ROUTES_SET_MY_ROUTE_LAYER = 'MY_ROUTES_SET_MY_ROUTE_LAYER';
export const MY_ROUTES_SET_ORIGIN = 'MY_ROUTES_SET_ORIGIN';
export const MY_ROUTES_SET_DESTINATION = 'MY_ROUTES_SET_DESTINATION';
export const MY_ROUTES_ENABLE_CREATE_ROUTE_MODE =
  'MY_ROUTES_ENABLE_CREATE_ROUTE_MODE';
export const MY_ROUTES_SET_POINTS_TO_GENERATE_REPORT =
  'MY_ROUTES_SET_POINTS_TO_GENERATE_REPORT';
export const MY_ROUTES_SET_GEOJSON_ROUTE = 'MY_ROUTES_SET_GEOJSON_ROUTE';

export const GEOLOGICAL_HAZARDS_UPDATE_FILTERS =
  'GEOLOGICAL_HAZARDS_UPDATE_FILTERS';
export const GEOLOGICAL_HAZARDS_SET_POINTS = 'GEOLOGICAL_HAZARDS_SET_POINTS';
export const TSUNAMIS_UPDATE_FILTERS = 'TSUNAMIS_UPDATE_FILTERS';

export const SET_FAVORITES = 'SET_FAVORITES';
