import L from 'leaflet';
import { TRACK } from '../../../../lib/consts';
import { pushDigitalData } from '../../../../lib/utils';

let geocoder;

export const clickOnPredictions = (placeId, description) =>
  new Promise(async (resolver, reject) => {
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData(
        {
          search: {
            keyword: description,
          },
        },
        TRACK.SEARCH_LOCATION,
      );
    }

    if (!geocoder) {
      const { Geocoder } = await window.google.maps.importLibrary('geocoding');
      geocoder = new Geocoder();
    }

    geocoder.geocode({ placeId }, function (results, status) {
      if (status === 'OK') {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        resolver(new L.LatLng(lat, lng));
      }
      reject(null);
    });
  });

export const adornText = (string, query) => {
  const regex = new RegExp('(' + query + ')', 'i');

  return string.replace(regex, '<b>$1</b>');
};
