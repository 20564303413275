import React, { useEffect, useState, useCallback } from 'react';
import { useMap } from 'react-leaflet';
import { useMapEvents } from 'react-leaflet';
import { Popup } from 'react-leaflet';
import axios from 'axios';
import ModalWeatherForecast from '../WeatherForecast';
import ModalTempRainWindForecast from '../WeatherForecast/ModalTempRainWindForecast';
import { buildRequestUrl, getFormattedTime } from '../../utils/tools';
import {
  RideActionButton,
  RideGlCloudySolid,
  RideGlCalendarSolid,
} from '@rimac-seguros/ride-system-components';
import { Skeleton } from 'antd';
import { validLayers, validTRWF } from '../../utils/tools';
import useLocationForm from './useLocationForm';
import LocationForm from './LocationForm';
import LocationButtons from './LocationButtons';

const wsmUrl = process.env.REACT_APP_GEOSERVER_MAPS_NEW;
const wmsUrlUV = 'https://idesep.senamhi.gob.pe/geoserver/g_03_04/wms';

const LocationPopup = ({
  addFavorite,
  selectLayer,
  selectLayerName,
  markerPosition,
}) => {
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [
    isTempRainWindForecastModalVisible,
    setIsTempRainWindForecastModalVisible,
  ] = useState(false);
  const [step, setStep] = useState(1);
  const [popupVisible, setPopupVisible] = useState(false);
  const [clickedLatLng, setClickedLatLng] = useState(null);
  const [bbox, setBbox] = useState('');
  const [requestUrl, setRequestUrl] = useState('');
  const [params, setParams] = useState({});
  const [shouldFetchData, setShouldFetchData] = useState(false);

  const map = useMap();
  const timeDimension = map.timeDimension;

  const formik = useLocationForm(clickedLatLng, addFavorite, setPopupVisible);

  const fetchData = async (url) => {
    setIsLoading(true);
    try {
      const response = await axios.get(url);
      setResponseData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMapClick = useCallback(
    (e) => {
      const lat = e.latlng.lat;
      const lng = e.latlng.lng;
      const calculatedBbox = `${lng - 0.0001},${lat - 0.0001},${lng + 0.0001},${
        lat + 0.0001
      }`;

      setClickedLatLng(e.latlng);
      setBbox(calculatedBbox);
      setPopupVisible(true);

      const formattedTime = timeDimension
        ? getFormattedTime(timeDimension)
        : null;

      const newParams = {
        time: formattedTime,
        bbox: calculatedBbox,
        styles: '',
        format: 'jpeg',
        request: 'GetFeatureInfo',
        layers: selectLayer,
        query_layers: selectLayer,
        width: 2,
        height: 2,
        x: 1,
        y: 1,
        INFO_FORMAT: 'application/json',
      };

      setParams(newParams);

      const newRequestUrl = buildRequestUrl(wsmUrl, newParams);
      setRequestUrl(newRequestUrl);

      const proxyUrl = 'https://corsproxy.io/?';
      const uvUrl = `${proxyUrl}${wmsUrlUV}?request=GetFeatureInfo&INFO_FORMAT=application/json&layers=${selectLayer}&query_layers=${selectLayer}&bbox=${calculatedBbox}&width=2&height=2&x=1&y=1`;

      if (
        selectLayer === '03_04_001_03_001_513_0000_00_00' ||
        selectLayer === '03_04_002_03_001_513_0000_00_00'
      ) {
        fetchData(uvUrl);
      } else {
        fetchData(newRequestUrl);
      }
    },
    [selectLayer, timeDimension],
  );

  useMapEvents({
    click: handleMapClick,
  });

  useEffect(() => {
    if (markerPosition) {
      setClickedLatLng(markerPosition);
      setPopupVisible(true);
    }
  }, [markerPosition]);

  useEffect(() => {
    if (timeDimension) {
      fetchData(requestUrl);

      const handleTimeLoad = (data) => {
        const newFormattedTime = getFormattedTime(timeDimension);
        const newParams = {
          ...params,
          time: newFormattedTime,
        };
        const newRequestUrl = buildRequestUrl(wsmUrl, newParams);
        fetchData(newRequestUrl);
      };

      timeDimension.on('timeload', handleTimeLoad);

      return () => {
        timeDimension.off('timeload', handleTimeLoad);
      };
    }
  }, [requestUrl, timeDimension, params]);

  const getFeatureValue = () => {
    if (
      !responseData ||
      !responseData.features ||
      responseData.features.length === 0
    ) {
      return 'No hay datos disponibles';
    }

    const feature = responseData.features[0];
    if (!feature || !feature.properties) {
      return 'No hay datos disponibles';
    }

    if (
      selectLayer === '03_04_001_03_001_513_0000_00_00' ||
      selectLayer === '03_04_002_03_001_513_0000_00_00'
    ) {
      const indice = feature.properties.indice;
      return indice !== undefined && indice !== null
        ? indice.toFixed(2)
        : 'No hay datos disponibles';
    } else {
      const grayIndex = feature.properties.GRAY_INDEX;
      return grayIndex !== undefined && grayIndex !== null
        ? grayIndex.toFixed(2)
        : 'No hay datos disponibles';
    }
  };

  return (
    popupVisible &&
    clickedLatLng && (
      <Popup position={clickedLatLng} onClose={() => setPopupVisible(false)}>
        <div className='p-4 pb-0'>
          <h3>{selectLayerName}</h3>
          <p>
            Coordenadas: {clickedLatLng.lng.toFixed(2)},{' '}
            {clickedLatLng.lat.toFixed(2)}
          </p>
        </div>
        {isLoading ? (
          <div className='px-4 mb-2'>
            <Skeleton title={true} paragraph={false} active />
          </div>
        ) : (
          responseData && (
            <div className='px-4 mb-2'>
              <pre className='mb-0'>{getFeatureValue()}</pre>
            </div>
          )
        )}
        {step === 1 ? (
          <LocationButtons setStep={setStep} />
        ) : (
          <LocationForm formik={formik} setStep={setStep} />
        )}
        <div className='flex gap-2 items-center p-4'>
          {validLayers.includes(selectLayer) && (
            <>
              <RideActionButton
                onClick={() => {
                  setIsModalVisible(true);
                  setShouldFetchData(true);
                }}
                glyph={<RideGlCloudySolid />}
                tooltipText='Meteograma'
              />
              <ModalWeatherForecast
                open={isModalVisible}
                onClose={() => {
                  setIsModalVisible(false);
                  setShouldFetchData(false);
                }}
                bbox={bbox}
                clickedLatLng={clickedLatLng}
                selectLayer={selectLayer}
                shouldFetchData={shouldFetchData}
                onFetchComplete={() => setShouldFetchData(false)}
              />
            </>
          )}
          {validTRWF.includes(selectLayer) && (
            <>
              <RideActionButton
                glyph={<RideGlCalendarSolid />}
                onClick={() => {
                  setIsTempRainWindForecastModalVisible(true);
                  setShouldFetchData(true);
                }}
                tooltipText='Pronóstico a largo plazo'
              />
              <ModalTempRainWindForecast
                open={isTempRainWindForecastModalVisible}
                onClose={() => {
                  setIsTempRainWindForecastModalVisible(false);
                  setShouldFetchData(false);
                }}
                bbox={bbox}
                shouldFetchData={shouldFetchData}
                onFetchComplete={() => setShouldFetchData(false)}
              />
            </>
          )}
        </div>
      </Popup>
    )
  );
};

export default LocationPopup;
