import React from 'react';
import Legend from '../Legend';

const RelativeHumidityLegend = ({ toggleVisibility }) => {
  return (
    <Legend
      title='Humedad en porcentaje %'
      numbers={[0, 20, 30, 40, 50, 60, 80, 90, 100]}
      descriptions={['Seco', 'Saturada']}
      toggleVisibility={toggleVisibility}
      type='relative-humidity'
    />
  );
};

export default RelativeHumidityLegend;
