import { useEffect, useRef, useState } from 'react';
import { loadGoogleApi } from '../../../../lib/google';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import {
  RideTextField,
  RideGlSearchSolid,
  RideGlErrorSolid,
} from '@rimac-seguros/ride-system-components';

const GoogleSearch = () => {
  const map = useMap();
  const markerRef = useRef(null);
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    loadGoogleApi.load().then((google) => {
      const input = inputRef.current;
      const searchBox = new google.maps.places.SearchBox(input);

      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces();
        if (places.length === 0) {
          setError(true);
          setErrorMessage('No se encontraron lugares.');
          return;
        }

        setError(false);
        setErrorMessage('');

        places.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            return;
          }

          const latLng = [
            place.geometry.location.lat(),
            place.geometry.location.lng(),
          ];
          map.flyTo(latLng, 18, { animate: true });

          if (markerRef.current) {
            map.removeLayer(markerRef.current);
          }

          const marker = L.marker(latLng).addTo(map);
          marker.bindPopup(`<b>${place.name}</b>`).openPopup();

          markerRef.current = marker;
        });
      });
    });
  }, [map]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const clearInput = () => {
    setInputValue('');
    setError(false);
    setErrorMessage('');
    if (markerRef.current) {
      map.removeLayer(markerRef.current);
      markerRef.current = null;
    }
    map.setView([-8.9382, -74.8882], 6);
  };

  return (
    <>
      <p>
        Busca por dirección, nombre o coordenadas (latitud y longitud) del
        lugar. Ejemplo: -15.1875, -74.1768
      </p>
      <div
        style={{ position: 'relative', display: 'flex', alignItems: 'center' }}
      >
        <RideTextField
          ref={inputRef}
          className='w-full'
          glyph={inputValue ? <RideGlErrorSolid /> : <RideGlSearchSolid />}
          glyphPosition='right'
          required
          error={error}
          errorMessage={errorMessage}
          value={inputValue}
          onChange={handleInputChange}
          onGlyphClick={clearInput}
        />
      </div>
    </>
  );
};

export default GoogleSearch;
