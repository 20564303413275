import defaultIcon from '../../../../common/Icons/cumulus/default.svg';
import greenIcon from '../../../../common/Icons/cumulus/green.svg';
import yellowIcon from '../../../../common/Icons/cumulus/yellow.svg';
import orangeIcon from '../../../../common/Icons/cumulus/orange.svg';
import redIcon from '../../../../common/Icons/cumulus/red.svg';
import skyBlueIcon from '../../../../common/Icons/cumulus/sky-blue.svg';
import pinkIcon from '../../../../common/Icons/cumulus/pink.svg';

export const scaleGroup = (total) => {
  if (total > 100000) return 100;
  if (total > 80000) return 80;
  if (total > 60000) return 60;
  if (total > 30000) return 50;
  if (total > 15000) return 45;
  if (total > 9000) return 40;
  if (total > 5000) return 35;
  if (total > 2000) return 30;
  if (total > 1000) return 37;
  if (total > 500) return 35;
  if (total > 300) return 30;

  return 28;
};

export const getIcon = (
  cenpred_riesgo_inundacion,
  cenpred_riesgo_movimiento_masa,
  selectedLegend,
) => {
  const riskLevels = {
    'Muy Bajo': skyBlueIcon,
    Bajo: greenIcon,
    Medio: yellowIcon,
    Alto: orangeIcon,
    'Muy Alto': redIcon,
  };

  if (selectedLegend === 'inundacion') {
    return riskLevels[cenpred_riesgo_inundacion] || defaultIcon;
  }

  if (selectedLegend === 'movimiento') {
    return riskLevels[cenpred_riesgo_movimiento_masa] || defaultIcon;
  }

  return pinkIcon;
};
