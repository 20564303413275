import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import { connect } from 'react-redux';
import {
  Card,
  Button,
  message,
  notification,
  Popover,
  Badge,
  Tooltip,
} from 'antd';
import {
  InfoCircleFilled,
  SwapOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import { deleteRequestService } from '../../../../../../services/requestServices';
import { useScreen } from '../../../../../../lib/screen';
import { checkPermission } from '../../../../../../lib/security';

import CardHeader from './CardHeader';
import ModalToPlain from './ModalToPlain';
import {
  IconDocument,
  IconDots,
  IconEditTable,
  IconEye,
  iconLeft,
  iconRight,
  IconTrash,
} from '../../Reports/utils';
import ModalDelete from '../../../../../admin/Support/ui/components/ModalDelete';
import SimpleTable from '../../../../../../common/Table/SimpleTable';
import ModalUploadTechnicalReport from '../../../../../../common/ModalUploadTechnicalReport';
import { addResult } from '../../../../../../services/requestServicesItemsResults';
import { pushDigitalData } from '../../../../../../lib/utils';
import { TRACK } from '../../../../../../lib/consts';

moment.locale('es');

export const statusAdminColors = (state) => {
  switch (state) {
    case 1:
      return { color: '#FCDA6A', dot: '#FC9700', status: 'Derivado' };
    case 2:
      return { color: '#A7EB92', dot: '#389E0D', status: 'Aceptado' };
    case 3:
      return {
        color: '#FCDA6A',
        dot: '#FC9700',
        status: 'Fecha de visita solicitada',
      };
    case 4:
      return { color: '#A7EB92', dot: '#389E0D', status: 'Fecha Programada' };
    case 5:
      return { color: '#FCDA6A', dot: '#FC9700', status: 'En Proceso' };
    case 6:
      return { color: '#D7DBF5', dot: '#7981B2', status: 'Ejecutado' };
    case 7:
      return { color: '#D7DBF5', dot: '#7981B2', status: 'Cancelado' };
    case 8:
      return { color: '#D7DBF5', dot: '#7981B2', status: 'Rechazado' };
    case 9:
      return {
        color: '#FCDA6A',
        dot: '#FC9700',
        status: 'Pendiente de aprobación',
      };
    default:
      return { color: '#B2DFFF', dot: '#42A7FA', status: 'Nuevo' };
  }
};

export const statusClientColors = (state) => {
  switch (state) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return { color: '#FCDA6A', dot: '#FC9700', status: 'En proceso' };
    case 6:
      return { color: '#D7DBF5', dot: '#7981B2', status: 'Ejecutado' };
    case 7:
      return { color: '#D7DBF5', dot: '#7981B2', status: 'Cancelado' };
    case 8:
    case 9:
      return { color: '#FCDA6A', dot: '#FC9700', status: 'En Proceso' };
    default:
      return { color: '#B2DFFF', dot: '#42A7FA', status: 'Nuevo' };
  }
};

const RSI_STATUS_FINALIZED = 6;

const commonColumns = [
  {
    title: 'SERVICIO',
    dataIndex: 'service',
    key: 'service',
    render: (service) => service?.name || '',
    width: '40%',
  },
  {
    title: 'PÓLIZA',
    dataIndex: 'requestService',
    key: 'requestService',
    render: (requestService) => requestService?.poliza?.typePoliza?.name || '',
    width: '20%',
  },
  {
    title: 'FECHA DE SOLICITUD',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt) => moment(createdAt).format('DD/MM/YYYY'),
    width: '20%',
  },
  {
    title: 'ESTADO',
    dataIndex: 'state',
    key: 'requestService',
    render: (state) => {
      const { color, status, dot } = statusClientColors(state) || {};

      return (
        <div
          className='flex flex-row flex-justify-justify-between'
          style={{ height: '20px' }}
        >
          <span
            style={{ background: color, borderRadius: 6, padding: '2px 8px' }}
            className={'withpoint'}
          >
            <div
              style={{ background: dot }}
              className={'withpoint-circle'}
            ></div>
            <span>{status}</span>
          </span>
        </div>
      );
    },
    width: '20%',
  },
];

const adminColumns = [
  {
    title: 'FECHA DE SOLICITUD',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt) => moment(createdAt).format('DD MMM YYYY - hh:mm'),
  },
  {
    title: 'SERVICIO',
    dataIndex: 'service',
    key: 'service',
    render: (service) => service?.name || '',
  },
  {
    title: 'EMPRESA',
    dataIndex: 'requestService',
    key: 'company',
    render: (requestService) => {
      return requestService?.company?.name || '';
    },
  },
  {
    title: 'ESTADO',
    dataIndex: 'state',
    key: 'requestService',
    render: (state) => {
      const { color, status, dot } = statusAdminColors(state) || {};

      return (
        <div
          className='flex flex-row flex-justify-justify-between'
          style={{ height: '20px' }}
        >
          <span
            style={{ background: color, borderRadius: 6, padding: '2px 8px' }}
            className={'withpoint'}
          >
            <div
              style={{ background: dot }}
              className={'withpoint-circle'}
            ></div>
            <span>{status}</span>
          </span>
        </div>
      );
    },
  },
  {
    title: 'GESTOR DE CUENTA',
    dataIndex: 'listUsersCreate',
    key: 'managerAccount',
    render: (_, record) => {
      const companiesUsers =
        record.requestService?.company?.companiesUsers || [];
      const u = companiesUsers[0];

      return u ? `${u?.name} ${u?.last_name1} ${u?.last_name2}` : '';
    },
  },
  {
    title: 'USUARIO CREADOR',
    dataIndex: 'requestService',
    key: 'userCreate',
    render: (record) => {
      const u = record.user_create;
      return (
        <>
          <strong>
            {u?.name ? `${u?.name} ${u?.last_name1} ${u?.last_name2}` : ''}
          </strong>{' '}
          {u?.rol?.name ? String(u?.rol?.name).toUpperCase() : ''}
        </>
      );
    },
  },
];

const ServiceRequestList = ({
  listRequestService,
  riskListItems,
  pagination,
  isLoading,
  loadData,
  userSlug,
  user,
  setEditContact,
  removeRequests,
  isRisk,
  onAddResult,
  getListServicesRequest,
  listUsersCreate,
}) => {
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [rowSelection, setRowSelection] = useState([]);
  const [elemetToDelete, setElementToDelete] = useState(null);
  const [rows, setRows] = useState([]);
  const [modalToPlain, setModalToPlain] = useState(false);
  const [additional, setAdditional] = useState(0);
  const [currentRequest, setCurrentRequest] = useState(null);
  const { widthScreen } = useScreen();
  const [openModalTechnicalReport, setOpenModalTechnicalReport] =
    useState(null);
  const [courseKey, setCourseKey] = useState();
  const isPhone = widthScreen < 768;

  const handlePushDigitalData = (element, servicio, estado) => {
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData(
        {
          action: {
            group: 'Centro Monitoreo',
            category: 'Servicios de Prevención',
            name: 'Lista de Servicios',
            label: `${element} - ${servicio} - ${estado}`,
          },
        },
        TRACK.ACTION,
      );
    }
  };

  useEffect(() => {
    if (openModalTechnicalReport?.service?.type === '5') {
      const course = openModalTechnicalReport?.requestServiceItemsData?.find(
        (el) => el.key === 'courses',
      )?.value;
      const courseParse = course ? JSON.parse(course ?? '{}') : null;
      courseParse && setCourseKey(Object.keys(courseParse)[0]);
    }
  }, [openModalTechnicalReport]);

  useEffect(() => {
    setRows(isRisk ? riskListItems : listRequestService);
  }, [listRequestService, riskListItems, openModalTechnicalReport]);

  useEffect(() => {
    if (!modalToPlain && additional) setAdditional(0);
  }, [modalToPlain]);

  const handleTableChange = (pag) => {
    loadData({
      current: pag,
      pageSize: pagination.pageSize,
    });
  };

  const handleTableChangeMobile = (current) => {
    loadData({
      current,
      pageSize: pagination.pageSize,
    });
  };

  const _handleDeleteOneElement = (current) => () => {
    handlePushDigitalData(
      'Eliminar servicio',
      current?.service?.name,
      statusAdminColors(current?.state).status,
    );

    setElementToDelete(current);
    setDeleteModal(true);
  };

  const handleDelete = async (willDelete, onFinish = () => {}) => {
    if (willDelete) {
      const data = {
        page: pagination.current,
        limit: pagination.pageSize,
      };

      if (elemetToDelete && elemetToDelete?.requestServiceId) {
        await removeRequests(
          elemetToDelete.requestServiceId,
          elemetToDelete.id,
          data,
          isRisk,
        );

        notification.success({
          description: 'Servicio eliminado con éxito.',
          message: '¡Servicios solicitados!',
        });
      } else {
        rowSelection.forEach(async (id) => {
          const element = rows.find((item) => item.id === id);

          if (element.requestService?.state === 1) {
            await removeRequests(element.requestServiceId, id, data);
            notification.success({
              description: 'Servicio eliminado',
              message: '¡Servicios solicitados!',
            });
          } else {
            notification.warning({
              description: 'Solo puedes eliminar servicios con estado "Nuevo".',
              message: '¡Advertencia!',
            });
          }
        });
      }
    }

    setElementToDelete(null);
    setRowSelection([]);
    setDeleteModal(false);
    onFinish();
  };

  const _handleRedirectEye = (row) => {
    if (row.requestServiceId) {
      const serviceType = isRisk
        ? 'servicio-de-riesgos'
        : 'servicio-de-prevencion';
      navigate(`/${serviceType}/${row.requestServiceId}/${row.id}`);
    } else {
      message.info('No hay detalles para esta solicitud');
    }
  };

  const handleOpenToPlain = (item) => {
    setAdditional(item.additional);
    setCurrentRequest(item);

    const element = document.querySelector(
      '.ant-popover.ant-popover-placement-topRight',
    );
    if (element) {
      element.style.zIndex = 1;
    }

    setModalToPlain(true);
  };

  const ActionsContent = ({ record }) => (
    <div className='buttons-popover'>
      {!['admin_rimac', 'gestor_de_cuenta'].includes(userSlug) ||
      record?.state ? (
        <Button
          onClick={() => {
            handlePushDigitalData(
              'Ver detalle',
              record?.service?.name,
              statusAdminColors(record?.state).status,
            );
            _handleRedirectEye(record);
          }}
        >
          <IconEye /> <span>Ver detalle</span>
        </Button>
      ) : null}
      {['admin_rimac', 'gestor_de_cuenta'].includes(userSlug) ? (
        <>
          {(record?.state !== null || Number(record?.service.type) !== 6) &&
            !record?.state && (
              <Button
                onClick={() => {
                  handlePushDigitalData(
                    isRisk ? 'Agendar fecha' : 'Derivar a provedor',
                    record?.service?.name,
                    statusAdminColors(record?.state).status,
                  );

                  _handleRedirectEye(record);
                }}
              >
                <SwapOutlined />
                <span>{isRisk ? 'Agendar fecha' : 'Derivar a provedor'}</span>
              </Button>
            )}
          {(record?.state !== null || Number(record?.service.type) !== 6) &&
          (record?.state === 1 || !record?.state) ? (
            <Button
              onClick={() => {
                handlePushDigitalData(
                  'Editar contacto',
                  record?.service?.name,
                  statusAdminColors(record?.state).status,
                );

                setEditContact(record);
              }}
            >
              <IconEditTable />
              <span style={{ marginLeft: 6 }}>Editar contacto</span>
            </Button>
          ) : null}
          {isRisk && [3, 4].includes(record?.state) ? (
            <Button
              onClick={() => {
                handlePushDigitalData(
                  'Subir informe',
                  record?.service?.name,
                  statusAdminColors(record?.state).status,
                );

                setOpenModalTechnicalReport(record);
              }}
              icon={<UploadOutlined />}
            >
              <span>Subir informe</span>
            </Button>
          ) : null}
        </>
      ) : null}

      {record?.additional &&
      record.additional >= 25 &&
      record.requestServiceItemsData.length === 0 ? (
        <Button
          onClick={() => {
            handlePushDigitalData(
              'Distribuir',
              record?.service?.name,
              statusAdminColors(record?.state).status,
            );
            handleOpenToPlain(record);
          }}
        >
          <IconDocument /> <span>Distribuir</span>
        </Button>
      ) : null}

      {['admin_rimac', 'gestor_de_cuenta'].includes(userSlug)
        ? ([1, 2, 3, 4].includes(record?.state) || !record?.state) && (
            <Button onClick={_handleDeleteOneElement(record)}>
              <IconTrash /> <span>Eliminar servicio</span>
            </Button>
          )
        : (record?.state === 1 || !record?.state) && (
            <Button onClick={_handleDeleteOneElement(record)}>
              <IconTrash /> <span>Eliminar servicio</span>
            </Button>
          )}
    </div>
  );

  const onChangeCheck = (e) => {
    if (e.target.checked) {
      setRowSelection([...rowSelection, e.target.value]);
    } else {
      setRowSelection(rowSelection.filter((item) => item !== e.target.value));
    }
  };

  const columns = (
    ['admin_rimac', 'gestor_de_cuenta'].includes(userSlug)
      ? adminColumns
      : commonColumns
  ).concat({
    dataIndex: 'eye',
    title: 'Acciones',
    editable: false,
    fixed: 'right',
    key: 'eye',
    width: 80,
    render: (__, record) => {
      const state = record?.requestService?.state;
      const havePendingQuizzes =
        checkPermission(user, 'list_self_request_services') &&
        !record.quiz &&
        state === RSI_STATUS_FINALIZED;

      const havePendingListParticipants = record.pendingParticipant;

      return rows.length > 0 ? (
        <Popover
          placement='topRight'
          trigger='click'
          content={<ActionsContent record={record} />}
          className='popover-actions'
        >
          {havePendingQuizzes || havePendingListParticipants ? (
            <Tooltip
              title={
                havePendingQuizzes
                  ? 'Encuesta pendiente'
                  : havePendingListParticipants
                  ? 'Solicitar certificado'
                  : ''
              }
            >
              <Button className='relative'>
                <Badge
                  count={
                    <InfoCircleFilled
                      style={{ color: '#FF1C44', fontSize: 16 }}
                    />
                  }
                  style={{ position: 'absolute', right: -8, top: -8 }}
                >
                  <IconDots />
                </Badge>
              </Button>
            </Tooltip>
          ) : (
            <Button>
              <IconDots />
            </Button>
          )}
        </Popover>
      ) : null;
    },
  });

  return (
    <div className='list-services'>
      {isPhone ? (
        rows.map((item) => (
          <Card
            key={item.id}
            style={{ width: '100%' }}
            className='d_content__card'
          >
            {item.requestServiceId ? (
              <CardHeader
                state={item?.state}
                id={item.id}
                rowSelection={rowSelection}
                onChangeCheck={onChangeCheck}
                userSlug={userSlug}
              />
            ) : (
              ''
            )}
            <div className='d_content__card__body'>
              <div className='card-detail__item'>
                <h4>Contacto</h4>
                <p>{item.contact || '-'}</p>
              </div>
              <div className='card-detail__item'>
                <h4>Coreo electrónico</h4>
                <p>{item.email || '-'}</p>
              </div>
              <div className='card-detail__item'>
                <h4>Celular</h4>
                <p>{item.number || '-'}</p>
              </div>
              <div className='card-detail__item'>
                <h4>Dirección</h4>
                <p>{item.address || '-'}</p>
              </div>
              <div className='card-detail__item'>
                <h4>Cargo</h4>
                <p>{item.position || '-'}</p>
              </div>
              <div className='card-detail__item'>
                <h4>Servicio</h4>
                <p>{item?.service?.name || '-'}</p>
              </div>
              <div className='card-detail__item'>
                <h4>FECHA DE SOLICITUD</h4>
                <p>{moment(item.createdAt).format('DD MMM YYYY - HH:mm')}</p>
              </div>
            </div>
            <div className='d_content__card__button'>
              <Button
                className='d_content__card__button__text'
                type='link'
                onClick={() => _handleRedirectEye(item)}
              >
                Ver detalle <i className='fas fa-chevron-right'></i>
              </Button>
            </div>
          </Card>
        ))
      ) : (
        <SimpleTable
          dataSource={rows}
          columns={columns}
          locale={{ emptyText: <span>No se encontrarón solicitudes</span> }}
          rowKey={(record) => record.id}
          scroll={
            user?.rol?.slug !== 'administrador_cliente'
              ? { x: 'calc(840px + 30%)' }
              : {}
          }
          pagination={{
            ...pagination,
            onChange: handleTableChange,
          }}
          onChangeRows={setRows}
          loading={isLoading}
        />
      )}

      {isPhone && rows.length && (
        <div className='d_content__list__pag'>
          <div
            className={'d_content__list__pag__button'}
            onClick={() => handleTableChangeMobile(pagination.current - 1)}
          >
            {iconLeft({
              color: pagination.current === 1 ? '#A9AFD9' : '#4F4FFF',
            })}
          </div>
          <div className='d_content__list__pag__number color-red-1'>
            {pagination.current}
          </div>
          <div className='d_content__list__pag__sep'>/</div>
          <div className='d_content__list__pag__number color-gray-3'>
            {Math.ceil(pagination.total / pagination.pageSize)}
          </div>
          <div
            className={'d_content__list__pag__button'}
            onClick={() => handleTableChangeMobile(pagination.current + 1)}
          >
            {iconRight({
              color:
                pagination.current === pagination.pageSize
                  ? '#A9AFD9'
                  : '#4F4FFF',
            })}
          </div>
        </div>
      )}

      {isPhone ? (
        <div className='d_content__list__button'>
          <Button
            className='d_content__list__button__text'
            type='primary'
            onClick={() => navigate('/solicitar-servicio', { replace: true })}
          >
            Nueva Solicitud
          </Button>
        </div>
      ) : null}

      {isPhone && rowSelection.length ? (
        <>
          <div className='d_content__selected_item_gradient'></div>
          <div className='d_content__selected_item'>
            <Button
              type='text'
              className='d_content__selected_item__cancel'
              onClick={() => setRowSelection([])}
            >
              Cancelar
            </Button>

            <p className='d_content__selected_item__text'>{`${
              rowSelection.length
            }${'  '} Seleccionada${rowSelection.length > 1 ? 's' : ''}`}</p>

            <Button
              type='text'
              className='d_content__selected_item__delete'
              onClick={() => setDeleteModal(true)}
            >
              Eliminar
            </Button>
          </div>
        </>
      ) : null}

      <ModalDelete
        visible={deleteModal}
        onConfirm={handleDelete}
        items={rowSelection}
      />

      <ModalToPlain
        isActive={modalToPlain}
        handleCancel={() => setModalToPlain(false)}
        count={additional}
        item={currentRequest}
        setModalToPlain={setModalToPlain}
      />

      <ModalUploadTechnicalReport
        item={openModalTechnicalReport}
        courseKey={courseKey}
        visible={Boolean(openModalTechnicalReport)}
        doAddResult={(...params) => {
          onAddResult(...params);
          getListServicesRequest();
        }}
        setVisible={setOpenModalTechnicalReport}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  removeRequests: async (rsId, rsiId, data, isRisk) =>
    await deleteRequestService(rsId, rsiId, dispatch, data, isRisk),
  onAddResult: (data, item) => addResult(dispatch, data, item.id),
});

const mapStateToProps = (state) => ({
  listRequestService: state.requestServices.listItems || [],
  listUsersCreate: state.requestServices.usersCreate || [],
  riskListItems: state.requestServices.riskItems || [],
  pagination: state.requestServices.pagination,
  isLoading: state.requestServices.isLoadingList,
  user: state.auth.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequestList);
