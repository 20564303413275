import L from 'leaflet';

export const initializeTimeDimension = (map, timeInterval, period) => {
  if (!map.timeDimension) {
    map.timeDimension = new L.TimeDimension({
      timeInterval: timeInterval,
      period: period,
    });
  }
};

export const initializeTimeDimensionControl = (map, hasTimeControl) => {
  if (map.timeDimensionControl) {
    map.removeControl(map.timeDimensionControl);
  }

  if (hasTimeControl) {
    map.timeDimensionControl = new L.Control.TimeDimension({
      styleNS: 'leaflet-control-timecontrol',
      position: 'bottomleft',
      backwardButton: true,
      forwardButton: true,
      playButton: true,
      playReverseButton: false,
      loopButton: true,
      displayDate: true,
      timeSlider: true,
      timeSliderDragUpdate: true,
      limitSliders: true,
      limitMinimumRange: 5,
      speedSlider: true,
      minSpeed: 1,
      maxSpeed: 10,
      speedStep: 1,
      timeSteps: 1,
      autoPlay: false,
      playerOptions: {
        transitionTime: 1000,
        startOver: true,
        loop: true,
        buffer: 5,
        minBufferReady: 2,
      },
      timeZones: ['America/Lima', 'UTC'],
    });
    map.addControl(map.timeDimensionControl);
  }
};

export const initializeWmsLayer = (map, wmsUrl, selectLayer) => {
  const wmsLayer = L.tileLayer.wms(wmsUrl, {
    layers: selectLayer,
    styles: '',
    version: '1.3.0',
    format: 'image/png',
    tileSize: 256,
    transparent: true,
    tiled: true,
  });

  const timeDimensionLayer = L.timeDimension.layer.wms(wmsLayer, {
    updateTimeDimension: true,
    setDefaultTime: true,
    cache: 48,
    wmsVersion: '1.1.1',
  });

  timeDimensionLayer.addTo(map);
  return timeDimensionLayer;
};

export const setupEventHandlers = (map, setLoadingPercentage) => {
  const handleTimeLoading = () => {
    setLoadingPercentage(0);
  };

  const handleTimeLoad = () => {
    setLoadingPercentage(100);
  };

  map.timeDimension.on('timeloading', handleTimeLoading);
  map.timeDimension.on('timeload', handleTimeLoad);

  if (map.timeDimensionControl && map.timeDimensionControl._player) {
    map.timeDimensionControl._player.on('waiting', (event) => {
      const { buffer, available } = event;
      const percentage = Math.round((available / buffer) * 100);
      setLoadingPercentage(percentage);
    });
  }

  return { handleTimeLoading, handleTimeLoad };
};

export const setupInterval = (map, selectLayer) => {
  const intervalDuration = selectLayer.includes('satellite') ? 600000 : 3600000; // 10 minutes or 1 hour
  const intervalId = setInterval(() => {
    const availableTimes = map.timeDimension.getAvailableTimes();
    const newTimes = availableTimes.map((time) => time + 600000); // Add 10 minutes (600,000 milliseconds)
    map.timeDimension.setAvailableTimes(newTimes, 'replace');
  }, intervalDuration);

  return intervalId;
};
