import { useState, useEffect, useCallback, useMemo } from 'react';
import 'leaflet/dist/leaflet.css';
import 'leaflet-timedimension/dist/leaflet.timedimension.control.min.css';
import { MapContainer, TileLayer } from 'react-leaflet';
import SelectControls from './components/SelectControls';
import { activeLayer } from './utils/layers';
import useMapState from './utils/useMapState';
import StaticLayer from './components/StaticLayer';
import LimitsLayer from './components/LimitsLayer';
import FlyToButton from './components/FlyToButton';
import ZoomControls from './components/ZoomControls';
import SubLayer from './components/SubLayer';
import { RimacLogo } from './utils';
import Legends from './legends';
import TestNewTimeDimensionLayer from './components/TestNewTimeDimensionLayer';
import ToolButtons from './components/ToolButtons';
import FavoriteManager from './tools/Favorites';
import Cumulus from './tools/Cumulus';

const NewMap = () => {
  const { selectLayer, setSelectLayer, changeTileLayer, currentTileLayer } =
    useMapState();
  const [showLayerPoints, setShowLayerPoints] = useState(true);
  const [timeDimension, setTimeDimension] = useState(true);
  const [timeInterval, setTimeInterval] = useState('');
  const [hasSubLayer, setHasSubLayer] = useState(false);
  const [subLayers, setSubLayers] = useState([]);
  const [activateCumulusTool, setActivateCumulusTool] = useState(false);
  const [checkedState, setCheckedState] = useState(null);
  const [name, setName] = useState('');
  const [hasTimeControl, setHasTimeControl] = useState(false);
  const [period, setPeriod] = useState('PT1H');
  const [color, setColor] = useState('#000000');

  const opacity = useMemo(
    () => (selectLayer === 'hcwv' ? 1 : 0),
    [selectLayer],
  );

  const handleSubLayerChange = useCallback((newCheckedState) => {
    setCheckedState(newCheckedState);
    localStorage.setItem('checkedState', JSON.stringify(newCheckedState));
  }, []);

  useEffect(() => {
    const savedSelectLayer = localStorage.getItem('selectLayer');
    const savedCheckedState = localStorage.getItem('checkedState');

    if (savedSelectLayer) {
      setSelectLayer(savedSelectLayer);
    }

    if (savedCheckedState) {
      setCheckedState(JSON.parse(savedCheckedState));
    }
  }, [setSelectLayer]);

  useEffect(() => {
    if (selectLayer) {
      const selectedLayer = activeLayer.find(
        (layer) => layer.id === selectLayer,
      );
      if (selectedLayer) {
        const isDynamic = selectedLayer.type === 'dynamic';
        const hasSub = selectedLayer.hasSubLayers;
        const hasTime = selectedLayer.timeControl;
        const subLay = selectedLayer.subLayers;
        const name = selectedLayer.name;

        if (hasSub) {
          setSubLayers(subLay);
        } else {
          setCheckedState(null);
        }

        setTimeDimension(isDynamic);
        setTimeInterval(selectedLayer.timeInterval);
        setPeriod(selectedLayer.period);
        setHasSubLayer(hasSub);
        setHasTimeControl(hasTime);
        setName(name);
        changeTileLayer(selectedLayer.tileLayer);
        setColor('#000000');
      }
    }

    if (checkedState && checkedState.id) {
      setTimeDimension(checkedState.type === 'dynamic');
      setTimeInterval(checkedState.timeInterval);
      setPeriod(checkedState.period);
      setHasTimeControl(checkedState.timeControl);
      changeTileLayer(checkedState.tileLayer);

      if (checkedState.id === 'satellite_c02') {
        setColor('#e4e710');
      } else {
        setColor('#000000');
      }
    }
  }, [selectLayer, activeLayer, checkedState]);

  useEffect(() => {
    if (selectLayer) {
      localStorage.setItem('selectLayer', selectLayer);
    }
  }, [selectLayer]);

  return (
    <div style={{ position: 'relative' }}>
      <SelectControls
        selectLayer={selectLayer}
        setSelectLayer={setSelectLayer}
      />
      <RimacLogo />
      {hasSubLayer && (
        <SubLayer
          subLayers={subLayers}
          onSubLayerChange={handleSubLayerChange}
        />
      )}
      <MapContainer
        key={period}
        center={[-8.9382, -74.8882]}
        zoom={6}
        minZoom={4}
        maxZoom={18}
        style={{ height: '100vh', width: '100%' }}
        zoomControl={false}
      >
        <TileLayer url={currentTileLayer.url} />
        <FavoriteManager
          selectLayer={checkedState ? checkedState.id : selectLayer}
          selectLayerName={name}
        />
        {timeDimension ? (
          <TestNewTimeDimensionLayer
            selectLayer={checkedState ? checkedState.id : selectLayer}
            period={period}
            timeInterval={timeInterval}
            hasTimeControl={hasTimeControl}
            selectTileLayer={currentTileLayer}
            setSelectTileLayer={changeTileLayer}
          />
        ) : (
          <StaticLayer
            selectLayer={checkedState ? checkedState.id : selectLayer}
            setSelectTileLayer={name}
          />
        )}
        <Legends
          selectLayer={checkedState ? checkedState.id : selectLayer}
          hasTimeControl={hasTimeControl}
        />
        <Cumulus activateCumulusTool={activateCumulusTool} />
        {showLayerPoints && <LimitsLayer color={color} opacity={opacity} />}
        <ToolButtons
          selectTileLayer={currentTileLayer}
          setSelectTileLayer={changeTileLayer}
          showLimitesWebLayer={showLayerPoints}
          setShowLimitesWebLayer={setShowLayerPoints}
          activateCumulusTool={activateCumulusTool}
          setActivateCumulusTool={setActivateCumulusTool}
        />
        <ZoomControls />
        <FlyToButton selectLayer={selectLayer} selectLayerName={name} />
      </MapContainer>
    </div>
  );
};

export default NewMap;
