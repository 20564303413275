import React, { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import {
  initializeTimeDimension,
  initializeTimeDimensionControl,
  initializeWmsLayer,
  setupEventHandlers,
  setupInterval,
} from '../utils/timeDimensionUtils';
import 'leaflet-timedimension';

const wmsUrl = process.env.REACT_APP_GEOSERVER_MAPS_NEW;

const TestNewTimeDimensionLayer = ({
  selectLayer,
  period,
  timeInterval,
  hasTimeControl,
}) => {
  const map = useMap();
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  useEffect(() => {
    if (!map) return;

    initializeTimeDimension(map, timeInterval, period);
    initializeTimeDimensionControl(map, hasTimeControl);
    const timeDimensionLayer = initializeWmsLayer(map, wmsUrl, selectLayer);
    const { handleTimeLoading, handleTimeLoad } = setupEventHandlers(
      map,
      setLoadingPercentage,
    );

    let intervalId;
    if (hasTimeControl) {
      intervalId = setupInterval(map, selectLayer);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
      map.removeLayer(timeDimensionLayer);
      if (map.timeDimensionControl) {
        map.removeControl(map.timeDimensionControl);
      }
      map.timeDimension.off('timeloading', handleTimeLoading);
      map.timeDimension.off('timeload', handleTimeLoad);
    };
  }, [map, selectLayer, hasTimeControl, timeInterval, period]);

  // return <TimeControlButtons loadingPercentage={loadingPercentage} />;
};

export default TestNewTimeDimensionLayer;
