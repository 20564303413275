import React from 'react';
import {
  RideButton,
  RideTextField,
} from '@rimac-seguros/ride-system-components';

const LocationForm = ({ formik, setStep }) => {
  return (
    <form className='px-4' onSubmit={formik.handleSubmit}>
      <div className='form-item'>
        <RideTextField
          id='name'
          name='name'
          htmltype='text'
          label='Nombre de la ubicación'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          required
          error={!!formik.errors.name}
          errorMessage={formik.errors.name}
        />
      </div>
      <div className='flex items-center justify-center gap-2 mt-2'>
        <RideButton
          onClick={(e) => {
            e.stopPropagation();
            setStep(1);
          }}
          size='medium'
          variant='text-primary'
          text='Cancelar'
        />
        <RideButton
          type='primary'
          htmltype='submit'
          size='medium'
          loading={formik.isSubmitting}
          disabled={formik.isSubmitting || !!formik.errors.name}
          text='Crear'
          variant='fill'
        />
      </div>
    </form>
  );
};

export default LocationForm;
