import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Popover, Avatar } from 'antd';
import { UserOutlined, PhoneOutlined, LogoutOutlined } from '@ant-design/icons';
import { useScreen } from '../../../lib/screen';
import {
  RideGlUserSolid,
  RideGlMenuOutline,
} from '@rimac-seguros/ride-system-components';
import { useDispatch, useSelector } from 'react-redux';

import {
  getUserNameSimulate,
  removeSimulateUser,
} from '../../../services/users';
import { clearTokenIntoLocalStore, logout } from '../../../lib/auth';
import { pushDigitalData } from '../../../lib/utils';
import { TRACK } from '../../../lib/consts';
import { checkPermission } from '../../../lib/security';
import { SIDEMENU_CHANGE_VISIBLE } from '../../../redux/actions/actionsType';

const Topbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobileScreen = useScreen().isMobileScreen;
  const { user } = useSelector((state) => ({
    user: state.auth.user,
  }));
  const isOpened = useSelector((state) => state.sideMenu.isOpened);

  const changeMenuVisible = (isOpen) =>
    dispatch({ type: SIDEMENU_CHANGE_VISIBLE, payload: isOpen });

  const handleDigitalData = (label) => {
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData(
        {
          action: {
            group: 'Centro Monitoreo',
            category: 'Menú Principal',
            name: 'Mi perfil',
            label,
          },
        },
        TRACK.ACTION,
      );
    }
  };

  const handleLogOut = async () => {
    handleDigitalData('Cerrar sesión');

    if (getUserNameSimulate()) {
      removeSimulateUser();
      setTimeout(() => {
        window.location.assign('/administracion/usuarios');
      }, 200);
    } else {
      await logout();
      clearTokenIntoLocalStore();
      localStorage.setItem('isAuth', false);
      setTimeout(() => {
        window.location.assign('/');
      }, 200);
    }
  };

  const userProfile = (user) => {
    return `${user.name.charAt(0)}${user.last_name1.charAt(0)}`;
  };

  const popupSession = (user) => (
    <div className='popover'>
      <Avatar className='pic' size='large'>
        {userProfile(user)}
      </Avatar>
      <div className='popup_session_box_info'>
        <span className='popup_session_first_line'>
          {user?.name} {user?.last_name1}
        </span>
        <span className='popup_session_second_line'>
          {user?.company?.business_name}
        </span>
        <span className='popup_session_third_line'>{user?.rol?.name}</span>
      </div>
      <ul className='popup_session_menu'>
        {checkPermission(user, 'access_view_profile_company') && (
          <li
            className='popup_session_menu_item'
            onClick={() => {
              handleDigitalData('Perfil de la empresa');
              navigate('/administracion/perfil-empresa');
            }}
          >
            <UserOutlined />
            Perfil de la empresa
          </li>
        )}
        <li
          className='popup_session_menu_item'
          onClick={() => {
            handleDigitalData('Centro de Ayuda');
            navigate('/soporte/centro-de-ayuda');
          }}
        >
          <PhoneOutlined />
          Centro de Ayuda
        </li>
        <li className='popup_session_menu_item' onClick={handleLogOut}>
          <LogoutOutlined />
          {getUserNameSimulate() ? 'Tomar mi sesión' : 'Cerrar sesión'}
        </li>
      </ul>
    </div>
  );

  return (
    <header className='topbar'>
      {isMobileScreen && (
        <button
          style={{
            border: 'none',
            background: 'none',
            padding: 0,
            outline: 'none',
            cursor: 'pointer',
          }}
          onClick={() => changeMenuVisible(!isOpened)}
        >
          <RideGlMenuOutline size='x-large' />
        </button>
      )}
      <Popover
        placement='bottomRight'
        content={popupSession(user)}
        trigger='click'
      >
        <button
          style={{
            border: 'none',
            background: 'none',
            padding: 0,
            outline: 'none',
            cursor: 'pointer',
          }}
        >
          <RideGlUserSolid size='x-large' />
        </button>
      </Popover>
    </header>
  );
};

export default Topbar;
