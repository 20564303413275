import React from 'react';
import { Spin } from 'antd';
import Chart from 'react-apexcharts';

const ChartComponent = ({ loading, options, series, type }) =>
  loading ? (
    <Spin tip='Cargando...'>
      <div style={{ height: '300px' }} />
    </Spin>
  ) : (
    <Chart
      options={options}
      series={series}
      type={type}
      width='100%'
      height='300'
    />
  );

export default ChartComponent;
