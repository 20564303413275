import { useState } from 'react';
import { Popup } from 'react-leaflet';
import { Marker } from '@adamscybot/react-leaflet-component-marker';
import { removeLocationNew } from '../../../../services/locations';
import { RideGlStarSolid } from '@rimac-seguros/ride-system-components';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import Alerts from '../../alerts';
import { SET_FAVORITES } from '../../../../redux/actions/actionsType';
import {
  RideSpinner,
  RideGlDeleteSolid,
  RideGlPinSolid,
  RideGlWarningTriangleSolid,
  RideGlCloudySolid,
  RideGlCalendarSolid,
  RideActionButton,
} from '@rimac-seguros/ride-system-components';
import { validLayers, validTRWF } from '../../utils/tools';
import ModalWeatherForecast from '../WeatherForecast';
import ModalTempRainWindForecast from '../WeatherForecast/ModalTempRainWindForecast';
import { roundToFiveDecimals } from '../../utils/tools';

const Favorites = ({ selectLayer, selectLayerName }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalWeatherForecastVisible, setIsModalWeatherForecastVisible] =
    useState(false);
  const [
    isTempRainWindForecastModalVisible,
    setIsTempRainWindForecastModalVisible,
  ] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [loading, setLoading] = useState(false);
  const favorites = useSelector((state) => state.favorites.list);
  const dispatch = useDispatch();

  const showModal = (id, type) => {
    setSelectedLocationId(id);
    setModalType(type);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (modalType === 'delete') {
      setLoading(true);
      removeLocationNew(selectedLocationId).then(() => {
        const updatedFavorites = favorites.filter(
          (location) => location.id !== selectedLocationId,
        );
        dispatch({ type: SET_FAVORITES, payload: updatedFavorites });
        setLoading(false);
        setIsModalVisible(false);
        resetModalState();
      });
    } else if (modalType === 'alerts') {
      setIsModalVisible(false);
      resetModalState();
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    resetModalState();
  };

  const resetModalState = () => {
    setSelectedLocationId(null);
    setModalType(null);
  };

  const handleGoogleMaps = (lat, lng) => () => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
    );
  };

  return (
    <>
      {favorites?.map((location) => (
        <Marker
          key={location.id}
          position={[location.lat, location.lng]}
          icon={
            <RideGlStarSolid fill='yellow' stroke='black' size='2x-large' />
          }
        >
          <Popup>
            <div className='p-4 pb-0'>
              <h3>{selectLayerName}</h3>
              <p>
                Coordenadas: {location.lng.toFixed(2)},{' '}
                {location.lat.toFixed(2)}
              </p>
            </div>
            <div className='p-4'>
              <span>
                <strong>Nombre:</strong> {location.name}
              </span>
              <br />
              <span>
                <strong>Ubicación:</strong>{' '}
                {location?.province?.trim() || location?.department ? (
                  <>
                    {location?.province?.trim() || 'Provincia desconocida'},{' '}
                    {location?.department || 'Departamento desconocido'}
                  </>
                ) : (
                  'Ubicación desconocida'
                )}
              </span>
              <div className='flex gap-2 items-center mt-2'>
                <RideActionButton
                  onClick={handleGoogleMaps(location.lat, location.lng)}
                  glyph={<RideGlPinSolid />}
                  tooltipText='Ver en Google Maps'
                />
                <RideActionButton
                  onClick={() => showModal(location.id, 'alerts')}
                  glyph={<RideGlWarningTriangleSolid />}
                  tooltipText='Definir Alertas'
                />
                {validLayers.includes(selectLayer) && (
                  <>
                    <RideActionButton
                      onClick={() => setIsModalWeatherForecastVisible(true)}
                      glyph={<RideGlCloudySolid />}
                      tooltipText='Meteograma'
                    />
                    <ModalWeatherForecast
                      open={isModalWeatherForecastVisible}
                      onClose={() => setIsModalWeatherForecastVisible(false)}
                      bbox={`${roundToFiveDecimals(
                        location.lng - 0.0001,
                      )},${roundToFiveDecimals(
                        location.lat - 0.0001,
                      )},${roundToFiveDecimals(
                        location.lng + 0.0001,
                      )},${roundToFiveDecimals(location.lat + 0.0001)}`}
                      clickedLatLng={{ lat: location.lat, lng: location.lng }}
                      selectLayer={selectLayer}
                    />
                  </>
                )}
                {validTRWF.includes(selectLayer) && (
                  <>
                    <RideActionButton
                      glyph={<RideGlCalendarSolid />}
                      onClick={() =>
                        setIsTempRainWindForecastModalVisible(true)
                      }
                      tooltipText='Pronóstico a largo plazo'
                    />
                    <ModalTempRainWindForecast
                      open={isTempRainWindForecastModalVisible}
                      onClose={() =>
                        setIsTempRainWindForecastModalVisible(false)
                      }
                      bbox={`${roundToFiveDecimals(
                        location.lng - 0.0001,
                      )},${roundToFiveDecimals(
                        location.lat - 0.0001,
                      )},${roundToFiveDecimals(
                        location.lng + 0.0001,
                      )},${roundToFiveDecimals(location.lat + 0.0001)}`}
                    />
                  </>
                )}
                <RideActionButton
                  onClick={() => showModal(location.id, 'delete')}
                  glyph={<RideGlDeleteSolid fill='red' />}
                  tooltipText='Eliminar'
                />
              </div>
            </div>
          </Popup>
        </Marker>
      ))}
      <Modal
        title={
          modalType === 'delete' ? 'Confirmar Eliminación' : 'Definir Alertas'
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='Sí'
        cancelText='No'
        width={modalType === 'alerts' ? 800 : undefined}
        footer={modalType === 'alerts' ? null : undefined}
      >
        {modalType === 'delete' ? (
          loading ? (
            <RideSpinner />
          ) : (
            '¿Está seguro que desea eliminar la ubicación de favoritos?'
          )
        ) : (
          <Alerts
            locationId={selectedLocationId}
            favoritesLocations={favorites}
          />
        )}
      </Modal>
    </>
  );
};

export default Favorites;
