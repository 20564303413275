import { get, put } from '../lib/ajax';
import { LOADED_ROLS } from '../redux/actions/actionsType';
import { checkPermission } from '../lib/security';

export const getRols = async (dispatch, user) => {
  let response;
  if (checkPermission(user, 'read_all_rols')) {
    response = await get('/rols/list');
  } else {
    response = await get('/rols/form');
  }
  const { status, data } = response;
  const payload = data?.payload;

  if (status === 200 && payload?.success) {
    dispatch({ type: LOADED_ROLS, payload: payload.list });
  }
};

export const listAllRols = async () => {
  const { status, data } = await get('/rols/list');
  const payload = data?.payload;

  if (status === 200 && payload?.success) {
    return payload.list;
  }
};

export const listFormRols = async () => {
  const { status, data } = await get('/rols/form');
  const payload = data?.payload;

  if (status === 200 && payload?.success) {
    return payload.list;
  }
};

export const getAllRols = async (dispatch) => {
  const { status, data } = await get('/rols/list');
  const payload = data?.payload;

  if (status === 200 && payload?.success) {
    dispatch({ type: LOADED_ROLS, payload: payload.list });

    return payload.list;
  }
};

export const changePermissionsRol = async (id, rolData) => {
  const { status, data } = await put(`/rols/changePermissions`, {
    payload: { id, ...rolData },
  });

  const payload = data?.payload;

  return status === 200 && payload?.success;
};
