import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import L from 'leaflet';
import { Marker, TileLayer } from 'react-leaflet';

import {
  getListRoadEmergency,
  getRoadEmergency,
  postListRoadEmergency,
  updateRoadEmergency,
} from '../../../../services/roadEmergency';

import { getSvgIcon } from '../../utils';
import RoadEmergenciesForm from './RoadEmergenciesForm';
import PopupSutran from './PopupSutran';
import PopupCMP from './PopupCMP';
import PopupMTC from './PopupMTC';

const LayerRoadEmergency = ({
  getRoadEmergencyPoint,
  updateRoadEmergencyPoint,
  opacity = 1,
}) => {
  const dispatch = useDispatch();
  const [popUpData, setPopUpData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const types = useSelector((state) => state.roadEmergency.types);
  const points = useSelector((state) => state.roadEmergency.list);
  const filters = useSelector((state) => state.roadEmergency.filter);

  const handleClick = async (id) => {
    setIsLoading(true);
    const response = await getRoadEmergencyPoint(id);
    setPopUpData(response);
    setIsLoading(false);
  };

  const openForm = () => setIsOpenForm(true);

  const onOk = async (data) => {
    const update = await updateRoadEmergencyPoint(data, filters);
    setIsOpenForm(false);

    const ContentMessage = ({ children }) => (
      <div className='pt-1 pb-1 pr-2 pl-2'>{children}</div>
    );

    if (update) {
      message.success({
        content: (
          <ContentMessage>Emergencia actualizada correctamente.</ContentMessage>
        ),
        icon: <></>,
      });

      return true;
    } else {
      message.warning({
        content: <ContentMessage>Ha ocurrido un error.</ContentMessage>,
        icon: <></>,
      });

      return false;
    }
  };

  const getPointsWithFilters = (filters) => {
    const { date, ...rest } = filters;

    postListRoadEmergency({ dispatch, data: { ...date, ...rest } });
  };

  useEffect(() => {
    Object.keys(filters).length === 0
      ? getListRoadEmergency()
      : getPointsWithFilters(filters);
  }, [filters]);

  const getIcon = (point) => {
    switch (point.source) {
      case 'sutran':
        return L.divIcon({
          html: getSvgIcon(
            'sutran',
            types.find((ct) => ct.id === point.condicionTransitoCodigo)?.color,
          ),
          iconSize: [27, 38],
          iconAnchor: [13, 38],
          className: 'leaflet-icon-marker-custom',
        });
      case 'provias':
      case 'manual':
        return L.divIcon({
          html: getSvgIcon(
            'emergenciavial',
            types.find((ct) => ct.id === point.condicionTransitoCodigo)
              ?.color ?? '#00BFFF',
          ),
          iconSize: [27, 38],
          iconAnchor: [13, 38],
          className: 'leaflet-icon-marker-custom',
        });
      default:
        return L.divIcon({
          html: getSvgIcon(
            'emergenciavial',
            types.find(
              (ct) => ct.type.toUpperCase() === point.condicionTransito,
            )?.color ?? '#00BFFF',
          ),
          iconSize: [27, 38],
          iconAnchor: [13, 38],
          className: 'leaflet-icon-marker-custom',
        });
    }
  };

  return (
    <>
      <TileLayer
        attribution='Google Maps'
        url='https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}'
        opacity={opacity}
      />
      {points.map((point) => (
        <Marker
          key={point.id}
          position={[point.latitud, point.longitud]}
          icon={getIcon(point)}
          eventHandlers={{ click: () => handleClick(point.id) }}
        >
          {(() => {
            switch (point.source) {
              case 'sutran':
                return (
                  <PopupSutran isLoading={isLoading} popUpData={popUpData} />
                );
              case 'provias':
                return null;
              case 'mtc-viales':
              case 'mtc-ferroviarias':
                return <PopupMTC isLoading={isLoading} popUpData={popUpData} />;
              default:
                return (
                  <PopupCMP
                    isLoading={isLoading}
                    openForm={openForm}
                    popUpData={popUpData}
                  />
                );
            }
          })()}
        </Marker>
      ))}
      <RoadEmergenciesForm
        visible={isOpenForm}
        openForm={setIsOpenForm}
        onOk={onOk}
        object={{ ...popUpData, id: popUpData?.id, action: 'edit' }}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getRoadEmergencyPoint: (id) => getRoadEmergency(id, dispatch),
  updateRoadEmergencyPoint: (data, filters) =>
    updateRoadEmergency({ data, dispatch, filters }),
});

export default connect(null, mapDispatchToProps)(LayerRoadEmergency);
