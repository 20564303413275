import React from 'react';
import Legend from '../Legend';

const WindLegend = ({ toggleVisibility }) => {
  return (
    <Legend
      title='Velocidad de viento en km/h.'
      numbers={[0, 5, 10, 15, 20, 30, 40, 50, 65, 80, 95]}
      descriptions={['Viento en calma', 'Viento tormentoso']}
      toggleVisibility={toggleVisibility}
      type='wind'
    />
  );
};

export default WindLegend;
