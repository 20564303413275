import React from 'react';
import { Select } from 'antd';
import { tileLayers } from '../../utils/leafletMapConfig';

const { Option } = Select;

const TileLayerSelect = ({ selectTileLayer, setSelectTileLayer }) => {
  return (
    <Select
      className='w-full'
      onChange={setSelectTileLayer}
      value={selectTileLayer}
    >
      {tileLayers.map((layer) => (
        <Option key={layer.key} value={layer.key}>
          {layer.name}
        </Option>
      ))}
    </Select>
  );
};

export default TileLayerSelect;
