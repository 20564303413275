import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Popover, Tooltip } from 'antd';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import toGeoJSON from '@mapbox/togeojson';

import { deleteMyLayer, getMyLayers } from '../../../../services/maps';
import {
  MY_LAYERS_SET_ACTIVE_LAYERS,
  MY_LAYERS_SET_DRAWS,
  MAP_CHANGE_BLOCKED_CLICK,
} from '../../../../redux/actions/actionsType';

import { RideButton } from '@rimac-seguros/ride-system-components';

const ListLayers = ({ setShowForm, setStep, setSelectForm }) => {
  const dispatch = useDispatch();
  const { list, activeLayers } = useSelector((state) => state.myLayers);
  const map = useMap();
  const layerRefs = useRef({});

  useEffect(() => {
    activeLayers.forEach((layer) => {
      if (layer.typeFile === 'geojson' && !layerRefs.current[layer.id]) {
        fetch(layer.url)
          .then((response) => response.json())
          .then((data) => {
            const geoJsonData = Array.isArray(data.features)
              ? data
              : data.features;
            const geoJsonLayer = L.geoJSON(geoJsonData);
            geoJsonLayer.addTo(map);
            layerRefs.current[layer.id] = geoJsonLayer;
          })
          .catch((error) => console.error('Error loading GeoJSON:', error));
      }
    });

    Object.keys(layerRefs.current).forEach((layerId) => {
      if (!activeLayers.some((layer) => layer.id === parseInt(layerId))) {
        map.removeLayer(layerRefs.current[layerId]);
        delete layerRefs.current[layerId];
      }
    });
  }, [activeLayers, map]);

  const handleSelectLayer = (layer) => {
    const foundLayer = activeLayers.findIndex(
      (activeLayer) => activeLayer.id === layer.id,
    );
    if (foundLayer === -1) {
      dispatch({
        type: MY_LAYERS_SET_ACTIVE_LAYERS,
        payload: [...activeLayers, layer],
      });
    } else {
      const copyActiveUtils = structuredClone(activeLayers);
      copyActiveUtils.splice(foundLayer, 1);
      dispatch({ type: MY_LAYERS_SET_ACTIVE_LAYERS, payload: copyActiveUtils });
    }
  };

  const fetchGeoJSONData = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Error al obtener el archivo GeoJSON');

      const text = await response.text();
      const geoJSON = JSON.parse(text);

      return geoJSON;
    } catch (error) {
      console.error(error);

      return [];
    }
  };

  const convertToGeoJSON = async (kmlURL) => {
    try {
      const response = await fetch(kmlURL);
      const kmlData = await response.text();
      const parser = new DOMParser();
      const kml = parser.parseFromString(kmlData, 'text/xml');
      const geoJSON = toGeoJSON.kml(kml);

      return geoJSON;
    } catch (error) {
      console.error(error);

      return [];
    }
  };

  const editLayer = (e, { id, name, url }) => {
    e.stopPropagation();
    const splitFileName = url.split('-');
    const [, ...restFileName] = splitFileName;
    const fileName = restFileName.join('-');
    console.log('Editing Layer:', { id, name, url, fileName });
    setShowForm({ id, name, url, fileName });
    setSelectForm('upload');
    setStep(3);
  };

  const deleteLayer = async (e, id) => {
    e.stopPropagation();
    await deleteMyLayer(id, dispatch);
    dispatch({
      type: MY_LAYERS_SET_ACTIVE_LAYERS,
      payload: activeLayers.filter((activeLayer) => activeLayer.id !== id),
    });
    setStep(1);
    getMyLayers(dispatch);
  };

  return (
    <>
      {list.map((layer) => {
        return (
          <div
            key={layer?.id}
            className={`drawer-map-layers__list__item ${
              activeLayers.some((activeLayer) => activeLayer?.id === layer?.id)
                ? 'active'
                : ''
            }`}
            onClick={() => handleSelectLayer(layer)}
          >
            <div className='drawer-map-layers__list__item__name'>
              <Popover
                placement='topRight'
                trigger='click'
                content={
                  <div className='buttons-popover'>
                    <RideButton
                      text='Actualizar capa'
                      variant='text-secondary'
                      onClick={(e) => editLayer(e, layer)}
                    />
                    <RideButton
                      text='Eliminar capa'
                      variant='text-secondary'
                      onClick={(e) => deleteLayer(e, layer?.id)}
                    />
                  </div>
                }
                className='popover-actions'
              >
                <RideButton text='...' onClick={(e) => e.stopPropagation()} />
              </Popover>
              <Tooltip
                placement='top'
                title={layer?.name}
                mouseEnterDelay={0.5}
              >
                <div className='drawer-map-layers__list__item__name__text ellipsis'>
                  {layer?.name}
                </div>
              </Tooltip>
            </div>
            <div className='drawer-map-layers__list__item__action'>
              <Checkbox
                className='checkbox-layer'
                checked={activeLayers.some(
                  (activeLayer) => activeLayer?.id === layer?.id,
                )}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ListLayers;
