import { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

const wmsUrl = process.env.REACT_APP_GEOSERVER_MAPS_NEW;

const useStaticLayer = (selectLayer) => {
  const map = useMap();
  const layerRef = useRef(null);

  useEffect(() => {
    const removeCurrentLayer = () => {
      if (layerRef.current) {
        map.removeLayer(layerRef.current);
        layerRef.current = null;
      }
    };

    removeCurrentLayer();

    if (selectLayer) {
      const staticLayer = L.tileLayer.wms(wmsUrl, {
        layers: selectLayer,
        styles: '',
        version: '1.3.0',
        format: 'image/png',
        tileSize: 256,
        transparent: true,
        tiled: true,
      });

      layerRef.current = staticLayer.addTo(map);

      if (selectLayer === 'mapa_areas_expuestas_tsunami') {
        map.setView([-12.027099, -77.046392], 11);
      } else {
        map.setView([-8.9382, -74.8882], 6);
      }
    }

    return () => {
      removeCurrentLayer();
    };
  }, [map, selectLayer, wmsUrl]);

  return layerRef;
};

export default useStaticLayer;
