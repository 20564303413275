import { post, get } from '../lib/ajax';

export const getTsunamis = async () => {
  const r = await get('/tsunamis/listTsunamis');

  if (r?.data?.success) {
    return r.data.payload;
  }

  return [];
};

export const getTsunamisByFilters = async (filters = {}) => {
  const r = await post('/tsunamis/listTsunamisFiltered', filters);

  if (r?.data?.success) {
    return r.data.payload;
  }

  return [];
};
