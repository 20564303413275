import { useState, useEffect } from 'react';
import { Button, Form, Modal, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { getAddressByPoint } from '../../../services/googlemaps';
import text from '../../../config/text_es';
import MyLocationFormMap from './MyLocationFormMap';
import { createLocation, updateLocation } from '../../../services/locations';
import { TRACK } from '../../../lib/consts';
import { pushDigitalData } from '../../../lib/utils';

const LocationForm = ({
  locationData,
  visible,
  handleClose,
  onItemCreated,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isWorking, setIsWorking] = useState(false);

  const handleClickedMap = async (e) => {
    form.setFieldsValue({
      lat: e.lat,
      lng: e.lng,
    });
  };

  const handleSave = async (values) => {
    setIsWorking(true);

    try {
      if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
        pushDigitalData(
          {
            form: {
              channel: 'Centro Monitoreo',
              name: 'Mis ubicaciones - Crea Ubicación',
            },
          },
          TRACK.FORM_SUBMIT,
        );
      }

      values.type = 2;
      let result;
      if (locationData) {
        result = await updateLocation(
          { ...values, id: locationData.id },
          dispatch,
        );
      } else {
        result = await createLocation(values, dispatch);
      }
      if (result) {
        handleClose();
        form.resetFields();
        onItemCreated();
      }
    } catch (err) {
      console.error(err);
    }

    setIsWorking(false);
  };

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    } else {
      if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
        pushDigitalData(
          {
            form: {
              channel: 'Centro Monitoreo',
              name: 'Mis ubicaciones - Crea Ubicación',
            },
          },
          TRACK.FORM_VIEW,
        );
      }
    }
  }, [visible, form]);

  return (
    <Modal
      title='Crear ubicación'
      open={visible}
      footer={false}
      onCancel={handleClose}
      destroyOnClose
    >
      <MyLocationFormMap
        lat={locationData?.lat}
        lng={locationData?.lng}
        handleClickedMap={handleClickedMap}
      />
      <Form onFinish={handleSave} form={form} clearOnDestroy>
        {locationData && (
          <Form.Item
            name='id'
            initialValue={locationData ? locationData.id : ''}
            rules={[{ required: true, message: text.required_message }]}
            style={{ display: 'none' }}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          className='mt-2'
          label='Nombre'
          name='name'
          initialValue={locationData ? locationData.name : ''}
          rules={[
            {
              required: true,
              message: text.required_message,
              validator: (_, value) => {
                if (!value || !value.trim()) {
                  return Promise.reject(new Error(text.required_message));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Ubicar Latitud'
          name='lat'
          initialValue={locationData ? locationData.lat : ''}
          rules={[{ required: true, message: text.required_message }]}
        >
          <Input step='any' type='number' />
        </Form.Item>
        <Form.Item
          label='ubicar Longitud'
          name='lng'
          initialValue={locationData ? locationData.lng : ''}
          rules={[{ required: true, message: text.required_message }]}
        >
          <Input step='any' type='number' />
        </Form.Item>
        <div className='flex' style={{ justifyContent: 'flex-end' }}>
          <Button
            className='mt-1'
            loading={isWorking}
            type='primary'
            htmlType='submit'
          >
            {locationData ? 'Editar' : 'Agregar'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default LocationForm;
