import { get, post } from '../lib/ajax';
import { isApiCanal } from '../lib/axios';
import { getUrlAPI } from '../lib/utils';

export const getModules = async () => {
  const path = '/modules/list';
  const pathCanal = '/modules/listModules';

  const r = await get(isApiCanal(getUrlAPI(path)) ? pathCanal : path);
  r.data = r.data?.payload || r.data;

  if (r?.data?.success) {
    return r.data.list || [];
  }

  return [];
};

export const assignModules = async (
  moduleId,
  usersActives = [],
  usersInactive = [],
) => {
  const path = `/modules/assign?moduleId=${moduleId}`;
  const pathCanal = `/modules/updateModules`;

  const apiCanal = isApiCanal(getUrlAPI(path));
  const r = await post(
    apiCanal ? pathCanal : path,
    apiCanal
      ? {
          payload: {
            moduleId,
            usersActive: usersActives,
            usersInactive,
          },
        }
      : {
          usersActives,
          usersInactive,
        },
  );
  r.data = r.data?.payload || r.data;
  return r.data?.success || false;
};
