import { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

const UVLayer = ({ selectLayer }) => {
  const map = useMap();
  const layerRef = useRef(null);
  const wmsUrl = 'https://idesep.senamhi.gob.pe/geoserver/g_03_04/wms';

  const removeCurrentLayer = () => {
    if (layerRef.current) {
      map.removeLayer(layerRef.current);
      layerRef.current = null;
    }
  };

  useEffect(() => {
    removeCurrentLayer();

    const staticLayer = L.tileLayer.wms(wmsUrl, {
      layers: selectLayer,
      styles: 'g_03_04:03_04_Índice_UV_Pronóstico',
      version: '1.3.0',
      tileSize: 256,
      format: 'image/png',
      transparent: true,
      crs: L.CRS.EPSG3857,
    });

    layerRef.current = staticLayer.addTo(map);

    return () => {
      removeCurrentLayer();
    };
  }, [map, wmsUrl, selectLayer]);

  return null;
};

export default UVLayer;
