import { useState } from 'react';
import { Select } from 'antd';
import { activeLayer } from '../utils/layers';
import { RideGlUp, RideGlDown } from '@rimac-seguros/ride-system-components';

const { Option } = Select;

const SelectControls = ({ selectLayer, setSelectLayer }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <>
      <Select
        onChange={setSelectLayer}
        value={selectLayer}
        size='medium'
        className='select-layer'
        suffixIcon={dropdownOpen ? <RideGlUp /> : <RideGlDown />}
        onDropdownVisibleChange={(open) => setDropdownOpen(open)}
        style={{
          position: 'absolute',
          top: 10,
          right: 95,
          zIndex: 1000,
          width: 200,
        }}
      >
        {activeLayer.map((layer) => (
          <Option key={layer.id} value={layer.id}>
            <label className='flex items-center gap-2'>
              {layer.icon && <layer.icon size='large' />}
              {layer.name}
            </label>
          </Option>
        ))}
      </Select>
    </>
  );
};

export default SelectControls;
