import { useEffect, useState, memo } from 'react';
import {
  Upload,
  Input,
  Form,
  Spin,
  Alert,
  Checkbox,
  Row,
  Col,
  message,
  Button,
  Select,
  DatePicker,
} from 'antd';
import { useDispatch } from 'react-redux';

import text from '../../config/text_es';
import {
  getPresignedUrl,
  sendFileTecnicalReport,
} from '../../services/requestServicesItemsResults';
import { getMyRequestServicesItems } from '../../services/requestServicesItems';
import ListAdditionalCosts from './ListAdditionalCosts';
import InputWithLabel, { SelectWithLabel } from '../InputWithLabel';
import ModalConfirm from '../ModalConfirm';
import { useScreen } from '../../lib/screen';

import IconUpload from '../../images/icon_upload.svg';
import IconCheck from '../../images/icon_check.svg';
import { getServiceOfProviders } from '../../services/providers';

import { removeAccents } from '../../lib/utils';
import { validateFile } from '../../lib/security';

const { Dragger } = Upload;
const { Option } = Select;

const TYPE_CAPACITACION = [1];
const TYPES_ESPECIALIZADO = [2, 4, 5, 6];
const TYPE_SIGNAL = 3;

const ModalUploadTechnicalReport = ({
  visible,
  courseKey,
  item,
  doAddResult,
  setVisible,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { isMobileScreen } = useScreen();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgressPercent, setUploadProgressPercent] = useState(0);
  const [enabledButtons, setEnabledButtons] = useState(true);
  const [messageError, setMessageError] = useState(null);
  const [file, setFile] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);

  const serviceType = parseInt(item?.service?.type);
  const minCost = 0 - (parseFloat(item?.service?.market_cost) || 0);

  useEffect(() => {
    if (!item) return;
    getServiceOfProviders(item?.provider_id).then((services) => {
      const service = services.find(
        (service) => service.name === item.service.name,
      );

      if (!service) {
        setSelectOptions([
          {
            name: 'No hay zonas',
            value: 'No hay zonas',
            price: 0,
            zone: 'No hay zonas',
          },
        ]);

        return;
      }
      if (service.users[0].service_user_provider?.data.length > 0) {
        const options = JSON.parse(service.users[0].service_user_provider.data);

        if (options.length === 0) {
          setSelectOptions([
            {
              name: 'No hay zonas',
              value: 'No hay zonas',
              price: 0,
              zone: 'No hay zonas',
            },
          ]);

          return;
        }

        const optionsFormat = options.map((option) => ({
          name: option.zone,
          value: option.zone,
          zone: option.zone,
          price: option.price,
        }));
        setSelectOptions(optionsFormat);

        return;
      }

      setSelectOptions([
        {
          name: 'No hay zonas',
          value: 'No hay zonas',
          price: 0,
          zone: 'No hay zonas',
        },
      ]);
    });
  }, [item]);

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        if (!file) {
          message.error('Archivo es requerido');
          return;
        } else {
          const validations = {
            maxSize: 5 * 1024 * 1024,
            allowedExtensions: ['.pdf'],
            maxNameLength: 50,
          };
          const { errors } = validateFile(file.file, validations);

          if (errors?.extension) {
            message.error('El formato del archivo no es válido');
            return;
          }

          if (errors?.size) {
            message.error('El archivo pesa más de 5MB');
            return;
          }

          if (errors?.nameLength) {
            message.error('El nombre del archivo es demasiado largo');
            return;
          }
        }

        const { date, zone } = values;
        values.costs = values.costs ? values.costs.filter(Boolean) : [];
        values.date = date.toISOString();
        values.price =
          selectOptions.find((selectOption) => selectOption.name === zone)
            ?.price ?? '0';

        if (
          TYPES_ESPECIALIZADO.includes(serviceType) &&
          Number(serviceType) !== 2 &&
          Number(serviceType) !== 6
        )
          values.compliance_percent = 100;

        getPresignedUrl(file?.file)
          .then((response) => {
            setUploadProgressPercent(1);
            if (
              response.data.success &&
              response.data.payload &&
              response.data.payload.url
            )
              sendFileTecnicalReport(
                response.data.payload.url,
                file.file,
                handleProgress,
              )
                .then(() => {
                  values.fileUploadedName = response.data.payload.nameKey;
                  doAddResult(values, item);
                  setVisible(false);
                })
                .catch(() => {
                  message.error('Ha ocurrido un error al subir el archivo.');
                })
                .finally(() => {
                  form.resetFields();
                  setFile(false);
                  getMyRequestServicesItems(dispatch, {
                    page: 1,
                    limit: 5,
                    filters: {
                      status: '',
                      date: '',
                    },
                  });
                });
          })
          .catch(() => {
            message.error('Ha ocurrido un error al subir el archivo.');
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleProgress = (progressEvent) => {
    const totalLength = progressEvent.lengthComputable
      ? progressEvent.total
      : progressEvent.target.getResponseHeader('content-length') ||
        progressEvent.target.getResponseHeader('x-decompressed-content-length');
    if (totalLength !== null) {
      setUploadProgressPercent(
        Math.round((progressEvent.loaded * 100) / totalLength),
      );
    }
  };

  const handleFile = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    setIsUploading(false);
    setEnabledButtons(true);
    setFile(false);
    setMessageError(null);
  }, [visible]);

  const generateInputText = ({ label, field, required }) => {
    const rules = required
      ? [{ required: true, message: text.required_message }]
      : [];

    return (
      <Form.Item name={field} initialValue='' rules={rules}>
        <InputWithLabel label={label} />
      </Form.Item>
    );
  };

  const generateInputDate = ({ label, field, required }) => {
    const rules = required
      ? [{ required: true, message: 'This field is required' }]
      : [];

    return (
      <Form.Item name={field} initialValue={null} rules={rules}>
        <DatePicker
          placeholder={label}
          format='DD-MM-YYYY'
          style={{ width: '100%' }}
        />
      </Form.Item>
    );
  };

  const generateInputNumber = ({ label, field, required, value = '' }) => {
    const rules = required
      ? [{ required: true, message: text.required_message }]
      : [];

    return (
      <Form.Item name={field} initialValue={value} rules={rules}>
        <InputWithLabel type='number' label={label} min={0} />
      </Form.Item>
    );
  };

  const generateSelect = ({ label, field, required }) => {
    const rules = required
      ? [{ required: true, message: text.required_message }]
      : [];

    return (
      <Form.Item name={field} initialValue='' rules={rules}>
        <SelectWithLabel label={label}>
          {selectOptions.map(({ name, value }) => {
            return (
              <Option key={name} value={value}>
                {name}
              </Option>
            );
          })}
        </SelectWithLabel>
      </Form.Item>
    );
  };

  const generateCheckbox = ({ label, field, required }) => {
    const rules = required
      ? [{ required: true, message: text.required_message }]
      : [];

    return (
      <Form.Item
        name={field}
        valuePropName='checked'
        initialValue={false}
        rules={rules}
      >
        <Checkbox>{label}</Checkbox>
      </Form.Item>
    );
  };

  const getTitle = () => {
    if (TYPE_CAPACITACION.includes(serviceType)) {
      return 'Subir Registro de Capacitación';
    } else if (TYPES_ESPECIALIZADO.includes(serviceType)) {
      return 'Subir Informe';
    } else {
      return 'Subir Informe Técnico';
    }
  };

  return (
    <ModalConfirm
      onClose={handleCancel}
      visible={visible}
      confirmLoading={
        (uploadProgressPercent > 0 && uploadProgressPercent < 100) ||
        !enabledButtons
      }
      width='90%'
      title={getTitle()}
      content={
        <Form form={form} clearOnDestroy>
          <Row
            justify='justify-between'
            align='middle'
            gutter={[16, 16]}
            style={{ margin: '16px 0' }}
          >
            <Col sm={{ span: 24 }} lg={{ span: 12 }}>
              {TYPE_CAPACITACION.includes(serviceType) && courseKey && (
                <>
                  <Form.Item
                    name='course'
                    initialValue={courseKey ? courseKey : ''}
                  >
                    <Input type='hidden' />
                  </Form.Item>
                  {generateInputText({
                    label: 'Contacto',
                    field: 'contact_name',
                    required: true,
                  })}
                  {generateInputDate({
                    label: 'Fecha de capacitación',
                    field: 'date',
                    required: true,
                  })}
                  {generateCheckbox({
                    label: '¿Es remoto?',
                    field: 'isRemote',
                    required: true,
                  })}
                  {generateSelect({
                    label: 'Seleccione una zona',
                    field: 'zone',
                    required: true,
                  })}
                  {generateInputNumber({
                    label: '¿Cuantas Horas Duró el servicio?',
                    field: 'service_hours',
                    required: true,
                  })}
                </>
              )}
              {TYPES_ESPECIALIZADO.includes(serviceType) && (
                <>
                  {serviceType === 5 ? (
                    <Form.Item
                      name='course'
                      initialValue={courseKey ? courseKey : ''}
                    >
                      <Input type='hidden' />
                    </Form.Item>
                  ) : null}
                  {generateInputText({
                    label: 'Nombre de la persona de contacto',
                    field: 'contact_name',
                    required: true,
                  })}
                  {generateInputText({
                    label: 'Nombre del archivo',
                    field: 'filename',
                    required: true,
                  })}
                  {generateInputDate({
                    label: 'Fecha de servicio',
                    field: 'date',
                    required: true,
                  })}
                  {generateSelect({
                    label: 'Seleccione una zona',
                    field: 'zone',
                    required: true,
                  })}
                  {generateInputNumber({
                    label: '¿Cuantas Horas Duró el servicio?',
                    field: 'service_hours',
                    required: true,
                  })}
                  {(serviceType === 2 || serviceType === 6) &&
                    generateInputNumber({
                      label: '% de cumplimiento',
                      field: 'compliance_percent',
                      required: true,
                    })}
                </>
              )}
              {serviceType === TYPE_SIGNAL && (
                <>
                  {generateInputText({
                    label: 'Contacto',
                    field: 'contact_name',
                    required: true,
                  })}
                  {generateInputDate({
                    label: 'Fecha de entrega',
                    field: 'date',
                    required: true,
                  })}
                  {generateSelect({
                    label: 'Seleccione una zona',
                    field: 'zone',
                    required: true,
                  })}
                  {generateInputNumber({
                    label: '% de cumplimiento',
                    field: 'compliance_percent',
                    required: true,
                  })}
                </>
              )}
              <Form.Item>
                <ListAdditionalCosts form={form} minCost={minCost} />
              </Form.Item>
              {file && (
                <Alert
                  message={file.name}
                  type='success'
                  style={{ marginBottom: 8 }}
                />
              )}
              <span style={{ color: '#f45a5a' }}>
                *Adjuntar archivo tipo pdf
              </span>
              <Dragger
                name='file'
                accept='.pdf'
                beforeUpload={(file) => {
                  setIsUploading(true);
                  handleFile(file)
                    .then((base64) =>
                      setFile({
                        name: removeAccents(file.name.replace(/[^\w\s]/gi, '')),
                        base64,
                        type: file.type,
                        file,
                      }),
                    )
                    .finally(() => {
                      setIsUploading(false);
                      setMessageError(null);
                    });

                  return false;
                }}
              >
                <p className='ant-upload-drag-icon'>
                  {file ? (
                    <img src={IconCheck} alt='' aria-hidden />
                  ) : (
                    <img src={IconUpload} alt='Subir Archivo' />
                  )}
                </p>
                <p className='ant-upload-text'>
                  {file
                    ? 'Subir nuevo archivo'
                    : 'Click o arrastre el archivo en esta área para subirlo'}
                </p>
              </Dragger>
              {messageError && <Alert message={messageError} type='error' />}
              {isUploading && (
                <div className='flex flex-justify-center'>
                  <Spin />
                </div>
              )}
            </Col>
            {!isMobileScreen ? (
              <Col
                sm={{ span: 24 }}
                lg={{ span: 12 }}
                className='request_service_items_details_previewPDF '
              >
                <div className='request_service_items_details_previewPDF_view'>
                  {file ? (
                    file.file.size / 1024 < 1024 * 2 ? (
                      <div style={{ width: '100%', height: '100vh' }}>
                        <iframe
                          src={file.base64}
                          title={file.name}
                          height='100%'
                          width='100%'
                        />
                      </div>
                    ) : (
                      <div className='message-preview'>
                        Preview no disponible{' '}
                      </div>
                    )
                  ) : (
                    <div className='message-preview'>
                      Preview del Documento{' '}
                    </div>
                  )}
                </div>
              </Col>
            ) : null}
          </Row>
        </Form>
      }
      actionsContent={
        <div className='actions-delete-modal mt-2'>
          <Button
            className='button secondary-button mr-2'
            disabled={!enabledButtons}
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <Button className='button ml-1' type='primary' onClick={handleSave}>
            Aceptar
          </Button>
        </div>
      }
      destroyOnClose={true}
    />
  );
};

export default memo(ModalUploadTechnicalReport);
