import * as Yup from 'yup';

const NO_SPECIAL_CHARECTERS = 'No se permiten caracteres especiales';

export const fields = [
  'rol_id',
  'name',
  'last_name1',
  'last_name2',
  'username',
  'position',
  'company_id',
  'phone',
  'state',
];

export const createValidationSchema = () => {
  const noSpecialCharacters = /^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ_ ]*$/;

  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .matches(noSpecialCharacters, NO_SPECIAL_CHARECTERS)
      .required('El nombre es requerido')
      .min(3, 'El nombre debe tener al menos 3 caracteres')
      .max(50, 'El nombre no debe tener más de 50 caracteres'),
    last_name1: Yup.string()
      .trim()
      .matches(noSpecialCharacters, NO_SPECIAL_CHARECTERS)
      .required('El primer apellido es requerido')
      .min(3, 'El primer apellido debe tener al menos 3 caracteres')
      .max(50, 'El primer apellido no debe tener más de 50 caracteres'),
    last_name2: Yup.string()
      .nullable()
      .transform((value) => {
        if (!value) return null;
        return value === '' ? null : value.trim();
      })
      .matches(noSpecialCharacters, NO_SPECIAL_CHARECTERS)
      .test(
        'min',
        'El segundo apellido debe tener al menos 3 caracteres',
        (value) => !value || value.length >= 3,
      )
      .test(
        'max',
        'El segundo apellido no debe tener más de 50 caracteres',
        (value) => !value || value.length <= 50,
      ),
    username: Yup.string()
      .trim()
      .email('El nombre de usuario debe ser un correo electrónico válido')
      .matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        'El dominio del correo electrónico debe ser válido',
      )
      .required('El nombre de usuario es requerido'),
    position: Yup.string()
      .trim()
      .matches(noSpecialCharacters, NO_SPECIAL_CHARECTERS)
      .required('La posición es requerida')
      .min(3, 'La posición debe tener al menos 3 caracteres')
      .max(50, 'La posición no debe tener más de 50 caracteres'),
    rol_id: Yup.number()
      .typeError('El rol debe ser un número')
      .required('El rol es requerido'),
    company_id: Yup.number()
      .typeError('La empresa debe ser un número')
      .required('La empresa es requerida'),
    state: Yup.number(),
    phone: Yup.string()
      .nullable()
      .transform((value) => {
        if (!value) return null;
        return value === '' ? null : value.trim();
      })
      .matches(/^\d*$/, 'El número de teléfono solo puede contener números')
      .test(
        'min',
        'El número de teléfono debe tener al menos 9 dígitos',
        (value) => !value || value.length >= 9,
      )
      .test(
        'max',
        'El número de teléfono no debe tener más de 15 dígitos',
        (value) => !value || value.length <= 15,
      ),
  });
};

export const createInitialValues = (fields) => {
  const initialValues = {};
  fields.forEach((field) => {
    if (
      field === 'last_name2' ||
      field === 'phone' ||
      field === 'company_id' ||
      field === 'rol_id'
    ) {
      initialValues[field] = null;
    } else {
      initialValues[field] = '';
    }
  });
  return initialValues;
};
