import { useState, useEffect } from 'react';
import { getPreventionArticles } from '../../../services/articles';

import Button from '../components/Button';
import FooterPortal from '../../../common/FooterPortal';
import HeaderPortal from '../../../common/HeaderPortal';
import OfferCard from '../components/OfferCard';
import ArticleCard from '../components/ArticleCard';
import ServiceIcon from '../components/ServiceIcon';
import SERVICES_ITEMS from './SERVICES_ITEMS';

const Prevention = () => {
  const [showAllServices, setShowAllServices] = useState(false);
  const [preventionArticles, setPreventionArticles] = useState([]);

  useEffect(() => {
    getPreventionArticles().then((data) => {
      if (data?.list) setPreventionArticles(data.list);
    });
  }, []);

  return (
    <div className='portal-web'>
      <HeaderPortal />
      <div className='portal-prevention'>
        <div className='section'>
          <div className='main'>
            <div className='main__left'>
              <img
                src='/images/portal/prevention_main.png'
                className='main__img'
                alt='Prevención'
              />
            </div>
            <div className='main__right'>
              <h1 className='main__title'>
                Especializados en{' '}
                <span className='main__title--strong'>
                  prevención de riesgos patrimoniales
                </span>
              </h1>
              <p className='main__description'>
                Brindamos asesoría y servicios altamente especializados para el
                control de riesgos que pueden afectar el patrimonio y la
                continuidad de las operaciones de nuestros clientes.
              </p>
              <div className='main__buttons'>
                <Button
                  href='https://www.rimac.com/empresa?rfid=publica:categoria-empresas:menu-principal:tab'
                  target='blank'
                >
                  Adquirir póliza
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className='section back-color-gray-7'>
          <div className='reasons'>
            <div className='reasons__left'>
              <div className='reasons__title'>
                ¿Por qué usar el{' '}
                <span className='reasons__title--strong'>
                  servicio de Prevención?
                </span>
              </div>
              <div className='reasons__description'>
                Este servicio no interfiere, suporpone o adiciona condiciones en
                la póliza.
              </div>
            </div>
            <div className='reasons__right'>
              <OfferCard
                img='/images/portal/icon_tablet-analytics.svg'
                title='Tecnología y plataforma web'
                description='Autoservicio, sistema de reportes y seguimiento, informes técnicos de prevención'
              />
              <OfferCard
                img='/images/portal/icon_person-cuorse.svg'
                title='Personalización y staff'
                description='Asesoramiento especializado, elaboración del programa y seguimiento, supervisión y control de los servicios'
              />
            </div>
          </div>
        </div>
        <div className='section'>
          <div className='services'>
            <h4 className='section__title'>
              El servicio que ha{' '}
              <span className='section__title--red'>pensado en todo</span>
            </h4>
            <div
              className={`services__list ${
                showAllServices ? 'services__list--show-all' : ''
              }`}
            >
              {SERVICES_ITEMS.map((service) => (
                <ServiceIcon
                  key={service.url}
                  src={service.url}
                  text={service.text}
                />
              ))}
            </div>
            <a
              className='section__button-more'
              onClick={() => setShowAllServices((s) => !s)}
            >
              <span
                style={{ display: showAllServices ? 'none' : 'inline-block' }}
              >
                ver más servicios
              </span>
              <span
                style={{ display: showAllServices ? 'inline-block' : 'none' }}
              >
                ver menos servicios
              </span>
              <svg
                style={{
                  transform: showAllServices ? 'rotateZ(180deg)' : 'none',
                }}
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle
                  cx='12'
                  cy='12'
                  r='11.5'
                  transform='rotate(90 12 12)'
                  stroke='#6F7DFF'
                  strokeOpacity='0.4'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M8.42905 9.93988C8.19032 10.1867 8.19032 10.5825 8.42905 10.8293L11.5541 14.0601C11.7989 14.3133 12.2011 14.3133 12.4459 14.0601L15.5709 10.8293C15.8097 10.5825 15.8097 10.1867 15.5709 9.93988C15.3261 9.6867 14.9239 9.6867 14.6791 9.93988L12 12.7096L9.32095 9.93988C9.07606 9.68671 8.67394 9.68671 8.42905 9.93988Z'
                  fill='#6F7DFF'
                />
              </svg>
            </a>
          </div>
        </div>
        {preventionArticles && preventionArticles.length > 0 && (
          <div className='section back-color-gray-7 articles-section'>
            <div className='articles'>
              <h4 className='section__title'>
                Revisa nuestros{' '}
                <span className='section__title--red'>artículos recientes</span>
              </h4>
              <div className='articles__list'>
                {preventionArticles.slice(0, 4).map((article) => (
                  <ArticleCard
                    key={`article-${article.id}`}
                    href={`/articulos/${article.url}`}
                    title={article.title}
                    description={article.subtitle}
                    img={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${article.image}`}
                  />
                ))}
              </div>
              <a
                className='section__button-more'
                href='/articulos?f=prevencion'
              >
                <span>ver todos los artículos</span>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle
                    cx='12'
                    cy='12'
                    r='11.5'
                    stroke='#6F7DFF'
                    strokeOpacity='0.4'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M9.93988 15.5709C10.1867 15.8097 10.5825 15.8097 10.8293 15.5709L14.0601 12.4459C14.3133 12.2011 14.3133 11.7989 14.0601 11.5541L10.8293 8.42905C10.5825 8.19032 10.1867 8.19032 9.93988 8.42905C9.6867 8.67394 9.6867 9.07606 9.93988 9.32095L12.7096 12L9.93988 14.6791C9.68671 14.9239 9.68671 15.3261 9.93988 15.5709Z'
                    fill='#6F7DFF'
                  />
                </svg>
              </a>
            </div>
          </div>
        )}
      </div>
      <FooterPortal />
    </div>
  );
};

export default Prevention;
