import { get, post, put } from '../lib/ajax';
import {
  LOADED_ALL_TYPE_POLIZA,
  LOADED_TYPE_POLIZA,
  LOADING_CREATE_TYPE_POLIZA,
  LOAD_TYPE_POLIZA_LOADING,
  OPEN_FORM_TYPE_POLIZA,
} from '../redux/actions/actionsType';

export const getTypePoliza = async (dispatch) => {
  try {
    const r = await get('/type_policy/list');
    if (r?.data?.success) {
      dispatch({ type: LOADED_TYPE_POLIZA, payload: r.data.payload });
      return r.data.payload ?? [];
    }
  } catch (error) {
    return [];
  }
};

export const getAllPolizasPagination = async (dispatch, pagination) => {
  dispatch({ type: LOAD_TYPE_POLIZA_LOADING, payload: true });

  const r = await post('/type_policy/listPagination', pagination);
  if (r?.data?.success) {
    dispatch({ type: LOADED_ALL_TYPE_POLIZA, payload: r.data.payload });

    return true;
  }

  return false;
};

export const updateTypePolizaPag = async (
  typeIndustry,
  dispatch,
  pagination = {},
) => {
  try {
    dispatch({ type: LOADING_CREATE_TYPE_POLIZA, payload: true });
    const r = await put('/type_policy/update', typeIndustry);

    if (r?.data?.success) {
      dispatch({ type: OPEN_FORM_TYPE_POLIZA, payload: false });
      dispatch({ type: LOADING_CREATE_TYPE_POLIZA, payload: false });
      dispatch && (await getAllPolizasPagination(dispatch, pagination));

      return true;
    }
    dispatch({ type: LOADING_CREATE_TYPE_POLIZA, payload: false });

    return false;
  } catch (error) {
    dispatch({ type: LOADING_CREATE_TYPE_POLIZA, payload: false });
    console.error(error);
    return false;
  }
};

export const createTypePolizaPag = async (
  typeIndustry,
  dispatch,
  pagination = {},
) => {
  try {
    dispatch({ type: LOADING_CREATE_TYPE_POLIZA, payload: true });
    const r = await post('/type_policy/create', typeIndustry);

    if (r?.data?.success) {
      dispatch({ type: OPEN_FORM_TYPE_POLIZA, payload: false });
      dispatch({ type: LOADING_CREATE_TYPE_POLIZA, payload: false });
      dispatch && (await getAllPolizasPagination(dispatch, pagination));

      return true;
    }
    dispatch({ type: LOADING_CREATE_TYPE_POLIZA, payload: false });

    return false;
  } catch (error) {
    dispatch({ type: LOADING_CREATE_TYPE_POLIZA, payload: false });
    console.error(error);
    return false;
  }
};

export const removeTypePolizaPag = async (id, dispatch, pagination) => {
  try {
    const r = await post('/type_policy/remove?id=' + id);
    if (r?.data?.success) {
      await getAllPolizasPagination(dispatch, pagination);

      return true;
    }
  } catch (error) {
    console.error(error);
  }

  return false;
};
