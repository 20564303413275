import { notification } from 'antd';
import { get, post } from '../lib/ajax';
import {
  LOAD_CAMPUS_IMPORTED_LOADING,
  LOADED_CAMPUS_IMPORTED,
  LOADING_SYNC_CARNAT,
  FAILED_SYNC_CARNAT,
  CHANGE_CAMPUS_IMPORTED_QUERY,
  CHANGE_CAMPUS_IMPORTED_FILTERS,
} from '../redux/actions/actionsType';
import { sendBannerImage } from './banner';

const endPoints = {
  risk: 'synCarnat',
  inspat: 'synInspat',
  newsletter: 'synBoletines',
};

export const getCumulo = async (dispatch, parameters = {}) => {
  dispatch({ type: CHANGE_CAMPUS_IMPORTED_QUERY, payload: parameters.query });
  dispatch({ type: LOAD_CAMPUS_IMPORTED_LOADING, payload: true });
  dispatch({
    type: CHANGE_CAMPUS_IMPORTED_FILTERS,
    payload: parameters.filters || [],
  });
  const r = await post('/polizas/list', parameters);
  if (r.data && r?.data?.success) {
    dispatch({ type: LOADED_CAMPUS_IMPORTED, payload: r.data.payload });
  }
};

export const synCarnat = async (dispatch, params) => {
  dispatch({ type: LOADING_SYNC_CARNAT });

  const endPoint = endPoints[params.type];

  const r = await post(`/polizas/synCarnat`, {
    data: params.data,
    ...params,
  });

  if (r.data && r?.data?.success) {
    const { payload } = r.data;
    !payload.running && dispatch({ type: FAILED_SYNC_CARNAT });
  }
};

export const syncsStatus = async () => {
  try {
    const r = await get('/polizas/syncStatus');

    if (r.data && r?.data?.success) {
      return r.data.payload;
    }

    return false;
  } catch {
    return false;
  }
};

export const syncsCancelStatus = async () => {
  try {
    const r = await post('/polizas/stopSyncProcessInProgress');
    if (r.data && r?.data?.success) {
      return r.data.payload;
    }

    return false;
  } catch {
    return false;
  }
};

export const startSyncClaims = async () => {
  const r = await get('/polizas/startSyncClaims');

  if (r.data && r?.data?.success) {
    return r.data.payload;
  }

  return false;
};

export const importedCampusIndicators = async (data) => {
  const r = await post('/polizas/indicators', data);

  if (r.data && r?.data?.success) {
    return r.data.payload;
  }

  return false;
};

export const syncRisks = async (_data, file) => {
  try {
    const res = await post('/polizas/getCenpredUrl');

    const { success, payload } = res?.data ?? {};

    if (success && payload && payload?.url) {
      await sendBannerImage(payload?.url, file);
      return await post('/polizas/synCenpred');
    }
  } catch (error) {
    notification.error({
      message: 'Ha ocurrido un error al subir el archivo.',
    });
    throw new Error(error);
  }

  return false;
};

export const updateCumuloStep = async () => {
  const r = await post('/polizas/setInitToUpdate');

  if (r.data && r?.data?.success) {
    return r.data.payload;
  }

  return false;
};
