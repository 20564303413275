import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { RideGlError } from '@rimac-seguros/ride-system-components';
import L from 'leaflet';

const Legend = ({ title, numbers, descriptions, toggleVisibility, type }) => {
  const legendRef = useRef(null);
  const gapSize = numbers.length >= 11 ? '23px' : '35px';

  useEffect(() => {
    if (legendRef.current) {
      L.DomEvent.disableClickPropagation(legendRef.current);
      L.DomEvent.disableScrollPropagation(legendRef.current);
    }
  }, []);

  return (
    <div ref={legendRef} className={classNames('legend', type)}>
      <div className='flex items-center justify-between'>
        <p className='m-0'>{title}</p>
        {toggleVisibility && (
          <button
            onClick={toggleVisibility}
            className='close-button'
            aria-label='Cerrar leyenda'
          >
            <RideGlError size='large' />
          </button>
        )}
      </div>
      <div className='numbers' style={{ gap: gapSize }}>
        {numbers.map((number, index) => (
          <span key={index}>{number}</span>
        ))}
      </div>
      <div className='text-wrapper'>
        {descriptions.map((description, index) => (
          <div key={index}>{description}</div>
        ))}
      </div>
    </div>
  );
};

export default Legend;
