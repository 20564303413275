import { get, post } from '../lib/ajax';
import {
  CLIENT_TRACKING_IS_LOADING,
  CLIENT_TRACKING_SET_LIST,
  CLIENT_TRACKING_SET_PAGINATION,
} from '../redux/actions/actionsType';

export const getListClients = async (dispatch, filters) => {
  try {
    dispatch({ type: CLIENT_TRACKING_IS_LOADING, payload: true });
    const r = await post('/clients_tracking/list', filters);

    if (r?.data?.success) {
      const { list, page, total } = r.data.payload;
      dispatch({ type: CLIENT_TRACKING_SET_LIST, payload: list });
      dispatch({
        type: CLIENT_TRACKING_SET_PAGINATION,
        payload: { page, total, pageSize: 5 },
      });
    }
  } catch (e) {
    console.error(e);
  } finally {
    dispatch({ type: CLIENT_TRACKING_IS_LOADING, payload: false });
  }
};

export const getClientById = async (id) => {
  try {
    const r = await get(`/clients_tracking/GetClient?id=${id}`);

    if (r?.data?.success) return r.data.payload;

    return [];
  } catch (e) {
    console.error(e);
  }
};

export const updateClient = async (id, data) => {
  try {
    const r = await post(`/clients_tracking/update?id=${id}`, data);

    if (r?.data?.success) return [];

    return [];
  } catch (e) {
    console.error(e);
  }
};

export const createClientsTracking = async (data, dispatch) => {
  try {
    const r = await post('/clients_tracking/create', data);

    if (r?.data?.success) dispatch && (await getListClients(dispatch, {}));

    return false;
  } catch (e) {
    console.error(e);
  }
};

export const downloadClientsTracking = async (filters) => {
  try {
    const r = await post('/clients_tracking/report', filters);

    if (r?.data?.success) return r.data.payload.url;

    return null;
  } catch (e) {
    console.error(e);
  }
};

export const presignedUrl = async (data) => {
  const payload = {
    type: data.type,
    name: data.name,
  };

  const r = await post('/clients_tracking/get_url_to_import', payload);
  if (r?.data?.success) return r.data.payload;

  return null;
};

export const getEmailClientsTracking = async (company_id) => {
  const r = await get(
    `/clients_tracking/get_clients_emails?company_id=${company_id}`,
  );
  if (r?.data?.success) return r.data.payload;

  return null;
};
