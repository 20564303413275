import { useState, useEffect } from 'react';
import L from 'leaflet';
import {
  TileLayer,
  MapContainer,
  useMapEvents,
  Marker,
  useMap,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [0, -41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const startPoint = [-9.27984584271891, -74.75373348350703];

const MarkerPosition = ({ onFlyTo, position, setPosition }) => {
  useMapEvents({
    click({ latlng }) {
      setPosition(latlng);
      onFlyTo(latlng);
    },
  });

  if (!position?.lat || !position?.lng) {
    return null;
  }

  return <Marker position={position}></Marker>;
};

const MapSizeInvalidator = () => {
  const map = useMap();

  useEffect(() => {
    setTimeout(() => {
      map.invalidateSize();
    }, 100);
  }, [map]);

  return null;
};

const MyLocationFormMap = ({ lat, lng, handleClickedMap }) => {
  const [position, setPosition] = useState(() => {
    return lat && lng ? { lat, lng } : null;
  });

  return (
    <MapContainer
      style={{ height: '400px', width: '100%' }}
      center={lat && lng ? [lat, lng] : startPoint}
      zoom={6}
    >
      <TileLayer
        attribution='Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
        url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'
      />
      <MarkerPosition
        onFlyTo={handleClickedMap}
        position={position}
        setPosition={setPosition}
      />
      <MapSizeInvalidator />
    </MapContainer>
  );
};

export default MyLocationFormMap;
