import { get, post } from '../lib/ajax';
import { isApiCanal } from '../lib/axios';
import { getUrlAPI } from '../lib/utils';

import {
  LOADED_SLIDER,
  LOADING_CREATE_SLIDER,
  LOADING_SLIDER,
} from '../redux/actions/actionsType';
import axios from 'axios';

//TODO PENDIENTE PUBLIC
export const getBannerWithoutAuth = async () => {
  const r = await get('/banner?list=1', false);

  if (r?.data?.success) {
    return r.data.payload;
  }

  return [];
};

export const getBanner = async (dispatch, params) => {
  dispatch({ type: LOADING_SLIDER, payload: true });

  const path = `/banner/list`;
  const pathCanal = `/banner/listBanner`;

  const apiCanal = isApiCanal(getUrlAPI(path));
  const r = await post(apiCanal ? pathCanal : path, params);

  if (r.data.payload.success) {
    dispatch({ type: LOADED_SLIDER, payload: r.data.payload.payload.list });
    dispatch({ type: LOADING_SLIDER, payload: false });

    return r.data.payload.payload;
  }

  dispatch({ type: LOADING_SLIDER, payload: false });

  return [];
};

export const createSlide = async (data, dispatch) => {
  dispatch?.({ type: LOADING_CREATE_SLIDER, payload: true });
  const path = `/banner/create`;
  const pathCanal = `/banner/createBanner`;
  try {
    const apiCanal = isApiCanal(getUrlAPI(path));
    const r = await post(
      apiCanal ? pathCanal : path,
      apiCanal ? { payload: data } : data,
    );

    r.data = r.data?.payload || r.data;

    if (r?.data?.success) {
      dispatch?.({ type: LOADING_CREATE_SLIDER, payload: false });
      return true;
    }
  } catch (error) {
    return false;
  }
  dispatch?.({ type: LOADING_CREATE_SLIDER, payload: false });
  return false;
};

export const updateSlide = async (data, dispatch) => {
  dispatch({ type: LOADING_CREATE_SLIDER, payload: true });
  const path = `/banner/update`;
  const pathCanal = `/banner/updateBanner`;
  const apiCanal = isApiCanal(getUrlAPI(path));
  const r = await post(
    apiCanal ? pathCanal : path,
    apiCanal
      ? {
          payload: data,
        }
      : data,
  );
  r.data = r.data?.payload || r.data;

  if (r?.data?.success) {
    dispatch({ type: LOADING_CREATE_SLIDER, payload: false });

    return true;
  }
  dispatch({ type: LOADING_CREATE_SLIDER, payload: false });

  return false;
};

export const removeSlide = async (id, dispatch) => {
  dispatch({ type: LOADING_CREATE_SLIDER, payload: true });
  const path = `/banner/delete?id=${id}`;
  const pathCanal = `/banner/deleteBanner`;
  const apiCanal = isApiCanal(getUrlAPI(path));

  const r = await post(
    apiCanal ? pathCanal : path,
    apiCanal ? { payload: { id } } : null,
  );

  r.data = r.data?.payload || r.data;
  if (r?.data?.success) {
    dispatch({ type: LOADING_CREATE_SLIDER, payload: false });

    return true;
  }
  dispatch({ type: LOADING_CREATE_SLIDER, payload: false });

  return false;
};

export const getPresignedUrl = async (data) => {
  const payload = {
    type: data.type,
    name: data.name,
  };

  const path = `/banner/get_url_to_import`;
  const pathCanal = `/banner/getUrlToImport`;
  const apiCanal = isApiCanal(getUrlAPI(path));

  const r = await post(
    apiCanal ? pathCanal : path,
    apiCanal
      ? {
          payload,
        }
      : payload,
  );

  r.data = r.data?.payload || r.data;

  return r;
};

export const sendBannerImage = (url, data) => axios.put(url, data);
