import { useSelector } from 'react-redux';
import { Input, Button, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { useScreen } from '../../../../lib/screen';
import DatePicker from '../../../../common/DatePicker';
import {
  RideButton,
  RideGlFiltersSolid,
} from '@rimac-seguros/ride-system-components';

const { Search } = Input;
const { RangePicker } = DatePicker;

const FilterSection = ({
  text,
  textSearch,
  complete = false,
  handleFilter,
  handleSearchFilter = () => {},
  handleClearFilters = () => {},
  filters,
  placeholder = false,
  total,
  handleToggleFiltersDrawer,
}) => {
  const eventTypes = useSelector((state) => state.eventType.eventTypes);
  const { isMobileScreen } = useScreen();

  return (
    <>
      <div className='profile-company__users--header filter-section mt-0'>
        <h2>{complete ? text : `Filtrar lista de ${text}`}</h2>

        <Search
          value={textSearch}
          placeholder={`${placeholder ? placeholder : 'Palabras clave'}`}
          onChange={handleSearchFilter}
          allowClear
          style={{ width: '100%', maxWidth: '300px' }}
        />
      </div>
      {!isMobileScreen ? (
        <div className='flex items-center mb-4' style={{ gap: '20px' }}>
          <Select
            placeholder='Seleccione el tipo de evento'
            value={filters?.event_type_id}
            style={{ width: '200px' }}
            onChange={(value) => handleFilter('event_type_id', value)}
            options={eventTypes.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
          />
          <RangePicker
            value={[
              filters?.date_event_end && dayjs(filters?.date_event_start),
              filters?.date_event_end && dayjs(filters?.date_event_end),
            ]}
            name='date'
            placeholder={['Fecha de inicio', 'Fecha de fin']}
            format='DD-MM-YYYY'
            onChange={(dates) => handleFilter('date', dates)}
          />

          <Button
            type='link'
            style={{ fontSize: '14px', lineHeight: '16px', fontWeight: '700' }}
            className='btn-clearFilter'
            onClick={handleClearFilters}
          >
            <DeleteOutlined className='mr-1' />
            Borrar Filtros
          </Button>
        </div>
      ) : (
        <div
          className='d_content__list__action mb-4 flex flex-row'
          style={{ fontSize: '14px', lineHeight: '16px', fontWeight: '700' }}
        >
          <div className='info-header'>
            {total === 1 ? (
              <>
                <div>
                  Se encontró <b>1</b> evento
                </div>
              </>
            ) : (
              <>
                <div>
                  Se encontraron <b>{total}</b> eventos
                </div>
              </>
            )}
          </div>
          {isMobileScreen && (
            <RideButton
              glyph={<RideGlFiltersSolid />}
              glyphPosition='left'
              onClick={handleToggleFiltersDrawer}
              variant='text-primary'
              text='Filtrar'
            />
          )}
        </div>
      )}
    </>
  );
};

export default FilterSection;
