import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Select from '../../../../common/Select';
import { getForecastConfigById } from '../../../../services/locations';
import NotSelectedAddress from '../components/NotSelectedAddress';

import FlashAlerts from './FlashAlerts';
import TemperatureAlerts from './TemperatureAlerts';
import WindsAlerts from './WindsAlerts';
import RainAlerts from './RainAlerts';
import CardCollapse from '../components/CardCollapse';

const DATA_BY_LOCATION = [
  { title: 'Lluvias', type: 'rains', component: RainAlerts },
  { title: 'Vientos', type: 'winds', component: WindsAlerts },
  { title: 'Temperatura', type: 'temperature', component: TemperatureAlerts },
  { title: 'Rayos', type: 'flash', component: FlashAlerts },
];

const Alerts = () => {
  const locations = useSelector((state) => state.locations.list || []);

  const [isLoading, setIsLoading] = useState(false);
  const [dataLocation, setDataLocation] = useState([]);
  const [locationSelected, setLocationSelected] = useState();

  const options = locations.map((location) => ({
    label: location.name,
    value: location.id,
  }));

  const handleSelectLocation = async (option) => {
    setIsLoading(true);
    setLocationSelected(option.value);
    await getForecastConfigById(option.value)
      .then((res) => {
        setDataLocation(res);
      })
      .finally(() => setIsLoading(false));
  };

  const handleUpdatedData = (type, updatedData) => {
    setDataLocation((prevData) => {
      const newData = [...prevData];
      const index = newData.findIndex((el) => el.type === type);
      if (index !== -1) {
        newData[index] = { ...newData[index], ...updatedData };
      } else {
        newData.push({ type, ...updatedData });
      }
      return newData;
    });
  };

  return (
    <>
      <div className='mb-2' style={{ maxWidth: '321px' }}>
        <Select
          label='Elige tu Ubicación'
          loading={isLoading}
          options={options}
          onSelect={handleSelectLocation}
          required
        />
      </div>
      {!locationSelected && (
        <NotSelectedAddress title='Aún no has seleccionado una ubicación para configurar alertas' />
      )}
      {locationSelected && (
        <div className='card-container'>
          {DATA_BY_LOCATION.map((el) => {
            const data = dataLocation.find((e) => e.type === el.type);
            const Component = el.component;
            return (
              <CardCollapse
                key={el.type}
                dataLocation={dataLocation}
                type={el.type}
                title={el.title}
              >
                <Component
                  data={data}
                  idLocation={Number(locationSelected)}
                  onUpdatedData={handleUpdatedData}
                />
              </CardCollapse>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Alerts;
