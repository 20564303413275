import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import React from 'react';

const CardProvider = ({ provider, states, prices, handleEdit }) => {
  const { tag, bg, point } = prices[provider.can_see_prices] ?? prices[0];
  const {
    tag: tagPri,
    bg: bgPri,
    point: pointPri,
  } = states[provider.state] ?? states[0];

  return (
    <article className='card-user'>
      <div className='flex pos-end'>
        <div className='flex'>
          <span className='table-state' style={{ background: bgPri }}>
            <div className='point' style={{ background: pointPri }}></div>
            <span>{tagPri}</span>
          </span>
        </div>
      </div>

      <div>
        <h4>Usuario</h4>
        <p>{`${provider.username}`}</p>
      </div>

      <div>
        <h4>Nombre</h4>
        <p>{`${provider.name} ${provider.last_name1}`}</p>
      </div>

      <div>
        <h4>Ver precios</h4>
        <div className='flex'>
          <span className='table-state' style={{ background: bg }}>
            <div className='point' style={{ background: point }}></div>
            <span>{tag}</span>
          </span>
        </div>
      </div>

      <div className='actions'>
        <Button
          icon={<EditOutlined />}
          shape='circle'
          size='small'
          onClick={() => handleEdit(provider)}
        />
      </div>
    </article>
  );
};

export default CardProvider;
