import React from 'react';
import { RideButton } from '@rimac-seguros/ride-system-components';

const LayerUpType = ({ setStep, setSelectForm }) => {
  const enabledUploadMode = () => {
    setStep((prev) => prev + 1);
    setSelectForm('upload');
  };

  const enabledCreateMode = () => {
    setStep((prev) => prev + 1);
    setSelectForm('create');
  };

  return (
    <div className='flex flex-col gap-3 mt-3'>
      <RideButton
        variant='fill'
        size='small'
        onClick={enabledUploadMode}
        text='Subir capa'
      />
      <RideButton
        variant='fill'
        size='small'
        onClick={enabledCreateMode}
        text='Crear capa'
      />
    </div>
  );
};

export default LayerUpType;
