import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Select, Tabs, Progress } from 'antd';
import { useMap } from 'react-leaflet';
import dayjs from 'dayjs';
import { fetchDataForAllTimes } from './fetchData';
import { generateChartOptions } from './chartOptions';
import ChartComponent from './ChartComponent';

const { Option } = Select;

const ModalWeatherForecast = ({
  open,
  onClose,
  bbox,
  clickedLatLng,
  selectLayer,
  shouldFetchData,
  onFetchComplete,
}) => {
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format('DD/MM/YYYY'),
  );
  const [cachedParams, setCachedParams] = useState(null);
  const map = useMap();
  const timeDimension = map.timeDimension;

  const arrayTime = useMemo(
    () =>
      timeDimension
        ? timeDimension
            .getAvailableTimes()
            .map((time) => dayjs(time).toISOString())
        : [],
    [timeDimension],
  );

  const uniqueDates = useMemo(
    () => [
      ...new Set(arrayTime.map((time) => dayjs(time).format('DD/MM/YYYY'))),
    ],
    [arrayTime],
  );

  useEffect(() => {
    if (open && shouldFetchData) {
      const currentParams = { arrayTime, bbox, selectLayer };
      if (
        cachedParams &&
        JSON.stringify(cachedParams) === JSON.stringify(currentParams)
      ) {
        setLoading(false);
      } else {
        setLoading(true);
        setProgress(0);
        fetchDataForAllTimes(arrayTime, bbox, selectLayer, setProgress).then(
          (data) => {
            setResponseData(data);
            setLoading(false);
            setCachedParams(currentParams);
            onFetchComplete();
          },
        );
      }
    }
  }, [
    arrayTime,
    bbox,
    open,
    selectLayer,
    cachedParams,
    shouldFetchData,
    onFetchComplete,
  ]);

  const handleDateChange = (value) => setSelectedDate(value);

  const groupedData = useMemo(() => {
    if (!selectedDate) return [];
    const filteredData = responseData.filter((item) =>
      dayjs(item.date).isSame(dayjs(selectedDate, 'DD/MM/YYYY'), 'day'),
    );
    const grouped = filteredData.reduce((acc, item) => {
      const hour = dayjs(item.date).format('HH:mm');
      if (!acc[hour]) acc[hour] = [];
      acc[hour].push(item);
      return acc;
    }, {});
    return Object.entries(grouped).map(([hour, items]) => {
      const totalValues = items.length;
      const sumValues = items
        .flatMap((feature) =>
          Object.values(feature.properties).map((value) =>
            value !== null ? parseFloat(value) : 0,
          ),
        )
        .reduce((acc, val) => acc + val, 0);
      return { hour, value: (sumValues / totalValues).toFixed(2) };
    });
  }, [responseData, selectedDate]);

  const dailyAverages = useMemo(() => {
    const groupedByDate = responseData.reduce((acc, item) => {
      const date = dayjs(item.date).format('DD/MM/YYYY');
      if (!acc[date]) acc[date] = [];
      acc[date].push(item);
      return acc;
    }, {});
    return Object.entries(groupedByDate).map(([date, items]) => {
      const totalValues = items.length;
      const sumValues = items
        .flatMap((feature) =>
          Object.values(feature.properties).map((value) =>
            value !== null ? parseFloat(value) : 0,
          ),
        )
        .reduce((acc, val) => acc + val, 0);
      return { date, value: (sumValues / totalValues).toFixed(2) };
    });
  }, [responseData]);

  const chartOptions = generateChartOptions(
    selectLayer,
    groupedData.map((data) => data.hour),
  );
  const chartSeries = [
    { name: 'Valor', data: groupedData.map((data) => data.value) },
  ];
  const barChartOptions = generateChartOptions(
    selectLayer,
    dailyAverages.map((data) => data.date),
  );
  const barChartSeries = [
    { name: 'Valor', data: dailyAverages.map((data) => data.value) },
  ];

  const tabItems = [
    {
      key: '1',
      label: 'Gráfico',
      children: (
        <>
          <Select
            style={{ width: 200, marginBottom: 16 }}
            placeholder='Selecciona una fecha'
            onChange={handleDateChange}
            defaultValue={selectedDate}
          >
            {uniqueDates.map((date) => (
              <Option key={date} value={date}>
                {date}
              </Option>
            ))}
          </Select>
          <ChartComponent
            loading={loading}
            options={chartOptions}
            series={chartSeries}
            type='line'
          />
        </>
      ),
    },
    {
      key: '2',
      label: 'Promedio Diario',
      children: (
        <ChartComponent
          loading={loading}
          options={barChartOptions}
          series={barChartSeries}
          type='bar'
        />
      ),
    },
  ];

  return (
    <Modal
      title='Pronóstico a largo plazo'
      open={open}
      onCancel={onClose}
      footer={null}
      width={1000}
      mask={false}
      maskClosable={false}
    >
      {clickedLatLng && (
        <p>
          Latitud: {clickedLatLng.lat.toFixed(2)}, Longitud:{' '}
          {clickedLatLng.lng.toFixed(2)}
        </p>
      )}
      {loading && (
        <div style={{ marginBottom: 16 }}>
          <Progress percent={Math.round(progress)} />
        </div>
      )}
      <Tabs defaultActiveKey='1' items={tabItems} />
    </Modal>
  );
};

export default ModalWeatherForecast;
