import { memo, useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';

import { useScreen } from '../../../../lib/screen';
import {
  downloadReportValueOffer,
  getStatusReport,
  getValueOffer,
} from '../../../../services/valueOffer';

import Title from '../../../../common/Typograph/Title';
import Topbar from '../../../../common/ui/Topbar';
import ListValueOffer from './TableValueOffer';
import FormValueOffer from './FormValueOffer';
import Tabs from '../../../../common/Tabs';
import {
  RideButton,
  RideGlDownloadSolid,
} from '@rimac-seguros/ride-system-components';

const getTabs = (openOpenForm) => [
  {
    key: 'renovation',
    label: 'OV por Renovación',
    children: (
      <ListValueOffer
        type='renovation'
        isValueOfferRenew={true}
        openOpenForm={openOpenForm}
      />
    ),
  },
  {
    key: 'prospectus',
    label: 'OV por Prospecto',
    children: (
      <ListValueOffer
        type='prospectus'
        isValueOfferRenew={false}
        openOpenForm={openOpenForm}
      />
    ),
  },
];

const ValueOffer = () => {
  const dispatch = useDispatch();
  const { isMobileScreen } = useScreen();
  const [activeTab, setActiveTab] = useState(getTabs()[0]);
  const [openForm, setOpenForm] = useState(null);
  const [loadingReport, setLoadingReport] = useState(false);

  useEffect(() => {
    getValueOffer(dispatch, { type: activeTab?.key });
  }, [activeTab]);

  const handleToogleForm = (action) =>
    setOpenForm((prev) => (prev ? null : action));

  const _handleDownloadAll = useCallback(async () => {
    try {
      setLoadingReport(true);
      const status = await downloadReportValueOffer(activeTab);
      if (!status) {
        throw new Error();
      }
      const config = await getStatusReport();
      if (!config) {
        throw new Error();
      }
      if (config.error) {
        throw new Error('Problemas con la descarga');
      }
      if (config.fileDownloadPath) {
        notification.success({
          message: 'Reporte generado correctamente.',
        });
        window.open(`${config.fileDownloadPath}`, '_blank');
      }
    } catch (error) {
      notification.error({
        message: error.message || 'Ha corrido un error',
      });
    } finally {
      setLoadingReport(false);
    }
  }, [activeTab]);

  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />
      {openForm && (
        <FormValueOffer
          initialData={openForm}
          isValueOfferRenew={activeTab.key === 'renovation'}
          onBack={handleToogleForm}
          type={activeTab.key}
        />
      )}
      <div className={`d_content ${openForm ? 'hidden' : 'block'}`}>
        <div className='flex items-center justify-between mb-2'>
          <div className='flex justify-between gap-2'>
            <Title className='header-container'>Oferta de valor</Title>
          </div>
          {!isMobileScreen && (
            <div className='flex gap-4'>
              <RideButton
                variant='text-primary'
                size='medium'
                onClick={_handleDownloadAll}
                disabled={loadingReport}
                text='Descargar'
                glyph={<RideGlDownloadSolid />}
                glyphPosition='left'
              />
              <RideButton
                variant='fill'
                size='medium'
                onClick={() => {
                  handleToogleForm({});
                }}
                text='Crear'
              />
            </div>
          )}
        </div>
        <Tabs
          activeKey={activeTab.key}
          style={isMobileScreen ? { marginTop: 40 } : {}}
          onChange={(activeKey) =>
            setActiveTab(
              getTabs(handleToogleForm).find((el) => el.key === activeKey),
            )
          }
          items={getTabs(handleToogleForm)}
        />
        {isMobileScreen && (
          <div
            className={'fixed-bottom full-width flex gap-2 flex-col'}
            style={{
              background:
                'linear-gradient(to bottom, rgba(255,255,255,0) 0%, #EDEFFC 100%)',
            }}
          >
            <RideButton
              variant='text-primary'
              size='medium'
              onClick={_handleDownloadAll}
              disabled={loadingReport}
              className='full-width'
              text='Descargar'
              glyph={<RideGlDownloadSolid />}
              glyphPosition='left'
            />
            <RideButton
              variant='fill'
              size='medium'
              className='full-width'
              onClick={() => {
                handleToogleForm({});
              }}
              text='Crear'
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ValueOffer);
