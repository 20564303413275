import React from 'react';
import Legend from '../Legend';

const TemperatureLegend = ({ toggleVisibility }) => {
  return (
    <Legend
      title='Temperatura a dos metros en °C'
      numbers={[-30, -25, -20, -15, -10, -5, 0, 5, 10, 15, 20, 25, 30, 35, 40]}
      descriptions={['Frío extremo', 'Calor extremo']}
      toggleVisibility={toggleVisibility}
      type='temperature'
    />
  );
};

export default TemperatureLegend;
