import {
  LOADING_DOWNLOAD_EMERGENCY,
  LOADING_DOWNLOAD_ROAD_EMERGENCY,
  LOADING_RPT_DASHBOARD_USERS,
  LOADING_RPT_DASHBOARD_USERS_FAILED,
  LOADING_RPT_QUIZZES_FAILED,
} from '../redux/actions/actionsType';
import { get, post } from '../lib/ajax';

import {
  handleSuccessResponseDownloadEmergencies,
  handleRoadEmergencyResponse,
  handleDashboardUsersResponse,
  handleDashboardCompanyResponse,
} from './dashboard.utils';

export const getDataDashboard = async (data) => {
  const r = await post('/dashboard/getInitData', data);
  if (r?.data?.success) {
    return r.data.payload;
  }

  return null;
};

export const getDataProvincesDashboard = async (data) => {
  const r = await post('/dashboard/getProvincesData', data);
  if (r?.data?.success) {
    return r.data.payload;
  }

  return null;
};

export const getInfoReportOperative = async (data) => {
  const r = await post('/dashboard/getInfoReportOperativeAllpolizas', data);
  if (r?.data?.success) {
    return r.data.payload;
  } else {
    return false;
  }
};

export const getInfoReportOperativeAllPolizas = async () => {
  const r = await post('/dashboard/getInfoReportOperativeAllpolizas');
  if (r?.data?.success) {
    return r.data.payload;
  } else {
    return false;
  }
};

export const downloadEmergencies = async (dispatch, params = {}) => {
  dispatch({ type: LOADING_DOWNLOAD_EMERGENCY, payload: true });
  let r = await post('/dashboard/downloadEmergencies', params);
  if (r.data && r?.data?.success) {
    handleSuccessResponseDownloadEmergencies(r, dispatch);
  } else {
    dispatch({ type: LOADING_RPT_QUIZZES_FAILED });
  }
};

export const downloadRoadEmergencies = async (dispatch, params = {}) => {
  dispatch({ type: LOADING_DOWNLOAD_ROAD_EMERGENCY, payload: true });
  let r = await post('/dashboard/downloadRoadEmergencies', params);
  if (r.data && r?.data?.success) {
    handleRoadEmergencyResponse(r, dispatch);
  } else {
    dispatch({ type: LOADING_RPT_QUIZZES_FAILED });
  }
};

export const reportDashboardUsers = async (dispatch, params = {}) => {
  dispatch({ type: LOADING_RPT_DASHBOARD_USERS, payload: true });
  let r = await post('/dashboard/reportUsers', params);
  if (r.data && r?.data?.success) {
    handleDashboardUsersResponse(r, dispatch);
  } else {
    dispatch({ type: LOADING_RPT_DASHBOARD_USERS_FAILED });
  }
};

export const reportDashboardCompany = async (dispatch, params = {}) => {
  dispatch({ type: LOADING_RPT_DASHBOARD_USERS, payload: true });
  let r = await post('/dashboard/reportCompany', params);
  if (r.data && r?.data?.success) {
    handleDashboardCompanyResponse(r, dispatch);
  } else {
    dispatch({ type: LOADING_RPT_DASHBOARD_USERS_FAILED });
  }
};

export const reportMonitoring = async () => {
  const r = await get('/dashboard/reportMonitoring');
  if (r?.data?.success) {
    return r.data.payload;
  }

  return null;
};

export const reportProfileCompany = async () => {
  const r = await get('/dashboard/reportProfileCompany');
  if (r?.data?.success) {
    return r.data.payload;
  }

  return null;
};

export const reportServicesRanking = async () => {
  try {
    const r = await get('/dashboard/reportServicesRanking');
    if (r?.data?.success) {
      return r.data.payload;
    } else {
      return null;
    }
  } catch (e) {
    return false;
  }
};
