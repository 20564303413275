import { Loader } from '@googlemaps/js-api-loader';

export const loadGoogleApi = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  version: 'weekly',
  libraries: ['places', 'elevation'],
});

export const getElevation = async (path) => {
  await loadGoogleApi.importLibrary('elevation');

  return new Promise((resolve) => {
    const elevator = new google.maps.ElevationService();
    elevator.getElevationAlongPath(
      {
        path: path.map((p) => ({ lat: p[0], lng: p[1] })),
        samples: 256,
      },
      function (elevations) {
        const geojson = {
          name: 'NewFeatureType',
          type: 'FeatureCollection',
          properties: {
            records: 2,
            summary: ' ',
          },
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: [],
              },
              properties: {
                label: 'Linea',
                attributeType: 'Altitud',
              },
            },
          ],
        };

        geojson.features[0].geometry.coordinates = [];
        if (elevations && elevations.length > 0) {
          for (let i = 0; i < elevations.length; i++) {
            const data = [
              parseFloat(elevations[i].location.lng().toFixed(6)),
              parseFloat(elevations[i].location.lat().toFixed(6)),
              parseFloat(elevations[i].elevation.toFixed(0)),
            ];
            geojson.features[0].geometry.coordinates[i] = data;
          }
          resolve({ ...geojson });
        } else {
          resolve(false);
        }
      },
    );
  });
};
