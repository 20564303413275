import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';

import FlashAlerts from './FlashAlerts';
import TemperatureAlerts from './TemperatureAlerts';
import WindsAlerts from './WindsAlerts';
import RainAlerts from './RainAlerts';
import CardCollapse from './CardCollapse';
import { getForecastConfigById } from '../../../services/locations';

const { Title } = Typography;

const DATA_BY_LOCATION = [
  { title: 'Lluvias', type: 'rains', component: RainAlerts },
  { title: 'Vientos', type: 'winds', component: WindsAlerts },
  { title: 'Temperatura', type: 'temperature', component: TemperatureAlerts },
  { title: 'Rayos', type: 'flash', component: FlashAlerts },
];

const Alerts = ({ locationId, favoritesLocations }) => {
  const [dataLocation, setDataLocation] = useState([]);
  const favoriteLocation = favoritesLocations?.find(
    (el) => el.id === locationId,
  );

  useEffect(() => {
    const fetchData = async () => {
      const data = await getForecastConfigById(locationId);
      setDataLocation(data);
    };
    fetchData();
  }, [locationId]);

  const handleUpdatedData = (type, updatedData) => {
    setDataLocation((prevData) => {
      const newData = [...prevData];
      const index = newData.findIndex((el) => el.type === type);
      if (index !== -1) {
        newData[index] = { ...newData[index], ...updatedData };
      } else {
        newData.push({ type, ...updatedData });
      }
      return newData;
    });
  };

  return (
    <>
      {favoriteLocation && <Title level={4}>{favoriteLocation.name}</Title>}
      <div className='card-container'>
        {DATA_BY_LOCATION.map((el) => {
          const data = dataLocation.find((e) => e.type === el.type);
          const Component = el.component;
          return (
            <CardCollapse
              key={el.type}
              dataLocation={dataLocation}
              type={el.type}
              title={el.title}
            >
              <Component
                data={data}
                idLocation={locationId}
                favoritesLocations={favoritesLocations}
                onUpdatedData={handleUpdatedData}
              />
            </CardCollapse>
          );
        })}
      </div>
    </>
  );
};

export default Alerts;
