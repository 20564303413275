import { useEffect } from 'react';
import { Typography, Modal } from 'antd';
const { Text } = Typography;

const ModalConfirm = ({
  visible,
  title,
  actionsContent,
  content,
  className,
  width,
  onClose,
  destroyOnClose = false,
  modalProps = {},
}) => {
  useEffect(() => {
    const elements = document.querySelectorAll('.ant-popover');
    if (elements.length > 0) {
      elements.forEach((element) => {
        element.classList.add('ant-popover-hidden');
      });
    }
  }, [visible]);

  return (
    <Modal
      width={width || 554}
      zIndex={2000}
      className={`modal-confirm ${className}`}
      centered
      footer={<></>}
      closable
      onCancel={onClose}
      open={visible}
      destroyOnClose={destroyOnClose}
      {...modalProps}
    >
      <div className='flex flex-col'>
        <Text
          className='confirm-modal-title mt-1 mb-2'
          style={{ textAlign: 'center' }}
        >
          {title}
        </Text>
        {content}
        {actionsContent}
      </div>
    </Modal>
  );
};

export default ModalConfirm;
