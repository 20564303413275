import { createRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import aws_exports from './lib/aws-configure.js';
import { LOG_IN_LOADING } from './redux/actions/actionsType.js';
import { getUserAuthenticated } from './services/users.js';
import { Provider } from 'react-redux';
import App from './App.jsx';
import store from './redux/store.js';
import './index.css';

Amplify.configure(aws_exports);

store.dispatch({ type: LOG_IN_LOADING, payload: true });

getUserAuthenticated(store.dispatch);

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "'BR Sonoma', 'Lato', 'sans-serif'",
          },
        }}
      >
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </Provider>,
);
