import { SET_FAVORITES } from '../actions/actionsType';

const initialState = {
  favorites: [],
};

const favorites = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case SET_FAVORITES:
      return {
        ...state,
        list: action.payload,
      };
    default:
      return state;
  }
};

export default favorites;
