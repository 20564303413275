import React, { useState, useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import { Switch } from 'antd';
import 'leaflet.nontiledlayer';

const wmsUrl = process.env.REACT_APP_GEOSERVER_MAPS_NEW;

const Roads = () => {
  const [activeLayers, setActiveLayers] = useState({
    Vias_departamentales: false,
    Vias_nacionales: false,
    Vias_vecinales: false,
  });
  const map = useMap();
  const layerRefs = useRef({});

  useEffect(() => {
    const removeCurrentLayers = () => {
      Object.keys(layerRefs.current).forEach((key) => {
        if (layerRefs.current[key]) {
          map.removeLayer(layerRefs.current[key]);
          delete layerRefs.current[key];
        }
      });
    };

    const selectLayers = () => {
      return Object.keys(activeLayers).filter((key) => activeLayers[key]);
    };

    removeCurrentLayers();

    const layers = selectLayers();
    layers.forEach((layerName) => {
      const staticLayer = L.nonTiledLayer.wms(wmsUrl, {
        layers: layerName,
        styles: '',
        version: '1.3.0',
        format: 'image/png',
        tileSize: 256,
        transparent: true,
        tiled: true,
      });

      layerRefs.current[layerName] = staticLayer.addTo(map);
    });

    return () => {
      removeCurrentLayers();
    };
  }, [map, activeLayers, wmsUrl]);

  const toggleLayer = (layer) => {
    setActiveLayers((prev) => ({
      ...prev,
      [layer]: !prev[layer],
    }));
  };

  return (
    <div>
      <div>
        <label>Vias Departamentales: </label>
        <Switch
          checked={activeLayers.ViasDepartamentales}
          onChange={() => toggleLayer('Vias_departamentales')}
        />
      </div>
      <div>
        <label>Vias Nacionales: </label>
        <Switch
          checked={activeLayers.ViasNacionales}
          onChange={() => toggleLayer('Vias_nacionales')}
        />
      </div>
      <div>
        <label>Vias Vecinales: </label>
        <Switch
          checked={activeLayers.ViasVecinales}
          onChange={() => toggleLayer('Vias_vecinales')}
        />
      </div>
    </div>
  );
};

export default Roads;
