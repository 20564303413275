import { post } from '../lib/ajax';
import axios from 'axios';
import { getMyRequestServicesItems } from './requestServicesItems';
import {
  CHANGE_REQUEST_SERVICES_ITEMS_RESULTS_FILTERS,
  CHANGE_REQUEST_SERVICES_ITEMS_RESULTS_QUERY,
  LOADED_REQUEST_SERVICES_ITEMS_RESULTS,
} from '../redux/actions/actionsType';

export const addResult = async (
  dispatch,
  data,
  requestServiceItemId,
  pagination,
) => {
  const r = await post(
    `/request_service_items_results/add_result?requestServiceItemId=${requestServiceItemId}`,
    data,
  );
  if (r?.data?.success && pagination) {
    getMyRequestServicesItems(dispatch, pagination);

    return true;
  } else {
    return false;
  }
};

export const getRequestServicesItemsResults = async (dispatch, data = {}) => {
  dispatch({
    type: CHANGE_REQUEST_SERVICES_ITEMS_RESULTS_QUERY,
    payload: data.query,
  });
  dispatch({
    type: CHANGE_REQUEST_SERVICES_ITEMS_RESULTS_FILTERS,
    payload: data.filters,
  });
  const r = await post('/request_service_items_results/list', data);
  if (r?.data?.success)
    dispatch({
      type: LOADED_REQUEST_SERVICES_ITEMS_RESULTS,
      payload: r.data.payload,
    });
};

export const getRequestServicesItemsResultsClient = async (
  dispatch,
  data = {},
) => {
  dispatch({
    type: CHANGE_REQUEST_SERVICES_ITEMS_RESULTS_QUERY,
    payload: data.query,
  });
  dispatch({
    type: CHANGE_REQUEST_SERVICES_ITEMS_RESULTS_FILTERS,
    payload: data.filters,
  });
  const r = await post('/request_service_items_results/listClient', data);
  if (r?.data?.success)
    dispatch({
      type: LOADED_REQUEST_SERVICES_ITEMS_RESULTS,
      payload: r.data.payload,
    });
};

export const getPresignedUrl = (data) => {
  const payload = {
    type: data.type,
    name: data.name,
  };

  return post('/request_service_items_results/get_url_to_import', payload);
};

export const sendFileTecnicalReport = (url, data, handleProgress) =>
  axios.put(url, data, {
    onUploadProgress: handleProgress,
  });
